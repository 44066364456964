export const FETCH_ATTEMPTEDTEST_LIST_ACTION_PENDING = 'FETCH_ATTEMPTEDTEST_LIST_ACTION_PENDING';
export const FETCH_ATTEMPTEDTEST_LIST_ACTION_SUCCESS = 'FETCH_ATTEMPTEDTEST_LIST_ACTION_SUCCESS';
export const FETCH_ATTEMPTEDTEST_LIST_ACTION_ERROR = 'FETCH_ATTEMPTEDTEST_LIST_ACTION_ERROR';

export function fetchattemptedListPending() {
    return {
        type: FETCH_ATTEMPTEDTEST_LIST_ACTION_PENDING,
    }
}

export function fetchattemptedListSuccess(attemptedList) {

    return {
        type: FETCH_ATTEMPTEDTEST_LIST_ACTION_SUCCESS,
        payload: attemptedList
    }
}

export function fetchattemptedListError(error) {
    return {
        type: FETCH_ATTEMPTEDTEST_LIST_ACTION_ERROR,
        error: error
    }
}