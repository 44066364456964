import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";

import {
  success,
  warning,
  successNotification,
  error,
  errorNotification,
  warningNotification
} from "../notification/notifications";

import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import {
  getCalenderEvents,
  getCalenderEventsPending
} from "../../reducer/calenderEventsReducer";
import {
  getAdminUser,
  getAdminUserPending
} from "../../reducer/adminUserReducer";
import { bindActionCreators } from "redux";
import { fetchAdminUser } from "../../services/admin.service";
import { ProductService } from "../../services/product.service";
//import AdminListLandingPage from "../../components/admin/adminList";
import { LoginService } from "../../services/login.service";
import { getPersonnelAddressData } from "../../reducer/userProfileReducer";
import { RegisterUserService } from "../../services/registration.service";
import { fetchRoles } from "../../services/fetchRoles";
import { getRoles } from "../../reducer/roleReducer";
import AdminHeader from "../../components/dashboardheader/adminheader";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import $ from "jquery";
import {
  getAlertNotificationEvents,
  getAlertNotificationEventsPending,
  getAlertNotificationEventsError
} from "../../reducer/alertsNotificationReducer";
import {
  getNotificationEvents,
  getNotificationEventsPending,
  getNotificationEventsError
} from "../../reducer/notificationReducer";
import {
  fetchAlertNotificationEvents,
  fetchNotificationEvents,
  fetchProductDetailsByPartyId
} from "../../services/fetchLearnerDashboard.service";

class AdminAddUserCourse extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    //alert("Userparty ID "+ this.props.history.location.state.role.partyID)
    if (localStorage.getItem("login") !== null) {
      this.state = {
        today: moment(todaydate).format("YYYY-MM-DD"),
        roleType: "",
        roleId: "",
        role: "",
        learnerID: "",
        loading: false,
        userpartyid: this.props.history.location.state.role.partyID,
        myselectedproducts: [],
        myProducts: [],
        filtered: [],
        mynonselectedproducts: []
      };
    } else {
      window.location.href = "/";
    }
  }

  handleCancel = () => {
    this.props.history.goBack('-1');
    //this.props.history.push("/admin-user-list");
  };

  addcoursestousers = () => {
    var selectedproducts = [];
    var CheckoutArray = [];

    $(".dual-list.list-right ul.list-group li").each(function () {
      if (!$(this).hasClass("disabled")) {
        //alert($(this).find('span.selectedprodid').text())
        selectedproducts.push(
          $(this)
            .find("span.selectedprodid")
            .text()
        );
      }
    });
    if (selectedproducts != '') {
      selectedproducts.forEach(element => {
        let CheckoutObj = {
          learnerPartyID: this.props.history.location.state.role.partyID,
          productID: element,
          productName: element,
          paymentID: 0,
          firstName: this.props.history.location.state.role.name,
          middleName: this.props.history.location.state.role.name,
          lastName: this.props.history.location.state.role.name,
          gender: 1,
          emailID: this.props.history.location.state.role.emailId,
          mobileNo: this.props.history.location.state.role.mobileNumber,
          isActive: 1,
          contactNo: "",
          dob: moment(new Date()).format("DD/MM/YYYY HH:mm:ss")
        };
        CheckoutArray.push(CheckoutObj);
      });
      RegisterUserService.AHRegisterUserBulk(
        CheckoutArray,
        res => {
          if (res.status === "success") {
            success("You have successfully added Product for Learner  ", successNotification);
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else {
            error(res.reasonText, errorNotification);
          }
        },
        error => {
          console.log(error);
        }
      );
    } else {
      warning('Please moved product first into the selected list before clicking Add Courses', warningNotification);
    }
  };

  componentWillMount() {
    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
    });
  }

  componentDidMount() {
    var mymappedProductId = [];
    //fetchProductDetailsByPartyId
    let partyID = "learnerPartyId=" + this.state.userpartyid;
    ProductService.fetchProductDetailsByPartyId(
      partyID,
      res => {
        if (res != null && res.responseListObject != null) {
          this.setState({ myProducts: res.responseListObject });
          this.state.myProducts.map(item => {
            mymappedProductId.push(item.productEntity.productID);
            let showmyproductdata = {
              productId: item.productEntity.productID,
              productName: item.productEntity.productName,
              paymentID: item.paymentID,
              isActive: item.productEntity.isActive
            };

            this.setState({
              // myselectedproducts : this.state.myselectedproducts.push(showmyproductdata)
              myselectedproducts: [...this.state.myselectedproducts, showmyproductdata]
            })
          });

          /*if(mymappedProductId.length === 0) {
        let showmyproductdata = {
          productId : 99999999,
          productName : 'Test',
          paymentID:1
        };
         mymappedProductId.push('0'); 
        }*/
          console.log(
            "State mapped products :",
            ...this.state.myselectedproducts
          );
          if (mymappedProductId.length > 0) {
            ProductService.fetchProductAllExceptMappedProduct(
              mymappedProductId,
              res => {
                console.log("UnMapped Products :" + JSON.stringify(res));
                if (res.status === "success" && res.reasonCode !== "SM-20") {
                  res.responseListObject.map(item => {
                    let showmynonproductdata = {
                      productId: item.productID,
                      productName: item.productName,
                      paymentID: 0,
                      isActive: item.isActive
                    };

                    this.setState({
                      //mynonselectedproducts : this.state.mynonselectedproducts.push(showmynonproductdata)
                      mynonselectedproducts: [
                        ...this.state.mynonselectedproducts,
                        showmynonproductdata
                      ]
                    });
                  });

                  // console.log(
                  //   "***********  Unmapped Products *******" +
                  //   JSON.stringify(...this.state.mynonselectedproducts)
                  // );
                } else {
                  if (res.reasonCode !== "SM-20") {
                    error(res.reasonText, errorNotification);
                  }
                }
              }
            );
          }

          // console.log(
          //   "***********  Mapped Products *******" +
          //   JSON.stringify(...this.state.myselectedproducts)
          // );
        } else {
          mymappedProductId.push(0);

          if (mymappedProductId.length > 0) {
            ProductService.fetchProductAllExceptMappedProduct(
              mymappedProductId,
              res => {
                console.log("UnMapped Products :" + JSON.stringify(res));
                if (res.status === "success") {
                  res.responseListObject.map(item => {
                    let showmynonproductdata = {
                      productId: item.productID,
                      productName: item.productName,
                      paymentID: 0,
                      isActive: item.isActive

                    };

                    this.setState({
                      //mynonselectedproducts : this.state.mynonselectedproducts.push(showmynonproductdata)
                      mynonselectedproducts: [
                        ...this.state.mynonselectedproducts,
                        showmynonproductdata
                      ]
                    });
                  });

                  // console.log(
                  //   "***********  Unmapped Products *******" +
                  //   JSON.stringify(...this.state.mynonselectedproducts)
                  // );
                } else {
                  error(res.reasonText, errorNotification);
                }
              }
            );
          }
        }
      },
      err => {
        console.log(err);
      }
    );

    // $("#topheader .tab a").on("click", function() {
    //   $("#topheader .tab")
    //     .find("li.active")
    //     .removeClass("active");
    //   $(this)
    //     .parent("li")
    //     .addClass("active");
    // });

    if (
      this.props.location.state.role.userRoleMatrixList[0].roleId.roleId == 5
    ) {
      this.setState({
        role: this.props.location.state.role.userRoleMatrixList[0].roleId
          .roleName,
        roleId: this.props.location.state.role.userRoleMatrixList[0].roleId
          .roleId,
        show: true
      });
    } else {
      this.setState({
        role: this.props.location.state.role.userRoleMatrixList[0].roleId
          .roleName,
        roleId: this.props.location.state.role.userRoleMatrixList[0].roleId
          .roleId
      });
    }

    //console.log("Role and Role ID : " + this.state.role + this.state.roleId);

    $(document).ready(function () {
      $(function () {

        $("body").on("click", ".list-group .list-group-item", function () {
          //alert(!($(this).hasClass('disabled')))
          if (!$(this).hasClass("disabled")) {
            $(this).toggleClass("active");
          }
        });
        $(".list-arrows button").click(function () {

          var $button = $(this),
            actives = "";
          if ($button.hasClass("move-left")) {
            actives = $(".list-right ul li.active");
            actives.clone().appendTo(".list-left ul");
            actives.remove();
            $(".list-left ul li").each(function () {
              if ($(this).hasClass("active")) {
                $(this).removeClass('active');
              }
            });
          } else if ($button.hasClass("move-right")) {
            actives = $(".list-left ul li.active");
            actives.clone().appendTo(".list-right ul");
            actives.remove();
            $(".list-right ul li").each(function () {
              if ($(this).hasClass("active")) {
                $(this).removeClass('active');
              }
            });
          }
        });
        $(".dual-list .selector").click(function () {
          var $checkBox = $(this);
          if (!$checkBox.hasClass("selected")) {
            $checkBox
              .addClass("selected")
              .closest(".well")
              .find("ul li:not(.active)")
              .addClass("active");
            $checkBox
              .children("i")
              .removeClass("glyphicon-unchecked")
              .addClass("glyphicon-check");
          } else {
            $checkBox
              .removeClass("selected")
              .closest(".well")
              .find("ul li.active")
              .removeClass("active");
            $checkBox
              .children("i")
              .removeClass("glyphicon-check")
              .addClass("glyphicon-unchecked");
          }
        });
        $('[name="SearchDualList"]').keyup(function (e) {
          var code = e.keyCode || e.which;
          if (code == "9") return;
          if (code == "27") $(this).val(null);
          var $rows = $(this)
            .closest(".dual-list")
            .find(".list-group li");
          var val = $.trim($(this).val())
            .replace(/ +/g, " ")
            .toLowerCase();
          $rows
            .show()
            .filter(function () {
              var text = $(this)
                .text()
                .replace(/\s+/g, " ")
                .toLowerCase();
              return !~text.indexOf(val);
            })
            .hide();
        });
      });
    });
  }
  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(value.toLowerCase()) : true
  }

  render() {
    var listdisabled = "";
    const {
      adminUser,
      adminUserPending,
      fetchUserAddressData,
      alertNotificationEvents,
      pendingAlertNotificationEvents,
      notificationEvents,
      pendingNotificationEvents
    } = this.props;
    //console.log("adminUserPending", this.props.adminUser);
    if (
      !adminUserPending &&
      !pendingNotificationEvents &&
      !pendingAlertNotificationEvents
    ) {
      return (
        <React.Fragment>
          <AdminHeader
            //alertNotificationEvents={this.props.alertNotificationEvents}
            //notificationEvents={this.props.notificationEvents}
            adminUser={this.props.adminUser}
            {...this.props}
          />
          <section class="dashboard-tab user-tab pt-50 mb-50">
            <div className="container">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6 learnerName">
                  <span class="left-icon">
                    <p> Learner Name : <b> {this.props.history.location.state.role.name + " " + (this.props.history.location.state.role.lastName ? this.props.history.location.state.role.lastName : '')}</b></p>
                  </span>{" "}
                </div>
              </div>
              <div className="add-course">
                <div className="row">
                  <div className="dual-list list-left col-md-5 col-sm-12">
                    <h3 class="learner-title text-center mb-10">Select Course</h3>
                    <div className="well text-right">

                      <div className="row">

                        <div className="col-md-10">

                          <div className="input-group">
                            <span className="input-group-addon glyphicon glyphicon-search"></span>
                            <input
                              type="text"
                              name="SearchDualList"
                              className="form-control"
                              placeholder="search"
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="btn-group">
                            <a
                              className="btn btn-default selector"
                              title="select all"
                            >
                              <i className="glyphicon glyphicon-unchecked"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <ul className="list-group">
                        {this.state.mynonselectedproducts != null
                          ? this.state.mynonselectedproducts.map(item => {
                            return (
                              //item.isActive ?
                              <li className="list-group-item">
                                {item.productName}
                                <span className="selectedprodid">
                                  {item.productId}
                                </span>
                              </li> //: ''
                            );
                          })
                          : ""}
                      </ul>
                    </div>
                  </div>

                  <div className="list-arrows col-md-2 text-center">
                    <button className="btn btn-default btn-sm move-left">
                      <span className="glyphicon glyphicon-chevron-left"></span>
                    </button>

                    <button className="btn btn-default btn-sm move-right">
                      <span className="glyphicon glyphicon-chevron-right"></span>
                    </button>
                  </div>

                  <div className="dual-list list-right col-md-5 col-sm-12">
                    <h3 class="learner-title text-center mb-10">My Courses</h3>
                    <div className="well">
                      <div className="row">

                        <div className="col-md-2">
                          <div className="btn-group">
                            <a
                              className="btn btn-default selector"
                              title="select all"
                            >
                              <i className="glyphicon glyphicon-unchecked"></i>
                            </a>
                          </div>
                        </div>
                        <div className="col-md-10">

                          <div className="input-group">
                            <input
                              type="text"
                              name="SearchDualList"
                              className="form-control"
                              placeholder="search"
                            />
                            <span className="input-group-addon glyphicon glyphicon-search"></span>
                          </div>
                        </div>
                      </div>
                      <ul className="list-group">
                        {this.state.myselectedproducts != null
                          ? this.state.myselectedproducts.map(item => {
                            return item.paymentID > 0 ? (
                              <li className="disabled list-group-item">
                                {item.productName}
                                <span className="selectedprodid">
                                  {item.productId}
                                </span>
                              </li>
                            ) : (
                                <li className="list-group-item">
                                  {item.productName}
                                  <span className="selectedprodid">
                                    {item.productId}
                                  </span>
                                </li>
                              );
                          })
                          : ""}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <button className="btn-5 mr-20 ml-20 mt-10 pull-right" onClick={this.handleCancel}><span aria-hidden="true">Cancel</span></button>
                  <button className="btn-5 mr-20 ml-20 mt-10 pull-right" onClick={this.addcoursestousers}><span aria-hidden="true">Add Courses</span></button>
                </div>
              </div>
            </div>


            <section class="dashboard-tab user-tab pt-50 mb-50">
              <div class="padding-rl-50">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              Add Course List
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <div class="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.myProducts}
                            filtered={this.state.filtered}
                            onFilteredChange={filtered => {
                              this.setState({
                                filtered: filtered,
                                griddata: this.state.myProducts
                              });
                            }}
                            className="-striped -highlight"
                            filterable
                            onPageChange={this.stateonpagechange}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            ref={refReactTable => {
                              this.refReactTable = refReactTable;
                            }}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "Product ID",
                                    id: "productID",
                                    accessor: d => d.productEntity.productID
                                  },
                                  {
                                    Header: "Product Name",
                                    id: "productName",
                                    accessor: d => d.productEntity.productName
                                  },
                                  {
                                    Header: "Assigned Date",
                                    id: "createdDate",
                                    accessor: d =>
                                      d.createdDate
                                        ? d.createdDate.split(" ")[0]
                                        : " "
                                  },
                                  {
                                    Header: "Product Price",
                                    id: "productPrice",
                                    accessor: d =>
                                      (new Intl.NumberFormat('en-IN', {
                                        style: 'currency',
                                        currency: 'INR'
                                      }).format(d.productEntity.productPrice) || '').toString()
                                  },
                                  {
                                    Header: "Payment ID",
                                    id: "paymentID",
                                    accessor: d => d.paymentID
                                  },
                                  {
                                    Header: "Payment Status",
                                    id: "paymentStatus",
                                    accessor: d =>
                                      d.paymentID > 0 ? "Completed" : "Pending"
                                  },
                                  {
                                    Header: "Final Status",
                                    id: "isScoreUpdated",
                                    accessor: d =>
                                      d.isScoreUpdated == 0
                                        ? "Pending"
                                        : d.isScoreUpdated === 1
                                          ? "Score Available"
                                          : d.isScoreUpdated === 2
                                            ? "Report Available"
                                            : ""
                                  }
                                ]
                              }
                            ]}
                          ></ReactTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          >
          <Finxfooter />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />

          <AdminHeader />

          <Finxfooter />
        </React.Fragment>
      );
    }
  }
}
export default AdminAddUserCourse;
