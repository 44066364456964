// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { Formik, Field, Form } from "formik";

import * as Yup from "yup";

class ViewEnquiry extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    //console.log(this.props.enquiry);
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={this.props.enquiry}
          validationSchema={Yup.object().shape({})}
          render={() => (
            <React.Fragment>
              <div
                className="modal fade"
                id="enquiry"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
                   data-keyboard="true"
              >
                <div class="modal-dialog modal-md" role="document">
                 <Form>
                  <div class="modal-content">
                  <button
                                type="reset"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                    <div class="checkout">
                      <div class="modal-header"></div>
                      <div class="modal-body">
                        <div class="bg-box">
                          <div class="payment-detail">
                            <h2 class="sub-header">Enquiry Details</h2>
                            <div class="table-responsive">
                              <table class="paydetail-table">
                                <tr>
                                  <td>Name</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.fullName}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mobile No</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.mobileNumber}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.emailID}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Message</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.enquiryMessage}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Status</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.isResolved}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Resolved On</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.ResolvedDate}</b>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Form>
                </div>
              </div>
             
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default ViewEnquiry;
