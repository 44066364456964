import {
  FETCH_LEARNER_PROGRESS_OVERVIEW_PENDING,
  FETCH_LEARNER_PROGRESS_OVERVIEW,
  FETCH_LEARNER_PROGRESS_OVERVIEW_ERROR
} from "../action/getLearnerProgressOverviewAction";

const initialState = {
  pending: false,
  learnersProgressOverview: [],
  error: null
};

const learnersProgressOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEARNER_PROGRESS_OVERVIEW_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_LEARNER_PROGRESS_OVERVIEW:
      return {
        ...state,
        pending: false,
        err: false,
        learnersProgressOverview: action.payload
      };
    case FETCH_LEARNER_PROGRESS_OVERVIEW_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default learnersProgressOverviewReducer;

export const getLearnersProgressOverview = state =>  state.learnersProgressOverview;
export const getLearnersProgressOverviewPending = state => state.pending;
export const getLearnersProgressOverviewError = state => state.error;
