import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginService } from "../../services/login.service";
import { EnrollmentService } from "../../services/enrollmentservice";
import * as Yup from "yup";
import * as moment from "moment";
import { ExcelService } from "../Excel/excel";

export class myEnrollment extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      data: this.props.learnerEnrollmentHistoryData,
      today: moment(todaydate).format("YYYY-MM-DD"),
    };
    this.showAll = this.showAll.bind(this);
  }

  showAll() {
    this.setState({ data: this.props.learnerEnrollmentHistoryData });
  }

  exportToCSV = () => {

    let modifiedData = [];
    let data = this.state.data;

    data.forEach(element => {
      var data1 = {
        Learner_ID: element.learnerID,
        Full_Name: element.firstName + " " + element.lastName,
        Email_ID: element.emailID,
        Mobile_No: element.mobileNo,
        Gender: element.gender === 2 ? 'Female' : (element.gender === 1 ? 'Male' : (element.gender === 1 ? 'Other' : ' ')),
        Source: element.entryMode === 1 ? 'ExpertMFD' : element.entryMode === 2 ? 'Bulk Upload' : element.entryMode === 3 ? 'Dap Website' : 'Self Registration',
        Created_Date: (element.createdDate || "").split(" ")[0],

      }
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, 'Enrollment_Report-');
  };

  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(value.toLowerCase()) : true
  }

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          enrollmentdata: "",
          paymentStatus: "",
          startDate: "",
          endDate: ""
        }}
        validationSchema={Yup.object().shape({
          startDate: Yup.string(), //.required("You must specify the start date"),

          endDate: Yup.lazy(key => {
            return Yup.string()
              .default(() => "")
            //.required("You must specify the end date")
            // .when("startDate", (startDate, schema) => {
            //   return schema.test({
            //     test: EndIntensity => !!startDate && EndIntensity >= startDate,
            //     message: "End date should be greater than or equal to start date"
            //   });
            // });
          })
        })}

        onSubmit={(values) => {
          this.setState({ loading: true });
          LoginService.TokenUser(res => {
            localStorage.setItem("jwt-token", res.responseObject);
            let data = {
              startDate: values.startDate
                ? moment(values.startDate).format("DD/MM/YYYY")
                : values.endDate
                  ? moment(new Date()).format("DD/MM/YYYY")
                  : "",
              endDate: values.endDate
                ? moment(values.endDate).format("DD/MM/YYYY")
                : values.startDate
                  ? moment(new Date()).format("DD/MM/YYYY")
                  : "",
            };

            let params = "limit=-1&fromDate=" + data.startDate + "&toDate=" + data.endDate + "&orderDir=asc&orderColumn=createdDate"
            EnrollmentService.fetchEnrollment(params,
              res => {
                if (res.status === "success") {
                  //console.log("enrollresponse", res.responseListObject)
                  this.setState({
                    loading: false,
                    data: res.responseListObject,
                  })

                }
                else {
                  this.setState({
                    loading: false,
                    data: []
                  });
                }
              }
            );
          });
        }}
        render={({ handleSubmit, handleChange }) => (
          <React.Fragment>
            <section class="dashboard-tab user-tab pt-80 mb-50">
              <div class="padding-rl-50">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-8 col-sm-6 col-xs-8">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              My Enrolments
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-4">
                              <span class="right-icon">
                                <a>
                                  <i onClick={() => this.exportToCSV()}

                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <Form onSubmit={handleSubmit} className="">
                          <div class="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                    <h5 className="filter-heading">Search Criteria</h5>
                                  </div>
                                  <div className="row">
                                    {/* <div className="col-md-2 col-sm-6 col-xs-12">
                              <label>Status</label>
                              <select
                                name="paymentStatus"
                                class="form-control"
                                onChange={handleChange}
                              >
                                <option value="selected">Select Status</option>
                                <option value="Txn Success">In Progress</option>
                                <option value="TXN_FAILURE">Completed</option>
                                <option value="initiated">Dropped</option>
                              </select>
                            </div> */}
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Start Date</label>
                                      <Field
                                        type="date"
                                        name="startDate"
                                        max={this.state.today}
                                        id="startDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="startDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>End Date</label>
                                      <Field
                                        type="date"
                                        name="endDate"
                                        max={this.state.today}
                                        id="endDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="endDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 mt-40">
                                      <div className="row">

                                        <button className="btn-5 mr-20 ml-20" type="submit">
                                          Search
                                          </button>
                                        <button
                                          className="btn-5"
                                          type="reset"
                                          onClick={this.showAll}
                                        >
                                          Reset
                                       </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                        <div class="table-responsive">

                          <ReactTable
                            minRows={2}
                            data={this.state.data || []}
                            filterable
                            ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            defaultSorted={[
                              {
                                id: "lastModifiedDate",
                                desc: false
                              }
                            ]}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "Learner ID",
                                    id: "learnerID",
                                    accessor: d => (d.learnerID || '').toString()
                                  },

                                  {
                                    Header: "Full Name",
                                    id: "firstName",
                                    accessor: d => (d.firstName + " " + d.lastName || '').toString()
                                  },
                                  {
                                    Header: "Email ID",
                                    id: "emailID",
                                    accessor: d => d.emailID
                                  },
                                  {
                                    Header: "Mobile No",
                                    id: "mobileNo",
                                    accessor: d => d.mobileNo
                                  },
                                  {
                                    Header: "Gender",
                                    id: "gender",

                                    accessor: d => d.gender === 2
                                      ? 'Female'
                                      : d.gender === 1 ? 'Male' : d.gender === 3 ? 'Other' : ''
                                  },
                                  {
                                    Header: "Source",
                                    id: "entryMode",
                                    accessor: d => d.entryMode === 1 ? 'ExpertMFD' : d.entryMode === 2 ? 'Bulk Upload' : d.entryMode === 3 ? 'Dap Website' : 'Self Registration',

                                    //d.entryMode ? d.entryMode === 1 ? 'DAP' : d.entryMode === 2 ? 'Bulk Upload' : d.entryMode === 3 ? 'Dap Website' : 'Self Registration' : ' '
                                  },
                                  {
                                    Header: "Created Date",
                                    id: "createdDate",

                                    accessor: d => d.createdDate ? d.createdDate : ' '
                                  },

                                ]
                              }
                            ]}
                            defaultPageSize={10}

                            style={{
                              width: "100%",
                              maxHeight: "500px"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </ React.Fragment>
        )}
      />
    );
  }
}

export default myEnrollment;
