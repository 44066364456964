import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import * as moment from "moment";
import { bindActionCreators } from "redux";
import { LoginService } from "../../services/login.service";
import AdminHeader from "../../components/dashboardheader/adminheader";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import $ from "jquery";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer"
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchLearnerDashboard.service";
import SendEmailList from "../../components/sentemail/senteEmailList";
import { GeneralEnquiryService } from "../../services/generalenquiryService";
import { getLearnerEnquiry } from "../../reducer/learnerEnquiryReducer";

const mapStateToProps = state => ({
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
  learnerEnquiry: getLearnerEnquiry(state.learnerEnquiryReducer),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
      fetchLearnerEnquiry: GeneralEnquiryService.getEnquiry,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const adminList = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {
      const { fetchAlertNotificationEvents, fetchNotificationEvents, fetchLearnerEnquiry
      } = this.props;
      let partyID = localStorage.getItem("userid-Token");

      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          fetchAlertNotificationEvents();
          fetchNotificationEvents("partyId=" + partyID);
          //let params = "enquiryType=2"
          fetchLearnerEnquiry("enquiryType=2");
        }
      });
    }

    render() {
      const { alertNotificationEvents, pendingAlertNotificationEvents,
        notificationEvents, pendingNotificationEvents, learnerEnquiry
      } = this.props;
      //console.log("enq", this.props.learnerEnquiry)
      if (!pendingNotificationEvents && !pendingAlertNotificationEvents) {
        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={this.props.alertNotificationEvents}
              //notificationEvents={this.props.notificationEvents}
              {...this.props}

            />
            <SendEmailList
              learnerEnquiry={learnerEnquiry}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />

            <AdminHeader />
            <SendEmailList
              learnerEnquiry={learnerEnquiry}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }

    }
  }
);

export default adminList;
