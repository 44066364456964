import React from "react";
import progresscalander from "../../assets/images/calendar.png";
import MyCalander from "../learner-dashboard/mycalander";
{
  /*https://www.npmjs.com/package/react-calendar 
https://github.com/wojtekmaj/react-calendar/blob/master/sample/parcel/Sample.jsx*/
}
class AdminCalander extends React.Component {
  constructor(props) {
    super(props);
    //
    //console.log("In Calander", this.props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
          <h3 className="learner-title">My Events</h3>
          <div className="calendar-event">
            <MyCalander props={this.props.calenderEvents} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AdminCalander;
