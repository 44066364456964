export const FETCH_LEARNER_PENDING = 'FETCH_LEARNER_PENDING';
export const FETCH_LEARNER_SUCCESS = 'FETCH_LEARNER_SUCCESS';
export const FETCH_LEARNER_ERROR = 'FETCH_LEARNER_ERROR';

export function fetchLearnerPending() {
    
    return {
        type: FETCH_LEARNER_PENDING
    }
}

export function fetchLearnerSuccess(learners) {
    

    return {
        type: FETCH_LEARNER_SUCCESS,
        payload: learners
    }
}

export function fetchLearnerError(error) {
    
    return {
        type: FETCH_LEARNER_ERROR,
        error: error
    }
}