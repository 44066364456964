import React, { Component } from "react";
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  withFormik,
  FormikProvider
} from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import { addAdminUser } from "../../services/admin.service";
import FileBase64 from "react-file-base64";
import {
  success,
  successNotification,
  error,
  errorNotification
} from "../notification/notifications";
import { LoginService } from "../../services/login.service";
import { RegionManagementService } from "../../services/regionManagement.service";
import { RegisterUserService } from "../../services/registration.service";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import $ from "jquery";
import { actions } from "react-table";
import ReactPlayer from "react-player";
import { pascalCase } from "change-case";

class AdminProfileLandingPage extends Component {
  constructor(props) {
    super(props);
    console.log("props.......");
    console.log(props);
    var todaydate = new Date();
    if (localStorage.getItem("login") !== null) {
      this.state = {
        today: moment(todaydate).format("YYYY-MM-DD"),
        countryList: [],
        countryListForPer: [],
        employeTypeForParents: [],
        listOfYear: []
      };
    } else {
      window.location.href = "/";
    }
  }

  componentDidMount() {
    // $("#topheader .tab a").on("click", function() {
    //   $("#topheader .tab")
    //     .find("li.active")
    //     .removeClass("active");
    //   $(this)
    //     .parent("li")
    //     .addClass("active");
    // });

    // $("#root").on("change","select[name='employment_type_1']", function(e){
    // 	if(e.target.value == 6)
    // 	{

    // 	  $("input[name='company_name_1']").parent().hide()
    // 	}
    // 	else{
    // 	  $("input[name='company_name_1']").parent().show()
    // 	}

    // });

    // $("#root").on("change","select[name='employment_type_2']", function(e){
    // 	if(e.target.value == 6)
    // 	{

    // 	  $("input[name='company_name_2']").parent().hide()
    // 	}
    // 	else{
    // 	  $("input[name='company_name_2']").parent().show()
    // 	}

    // });

    window.addEventListener("scroll", e => onScroll(e), true);

    function onScroll(event) {
      var scrollPos = "";
      //var scrollPos = $("section.createusers").offset().top;

      var nav = $("section.createusers");
      if (nav.length) {
        scrollPos = $("section.createusers").offset().top;
      } else {
        scrollPos = "";
      }
      //console.log("hiiiiiiiii" + scrollPos)

      $("section.page-section").each(function () {
        var scrollid = $(this).attr("id");
        var scrollPos = $("section#" + scrollid).offset().top;
        if (scrollPos > 100 && scrollPos < 300) {
          console.log("Scroll position :" + scrollid + scrollPos);
          $("#topheader .tab li").removeClass("active");
          $("a[href='#" + [scrollid] + "']")
            .parent("li")
            .addClass("active");
        }
      });
    }
  }
  componentWillMount() {
    var year = new Date().getFullYear();
    var listOfYear = [];
    for (var i = 0; i < 20; i++) {
      var current = year - i;
      listOfYear.push(current);
      this.setState({ listOfYear: listOfYear });
    }
    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
    });

    RegionManagementService.getCountry(res => {
      this.setState({
        countryList: res.responseListObject,
        countryListForPer: res.responseListObject
      });
    });

    RegisterUserService.getEmployeType(res => {
      this.setState({
        employeTypeForParents: res.responseListObject
      });
    });
  }
  handleCancel = () => {
    this.props.history.goBack('-1');
  }

  render() {
    let dob_2_validate_message = "";
    let dob_3_validate_message = "";
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          mobileNo: "",
          emailID: "",
          gender: "",
          firstName: "",
          middleName: "",
          lastName: "",
          contactNo: "",
          secondaryEmailID: "",
          dob_1: "",
          panNumber: "",
          aadharNumber: "",
          ca_address_1: "",
          ca_address_2: "",
          ca_country: "",
          ca_state: "",
          ca_city: "",
          ca_postal_code: "",
          pa_address_1: "",
          pa_address_2: "",
          pa_country: "",
          pa_state: "",
          pa_city: "",
          pa_postal_code: "",
          addressFlag: "",
          fatherName: "",
          dob_2: "",
          dob_3: "",
          company_name_1: "",
          mother_name: "",
          company_name_2: "",
          employment_type_1: "",
          employment_type_2: "",
          learnerQualification: [],
          roleId: "",
          show: false,
          ShowMotherCompany: true,
          ShowFatherCompany: true,
          role: "",
          // today: moment(new Date()).format("DD/MM/YYYY"),
          stateList: [],
          cityList: [],
          stateListForPer: [],
          cityListForPer: [],
          document: [],
          resumeDocumentName: "",
          panCardDocumentName: "",
          aadharCardDocumentName: "",
          documentSizeCheckForProfileCheck: false,
          documentTypeCheckForProfileImage: false,
          documentTypeCheckForResume: false,
          documentSizeCheckForResume: false,
          documentTypeCheckForAadharImage: false,
          documentSizeCheckForVideoCheck: false,
          documentSizeCheckForPanCard: false,
          documentSizeCheckForAadharCard: false,
          documentTypeCheckForPANImage: false,
          resumeDocumentURL: "",
          resumeVideoURL: "",
          loading: false,
          userExpiration: "",
          expiryDate: "",
          downloadResumeAfterSelecting: ""
        }}
        validationSchema={Yup.object().shape({
          //static validations
          role: Yup.string().required("You must specify a Role"),
          mobileNo: Yup.string()
            .required("You must specify a Mobile Number")
            .matches(/^[6-9]\d{9}$/, "Please enter the valid mobile number"),
          emailID: Yup.string()
            .required("You must specify a Email ID")
            .email("Please enter the valid email ID"),
          gender: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string().required("You must specify a Gender");
              default:
                return Yup.string();
            }
          }),
          firstName: Yup.string().required("You must specify a First Name"),
          middleName: Yup.string(),
          lastName: Yup.string().required("You must specify a Last Name"),
          contactNo: Yup.string().matches(
            /^[6-9]\d{9}$/,
            "Please enter the valid mobile number"
          ),
          secondaryEmailID: Yup.string().email(
            "Please enter the valid email ID"
          ),
          ca_address_1: Yup.string().required("You must specify a Address"),
          ca_address_2: Yup.string(),
          ca_country: Yup.string().required("You must specify a Country"),
          ca_state: Yup.string().required("You must specify a State"),
          ca_city: Yup.string().required("You must specify a City"),
          ca_postal_code: Yup.string()
            .required("You must specify a Pin Code")
            .matches(/^\d{6}$/, "Please enter valid postal code"),
          pa_address_1: Yup.string().required("You must specify a Address"),
          pa_address_2: Yup.string(),
          pa_country: Yup.string().required("You must specify a Country"),
          pa_state: Yup.string().required("You must specify a State"),
          pa_city: Yup.string().required("You must specify a City"),
          pa_postal_code: Yup.string()
            .required("You must specify a Pin Code")
            .matches(/^\d{6}$/, "Please enter valid postal code"),
          dob_2: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string()
                  .default(() => "")
                  .test("dateOfBirth", dob_2_validate_message, value => {
                    if (value !== undefined) {
                      if (key.parent.dob_1 === undefined) {
                        dob_2_validate_message =
                          "please enter learner's date of birth";
                      } else {
                        dob_2_validate_message =
                          "age should be greater than learner's age";
                      }
                    }
                    return value !== undefined
                      ? moment().diff(moment(value), "years") >
                      moment().diff(moment(key.parent.dob_1), "years")
                      : "";
                  });
              default:
                return Yup.string();
            }
          }),

          dob_3: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string()
                  .default(() => "")
                  .test("dateOfBirth", dob_3_validate_message, value => {
                    if (value !== undefined) {
                      if (key.parent.dob_1 === undefined) {
                        dob_3_validate_message =
                          "please enter learner's date of birth";
                      } else {
                        dob_3_validate_message =
                          "age should be greater than learner's age";
                      }
                    }
                    return value !== undefined
                      ? moment().diff(moment(value), "years") >
                      moment().diff(moment(key.parent.dob_1), "years")
                      : "";
                  });
              default:
                return Yup.string();
            }
          }),
          company_name_1: Yup.string(),
          company_name_2: Yup.string(),
          mother_name: Yup.string(),
          employment_type_1: Yup.string(),
          employment_type_2: Yup.string(),
          //dynamic validation starts from here
          //Committed because of requirement
          // fatherName: Yup.lazy((values, key) => {
          //   switch (key.parent.role) {
          //     case "Learner":
          //       return Yup.string().required(
          //         "You must specify a Father's name"
          //       );
          //     default:
          //       return Yup.string();
          //   }
          // }),
          dob_1: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string()
                  .required("You must specify a Date of Birth")
                  .default(() => "")
                  .test(
                    "dateOfBirth",
                    "age should be greater then 18 years",
                    value => {
                      return moment().diff(moment(value), "years") >= 18;
                    }
                  );
              default:
                return Yup.string();
            }
          }),
          panNumber: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return (
                  Yup.string()
                    // .required("You must specify a PAN number")
                    .matches(
                      /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                      "Please enter the valid PAN number"
                    )
                );
              default:
                return Yup.string();
            }
          }),
          aadharNumber: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string()
                  .required("You must specify a Aadhar number")
                  .matches(/^\d{12}$/, "Please enter the valid aadhar number");
              default:
                return Yup.string();
            }
          })
        })}
        onSubmit={(values, actions) => {
          //actions.setFieldValue((values.loading = true));
          this.setState({ loading: true });

          let data = {
            roleID: values.roleId,
            mobileNo: values.mobileNo,
            isActive: 1,
            emailID: values.emailID.toLowerCase().trim(),
            gender: values.gender.trim(),
            firstName: values.firstName.trim(),
            middleName: values.middleName.trim(),
            lastName: values.lastName.trim(),
            contactNo: values.contactNo,
            secondaryEmailID: values.secondaryEmailID.trim(),
            dob: values.dob_1
              ? moment(values.dob_1).format("DD/MM/YYYY HH:mm:ss")
              : " ",
            panNumber: values.panNumber.trim(),
            aadharNumber: values.aadharNumber,
            address: [
              {
                addressType: "permanent",
                street: values.pa_address_1,
                street2: values.pa_address_2,
                cityId: values.pa_city,
                stateId: values.pa_state,
                countryId: values.pa_country,
                postalCode: values.pa_postal_code
              },
              {
                addressType: "correspondence",
                street: values.ca_address_1,
                street2: values.ca_address_2,
                cityId: values.ca_city,
                stateId: values.ca_state,
                countryId: values.ca_country,
                postalCode: values.ca_postal_code
              }
            ],
            learnerFatherName: values.fatherName.trim(),
            learnerFatherDOB: values.dob_2
              ? moment(values.dob_2).format("DD/MM/YYYY HH:mm:ss")
              : " ",
            learnerFatherEmployementType: values.employment_type_1.trim(),
            learnerFatherCompanyName: values.company_name_1.trim(),
            learnerMotherName: values.mother_name.trim(),
            learnerMotherDOB: values.dob_3
              ? moment(values.dob_3).format("DD/MM/YYYY HH:mm:ss")
              : " ",
            learnerMotherEmployementType: values.employment_type_2.trim(),
            learnerMotherCompanyName: values.company_name_2.trim(),
            learnerQualification: values.learnerQualification.filter(x => !!x),
            document: values.document,
            profilePhotoURL: values.profilePhotoURL,
            resumeDocumentURL: values.resumeDocumentURL,
            resumeVideoURL: values.resumeVideoURL,
            expiryDate: values.expiryDate
              ? moment(values.expiryDate).format("DD/MM/YYYY HH:mm:ss")
              : " "
          };
          console.log("Add user", data);
          addAdminUser(
            data,
            cb => {
              if (cb.status === "success") {
                this.setState({ loading: false });
                success(
                  "User profile has been created on Dap successfully",
                  successNotification
                );
                //actions.setFieldValue((values.loading = false));
                this.props.history.goBack('-1');

                //this.props.history.push("/admin-user-list");
                //this.props.fetchAdminUser("limit=-1");

              } else {
                //actions.setFieldValue((values.loading = false));
                this.setState({ loading: false });
                error(cb.reasonText, {
                  ...errorNotification,
                  container: "top-center"
                });
              }
            },
            err => console.log("Error", err)
          );
        }}
        render={({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
          ...formikProps
        }) => (
          <React.Fragment>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
              )}
            <section class="dashboard-tab user-profile pt-35" id="admin-profile">
              <Form
                onSubmit={handleSubmit}
                onKeyDown={onKeyDown => {
                  if (
                    (onKeyDown.charCode || onKeyDown.keyCode) === 13 &&
                    onKeyDown.target.name != "ca_country" &&
                    onKeyDown.target.name != "gender" &&
                    onKeyDown.target.name != "role" &&
                    onKeyDown.target.name != "userExpiration" &&
                    onKeyDown.target.name != "isExpire" &&
                    onKeyDown.target.name != "expiryDate" &&
                    onKeyDown.target.name != "ca_state" &&
                    onKeyDown.target.name != "ca_city" &&
                    onKeyDown.target.name != "pa_country" &&
                    onKeyDown.target.name != "pa_state" &&
                    onKeyDown.target.name != "pa_city" &&
                    onKeyDown.target.name != "employment_type_1" &&
                    onKeyDown.target.name != "employment_type_2" &&
                    onKeyDown.target.name != "submitForm" &&
                    onKeyDown.target.name != "cancelForm"
                  ) {
                    onKeyDown.preventDefault();
                  }
                }}
                className="form-group"
              >
                <div class="">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                        <div class="profile-left-sidebar">
                          <div class="profile-sidebar">
                            <div class="table-header">
                              <div class="container-fluid">
                                <div id="topheader">

                                  <nav data-scroll-header>
                                    <ul class="tab">
                                      <li>
                                        <a href="#create-userType">User Type</a>
                                      </li>
                                      <li>
                                        <a href="#create-user">
                                          Personal Details
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#create-address">
                                          Address Details
                                        </a>
                                      </li>
                                      {values.show ? (
                                        <li>
                                          <a href="#create-parent">
                                            Parent Details
                                          </a>
                                        </li>
                                      ) : (
                                          <div></div>
                                        )}
                                      {values.show && (
                                        <li>
                                          <a href="#create-qualification">
                                            Qualification Details
                                          </a>
                                        </li>
                                      )}
                                      {values.show && (
                                        <li>
                                          <a href="#create-profile">
                                            Profile Details
                                          </a>
                                        </li>
                                      )}

                                    </ul>
                                  </nav>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12 pt-130">
                        <main class="content-pos-fixed">
                          <div class="bg-box">
                            <section
                              class="create-userType page-section"
                              id="create-userType"
                            >
                              <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <h2 class="sub-header">User Type</h2>
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Role Type{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="role"
                                          id="role"
                                          onClick={event => {
                                            if (
                                              event.target.value === "DISTRIBUTOR"
                                            ) {
                                              values.show = true;
                                              values.role = event.target.value;
                                              values.roleId =
                                                event.target.options[
                                                  event.target.selectedIndex
                                                ].id;
                                            } else {
                                              values.show = false;
                                              values.role = event.target.value;
                                              values.roleId =
                                                event.target.options[
                                                  event.target.selectedIndex
                                                ].id;
                                            }
                                          }}
                                          className={"form-control"}
                                        >
                                          <option value="">--Select--</option>
                                          {(this.props.roles || [])
                                            .filter(
                                              role =>
                                                role.roleId != 1 &&
                                                role.isActive == 1
                                            )
                                            .map(role => (
                                              <option
                                                value={role.roleName}
                                                id={role.roleId}
                                              >
                                                {role.roleName}
                                              </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage
                                          name="role"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>User Expired On</label>
                                        <div className="expriry-radio">
                                          <div className="radio">
                                            <Field
                                              type="radio"
                                              className="form-control1"
                                              name="userExpiration"
                                              value="1"
                                              onChange={handleChange}
                                            ></Field>
                                          </div>
                                          <label className="never">
                                            Non Expired
                                          </label>
                                        </div>
                                        <div className="expriry-radio">
                                          <div className="radio">
                                            <Field
                                              type="radio"
                                              className="form-control1"
                                              name="userExpiration"
                                              // name="isExpire"
                                              value="2"
                                              onChange={handleChange}
                                            ></Field>
                                          </div>
                                          <label className="never">
                                            Is Expire
                                          </label>
                                        </div>
                                      </div>
                                      {values.userExpiration === "2" ? (
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>User Expiry Date</label>
                                          <Field
                                            type="date"
                                            className="form-control"
                                            min={this.state.today}
                                            onChange={handleChange}
                                            name="expiryDate"
                                          ></Field>
                                        </div>
                                      ) : (
                                          ""
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>

                            <section
                              className="create-user page-section createusers"
                              id="create-user"
                            >
                              <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <h2 class="sub-header">Personal Details</h2>
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Mobile No{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="mobileNo"
                                          name="mobileNo"
                                          autoComplete="mobileNo"
                                          onChange={handleChange}
                                          onBlur={event => {
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            RegisterUserService.validateUserData(
                                              "&mobileNumber=" +
                                              event.target.value,
                                              res => {
                                                if (res.status === "fail") {
                                                  setFieldValue(
                                                    (values.mobileNo = "")
                                                  );
                                                  error(res.reasonText, {
                                                    ...errorNotification,
                                                    container: "top-center"
                                                  });
                                                }
                                              }
                                            );
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.mobileNo && touched.mobileNo
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Mobile No"
                                        />
                                        <ErrorMessage
                                          name="mobileNo"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Email Id{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="emailID"
                                          id="emailID"
                                          onChange={handleChange}
                                          autoComplete="emailID"
                                          onBlur={event => {
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            RegisterUserService.validateUserData(
                                              "&emailID=" + event.target.value,
                                              res => {
                                                if (res.status === "fail") {
                                                  setFieldValue(
                                                    (values.emailID = "")
                                                  );
                                                  error(res.reasonText, {
                                                    ...errorNotification,
                                                    container: "top-center"
                                                  });
                                                }
                                              }
                                            );
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.emailID && touched.emailID
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Email ID"
                                        />
                                        <ErrorMessage
                                          name="emailID"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>{" "}
                                      {values.show && (
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Gender{" "}
                                            <span className="validation">
                                              {" "}
                                                *
                                            </span>
                                          </label>
                                          <Field
                                            as="select"
                                            name="gender"
                                            id="gender"
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.gender && touched.gender
                                                ? "is-invalid"
                                                : "")
                                            }
                                            className="form-control"
                                          >
                                            <option value="">--Select--</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Other</option>
                                          </Field>
                                          <ErrorMessage
                                            name="gender"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          First Name{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="firstName"
                                          id="firstName"
                                          onChange={handleChange}
                                          autoComplete="firstName"
                                          className={
                                            "form-control" +
                                            (errors.firstName &&
                                              touched.firstName
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="First Name"
                                        />
                                        <ErrorMessage
                                          name="firstName"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>Middle Name</label>
                                        <Field
                                          type="text"
                                          name="middleName"
                                          onChange={handleChange}
                                          autoComplete="middleName"
                                          className={
                                            "form-control" +
                                            (errors.middleName &&
                                              touched.middleName
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Middle Name"
                                        />
                                        <ErrorMessage
                                          name="middleName"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Last Name{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="lastName"
                                          id="lastName"
                                          autoComplete="lastName"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.lastName && touched.lastName
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Last Name"
                                        />
                                        <ErrorMessage
                                          name="lastName"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>Secondary Contact No</label>
                                        <Field
                                          type="text"
                                          name="contactNo"
                                          onChange={handleChange}
                                          autoComplete="contactNo"
                                          className={
                                            "form-control" +
                                            (errors.contactNo &&
                                              touched.contactNo
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Mobile Number"
                                        />
                                        <ErrorMessage
                                          name="contactNo"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>Secondary Email ID</label>
                                        <Field
                                          type="text"
                                          name="secondaryEmailID"
                                          onChange={handleChange}
                                          autoComplete="secondaryEmailID"
                                          className={
                                            "form-control" +
                                            (errors.secondaryEmailID &&
                                              touched.secondaryEmailID
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Email ID"
                                        />
                                        <ErrorMessage
                                          name="secondaryEmailID"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      {values.show && (
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Birth Date{" "}
                                            <span className="validation">
                                              {" "}
                                                *
                                            </span>
                                          </label>
                                          <Field
                                            type="date"
                                            name="dob_1"
                                            id="dob_1"
                                            max={this.state.today}
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.dob_1 && touched.dob_1
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="dob_1"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                      )}
                                    </div>

                                    {values.show && (
                                      <div class="row">
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Pan No </label>
                                          <Field
                                            type="text"
                                            name="panNumber"
                                            id="panNumber"
                                            onChange={handleChange}
                                            onBlur={event => {
                                              LoginService.TokenUser(res => {
                                                localStorage.setItem(
                                                  "jwt-token",
                                                  res.responseObject
                                                );
                                              });
                                              RegisterUserService.validateUserData(
                                                "&panNumber=" +
                                                event.target.value,
                                                res => {
                                                  if (res.status === "fail") {
                                                    setFieldValue(
                                                      (values.panNumber = "")
                                                    );
                                                    error(res.reasonText, {
                                                      ...errorNotification,
                                                      container: "top-center"
                                                    });
                                                  }
                                                }
                                              );
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.panNumber &&
                                                touched.panNumber
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Pan number"
                                          />
                                          <ErrorMessage
                                            name="panNumber"
                                            className="validation"
                                            on
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Aadhar No{" "}
                                            <span className="validation">
                                              {" "}
                                                *
                                            </span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="aadharNumber"
                                            id="aadharNumber"
                                            onChange={handleChange}
                                            onBlur={event => {
                                              LoginService.TokenUser(res => {
                                                localStorage.setItem(
                                                  "jwt-token",
                                                  res.responseObject
                                                );
                                              });
                                              RegisterUserService.validateUserData(
                                                "&aadharNumber=" +
                                                event.target.value,
                                                res => {
                                                  if (res.status === "fail") {
                                                    setFieldValue(
                                                      (values.aadharNumber = "")
                                                    );
                                                    error(res.reasonText, {
                                                      ...errorNotification,
                                                      container: "top-center"
                                                    });
                                                  }
                                                }
                                              );
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.aadharNumber &&
                                                touched.aadharNumber
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Aadhar number"
                                          />
                                          <ErrorMessage
                                            name="aadharNumber"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div class="col-md-4 col-sm-4 col-xs-12"></div>
                                    <div class="col-md-12 col-sm-12 col-xs-12"></div>
                                    {values.show && (
                                      <div class="row">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Upload PAN</label>
                                          <div className="profile-detail">
                                            <div className="circle">
                                              <img
                                                className="profile-pic"
                                                src={values.panCardURL}
                                              />
                                              <div className="profile">
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="profile-add">
                                            <span className="icon btn-file text-center">
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                              <FileBase64
                                                multiple={true}
                                                name="panCardURL"
                                                onDone={event => {
                                                  if (
                                                    [
                                                      "image/jpg",
                                                      "image/jpeg",
                                                      "image/png"
                                                    ].indexOf(event[0].type) < 0
                                                  ) {
                                                    setFieldValue(
                                                      (values.documentTypeCheckForPANImage = true)
                                                    );
                                                  } else if (
                                                    event[0].file.size <=
                                                    5000000
                                                  ) {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType: "PanCard",
                                                      documentName:
                                                        event[0].name
                                                    });
                                                    setFieldValue(
                                                      (values.documentTypeCheckForPANImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForPanCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.panCardDocumentName =
                                                        event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.panCardURL =
                                                        event[0].base64)
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      (values.documentSizeCheckForPanCard = true)
                                                    );
                                                  }
                                                }}
                                              />
                                            </span>
                                            <h4>Add PAN</h4>
                                            {/* <span className="icon">
                                                <i
                                                  className="fa fa-times-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                              <h4>Remove PAN</h4> */}
                                            <div className="col-md-12 col-sm-12">
                                              <span class="file-size-limit">
                                                Supported Formats: .jpg, .jpeg,
                                                .png only. <br />
                                                  File Size: Max 5 MB
                                              </span>
                                            </div>
                                            {values.documentSizeCheckForPanCard ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:5MB).
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForPANImage ? (
                                              <p style={{ color: "red" }}>
                                                Invalid image format
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Upload Aadhaar</label>
                                          <div className="profile-detail">
                                            <div className="circle">
                                              <img
                                                className="profile-pic"
                                                src={values.aadharCardURL}
                                              />
                                              <div className="profile">
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="profile-add">
                                            <span className="icon btn-file text-center">
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                              <FileBase64
                                                multiple={true}
                                                name="aadharCardURL"
                                                onChange={handleChange}
                                                onDone={event => {
                                                  if (
                                                    [
                                                      "image/jpg",
                                                      "image/jpeg",
                                                      "image/png"
                                                    ].indexOf(event[0].type) < 0
                                                  ) {
                                                    setFieldValue(
                                                      (values.documentTypeCheckForAadharImage = true)
                                                    );
                                                  } else if (
                                                    event[0].file.size <=
                                                    5000000
                                                  ) {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType:
                                                        "AadharCard",
                                                      documentName:
                                                        event[0].name
                                                    });
                                                    setFieldValue(
                                                      (values.documentTypeCheckForAadharImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForAadharCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.aadharCardDocumentName =
                                                        event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.aadharCardURL =
                                                        event[0].base64)
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      (values.documentSizeCheckForAadharCard = true)
                                                    );
                                                  }
                                                }}
                                              />
                                            </span>
                                            <h4>Add Aadhaar</h4>
                                            {/* <span className="icon">
                                                <i
                                                  className="fa fa-times-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                              <h4>Remove</h4> */}
                                            <div className="col-md-12 col-sm-12">
                                              <span class="file-size-limit">
                                                Supported Formats: .jpg, .jpeg,
                                                .png only. <br />
                                                  File Size: Max 5 MB
                                              </span>
                                            </div>
                                            {values.documentSizeCheckForAadharCard ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:5MB).
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForAadharImage ? (
                                              <p style={{ color: "red" }}>
                                                Invalid image format
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div class="col-md-4 col-sm-4 col-xs-12"></div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              class="create-address page-section"
                              id="create-address"
                            >
                              <h2 class="sub-header">Address Details</h2>
                              <div class="address-border">
                                <div class="form-group">
                                  <div class="row">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                      <h5 class="address-heading">
                                        Correspondence Address
                                      </h5>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Address 1{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="ca_address_1"
                                        id="ca_address_1"
                                        autoComplete="ca_address_1"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.ca_address_1 &&
                                            touched.ca_address_1
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Address"
                                      />
                                      <ErrorMessage
                                        name="ca_address_1"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>Address 2</label>
                                      <Field
                                        type="text"
                                        name="ca_address_2"
                                        autoComplete="ca_address_2"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.ca_address_2 &&
                                            touched.ca_address_2
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Address"
                                      />
                                      <ErrorMessage
                                        name="ca_address_2"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Country{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="ca_country"
                                        id="ca_country"
                                        onChange={event => {
                                          LoginService.TokenUser(res => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (event.target.value !== "") {
                                            setFieldValue(
                                              (values.ca_country =
                                                event.target.value)
                                            );

                                            // values.stateList = [];
                                            // values.cityList = [];
                                            RegionManagementService.getState(
                                              "countryId=" + values.ca_country,
                                              res => {
                                                setFieldValue(
                                                  (values.stateList =
                                                    res.responseListObject)
                                                );
                                              }
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.ca_country &&
                                            touched.ca_country
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>
                                        {(this.state.countryList || []).map(
                                          team =>
                                            team.isActive ? (
                                              <option
                                                key={team.countryId}
                                                value={team.countryId}
                                              >
                                                {pascalCase(team.countryName)}
                                              </option>
                                            ) : (
                                                ""
                                              )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="ca_country"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        State{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="ca_state"
                                        id="ca_state"
                                        onChange={event => {
                                          LoginService.TokenUser(res => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (event.target.value !== "") {
                                            setFieldValue(
                                              (values.ca_state =
                                                event.target.value)
                                            );

                                            RegionManagementService.getCity(
                                              "stateId=" + values.ca_state,
                                              res => {
                                                setFieldValue(
                                                  (values.cityList =
                                                    res.responseListObject)
                                                );
                                              }
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.ca_state && touched.ca_state
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>
                                        {(values.stateList || []).map(team => (
                                          <option
                                            key={team.stateId}
                                            value={team.stateId}
                                          >
                                            {pascalCase(team.stateName)}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="ca_state"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        City{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="ca_city"
                                        id="ca_city"
                                        onChange={event => {
                                          if (
                                            event.target.value !== undefined
                                          ) {
                                            setFieldValue(
                                              (values.ca_city =
                                                event.target.value)
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.ca_city && touched.ca_city
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>
                                        {(values.cityList || []).map(team => (
                                          <option
                                            key={team.cityId}
                                            value={team.cityId}
                                          >
                                            {pascalCase(team.cityName)}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="ca_city"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Pin{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="ca_postal_code"
                                        id="ca_postal_code"
                                        autoComplete="ca_postal_code"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.ca_postal_code &&
                                            touched.ca_postal_code
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Postal Code"
                                      />
                                      <ErrorMessage
                                        name="ca_postal_code"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="address-border">
                                <div class="form-group">
                                  <div class="row">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                      <div class="row row-no-margin">
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <div class="col-md-9 col-lg-9 col-sm-9 col-xs-12">
                                            <h5 class="address-heading">
                                              Permanent Address{" "}
                                            </h5>
                                          </div>
                                          <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                            <div class="form-check pull-right">
                                              <Field
                                                className="form-check-input"
                                                type="checkbox"
                                                name="sameAsAbove"
                                                checked={values.addressFlag}
                                                onClick={event => {
                                                  if (event.target.checked) {
                                                    values.stateListForPer =
                                                      values.stateList;
                                                    values.cityListForPer =
                                                      values.cityList;
                                                    values.pa_address_1 =
                                                      values.ca_address_1;
                                                    values.pa_address_2 =
                                                      values.ca_address_2;
                                                    values.pa_country =
                                                      values.ca_country;
                                                    values.pa_state =
                                                      values.ca_state;
                                                    values.pa_city =
                                                      values.ca_city;
                                                    values.pa_postal_code =
                                                      values.ca_postal_code;
                                                    values.addressFlag =
                                                      "checked";
                                                  } else {
                                                    values.pa_address_1 = "";
                                                    values.pa_address_2 = "";
                                                    values.pa_country = "";
                                                    values.pa_state = "";
                                                    values.pa_city = "";
                                                    values.pa_postal_code = "";
                                                    values.addressFlag = "";
                                                    values.stateListForPer = [];
                                                    values.cityListForPer = [];
                                                  }
                                                }}
                                              />
                                              <label className="form-check-label">
                                                Same as above
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Address 1{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="pa_address_1"
                                        id="pa_address_1"
                                        autoComplete="pa_address_1"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.pa_address_1 &&
                                            touched.pa_address_1
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Address"
                                      />
                                      <ErrorMessage
                                        name="pa_address_1"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>Address 2</label>
                                      <Field
                                        type="text"
                                        name="pa_address_2"
                                        autoComplete="pa_address_2"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.pa_address_2 &&
                                            touched.pa_address_2
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Address"
                                      />
                                      <ErrorMessage
                                        name="pa_address_2"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Country{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="pa_country"
                                        id="pa_country"
                                        onChange={event => {
                                          LoginService.TokenUser(res => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (event.target.value !== "") {
                                            setFieldValue(
                                              (values.pa_country =
                                                event.target.value)
                                            );

                                            values.stateListForPer = [];
                                            values.cityListForPer = [];
                                            RegionManagementService.getState(
                                              "countryId=" + values.pa_country,
                                              res => {
                                                setFieldValue(
                                                  (values.stateListForPer =
                                                    res.responseListObject)
                                                );
                                              }
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.pa_country &&
                                            touched.pa_country
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>
                                        {(
                                          this.state.countryListForPer || []
                                        ).map(team =>
                                          team.isActive ? (
                                            <option
                                              key={team.countryId}
                                              value={team.countryId}
                                            >
                                              {pascalCase(team.countryName)}
                                            </option>
                                          ) : (
                                              ""
                                            )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="pa_country"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        State{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="pa_state"
                                        id="pa_state"
                                        onChange={event => {
                                          LoginService.TokenUser(res => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (event.target.value !== "") {
                                            setFieldValue(
                                              (values.pa_state =
                                                event.target.value)
                                            );

                                            RegionManagementService.getCity(
                                              "stateId=" + values.pa_state,
                                              res => {
                                                setFieldValue(
                                                  (values.cityListForPer =
                                                    res.responseListObject)
                                                );
                                              }
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.pa_state && touched.pa_state
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>
                                        {(values.stateListForPer || []).map(
                                          team => (
                                            <option
                                              key={team.stateId}
                                              value={team.stateId}
                                            >
                                              {pascalCase(team.stateName)}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="pa_state"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        City{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="pa_city"
                                        id="pa_city"
                                        onChange={event => {
                                          if (
                                            event.target.value !== undefined
                                          ) {
                                            setFieldValue(
                                              (values.pa_city =
                                                event.target.value)
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.pa_city && touched.pa_city
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>
                                        {(values.cityListForPer || []).map(
                                          team => (
                                            <option
                                              key={team.cityId}
                                              value={team.cityId}
                                            >
                                              {pascalCase(team.cityName)}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="pa_city"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>

                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Pin{" "}
                                        <span className="validation"> *</span>{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="pa_postal_code"
                                        id="pa_postal_code"
                                        autoComplete="pa_postal_code"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.pa_postal_code &&
                                            touched.pa_postal_code
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Postal Code"
                                      />
                                      <ErrorMessage
                                        name="pa_postal_code"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>

                            {values.show && (
                              <section
                                className="create-parent page-section"
                                id="create-parent"
                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                      Parent Details
                                    </h2>
                                    <div className="address-border">
                                      <div className="form-group">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <h5 className="address-heading">
                                            Father Details
                                          </h5>
                                        </div>
                                        <div className="form-group">
                                          <div class="row">
                                            <div class="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                Father's Name{" "}
                                                {/* <span className="validation">
                                                    {" "}
                                                    *
                                                </span>{" "} */}
                                              </label>
                                              <Field
                                                type="text"
                                                name="fatherName"
                                                id="fatherName"
                                                autoComplete="fatherName"
                                                onChange={handleChange}
                                                className={
                                                  "form-control" +
                                                  (errors.fatherName &&
                                                    touched.fatherName
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Father's Name"
                                              />
                                              <ErrorMessage
                                                name="fatherName"
                                                className="validation"
                                                component="div"
                                              />
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12">
                                              <label>Birth Date</label>
                                              <Field
                                                type="date"
                                                name="dob_2"
                                                id="dob_2"
                                                max={this.state.today}
                                                onChange={handleChange}
                                                className={
                                                  "form-control" +
                                                  (errors.dob_2 && touched.dob_2
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name="dob_2"
                                                className="validation"
                                                component="div"
                                              />
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12">
                                              <label>Occupation Type</label>
                                              <Field
                                                as="select"
                                                name="employment_type_1"
                                                onChange={event => {
                                                  setFieldValue(
                                                    (values.employment_type_1 =
                                                      event.target.value)
                                                  );
                                                  if (
                                                    event.target.value ===
                                                    "6" ||
                                                    event.target.value === "8"
                                                  ) {
                                                    setFieldValue(
                                                      (values.company_name_1 =
                                                        "")
                                                    );
                                                  }
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.learnerFatherEmployementType &&
                                                    touched.learnerFatherEmployementType
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              >
                                                <option>--Select--</option>
                                                {(
                                                  this.state
                                                    .employeTypeForParents || []
                                                ).map(team => (
                                                  <option
                                                    key={team.codeValueId}
                                                    value={team.codeValueId}
                                                  >
                                                    {team.codeValue}
                                                  </option>
                                                ))}
                                              </Field>
                                              <ErrorMessage
                                                name="employment_type_1"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                          </div>
                                          <div class="row">
                                            {values.employment_type_1 === "6" ||
                                              values.employment_type_1 === "8" ? (
                                                ""
                                              ) : (
                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                  <label>Company Name </label>
                                                  <Field
                                                    type="text"
                                                    name="company_name_1"
                                                    onChange={handleChange}
                                                    autoComplete="company_name_1"
                                                    className={
                                                      "form-control" +
                                                      (errors.company_name_1 &&
                                                        touched.company_name_1
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    placeholder="Company Name"
                                                  />
                                                  <ErrorMessage
                                                    name="company_name_1"
                                                    className="validation"
                                                    component="div"
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="address-border">
                                      <div className="form-group">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <h5 className="address-heading">
                                            Mother Details
                                          </h5>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-4 col-sm-4 col-xs-12">
                                            <label>Mother Name </label>
                                            <Field
                                              type="text"
                                              name="mother_name"
                                              autoComplete="mother_name"
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.mother_name &&
                                                  touched.mother_name
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Mother's Name"
                                            />
                                            <ErrorMessage
                                              name="mother_name"
                                              className="validation"
                                              component="div"
                                            />
                                          </div>
                                          <div class="col-md-4 col-sm-4 col-xs-12">
                                            <label>Birth Date</label>
                                            <Field
                                              type="date"
                                              name="dob_3"
                                              id="dob_3"
                                              max={this.state.today}
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.dob_3 && touched.dob_3
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name="dob_3"
                                              className="validation"
                                              component="div"
                                            />
                                          </div>

                                          <div class="col-md-4 col-sm-4 col-xs-12">
                                            <label>Occupation Type</label>
                                            <Field
                                              as="select"
                                              name="employment_type_2"
                                              id="employment_type_2"
                                              onChange={event => {
                                                setFieldValue(
                                                  (values.employment_type_2 =
                                                    event.target.value)
                                                );
                                                if (
                                                  event.target.value === "6" ||
                                                  event.target.value === "8"
                                                ) {
                                                  setFieldValue(
                                                    (values.company_name_2 = "")
                                                  );
                                                }
                                              }}
                                              className={
                                                "form-control" +
                                                (errors.employment_type_2 &&
                                                  touched.employment_type_2
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            >
                                              <option>--Select--</option>
                                              {(
                                                this.state
                                                  .employeTypeForParents || []
                                              ).map(team => (
                                                <option
                                                  key={team.codeValueId}
                                                  value={team.codeValueId}
                                                >
                                                  {team.codeValue}
                                                </option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="employment_type_2"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>
                                        </div>
                                        <div class="row">
                                          {values.employment_type_2 === "6" ||
                                            values.employment_type_2 === "8" ? (
                                              ""
                                            ) : (
                                              <div class="col-md-4 col-sm-4 col-xs-12">
                                                <label>Company Name </label>
                                                <Field
                                                  type="text"
                                                  name="company_name_2"
                                                  onChange={handleChange}
                                                  autoComplete="company_name_2"
                                                  className={
                                                    "form-control" +
                                                    (errors.company_name_2 &&
                                                      touched.company_name_2
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="Company Name"
                                                />
                                                <ErrorMessage
                                                  name="company_name_2"
                                                  className="validation"
                                                  component="div"
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                            {values.show && (
                              <section
                                class="create-qualification page-section"
                                id="create-qualification"
                              >
                                <div class="row">
                                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 class="sub-header">
                                      Qualification Details
                                    </h2>
                                    <div className="qualification-table">
                                      <FieldArray
                                        name="learnerQualification"
                                        render={arrayHelpers => (
                                          <div>
                                            {values.learnerQualification &&
                                              values.learnerQualification.length >
                                              0 ? (
                                                <div className="table-row">
                                                  <table
                                                    className="table table-bordered table-hover"
                                                    id="tab_logic"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th className="text-center">
                                                          {" "}
                                                            Institute Name
                                                      </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            Qualification Category{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            Qualification Type{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            Passing Year
                                                      </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            Pursuing Year{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            CGPA / Percentage{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            Action{" "}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {values.learnerQualification.map(
                                                        (friend, index) => (
                                                          <tr key={index}>
                                                            <td>
                                                              <Field
                                                                name={`learnerQualification.${index}.instituteName`}
                                                                className="form-control"
                                                                value={
                                                                  friend.instituteName
                                                                }
                                                              />
                                                            </td>
                                                            <td>
                                                              <Field
                                                                name={`learnerQualification.${index}.qualificationCategory`}
                                                                className="form-control"
                                                                value={
                                                                  friend.qualificationCategory
                                                                }
                                                              />
                                                            </td>
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                name={`learnerQualification.${index}.qualificationType`}
                                                                className="form-control"
                                                              // value={
                                                              //   friend.qualificationType
                                                              // }
                                                              >
                                                                <option value="">
                                                                  Select Education
                                                              </option>
                                                                <option value="Under Graduate">
                                                                  Under Graduate
                                                              </option>
                                                                <option value="Graduate">
                                                                  Graduate
                                                              </option>
                                                                <option value="Post Graduate">
                                                                  Post Graduate
                                                              </option>
                                                              </Field>
                                                            </td>
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                className="form-control"
                                                                name={`learnerQualification.${index}.passingYear`}
                                                                disabled={
                                                                  friend.qualificationType ==
                                                                    "Under Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "under Graduate"
                                                                    ? true
                                                                    : false
                                                                }
                                                                value={
                                                                  friend.qualificationType ==
                                                                    "Under Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "under Graduate"
                                                                    ? ""
                                                                    : friend.passingYear
                                                                }
                                                                type="number"
                                                              >
                                                                <option value="">
                                                                  --Select--
                                                              </option>
                                                                {(
                                                                  this.state
                                                                    .listOfYear ||
                                                                  []
                                                                ).map(year => (
                                                                  <option
                                                                    value={year}
                                                                  >
                                                                    {year}
                                                                  </option>
                                                                ))}
                                                              </Field>
                                                            </td>
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                className="form-control"
                                                                disabled={
                                                                  friend.qualificationType ==
                                                                    "Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "Post Graduate"
                                                                    ? true
                                                                    : false
                                                                }
                                                                name={`learnerQualification.${index}.persuingYear`}
                                                                value={
                                                                  friend.qualificationType ==
                                                                    "Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "Post Graduate"
                                                                    ? ""
                                                                    : friend.persuingYear
                                                                }
                                                              >
                                                                <option value="">
                                                                  --Select--
                                                              </option>
                                                                <option value="1">
                                                                  1st
                                                              </option>
                                                                <option value="2">
                                                                  2nd
                                                              </option>
                                                                <option value="3">
                                                                  3rd
                                                              </option>
                                                                <option value="4">
                                                                  4th
                                                              </option>
                                                              </Field>
                                                            </td>
                                                            <td>
                                                              <Field
                                                                className="form-control"
                                                                name={`learnerQualification.${index}.grade`}
                                                                value={
                                                                  friend.grade
                                                                }
                                                              />
                                                            </td>
                                                            <td>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                onClick={() => {
                                                                  arrayHelpers.remove(
                                                                    index
                                                                  );

                                                                  // this.removeByAttr(
                                                                  //   this.state
                                                                  //     .learnerQualification,
                                                                  //   "instituteName",
                                                                  //   friend.instituteName
                                                                  // );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-minus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                onClick={() => {
                                                                  arrayHelpers.insert(
                                                                    index + 1,
                                                                    ""
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-4"
                                                  onClick={() => {
                                                    arrayHelpers.push("");
                                                  }}
                                                >
                                                  Add a Qualification
                                                </button>
                                              )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                            {values.show && (
                              <section
                                className="create-profile page-section"
                                id="create-profile"
                              >
                                <h2 className="sub-header">Profile Details</h2>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Upload Profile Image</label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <img
                                            className="profile-pic"
                                            src={values.profilePhotoURL}
                                          />
                                          <div className="profile">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            ></i>
                                            <FileBase64
                                              multiple={true}
                                              onChange={handleChange}
                                              onDone={event => {
                                                if (
                                                  [
                                                    "image/jpg",
                                                    "image/jpeg",
                                                    "image/png"
                                                  ].indexOf(event[0].type) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForProfileImage = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 5000000
                                                ) {
                                                  values.document.push({
                                                    documentHash: event[0].base64,
                                                    documentType: "ProfilePhoto",
                                                    documentName: event[0].name
                                                  });
                                                  setFieldValue(
                                                    (values.documentTypeCheckForProfileImage = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForProfileCheck = false)
                                                  );
                                                  values.profilePhotoURL =
                                                    event[0].base64;
                                                  setFieldValue(
                                                    (values.profilePhotoURL =
                                                      event[0].base64)
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    (values.documentSizeCheckForProfileCheck = true)
                                                  );
                                                }
                                              }}
                                            />
                                          </span>
                                          <h4>Add Image</h4>
                                          {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Image</h4> */}
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              Supported Formats: .jpg, .jpeg, .png
                                            only. <br />
                                              File Size: Max 5 MB
                                          </span>
                                          </div>
                                          {values.documentSizeCheckForProfileCheck ? (
                                            <p style={{ color: "red" }}>
                                              File Size is too large(Maximum File
                                              Size:5MB).
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                          {values.documentTypeCheckForProfileImage ? (
                                            <p style={{ color: "red" }}>
                                              Invalid image format
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Upload Text Resume</label>
                                      <div className="text-resume">
                                        <h3>Select File To Upload</h3>
                                      </div>
                                      <div className="profile-add text-center">
                                        {values.resumeDocumentName ? (
                                          <span>
                                            {values.resumeDocumentName}
                                          </span>
                                        ) : (
                                            <span>
                                              <span className="icon">
                                                <i
                                                  className="fa fa-times-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                              <h4>No File Chosen</h4>
                                            </span>
                                          )}
                                        <div>
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-paperclip color_magento"
                                              aria-hidden="true"
                                            ></i>
                                            {values.documentSizeCheckForResume ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:2MB).
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForResume ? (
                                              <p style={{ color: "red" }}>
                                                Invalid Format
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                            <FileBase64
                                              multiple={true}
                                              name="resumeDocumentURL"
                                              onChange={handleChange}
                                              onDone={event => {
                                                const checkType = event[0].name.split(
                                                  "."
                                                );
                                                if (
                                                  [
                                                    "pdf",
                                                    "docx",
                                                    "rtf",
                                                    "doc"
                                                  ].indexOf(checkType[1]) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForResume = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 2000000
                                                ) {
                                                  values.document.push({
                                                    documentHash:
                                                      event[0].base64,
                                                    documentType:
                                                      "ResumeDocument",
                                                    documentName: event[0].name
                                                  });
                                                  setFieldValue(
                                                    (values.documentTypeCheckForResume = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForResume = false)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeDocumentName =
                                                      event[0].name)
                                                  );
                                                  setFieldValue(
                                                    (values.downloadResumeAfterSelecting =
                                                      event[0].base64)
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    (values.documentSizeCheckForResume = true)
                                                  );
                                                }
                                              }}
                                            />

                                            {/* <a
                                          href={values.resumeDocumentURL}
                                          className="fa fa-download"
                                        >
                                          Download
                                        </a> */}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                          <a className="icon btn-file">
                                            {values.downloadResumeAfterSelecting ? (
                                              <a
                                                href={
                                                  values.downloadResumeAfterSelecting
                                                }
                                                className="download"
                                                download={
                                                  values.resumeDocumentName
                                                }
                                              >
                                                <i
                                                  className="fa fa-download"
                                                  aria-hidden="true"
                                                ></i>
                                                <h4> Download Resume </h4>
                                              </a>
                                            ) : (values.resumeDocumentURL || '').includes(
                                              "pdf"
                                            ) ? (
                                                  <a
                                                    href={values.resumeDocumentURL}
                                                    className="download"
                                                    target="_blank"
                                                  >
                                                    <i
                                                      className="fa fa-download"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <h4> Download Resume </h4>
                                                  </a>
                                                ) : values.resumeDocumentURL ? (
                                                  <a
                                                    href={values.resumeDocumentURL}
                                                    className="download"
                                                  >
                                                    <i
                                                      className="fa fa-download"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <h4> Download Resume </h4>
                                                  </a>
                                                ) : (
                                                    <span>

                                                      <i
                                                        className="fa fa-download"
                                                        aria-hidden="true"
                                                      ></i>
                                                      <h4> Download Resume </h4>
                                                    </span>
                                                  )}
                                          </a>
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              Supported Formats: .docx, .pdf, .rtf
                                              only. File Size: Max 5 MB
                                          </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Upload Video Resume</label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <div className="profile-pic">
                                            <div className="profile">
                                              <ReactPlayer
                                                url={values.resumeVideoURL}
                                                width="100%"
                                                height="90"
                                                playing={false}
                                                controls
                                              ></ReactPlayer>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            ></i>
                                            <FileBase64
                                              multiple={true}
                                              name="resumeVideoURL"
                                              onChange={handleChange}
                                              onDone={event => {
                                                if (
                                                  [
                                                    "video/mp4",
                                                    "video/avi",
                                                    "video/mpeg"
                                                  ].indexOf(event[0].type) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForVideo = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 10000000
                                                ) {
                                                  values.document.push({
                                                    documentHash: event[0].base64,
                                                    documentType: "VideoResume",
                                                    documentName: event[0].name
                                                  });
                                                  setFieldValue(
                                                    (values.documentTypeCheckForVideo = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForVideoCheck = false)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeVideoURL =
                                                      event[0].base64)
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    (values.documentSizeCheckForVideoCheck = true)
                                                  );
                                                }
                                              }}
                                            />
                                          </span>
                                          <h4>Add Video</h4>
                                          {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Video</h4> */}
                                          {values.documentSizeCheckForVideoCheck ? (
                                            <p style={{ color: "red" }}>
                                              File Size is too large(Maximum File
                                              Size:10MB).
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                          {values.documentTypeCheckForVideo ? (
                                            <p style={{ color: "red" }}>
                                              Invalid Format
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              Supported Formats: .mp4, .avi, .mpeg
                                            only. <br />
                                              File Size: Max 10 MB
                                          </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-sm-4 hidden-xs">
                                      <div className="profile-add">
                                        <span className="icon btn-file text-center">
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          ></i>
                                          <FileBase64
                                            multiple={true}
                                            onChange={handleChange}
                                            onDone={event => {
                                              if (
                                                [
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/png"
                                                ].indexOf(event[0].type) < 0
                                              ) {
                                                setFieldValue(
                                                  (values.documentTypeCheckForProfileImage = true)
                                                );
                                              } else if (
                                                event[0].file.size <= 5000000
                                              ) {
                                                values.document.push({
                                                  documentHash: event[0].base64,
                                                  documentType: "ProfilePhoto",
                                                  documentName: event[0].name
                                                });
                                                setFieldValue(
                                                  (values.documentTypeCheckForProfileImage = false)
                                                );
                                                setFieldValue(
                                                  (values.documentSizeCheckForProfileCheck = false)
                                                );
                                                values.profilePhotoURL =
                                                  event[0].base64;
                                                setFieldValue(
                                                  (values.profilePhotoURL =
                                                    event[0].base64)
                                                );
                                              } else {
                                                setFieldValue(
                                                  (values.documentSizeCheckForProfileCheck = true)
                                                );
                                              }
                                            }}
                                          />
                                        </span>
                                        <h4>Add Image</h4>
                                        {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Image</h4> */}
                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            Supported Formats: .jpg, .jpeg, .png
                                            only. <br />
                                              File Size: Max 5 MB
                                          </span>
                                        </div>
                                        {values.documentSizeCheckForProfileCheck ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:5MB).
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                        {values.documentTypeCheckForProfileImage ? (
                                          <p style={{ color: "red" }}>
                                            Invalid image format
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 hidden-xs">
                                      <div className="profile-add">
                                        <a className="icon btn-file">
                                          {values.downloadResumeAfterSelecting ? (
                                            <a
                                              href={
                                                values.downloadResumeAfterSelecting
                                              }
                                              className="download"
                                              download={
                                                values.resumeDocumentName
                                              }
                                            >
                                              <i
                                                className="fa fa-download"
                                                aria-hidden="true"
                                              ></i>
                                              <h4> Download Resume </h4>
                                            </a>
                                          ) : (values.resumeDocumentURL || '').includes(
                                            "pdf"
                                          ) ? (
                                                <a
                                                  href={values.resumeDocumentURL}
                                                  className="download"
                                                  target="_blank"
                                                >
                                                  <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                  ></i>
                                                  <h4> Download Resume </h4>
                                                </a>
                                              ) : values.resumeDocumentURL ? (
                                                <a
                                                  href={values.resumeDocumentURL}
                                                  className="download"
                                                >
                                                  <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                  ></i>
                                                  <h4> Download Resume </h4>
                                                </a>
                                              ) : (
                                                  <span>

                                                    <i
                                                      className="fa fa-download"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <h4> Download Resume </h4>
                                                  </span>
                                                )}
                                        </a>
                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            Supported Formats: .docx, .pdf, .rtf
                                            only. File Size: Max 5 MB
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-4 col-sm-4 hidden-xs">
                                      <div className="profile-add">
                                        <span className="icon btn-file text-center">
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          ></i>
                                          <FileBase64
                                            multiple={true}
                                            name="resumeVideoURL"
                                            onChange={handleChange}
                                            onDone={event => {
                                              if (
                                                [
                                                  "video/mp4",
                                                  "video/avi",
                                                  "video/mpeg"
                                                ].indexOf(event[0].type) < 0
                                              ) {
                                                setFieldValue(
                                                  (values.documentTypeCheckForVideo = true)
                                                );
                                              } else if (
                                                event[0].file.size <= 10000000
                                              ) {
                                                values.document.push({
                                                  documentHash: event[0].base64,
                                                  documentType: "VideoResume",
                                                  documentName: event[0].name
                                                });
                                                setFieldValue(
                                                  (values.documentTypeCheckForVideo = false)
                                                );
                                                setFieldValue(
                                                  (values.documentSizeCheckForVideoCheck = false)
                                                );
                                                setFieldValue(
                                                  (values.resumeVideoURL =
                                                    event[0].base64)
                                                );
                                              } else {
                                                setFieldValue(
                                                  (values.documentSizeCheckForVideoCheck = true)
                                                );
                                              }
                                            }}
                                          />
                                        </span>
                                        <h4>Add Video</h4>
                                        {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Video</h4> */}
                                        {values.documentSizeCheckForVideoCheck ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:10MB).
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                        {values.documentTypeCheckForVideo ? (
                                          <p style={{ color: "red" }}>
                                            Invalid Format
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            Supported Formats: .mp4, .avi, .mpeg
                                            only. <br />
                                              File Size: Max 10 MB
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                              <a href="#">
                                <button
                                  type="submit"
                                  name="submitForm"
                                  class="btn-4 pull-right"
                                  onClick={async () => {
                                    const curErrors = await formikProps.validateForm();
                                    const curErrorsKeys = Object.keys(
                                      curErrors
                                    );

                                    if (curErrorsKeys.length) {
                                      // Assuming curErrorsKeys[0] identifies the first failing field, use that to
                                      // find the correct DOM element to call .focus() on.
                                      //
                                      // Since I set the id attribute on all my form's <input>s to match the error
                                      // keys, I just use document.getElementById(), but of course you could use refs
                                      const el = document.getElementById(
                                        curErrorsKeys[0]
                                      );

                                      if (el) el.focus();
                                    }
                                  }}
                                >
                                  Submit
                                </button>
                              </a>
                              <a href="#">
                                <button
                                  type="button"
                                  name="cancelForm"
                                  class="btn-4 mr-20 pull-right"
                                  onClick={this.handleCancel}
                                >
                                  Cancel
                                </button>
                              </a>
                            </div>
                          </div>
                        </main>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

export default AdminProfileLandingPage;
