import React from 'react';
import { LoginService } from "../../services/login.service";
import { error, success, warning, warningNotification, errorNotification, successNotification } from '../notification/notifications';

import DashboardHeader from "../dashboardheader/dashboardheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import Finxheader from "../../containers/finxheader/finxheader";
import ReactHTMLParser from "react-html-parser";
import { FetchAlertService } from "../../services/fetchalerts";

class MyAlert extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alertName: '',
            alertText: '',
            length: '',
            alertNotificationEvents: [],
            fetchalerts: '',
            openalert: JSON.stringify(this.props.location.state.alertid),
            alertcount: '',
            notificationcount: ''
        }
        this.handleCancel = this.handleCancel.bind(this);
    }
    handleCancel = () => {
        this.props.history.goBack('-1');
    };

    componentDidMount() {
        LoginService.TokenUser(res => {
            localStorage.setItem('jti-token', res.responseObject);
            if (res.status === 'success') {
                localStorage.setItem('TokenName', 'jwt');
                //let productName = "productName=Feat";   
                FetchAlertService.fetchAlert((res) => {
                    //console.log("res", res)
                    if (res.status === 'success') {
                        //console.log("Res ovj :" + res.responseListObject)
                        this.setState({ fetchalerts: res.responseListObject });
                    } else if (res.status === 'fail') {
                        this.setState({ loading: false });
                        if (res.reasonCode === 'SM_11') {
                            window.location.href = '/';
                        } else {
                            error(res.reasonText, errorNotification);
                        }

                    }
                    // alert("fetchalerts"+JSON.stringify(this.state.fetchalerts))
                    window.localStorage.setItem("alerts", this.state.fetchalerts)
                });
            }
        }, err => {
            //console.log(err);
            error("Sorry for Inconvience! Error has been raised", errorNotification);
        });


    }
    render(props) {
        const {
            alertNotificationEvents,
            pendingAlertNotificationEvents,
            errorAlertNotigicationEvents,
            notificationEvents,
            pendingNotificationEvents,
            errorNotificationEvents,
        } = this.props;
        return (

            <React.Fragment>
                <DashboardHeader
                    //alertNotificationEvents={this.props.location.state.alerts} {...this.props}
                    //notificationEvents={this.props.location.state.notification}{...this.props}
                    {...this.props}
                />

                <section id="notification-page" class="pt-20 mb-50">
                    <div class="container">
                        <div class="panel-group" id="accordion">
                            {this.state.fetchalerts ? this.state.fetchalerts.map((item, key) => (
                                <div class="panel panel-default">
                                    <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href={'#collapse' + item.alertID}>
                                        <div class="panel-heading">
                                            <h4 class="panel-title">
                                                {item.alertName}
                                            </h4>
                                        </div>
                                    </a>

                                    <div id={'collapse' + item.alertID}
                                        class={"panel-collapse collapse" + (this.props.location.state.alertid ? (this.props.location.state.alertid === item.alertID ? "in" : '') : "")} >
                                        <div class="panel-body">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div className="notification-content">
                                                    <div className="notification-body">
                                                        <p className="alert-body">{item.alertText} </p>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : ""

                            }
                        </div>
                        <div className="row">
                            <button className="btn-5 mr-20 ml-20 mt-10 pull-right" onClick={this.handleCancel}><span aria-hidden="true">Back</span></button>
                        </div>

                    </div>
                </section>

                <Finxfooter />
            </React.Fragment>

        )
    }

}

export default MyAlert;

