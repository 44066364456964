import React, { Component } from "react";
import zxcvbn from "zxcvbn";

class passwordStrengthMeter extends Component {
  createPasswordLabel = result => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{5,})"
    );
    var check = strongRegex.test(result.password);
    switch (check) {
      case false:
        return "Weak";
      case true:
        return "Strong";
    }
  };
  render() {
    const { password } = this.props;
    const testedResult = zxcvbn(password);
    return (
      <div className="password-strength-meter">
        <progress
          className={`password-strength-meter-progress strength-${this.createPasswordLabel(
            testedResult
          )}`}
          value={testedResult.score}
          max="2"
        />

        <label className="password-strength-meter-label">
          {password && (
            <>
              <strong>Password strength:</strong>
              {this.createPasswordLabel(testedResult)}
            </>
          )}
        </label>
      </div>
    );

  }
}
export default passwordStrengthMeter;
