import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import saveAs from 'save-as'
import { ReattemptTestService } from "../../services/reattemptTest.service"
import JSZipUtils from 'jszip-utils'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { createFilter } from 'react-select';
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import jsPDF from "jspdf";
import { ExcelService } from "../Excel/excel";
import Modal from "react-responsive-modal";
import "jspdf-autotable";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import { CSVLink, CSVDownload } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import { LookUpService } from "../../services/lookupService";
import { RegisterUserService } from "../../services/registration.service";
import { Global_var } from "../../global/global_var";
import { RegionManagementService } from "../../services/regionManagement.service";
import { LoginService } from "../../services/login.service";
var JSZip = require("jszip");

class EmpanellementReport extends Component {
    constructor(props) {
        super(props);
        if (localStorage.getItem('login') !== null) {
            this.state = {
                loading: false,
                selected: {},
                selectAll: 0,
                data: [],
                AmcList:[],
                exportData: [],
                listOfYear1: [],
                listOfMonth: [],
                listOfDay: [],
                jobData: [],
            }

        }
        else {
            window.location.href = '/';
        }
    }
    componentDidMount() {

        LookUpService.getAmc(
            res => {
            this.setState({
                AmcList: res.responseListObject,
              });
            }
          );
        
        LookUpService.getEmpanelmentReport(
            "fromDate=&toDate=&firstName=&lastName=&emailID=&mobileNo=&panNumber=&amcId=",
            res => {

                this.setState({
                    jobData: res.responseListObject,
                });

            }
        );

        var year = new Date().getFullYear();
        year = year
        var listOfYear = [];
        for (var i = 0; i < 100; i++) {
            var current = year - i;
            listOfYear.push(current);

        }
        var listOfMonth = [];

        var listOfMonth = [{ "key": 1, "value": "Jan" },
        { "key": 2, "value": "Feb" },
        { "key": 3, "value": "Mar" },
        { "key": 4, "value": "Apr" },
        { "key": 5, "value": "May" },
        { "key": 6, "value": "Jun" },
        { "key": 7, "value": "Jul" },
        { "key": 8, "value": "Aug" },
        { "key": 9, "value": "Sep" },
        { "key": 10, "value": "Oct" },
        { "key": 11, "value": "Nov" },
        { "key": 12, "value": "Dec" },
        ];
        var listOfDay = [];
        for (var i = 1; i <= 31; i++) {
            listOfDay.push(i);
        }
        this.setState({ listOfMonth: listOfMonth });
        this.setState({ listOfYear1: listOfYear });
        this.setState({ listOfDay: listOfDay });

    }

    filterCaseInsensitive = ({ id, value }, row) =>
        row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    
    exportToCSV = () => {

        let modifiedData = [];
        let data = this.state.jobData
        if (data.length > 0) {
    
          data.forEach((element, index) => {
            let d = element
            var data1 = {
    
              "First Name": element.firstName,
              "Last Name": element.lastName,
              "Pan Number": element.panNumber,
              "Email ID": element.emailID,
              "Mobile Number": element.mobileNumber,
              "Registration ate": ((d.registrationDate ||'').toString() !== '--'?(d.registrationDate.split(' ')[0].split('-')[2] + "-" + d.registrationDate.split(' ')[0].split('-')[1] + "-" + d.registrationDate.split(' ')[0].split('-')[0]):''),
              "Aditya Birla Sun Life Mutual Fund": ((d.dateOfAmc1 ||'').toString() !== '--'?(d.dateOfAmc1.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc1.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc1.split(' ')[0].split('-')[0]):'--'),
              "Axis Mutual Fund": ((d.dateOfAmc2 ||'').toString() !== '--'?(d.dateOfAmc2.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc2.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc2.split(' ')[0].split('-')[0]):'--'),
              "HDFC Mutual Fund": ((d.dateOfAmc3 ||'').toString() !== '--'?(d.dateOfAmc3.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc3.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc3.split(' ')[0].split('-')[0]):'--'),
              "ICICI Prudential Mutual Fund": ((d.dateOfAmc4 ||'').toString() !== '--'?(d.dateOfAmc4.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc4.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc4.split(' ')[0].split('-')[0]):'--'),
              "L&T Mutual Fund": ((d.dateOfAmc5 ||'').toString() !== '--'?(d.dateOfAmc5.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc5.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc5.split(' ')[0].split('-')[0]):'--'),
              "Nippon India Mutual Fund": ((d.dateOfAmc6 ||'').toString() !== '--'?(d.dateOfAmc6.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc6.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc6.split(' ')[0].split('-')[0]):'--'),
              "SBI Mutual Fund": ((d.dateOfAmc7 ||'').toString() !== '--'?(d.dateOfAmc7.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc7.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc7.split(' ')[0].split('-')[0]):'--'),
              "Sundaram Mutual Fund": ((d.dateOfAmc8 ||'').toString() !== '--'?(d.dateOfAmc8.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc8.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc8.split(' ')[0].split('-')[0]):'--'),
    
            };
            modifiedData.push(data1);
          });
    
          ExcelService.ExcelData(modifiedData, 'EmpanelmentDetail-Report');
        }
        else {
          warning("No record found", warningNotification)
        }
    };

    exportPDF = () => {

        if (this.state.jobData.length > 0) {
          const unit = "pt";
          const size = "A4"; // Use A1, A2, A3 or A4
          const orientation = "landscape"; // portrait or landscape
    
          const marginLeft = 40;
          const doc = new jsPDF(orientation, unit, size);
    
          doc.setFontSize(15);
          let modifiedData = [];
          const title = "Feat List";
          let data = this.state.jobData.length > 0 ? this.state.jobData :  this.state.jobData;
    
          const headers = [["Sr. No.", "First Name", "Last Name", "Email ID", "PAN No","Mobile Number",
            "Registration Date", "Aditya Birla Sun Life Mutual Fund", "Axis Mutual Fund", "HDFC Mutual Fund", "ICICI Prudential Mutual Fund",
            "ICICI Prudential Mutual Fund", "L&T Mutual Fund", "Nippon India Mutual Fund", "SBI Mutual Fund", "Sundaram Mutual Fund"]];
          data.forEach((element, index) => {
            
            modifiedData.push([
              index + 1,
              element.firstName,
              element.lastName,
              element.emailID,
              element.panNumber,
              element.mobileNumber,
              (element.registrationDate ||'').toString() !== '--'?(element.registrationDate.split(' ')[0].split('-')[2] + "-" + element.registrationDate.split(' ')[0].split('-')[1] + "-" + element.registrationDate.split(' ')[0].split('-')[0]):'',
              (element.dateOfAmc1 ||'').toString() !== '--'?(element.dateOfAmc1.split(' ')[0].split('-')[2] + "-" + element.dateOfAmc1.split(' ')[0].split('-')[1] + "-" + element.dateOfAmc1.split(' ')[0].split('-')[0]):'--',
              (element.dateOfAmc2 ||'').toString() !== '--'?(element.dateOfAmc2.split(' ')[0].split('-')[2] + "-" + element.dateOfAmc2.split(' ')[0].split('-')[1] + "-" + element.dateOfAmc2.split(' ')[0].split('-')[0]):'--',
              (element.dateOfAmc3 ||'').toString() !== '--'?(element.dateOfAmc3.split(' ')[0].split('-')[2] + "-" + element.dateOfAmc3.split(' ')[0].split('-')[1] + "-" + element.dateOfAmc3.split(' ')[0].split('-')[0]):'--',
              (element.dateOfAmc4 ||'').toString() !== '--'?(element.dateOfAmc4.split(' ')[0].split('-')[2] + "-" + element.dateOfAmc4.split(' ')[0].split('-')[1] + "-" + element.dateOfAmc4.split(' ')[0].split('-')[0]):'--',
              (element.dateOfAmc5 ||'').toString() !== '--'?(element.dateOfAmc5.split(' ')[0].split('-')[2] + "-" + element.dateOfAmc5.split(' ')[0].split('-')[1] + "-" + element.dateOfAmc5.split(' ')[0].split('-')[0]):'--',
              (element.dateOfAmc6 ||'').toString() !== '--'?(element.dateOfAmc6.split(' ')[0].split('-')[2] + "-" + element.dateOfAmc6.split(' ')[0].split('-')[1] + "-" + element.dateOfAmc6.split(' ')[0].split('-')[0]):'--',
              (element.dateOfAmc7 ||'').toString() !== '--'?(element.dateOfAmc7.split(' ')[0].split('-')[2] + "-" + element.dateOfAmc7.split(' ')[0].split('-')[1] + "-" + element.dateOfAmc7.split(' ')[0].split('-')[0]):'--',
              (element.dateOfAmc8 ||'').toString() !== '--'?(element.dateOfAmc8.split(' ')[0].split('-')[2] + "-" + element.dateOfAmc8.split(' ')[0].split('-')[1] + "-" + element.dateOfAmc8.split(' ')[0].split('-')[0]):'--',
            ])
    
          });
    
          let content = {
            startY: 50,
            head: headers,
            body: modifiedData
          };
    
          doc.text(title, marginLeft, 40);
          doc.autoTable(content);
    
          let date = new Date();
          let time = new Date().toLocaleString().split(",")[1]
    
          let time1 = time.split(" ")[2]
          time = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1
          time = time.split(" ")
    
          let date1 = moment(date).format("DD-MM-YYYY")
          let title1 = "Empanelment-Detail-" + date1 + "-" + time[1] + ".pdf"
    
          doc.save(title1)
        }
        else {
          warning("No record found", warningNotification)
        }
      }

    isValidDate = (fromDate, toDate) => {
        
        if (fromDate !== "") {
            let fromDate1 = fromDate.split('-')
            if (fromDate1[1] == 2 && fromDate1[2] > 29) {
                warning("Please select valid Date", warningNotification)
                return false
            } else if (fromDate1[1] == 4 || fromDate1[1] == 6 || fromDate1[1] == 9 || fromDate1[1] == 11) {
                if (fromDate1[2] > 30) {
                    warning("Please select valid Date", warningNotification)
                    return false
                } else {
                    return true
                }
            }
            else {
                return true
            }
        }

        if (toDate !== "") {
            let toDate1 = fromDate.split('-')
            if (toDate1[1] == 2 && toDate1[2] > 29) {
                warning("Please select valid Date", warningNotification)
                return false

            } else if (toDate1[1] == 4 || toDate1[1] == 6 || toDate1[1] == 9 || toDate1[1] == 11) {
                if (toDate1[2] > 30) {
                    warning("Please select valid Date", warningNotification)
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        }
    }

    calculateDate = (fromYear, fromMonth, fromDay, toYear, toMonth, toDay) => {

        fromYear = this.state.fromYear
        fromMonth = this.state.fromMonth
        fromDay = this.state.fromDay
        toYear = this.state.toYear
        toMonth = this.state.toMonth
        toDay = this.state.toDay
        
        let fromDate1 = ""
        if ((fromYear !== undefined && fromYear !== "" && fromYear !== "0") && (fromMonth !== undefined && fromMonth !== "" && fromMonth !== "0") && (fromDay !== undefined && fromDay !== "" && fromDay !== "0")) {
            fromDate1 = fromYear + "-" + fromMonth + "-" + fromDay
            let flag = this.isValidDate(fromDate1, "")
            this.setState({
                fromDate: fromDate1
            })
        } else {
            fromDate1 = ""
        }

        let toDate1 = ""
        if ((toYear !== undefined && toYear !== "" && toYear !== "0") && (toMonth !== undefined && toMonth !== "" && toMonth !== "0") && (toDay !== undefined && toDay !== "" && toDay !== "0")) {
            toDate1 = toYear + "-" + toMonth + "-" + toDay
            let flag = this.isValidDate("", toDate1)
            this.setState({
                toDate: toDate1
            })
        } else {
            toDate1 = ""
        }



        if (fromDate1 != "" && toDate1 != "") {
            if (fromDate1 > toDate1) {
                // warning("To Date should be greater than from date ",warningNotification)
                return false
            } else {

                return true

            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <DashboardHeader

                    {...this.props}

                />
                <PopUpPortal
                    HidePopup={this.state.loading}
                    IsVisible={this.state.loading}
                />

                <section class="dashboard-tab user-tab pt-50 mb-50">
                    <div class="padding-rl-50">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">

                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            status: '',
                                            fname: '',
                                            lname: '',
                                            mobileNo: '',
                                            fromDate: "",
                                            toDate: "",
                                            fromDate1: '',
                                            toDate1: '',
                                            emailID: '',
                                            panNo: '',
                                            cityId: '',
                                            city: '',
                                            parameter: '',
                                            listOfMonth: this.state.listOfMonth,
                                            listOfDay: this.state.listOfDay,
                                            listOfMonth1: this.state.listOfMonth,
                                            listOfDay1: this.state.listOfDay,
                                            day: '',
                                            month: '',
                                            year: '',
                                            today: '',
                                            tomonth: '',
                                            toyear: ''
                                        }}
                                        validationSchema={Yup.object().shape({
                                            //static validations
                                            fname: Yup.string()
                                                .matches(/^[a-zA-Z ]{1,180}$/, "you must specify text"),
                                            emailID: Yup.string()
                                                .email('Invalid E-Mail'),
                                            lname: Yup.string()
                                                .matches(/^[a-zA-Z ]{1,180}$/, "you must specify text"),
                                            panNo: Yup.string()
                                                .matches(
                                                    /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                                                    "Please enter the valid PAN number"
                                                ),
                                            mobileNo: Yup.string()
                                                .matches(/^[6-9]\d{9}$/, "Please enter the valid mobile number")
                                        }
                                        )}

                                        onSubmit={(fields, { resetForm }) => {
                                            
                                            let fromDate1 = ""
                                            if ((fields['year'] !== undefined && fields['year'] !== "") || (fields['month'] !== undefined && fields['month'] !== "") || (fields['day'] !== undefined && fields['day'] !== "")) {
                                                fromDate1 = fields['year'] + "-" + fields['month'] + "-" + fields['day']

                                            } else {
                                                //warning("Please select valid from date", warningNotification);

                                                fromDate1 = ""

                                            }

                                            let toDate1 = ""
                                            if ((fields['toyear'] !== undefined && fields['toyear'] !== "") || (fields['tomonth'] !== undefined && fields['tomonth'] !== "") || (fields['today'] !== undefined && fields['today'] !== "")) {
                                                toDate1 = fields['toyear'] + "-" + fields['tomonth'] + "-" + fields['today']

                                            } else {
                                                //warning("Please select valid to date", warningNotification);

                                                toDate1 = ""

                                            }
                                            if (fromDate1 !== "" && toDate1 !== "") {
                                                if (new Date(fromDate1) > new Date(toDate1)) {
                                                    warning("To Date should be greater than From Date", warningNotification)
                                                    fromDate1 = ""
                                                    toDate1 = ""
                                                    this.setState({
                                                        fromYear: "",
                                                        fromMonth: "",
                                                        fromDay: "",
                                                        toYear: "",
                                                        toMonth: "",
                                                        toDay: "",
                                                        fromDate: "",
                                                        toDate: ""
                                                    })
                                                    
                                                }
                                            }
                                            if (fields.status == "SEARCH") {
                                                //alert(fields.amcList)
                                                let param = ''
                                                let isblank = false;
                                                if ((fields.fname !== undefined || fields.fname !== '')) {
                                                    param = param + "firstName=" + fields.fname + '&'
                                                }
                                                if ((fields.lname !== undefined || fields.lname !== '')) {
                                                    param = param + "lastName=" + fields.lname + '&'
                                                }
                                                if (fromDate1 !== undefined && fromDate1 !== "") {
                                                    param = param + "fromDate=" + fromDate1 + '&'
                                                }
                                                else {
                                                    param = param + "fromDate=" + '&'
                                                }
                                                if (toDate1 !== undefined && toDate1 !== "") {
                                                    param = param + "toDate=" + toDate1 + '&'
                                                }
                                                else {
                                                    param = param + "toDate=" + '&'
                                                }
                                                if ((fields.emailID !== undefined || fields.emailID !== '')) {
                                                    param = param + "emailID=" + fields.emailID + '&'
                                                }
                                                if ((fields.panNo !== undefined || fields.panNo !== '')) {
                                                    param = param + "panNumber=" + fields.panNo + '&'
                                                }
                                                
                                                if ((fields.mobileNo !== undefined || fields.mobileNo !== '')) {
                                                    param = param + "mobileNo=" + fields.mobileNo + '&'
                                                }
                                                if ((fields.amcList !== '' && fields.amcList !== undefined)) {
                                                        param = param + "amcId=" + fields.amcList
                                                    } else {
                                                        param = param + "amcId=" 
                                                 }
                                                
                                                if ( fields.fname !== '' || fields.lname !== '' || fields.panNo !== '' || fields.emailID !== '' || toDate1 !== "" || fromDate1 !== "" || fields.mobileNo !== '' || fields.amcList !== '') {
                                                    LookUpService.getEmpanelmentReport(param, res => {
                                                        if (res.status === "success") {
                                                            this.setState({
                                                                jobData: res.responseListObject
                                                            })
                                                        }
                                                    });
                                                } else {
                                                    LookUpService.getEmpanelmentReport(
                                                    "fromDate=&toDate=&firstName=&lastName=&emailID=&mobileNo=&panNumber=&amcId=", res => {
                                                        if (res.status === "success") {
                                                            this.setState({
                                                                jobData: res.responseListObject
                                                            })
                                                        }
                                                    });

                                                    //warning("Atleast one parameter is required to fetch the data", warningNotification);
                                                }
                                            }
                                            else {
                                                LookUpService.getEmpanelmentReport(
                                                    "fromDate=&toDate=&firstName=&lastName=&emailID=&mobileNo=&panNumber=&amcId=", res => {
                                                        if (res.status === "success") {
                                                            this.setState({
                                                                jobData: res.responseListObject
                                                            })
                                                        }
                                                    });

                                            }

                                        }}

                                        render={({
                                            errors, touched, handleSubmit, values, setFieldValue
                                        }) => (
                                            <React.Fragment>
                                                <Form onSubmit={handleSubmit} className="form-group">
                                                    <section class="updateGDPI">
                                                        <div class="modal-body">
                                                            <div class="row">
                                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                                    <div class="product-add">
                                                                        <div class="form-group">
                                                                            <div class="row">
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        First Name
                                        </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="fname"
                                                                                        id="fname"
                                                                                        autoComplete="fname"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.fname &&
                                                                                                touched.text
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="First Name"
                                                                                        className="form-control"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="fname"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Last name
                        </label>
                                                                                    <Field
                                                                                        type="lname"
                                                                                        name="lname"
                                                                                        id="lname"
                                                                                        autoComplete="lname"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.lname &&
                                                                                                touched.lname
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="Last Name"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="lname"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Email Id
                                        </label>
                                                                                    <Field
                                                                                        type="emailID"
                                                                                        name="emailID"
                                                                                        id="emailID"
                                                                                        autoComplete="emailID"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.emailID &&
                                                                                                touched.emailID
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="Email Id"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="emailID"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        AMC From Date
                        </label>



                                                                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                                                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="day"
                                                                                                id="day"
                                                                                                // onChange={handleChange}
                                                                                                //  disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {

                                                                                                        
                                                                                                        var listOfMonth = []
                                                                                                        setFieldValue(values.day = e.target.value)
                                                                                                        if (e.target.value == 31) {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },

                                                                                                            { "key": 3, "value": "Mar" },

                                                                                                            { "key": 5, "value": "May" },

                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },

                                                                                                            { "key": 10, "value": "Oct" },

                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];


                                                                                                        } else if (e.target.value == 30) {
                                                                                                            listOfMonth = [

                                                                                                                { "key": 1, "value": "Jan" },

                                                                                                                { "key": 3, "value": "Mar" },
                                                                                                                { "key": 4, "value": "Apr" },
                                                                                                                { "key": 5, "value": "May" },
                                                                                                                { "key": 6, "value": "Jun" },
                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },
                                                                                                                { "key": 9, "value": "Sep" },
                                                                                                                { "key": 10, "value": "Oct" },
                                                                                                                { "key": 11, "value": "Nov" },
                                                                                                                { "key": 12, "value": "Dec" },

                                                                                                            ];


                                                                                                        }
                                                                                                        else if (e.target.value == 28 || e.target.value == 29) {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                            { "key": 2, "value": "Feb" },
                                                                                                            { "key": 3, "value": "Mar" },
                                                                                                            { "key": 4, "value": "Apr" },
                                                                                                            { "key": 5, "value": "May" },
                                                                                                            { "key": 6, "value": "Jun" },
                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },
                                                                                                            { "key": 9, "value": "Sep" },
                                                                                                            { "key": 10, "value": "Oct" },
                                                                                                            { "key": 11, "value": "Nov" },
                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];
                                                                                                        } else {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                            { "key": 2, "value": "Feb" },
                                                                                                            { "key": 3, "value": "Mar" },
                                                                                                            { "key": 4, "value": "Apr" },
                                                                                                            { "key": 5, "value": "May" },
                                                                                                            { "key": 6, "value": "Jun" },
                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },
                                                                                                            { "key": 9, "value": "Sep" },
                                                                                                            { "key": 10, "value": "Oct" },
                                                                                                            { "key": 11, "value": "Nov" },
                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];

                                                                                                        }
                                                                                                        setFieldValue(values.listOfMonth = listOfMonth)
                                                                                                        // this.setState({
                                                                                                        //   listOfMonth: listOfMonth
                                                                                                        // })
                                                                                                    }}

                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.day && touched.day
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">DD</option>
                                                                                                {(
                                                                                                    values.listOfDay ||
                                                                                                    []
                                                                                                ).map(d => (
                                                                                                    <option
                                                                                                        value={d}
                                                                                                    >
                                                                                                        {d}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="month"
                                                                                                id="month"
                                                                                                //   onChange={handleChange}
                                                                                                //     disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {
                                                                                                        

                                                                                                        setFieldValue(values.month = e.target.value)
                                                                                                        if (e.target.value == 2) {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 29; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                // this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 31; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                //  this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 30; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                //  this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        }

                                                                                                        setFieldValue(values.listOfDay = listOfDay)
                                                                                                    }
                                                                                                }
                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.month && touched.month
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">MM</option>
                                                                                                {(
                                                                                                    values.listOfMonth ||
                                                                                                    []
                                                                                                ).map(m => (
                                                                                                    <option
                                                                                                        value={m.key}
                                                                                                    >
                                                                                                        {m.value}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="year"
                                                                                                id="year"
                                                                                                // onChange={handleChange}
                                                                                                //    disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {


                                                                                                        setFieldValue(values.year = e.target.value)
                                                                                                    }}
                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.year && touched.year
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">YYYY</option>
                                                                                                {(
                                                                                                    this.state.listOfYear1 ||
                                                                                                    []
                                                                                                ).map(year => (
                                                                                                    <option
                                                                                                        value={year}
                                                                                                    >
                                                                                                        {year}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                    </div>



                                                                                    <p className="validation">  {values.errmsg}</p>
                                                                                    {errors.fromDate && <div className="validation">{errors.fromDate}</div>}
                                                                                </div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        AMC To Date
                                      </label>


                                                                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                                                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="today"
                                                                                                id="today"
                                                                                                // onChange={handleChange}
                                                                                                //  disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {

                                                                                                        
                                                                                                        var listOfMonth = []
                                                                                                        setFieldValue(values.today = e.target.value)
                                                                                                        if (e.target.value == 31) {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },

                                                                                                            { "key": 3, "value": "Mar" },

                                                                                                            { "key": 5, "value": "May" },

                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },

                                                                                                            { "key": 10, "value": "Oct" },

                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];


                                                                                                        } else if (e.target.value == 30) {
                                                                                                            listOfMonth = [

                                                                                                                { "key": 1, "value": "Jan" },

                                                                                                                { "key": 3, "value": "Mar" },
                                                                                                                { "key": 4, "value": "Apr" },
                                                                                                                { "key": 5, "value": "May" },
                                                                                                                { "key": 6, "value": "Jun" },
                                                                                                                { "key": 7, "value": "Jul" },
                                                                                                                { "key": 8, "value": "Aug" },
                                                                                                                { "key": 9, "value": "Sep" },
                                                                                                                { "key": 10, "value": "Oct" },
                                                                                                                { "key": 11, "value": "Nov" },
                                                                                                                { "key": 12, "value": "Dec" },

                                                                                                            ];


                                                                                                        }
                                                                                                        else if (e.target.value == 28 || e.target.value == 29) {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                            { "key": 2, "value": "Feb" },
                                                                                                            { "key": 3, "value": "Mar" },
                                                                                                            { "key": 4, "value": "Apr" },
                                                                                                            { "key": 5, "value": "May" },
                                                                                                            { "key": 6, "value": "Jun" },
                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },
                                                                                                            { "key": 9, "value": "Sep" },
                                                                                                            { "key": 10, "value": "Oct" },
                                                                                                            { "key": 11, "value": "Nov" },
                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];
                                                                                                        } else {
                                                                                                            listOfMonth = [{ "key": 1, "value": "Jan" },
                                                                                                            { "key": 2, "value": "Feb" },
                                                                                                            { "key": 3, "value": "Mar" },
                                                                                                            { "key": 4, "value": "Apr" },
                                                                                                            { "key": 5, "value": "May" },
                                                                                                            { "key": 6, "value": "Jun" },
                                                                                                            { "key": 7, "value": "Jul" },
                                                                                                            { "key": 8, "value": "Aug" },
                                                                                                            { "key": 9, "value": "Sep" },
                                                                                                            { "key": 10, "value": "Oct" },
                                                                                                            { "key": 11, "value": "Nov" },
                                                                                                            { "key": 12, "value": "Dec" },
                                                                                                            ];

                                                                                                        }
                                                                                                        setFieldValue(values.listOfMonth1 = listOfMonth)
                                                                                                        // this.setState({
                                                                                                        //   listOfMonth: listOfMonth
                                                                                                        // })
                                                                                                    }}

                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.today && touched.today
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">DD</option>
                                                                                                {(
                                                                                                    values.listOfDay1 ||
                                                                                                    []
                                                                                                ).map(d => (
                                                                                                    <option
                                                                                                        value={d}
                                                                                                    >
                                                                                                        {d}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="tomonth"
                                                                                                id="tomonth"
                                                                                                //   onChange={handleChange}
                                                                                                //     disabled={this.state.isDisabled}
                                                                                                //  disabled
                                                                                                onChange={
                                                                                                    e => {


                                                                                                        setFieldValue(values.tomonth = e.target.value)
                                                                                                        if (e.target.value == 2) {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 29; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                // this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        } else if (e.target.value == 1 || e.target.value == 3 || e.target.value == 5 || e.target.value == 7 || e.target.value == 8 || e.target.value == 10 || e.target.value == 12) {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 31; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                //  this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        }
                                                                                                        else {
                                                                                                            var listOfDay = [];
                                                                                                            for (var i = 1; i <= 30; i++) {
                                                                                                                //    var current = year - i;
                                                                                                                listOfDay.push(i);
                                                                                                                //  this.setState({ listOfDay: listOfDay });
                                                                                                            }
                                                                                                        }
                                                                                                        setFieldValue(values.listOfDay1 = listOfDay)
                                                                                                    }
                                                                                                }
                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.tomonth && touched.tomonth
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">MM</option>
                                                                                                {(
                                                                                                    values.listOfMonth1 ||
                                                                                                    []
                                                                                                ).map(m => (
                                                                                                    <option
                                                                                                        value={m.key}
                                                                                                    >
                                                                                                        {m.value}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                                                                            <Field
                                                                                                as="select"
                                                                                                name="toyear"
                                                                                                id="toyear"
                                                                                                onChange={
                                                                                                    e => {


                                                                                                        setFieldValue(values.toyear = e.target.value)
                                                                                                    }}
                                                                                                className={
                                                                                                    "form-control" +
                                                                                                    (errors.toyear && touched.toyear
                                                                                                        ? "is-invalid"
                                                                                                        : "")
                                                                                                }
                                                                                                className="form-control"
                                                                                            >
                                                                                                <option value="">YYYY</option>
                                                                                                {(
                                                                                                    this.state.listOfYear1 ||
                                                                                                    []
                                                                                                ).map(year => (
                                                                                                    <option
                                                                                                        value={year}
                                                                                                    >
                                                                                                        {year}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Field>
                                                                                        </div>
                                                                                    </div>
                                                                                   
                                                                                    <p className="validation">  {values.errmsg1}</p>

                                                                                    {errors.toDate && <div className="validation">{errors.toDate}</div>}
                                                                                    
                                                                                </div>

                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Pan No
                        </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="panNo"
                                                                                        id="panNo"
                                                                                        autoComplete="panNo"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.panNo &&
                                                                                                touched.panNo
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="PAN No"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="panNo"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>
                                                                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12"></div>
                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        AMC
                        </label>


                        <Field
                                               as="select"
                                              name="amcList"
                                              id="amcList"
                                              autoComplete="amcList"
                                              className={
                                                "form-control" +
                                                (errors.amcList &&
                                                  touched.amcList
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="amcList"
                                             
                                             
                                            >

{/* AmcList */}
                                            <option value="">--Select--</option>
                                            {this.state.AmcList.length>0 && this.state.AmcList.map(team =>
                                           
                                              <option
                                                key={team.mst_amc_id}
                                                value={team.mst_amc_id}
                                              >
                                                {team.mst_amc_name}
                                              </option>
                                            ) }
                                                </Field>


                                                                                </div>

                                                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                                                    <label>
                                                                                        Mobile Number
                                        </label>
                                                                                    <Field
                                                                                        type="text"
                                                                                        name="mobileNo"
                                                                                        id="mobileNo"
                                                                                        autoComplete="mobileNo"
                                                                                        className={
                                                                                            "form-control" +
                                                                                            (errors.mobileNo &&
                                                                                                touched.text
                                                                                                ? " is-invalid"
                                                                                                : "")
                                                                                        }
                                                                                        placeholder="Mobile Number"
                                                                                        className="form-control"
                                                                                    />
                                                                                    <ErrorMessage
                                                                                        name="mobileNo"
                                                                                        component="div"
                                                                                        className="validation"
                                                                                    />
                                                                                </div>

                                                                                {/* :""} */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr mb-20">
                                                                            <button
                                                                                type="submit"
                                                                                class="btn-4 pull-right"
                                                                                onClick={async () => {
                                                                                    setFieldValue(
                                                                                        (values.status = 'SEARCH')
                                                                                    );
                                                                                }}
                                                                            >Search</button>
                                                                            <button
                                                                                type="submit"
                                                                                id="close"
                                                                                class="btn-4 pull-right"
                                                                                onClick={async () => {
                                                                                    setFieldValue(
                                                                                        (values.status = 'RESET')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.mobileNo = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.fname = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.lname = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.fromDate = '')
                                                                                    ); setFieldValue(
                                                                                        (values.toDate = '')
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.emailID = '')
                                                                                    ); setFieldValue(
                                                                                        (values.panNo = '')
                                                                                    );

                                                                                    setFieldValue(
                                                                                        (values.city = '')
                                                                                    );
                                                                                    
                                                                                    setFieldValue(
                                                                                        (values.amcList = '')
                                                                                    );



                                                                                    setFieldValue(
                                                                                        (values.day = "")
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.month = "")
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.year = "")
                                                                                    );


                                                                                    setFieldValue(
                                                                                        (values.today = "")
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.tomonth = "")
                                                                                    );
                                                                                    setFieldValue(
                                                                                        (values.toyear = "")
                                                                                    );
                                                                                }}
                                                                            >
                                                                                Reset
                  </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </Form>
                                            </React.Fragment>
                                        )}
                                    />

                                    <div class="table-header">


                                        <h3>
                                            <div class="row">
                                                <div class="col-md-6 col-sm-6 col-xs-12">
                                                    <span class="left-icon">
                                                        <i class="fa fa-list" aria-hidden="true"></i>
                                                    </span>{" "}
                                                    Empanelment Details
                        </div>
                                                <div class="col-md-6 col-sm-6 col-xs-12">
                                                    <span class="right-icon">

                                                        <a title="Export to Excel" >
                                                            <i
                                                                onClick={() => this.exportToCSV()}
                                                                class="fa fa-file-excel-o"
                                                                aria-hidden="true"

                                                            ></i>
                                                        </a>
                                                    </span>
                                                    {/* <span class="right-icon">
                                                        <a title="Export to PDF">
                                                            <i
                                                                onClick={() => this.exportPDF()}
                                                                class="fa fa-file-pdf-o"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </a>
                                                    </span> */}
                                                </div>
                                            </div>
                                        </h3>
                                    </div>

                                    <div class="bg-box mb-30">
                                        <div class="table-responsive">

                                            <ReactTable
                                                minRows={2}
                                                data={this.state.jobData === null ? [] : this.state.jobData}


                                                className="-striped -highlight"
                                                filterable={false}
                                                onPageChange={this.stateonpagechange}
                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                ref={(refReactTable) => { this.refReactTable = refReactTable; }}

                                                columns={[
                                                    {
                                                        columns: [

                                                            {
                                                                Header: "Sr No",
                                                                id: "row",
                                                                width: 50,
                                                                Cell: (row) => {
                                                                    return <div>{row.index + 1}</div>;
                                                                }
                                                            },
                                                           
                                                            {
                                                                Header: "First Name",
                                                                id: "firstName",
                                                                accessor: d => d.firstName,

                                                            },
                                                            {
                                                                Header: "Last Name",
                                                                id: "lastName",
                                                                accessor: d => d.lastName,

                                                            },


                                                            {
                                                                Header: "Pan Card",
                                                                id: "pan",
                                                                accessor: d => d.panNumber
                                                            },
                                                            {
                                                                Header: "Email Id",
                                                                id: "email",
                                                                accessor: d => d.emailID,

                                                            },
                                                            {
                                                                Header: "Mobile No.",
                                                                id: "MobNo",
                                                                accessor: d => d.mobileNumber,

                                                            },


                                                            {
                                                                Header: "Registration Date",
                                                                id: "registrationDate",
                                                                accessor: (d) =>
                                    (d.registrationDate ||'').toString() !== '--'?(d.registrationDate.split(' ')[0].split('-')[2] + "-" + d.registrationDate.split(' ')[0].split('-')[1] + "-" + d.registrationDate.split(' ')[0].split('-')[0]):'',
                                                            },
                                                            {
                                                                Header: "Aditya Birla Sun Life Mutual Fund",
                                                                id: "dateOfAmc1",
                                                                accessor: (d) =>
                                    (d.dateOfAmc1 ||'').toString() !== '--'?(d.dateOfAmc1.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc1.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc1.split(' ')[0].split('-')[0]):'--',
                                                            },
                                                            {
                                                                Header: "Axis Mutual Fund",
                                                                id: "dateOfAmc2",
                                                                accessor: (d) =>
                                    (d.dateOfAmc2 ||'').toString() !== '--'?(d.dateOfAmc2.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc2.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc2.split(' ')[0].split('-')[0]):'--',
                                                            },

                                                            {
                                                                Header: "HDFC Mutual Fund",
                                                                id: "dateOfAmc3",
                                                                accessor: (d) =>
                                    (d.dateOfAmc3 ||'').toString() !== '--'?(d.dateOfAmc3.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc3.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc3.split(' ')[0].split('-')[0]):'--',

                                                            },
                                                            {
                                                                Header: "ICICI Prudential Mutual Fund",
                                                                id: "dateOfAmc4",
                                                                accessor: (d) =>
                                    (d.dateOfAmc4 ||'').toString() !== '--'?(d.dateOfAmc4.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc4.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc4.split(' ')[0].split('-')[0]):'--',

                                                            },

                                                            {
                                                                Header: "L&T Mutual Fund",
                                                                id: "dateOfAmc5",
                                                                accessor: (d) =>
                                    (d.dateOfAmc5 ||'').toString() !== '--'?(d.dateOfAmc5.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc5.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc5.split(' ')[0].split('-')[0]):'--',

                                                            },
                                                            {
                                                                Header: "Nippon India Mutual Fund",
                                                                id: "dateOfAmc6",
                                                                accessor: (d) =>
                                    (d.dateOfAmc6 ||'').toString() !== '--'?(d.dateOfAmc6.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc6.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc6.split(' ')[0].split('-')[0]):'--',

                                                            },
                                                            {
                                                                Header: "SBI Mutual Fund",
                                                                id: "dateOfAmc7",
                                                                accessor: (d) =>
                                    (d.dateOfAmc7 ||'').toString() !== '--'?(d.dateOfAmc7.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc7.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc7.split(' ')[0].split('-')[0]):'--',

                                                            },
                                                            {
                                                                Header: "Sundaram Mutual Fund",
                                                                id: "dateOfAmc8",
                                                                accessor: (d) =>
                                    (d.dateOfAmc8 ||'').toString() !== '--'?(d.dateOfAmc8.split(' ')[0].split('-')[2] + "-" + d.dateOfAmc8.split(' ')[0].split('-')[1] + "-" + d.dateOfAmc8.split(' ')[0].split('-')[0]):'--',

                                                            }

                                                        ]
                                                    }
                                                ]}
                                                defaultPageSize={10}
                                                style={{
                                                    width: "100%",
                                                    maxHeight: "500px"
                                                }}
                                            />


                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>


            </React.Fragment>
        );

    }
}

export default EmpanellementReport;
