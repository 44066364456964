import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Checkout from '../../components/checkout/checkout';
import DashboardHeader from '../../components/dashboardheader/dashboardheader'
import Finxfooter from '../finxfooter/finxfooter'
class CheckoutContainer extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <React.Fragment>

                <Checkout {...this.props} />

            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {

}
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer)