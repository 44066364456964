import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import { addAdminUser, editAdminUser } from "../../services/admin.service";
import $ from "jquery";
import {
  success,
  warning,
  successNotification,
  error,
  errorNotification
} from "../notification/notifications";
import RestDataSource from "../../services/restdatasource";
import { LoginService } from "../../services/login.service";
import { RegionManagementService } from "../../services/regionManagement.service";
import { Global_var } from "../../global/global_var";
import { RegisterUserService } from "../../services/registration.service";
import Adminheader from "../dashboardheader/adminheader";
import { pascalCase } from "change-case";

import Finxfooter from "../../containers/finxfooter/finxfooter";
import { da } from "date-fns/locale";
import AdminProfileComplitionBar from "./adminProfileComplitionBar";

class AdminProfileEdit extends Component {
  constructor(props) {
    super(props);

    //console.log("Admin props:")
    ///console.log(props)
    //console.log("AdminProfileEdit", this.props)
    //console.log("AdminProfile", this.props.history.location.query.alertNotificationEvents)
    this.state = {
      addressFlag: "",
      role: "",
      firstName: "",
      middleName: "",
      lastName: "",
      emailID: "",
      mobileNo: "",
      panNumber: "",
      aadharNumber: "",
      contactNo: "",
      secondaryEmailID: "",
      isActive: "",
      createdDate: "",
      lastModifiedDate: "",
      peraddress: "",
      corsAddress: "",
      ca_address_id: "",
      ca_address_1: "",
      ca_address_2: "",
      ca_country: "",
      ca_state: "",
      ca_city: "",
      ca_postal_code: "",
      pa_address_id: "",
      pa_address_1: "",
      pa_address_2: "",
      pa_country: "",
      pa_state: "",
      pa_city: "",
      pa_postal_code: "",
      pa_address_1_1: "",
      pa_address_2_1: "",
      pa_country_1: "",
      pa_state_1: "",
      pa_city_1: "",
      pa_postal_code_1: "",
      countryList: [],
      stateList: [],
      cityList: [],
      countryListForPer: [],
      stateListForPer: [],
      cityListForPer: []
    };
  }

  handleCancel = () => {
    this.props.history.goBack('-1');
    // if (localStorage.getItem("profilebackto") == "admin-dashboard") {
    //   this.props.history.push("/admin-dashboard");
    // }
    // else {
    //   this.props.history.push("/admin-user-list");
    // }
  };

  componentWillMount() {
    LoginService.TokenUser(res => {
      if (res.status === 'success') {
        localStorage.setItem("jwt-token", res.responseObject);
      } else if (res.status === 'fail') {
        if (res.reasonCode === 'SM_11') {
          window.location.href = '/';
        } else {
          error(res.reasonText, errorNotification);
        }
      }


    });

    RegionManagementService.getCountry(res => {
      this.setState({
        countryList: res.responseListObject,
        countryListForPer: res.responseListObject
      });
    });
    RegisterUserService.getEmployeType(res => {
      this.setState({
        employeTypeForParents: res.responseListObject
      });
    });
  }

  componentDidMount() {
    //localStorage.setItem("profilebackto", 'admin-dashboard')
    // $("#topheader .tab a").on("click", function () {
    //   $("#topheader .tab")
    //     .find("li.active")
    //     .removeClass("active");
    //   $(this)
    //     .parent("li")
    //     .addClass("active");
    // });

    window.addEventListener("scroll", e => onScroll(e), true);

    function onScroll(event) {
      //var scrollPos = $("section.createusers").offset().top;
      var scrollPos = ''
      var nav = $('section.createusers');
      if (nav.length) {
        scrollPos = $("section.createusers").offset().top;
      }
      else {
        scrollPos = '';
      }
      $("section.page-section").each(function () {
        var scrollid = $(this).attr("id");
        var scrollPos = $("section#" + scrollid).offset().top;
        if (scrollPos > 100 && scrollPos < 300) {
          //console.log("Scroll position :" + scrollid + scrollPos);
          $("#topheader .tab li").removeClass("active");
          $("a[href='#" + [scrollid] + "']")
            .parent("li")
            .addClass("active");
        }
      });
    }

    let partyID = localStorage.getItem("userid-Token");
    let roleId = localStorage.getItem("roleId");

    this.dataSource = new RestDataSource(
      Global_var.BASEURL +
      Global_var.URL_FETCH_ADMIN_USER_BY_ROLE_PARTY_ID +
      "?partyID=" +
      partyID +
      "&roleID=" +
      roleId
    );
    LoginService.TokenUser(res => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);
        this.dataSource.GetData(res => {
          this.setState({
            learnerID: res.data.responseObject.learnerID || "",
            mobileNo:
              res.data.responseObject.mobileNo ||
              res.data.responseObject.contactNo ||
              "",
            emailID:
              res.data.responseObject.emailID ||
              res.data.responseObject.contactEmail ||
              "",

            firstName: res.data.responseObject.firstName || "",
            middleName: res.data.responseObject.middleName || "",
            lastName: res.data.responseObject.lastName || "",
            secondaryEmailID:
              res.data.responseObject.contactEmail2 ||
              res.data.responseObject.secondaryEmailID ||
              "",
            contactNo:
              res.data.responseObject.contactNo2 ||
              res.data.responseObject.contactNo ||
              "",

            isActive: res.data.responseObject.isActive
          });
        });
      }
    });

    LoginService.TokenUser(res => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);

        RegisterUserService.getAdminUserAddress("partyId=" + partyID, res => {
          res.responseListObject.forEach(addr => {
            //console.log("ADDRESS: ", addr);
            if (addr.addressType == "permanent") {
              RegionManagementService.getState(
                "countryId=" + addr.countryId,
                res => {
                  this.setState({
                    stateListForPer: res.responseListObject
                  });
                }
              );
              RegionManagementService.getCity(
                "stateId=" + addr.stateId,
                res => {
                  this.setState({
                    cityListForPer: res.responseListObject
                  });
                }
              );
              this.setState({
                pa_address_id: addr.addressId,
                pa_address_1: addr.street,
                pa_address_2: addr.street2,
                pa_country: addr.countryId,
                pa_state: addr.stateId,
                pa_city: addr.cityId,
                pa_postal_code: addr.postalCode
              });
            }
            if (addr.addressType == "correspondence") {
              RegionManagementService.getState(
                "countryId=" + addr.countryId,
                res => {
                  this.setState({
                    stateList: res.responseListObject
                  });
                }
              );
              RegionManagementService.getCity(
                "stateId=" + addr.stateId,
                res => {
                  this.setState({
                    cityList: res.responseListObject
                  });
                }
              );
              this.setState({
                ca_address_id: addr.addressId,
                ca_address_1: addr.street,
                ca_address_2: addr.street2,
                ca_country: addr.countryId,
                ca_state: addr.stateId,
                ca_city: addr.cityId,
                ca_postal_code: addr.postalCode
              });
            }
          });
          RegionManagementService.getCountry(res => {
            this.setState({
              countryList: res.responseListObject,
              countryListForPer: res.responseListObject
            });
          });
        });
      }
    });
  }

  render() {
    var alertdata = JSON.parse(localStorage.getItem("alertd"))
    var notealertdata = JSON.parse(localStorage.getItem("notealert"))


    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          mobileNo: this.state.mobileNo,
          emailID: this.state.emailID,
          isActive: this.state.isActive,
          firstName: this.state.firstName,
          middleName: this.state.middleName,
          lastName: this.state.lastName,
          contactNo: this.state.contactNo,
          secondaryEmailID: this.state.secondaryEmailID,
          ca_address_1: this.state.ca_address_1,
          ca_address_2: this.state.ca_address_2,
          ca_country: this.state.ca_country,
          ca_state: this.state.ca_state,
          ca_city: this.state.ca_city,
          ca_postal_code: this.state.ca_postal_code,
          pa_address_1: this.state.pa_address_1,
          pa_address_2: this.state.pa_address_2,
          pa_country: this.state.pa_country,
          pa_state: this.state.pa_state,
          pa_city: this.state.pa_city,
          pa_postal_code: this.state.pa_postal_code,
          stateList: this.state.stateList,
          cityList: this.state.cityList,
          stateListForPer: this.state.stateListForPer,
          cityListForPer: this.state.cityListForPer,
          role: this.state.role,
          addressFlag: this.state.addressFlag
        }}
        validationSchema={Yup.object().shape({
          //static validations
          mobileNo: Yup.string()
            .required("You must specify a Mobile Number")
            .matches(/^[6-9]\d{9}$/, "Please enter the valid mobile number"),
          emailID: Yup.string()
            .required("You must specify a Email ID")
            .email("Please enter the valid email ID"),

          firstName: Yup.string().required("You must specify a First Name"),
          middleName: Yup.string(),
          lastName: Yup.string().required("You must specify a Last Name"),
          contactNo: Yup.string().matches(
            /^[6-9]\d{9}$/,
            "Please enter the valid mobile number"
          ),
          secondaryEmailID: Yup.string().email(
            "Please enter the valid email ID"
          ),
          ca_address_1: Yup.string().required("You must specify a Address"),
          ca_address_2: Yup.string(),
          ca_country: Yup.string().required("You must specify a Country"),
          ca_state: Yup.string().required("You must specify a State"),
          ca_city: Yup.string().required("You must specify a City"),
          ca_postal_code: Yup.string()
            .required("Pin Code is required")
            .matches(/^\d{6}$/, "Please enter valid postal code"),
          pa_address_1: Yup.string().required("You must specify a Address"),
          pa_address_2: Yup.string(),
          pa_country: Yup.string().required("You must specify a Country"),
          pa_state: Yup.string().required("You must specify a State"),
          pa_city: Yup.string().required("You must specify a City"),
          pa_postal_code: Yup.string()
            .required("You must specify a Pin Code")
            .matches(/^\d{6}$/, "Please enter valid postal code")
        })}
        onSubmit={values => {
          let partyID = localStorage.getItem("userid-Token");
          let roleId = localStorage.getItem("roleId");
          let userId = localStorage.getItem("userid");
          let data = {
            roleID: roleId,
            partyID: partyID,
            userID: userId,
            mobileNo: values.mobileNo,
            emailID: values.emailID,
            gender: "",
            isActive: values.isActive,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            contactNo: values.contactNo,
            secondaryEmailID: values.secondaryEmailID,
            dob: "",
            panNumber: "",
            aadharNumber: "",
            address: [
              {
                addressType: "permanent",
                addressId: this.state.pa_address_id,
                street: values.pa_address_1,
                street2: values.pa_address_2,
                cityId: values.pa_city,
                stateId: values.pa_state,
                countryId: values.pa_country,
                postalCode: values.pa_postal_code
              },
              {
                addressType: "correspondence",
                addressId: this.state.ca_address_id,
                street: values.ca_address_1,
                street2: values.ca_address_2,
                cityId: values.ca_city,
                stateId: values.ca_state,
                countryId: values.ca_country,
                postalCode: values.ca_postal_code
              }
            ],
            learnerFatherName: "",
            learnerFatherDOB: "",
            learnerFatherEmployementType: "",
            learnerFatherCompanyName: "",
            learnerMotherName: "",
            learnerMotherDOB: "",
            learnerMotherEmployementType: "",
            learnerMotherCompanyName: "",
            learnerQualification: [],
            profilePhotoURL: "",
            resumeDocumentURL: "",
            resumeVideoURL: ""
          };

          //console.log(JSON.stringify(data));

          editAdminUser(data, cb => {
            if (cb.status === "success") {
              success(
                "Dear" +
                " " +
                values.firstName +
                " , your admin profile has been updated on Dap successfully",
                successNotification
              );

              this.props.history.push("/admin-dashboard");
            } else {
              error(cb.reasonText, {
                ...errorNotification,
                container: "top-center"
              });
            }
          });
        }}
        render={({
          errors,
          touched,
          handleSubmit,
          handleChange,
          values,
          ...formikProps
        }) => (
            <React.Fragment>
              <Adminheader
                //alertNotificationEvents={(this.props.history.location.query || '').alertNotificationEvents || []}
                //notificationEvents={(this.props.history.location.query || '').notificationEvents || []}
                {...this.props}
              />
              <section class="dashboard-tab user-profile">
                <Form onSubmit={handleSubmit}
                  onKeyDown={onKeyDown => {
                    if ((onKeyDown.charCode || onKeyDown.keyCode) === 13) {
                      onKeyDown.preventDefault();
                    }
                  }}
                  className="form-group">

                  <div class="">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                          <div class="profile-left-sidebar">
                            <div class="profile-sidebar">
                              <div class="table-header">
                                <div class="container-fluid">
                                  <div id="topheader">
                                    <nav data-scroll-header>
                                      <ul class="tab">
                                        <div className="col-md-12 col-lg-12 col-sm-6 col-xs-12">
                                          <AdminProfileComplitionBar
                                            adminProfileData={(this.props.location.query || '').adminProfileData}
                                            checkFlag="fromEditProfile"
                                          />
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-sm-6 col-xs-12">
                                          <li>
                                            <a href="#create-user">
                                              Personal Details
                                        </a>
                                            {values.mobileNo &&
                                              values.contactNo &&
                                              values.emailID &&
                                              values.secondaryEmailID &&
                                              values.firstName &&
                                              values.middleName &&
                                              values.lastName ? (
                                                <span className="pull-right">
                                                  {" "}
                                                  <i
                                                    className="fa fa-check-circle"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                </span>
                                              ) : (
                                                <span className="pull-right">
                                                  {" "}
                                                  <i
                                                    className="fa fa-times-circle red"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              )}
                                          </li>
                                          <li>
                                            <a href="#create-address">
                                              Address Details
                                        </a>
                                            {values.ca_address_1 &&
                                              values.ca_address_2 &&
                                              values.ca_city &&
                                              values.ca_country &&
                                              values.ca_state &&
                                              values.ca_postal_code &&
                                              values.pa_address_1 &&
                                              values.pa_address_2 &&
                                              values.pa_city &&
                                              values.pa_state &&
                                              values.pa_country &&
                                              values.pa_postal_code ? (
                                                <span className="pull-right">
                                                  {" "}
                                                  <i
                                                    className="fa fa-check-circle"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                </span>
                                              ) : (
                                                <span className="pull-right">
                                                  {" "}
                                                  <i
                                                    className="fa fa-times-circle red"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              )}
                                          </li>
                                        </div>
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12 pt-130">
                          <main class="content-pos-fixed">
                            <div class="bg-box">
                              <section class="create-user page-section createusers" id="create-user">
                                <div class="row">
                                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 class="sub-header">Personal Details</h2>
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Mobile No{" "}
                                            <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            id="mobileNo"
                                            name="mobileNo"
                                            onChange={handleChange}
                                            disabled
                                            className={
                                              "form-control" +
                                              (errors.mobileNo && touched.mobileNo
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Mobile No"
                                          />
                                          <ErrorMessage
                                            name="mobileNo"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Email Id{" "}
                                            <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="emailID"
                                            id="emailID"
                                            disabled
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.emailID && touched.emailID
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Email ID"
                                          />
                                          <ErrorMessage
                                            name="emailID"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            First Name{" "}
                                            <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.firstName &&
                                                touched.firstName
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="First Name"
                                          />
                                          <ErrorMessage
                                            name="firstName"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Middle Name</label>
                                          <Field
                                            type="text"
                                            name="middleName"
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.middleName &&
                                                touched.middleName
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Middle Name"
                                          />
                                          <ErrorMessage
                                            name="middleName"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Last Name{" "}
                                            <span className="validation"> *</span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.lastName && touched.lastName
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Last Name"
                                          />
                                          <ErrorMessage
                                            name="lastName"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Secondary Contact No</label>
                                          <Field
                                            type="text"
                                            name="contactNo"
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.contactNo &&
                                                touched.contactNo
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Mobile Number"
                                          />
                                          <ErrorMessage
                                            name="contactNo"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Secondary Email ID</label>
                                          <Field
                                            type="text"
                                            name="secondaryEmailID"
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.secondaryEmailID &&
                                                touched.secondaryEmailID
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Email ID"
                                          />
                                          <ErrorMessage
                                            name="secondaryEmailID"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                              <section class="create-address page-section" id="create-address">
                                <h2 class="sub-header">Address Details</h2>
                                <div class="address-border">
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                        <h5 class="address-heading">
                                          Correspondence Address
                                      </h5>
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Address 1{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="ca_address_1"
                                          id="ca_address_1"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.ca_address_1 &&
                                              touched.ca_address_1
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Address"
                                        />
                                        <ErrorMessage
                                          name="ca_address_1"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>Address 2</label>
                                        <Field
                                          type="text"
                                          name="ca_address_2"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.ca_address_2 &&
                                              touched.ca_address_2
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Address"
                                        />
                                        <ErrorMessage
                                          name="ca_address_2"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Country{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="ca_country"
                                          id="ca_country"
                                          onClick={event => {
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            if (event.target.value !== "") {
                                              values.ca_country =
                                                event.target.value;
                                              values.stateList = [];
                                              values.cityList = [];
                                              RegionManagementService.getState(
                                                "countryId=" + values.ca_country,
                                                res => {
                                                  values.stateList =
                                                    res.responseListObject;
                                                }
                                              );
                                            }
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.ca_country &&
                                              touched.ca_country
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          {this.state.countryList.map(team => (
                                            <option
                                              key={team.countryId}
                                              value={team.countryId}
                                            >
                                              {pascalCase(team.countryName)}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="ca_country"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          State{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="ca_state"
                                          id="ca_state"
                                          onClick={event => {
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            if (event.target.value !== "") {
                                              values.ca_state =
                                                event.target.value;
                                              RegionManagementService.getCity(
                                                "stateId=" + values.ca_state,
                                                res => {
                                                  values.cityList =
                                                    res.responseListObject;
                                                }
                                              );
                                            }
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.ca_state && touched.ca_state
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          {(values.stateList || []).map(team => (
                                            <option
                                              key={team.stateId}
                                              value={team.stateId}
                                            >
                                              {pascalCase(team.stateName)}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="ca_state"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          City{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="ca_city"
                                          id="ca_city"
                                          onClick={event => {
                                            if (
                                              event.target.value !== undefined
                                            ) {
                                              values.ca_city = event.target.value;
                                            }
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.ca_city && touched.ca_city
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          {(values.cityList || []).map(team => (
                                            <option
                                              key={team.cityId}
                                              value={team.cityId}
                                            >
                                              {pascalCase(team.cityName)}
                                            </option>
                                          ))}
                                        </Field>
                                        <ErrorMessage
                                          name="ca_city"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Pin{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="ca_postal_code"
                                          id="ca_postal_code"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.ca_postal_code &&
                                              touched.ca_postal_code
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Postal Code"
                                        />
                                        <ErrorMessage
                                          name="ca_postal_code"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="address-border">
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                        <div class="row row-no-margin">
                                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                            <div class="col-md-9 col-lg-9 col-sm-9 col-xs-12">
                                              <h5 class="address-heading">
                                                Permanent Address{" "}
                                              </h5>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                              <div class="form-check pull-right">
                                                <Field
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="sameAsAbove"
                                                  checked={values.addressFlag}
                                                  onClick={event => {
                                                    if (event.target.checked) {
                                                      // LoginService.TokenUser(
                                                      //   res => {
                                                      //     localStorage.setItem(
                                                      //       "jwt-token",
                                                      //       res.responseObject
                                                      //     );
                                                      //   }
                                                      // );
                                                      // RegionManagementService.getState(
                                                      //   values.ca_country,
                                                      //   res => {
                                                      //     values.stateListForPer =
                                                      //       res.responseListObject;
                                                      //   }
                                                      // );
                                                      // RegionManagementService.getCity(
                                                      //   values.ca_state,
                                                      //   res => {
                                                      //     values.cityListForPer =
                                                      //       res.responseListObject;
                                                      //   }
                                                      // );
                                                      values.stateListForPer =
                                                        values.stateList;
                                                      values.cityListForPer =
                                                        values.cityList;
                                                      values.pa_address_1 =
                                                        values.ca_address_1;
                                                      values.pa_address_2 =
                                                        values.ca_address_2;
                                                      values.pa_country =
                                                        values.ca_country;
                                                      values.pa_state =
                                                        values.ca_state;
                                                      values.pa_city =
                                                        values.ca_city;
                                                      values.pa_postal_code =
                                                        values.ca_postal_code;
                                                      values.addressFlag =
                                                        "checked";
                                                    } else {
                                                      values.pa_address_1 = "";
                                                      values.pa_address_2 = "";
                                                      values.pa_country = "";
                                                      values.pa_state = "";
                                                      values.pa_city = "";
                                                      values.pa_postal_code = "";
                                                      values.addressFlag = "";
                                                      values.stateListForPer = [];
                                                      values.cityListForPer = [];
                                                    }
                                                  }}
                                                />
                                                <label className="form-check-label">
                                                  Same as above
                                              </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Address 1{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="pa_address_1"
                                          id="pa_address_1"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.pa_address_1 &&
                                              touched.pa_address_1
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Address"
                                        />
                                        <ErrorMessage
                                          name="pa_address_1"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>Address 2</label>
                                        <Field
                                          type="text"
                                          name="pa_address_2"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.pa_address_2 &&
                                              touched.pa_address_2
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Address"
                                        />
                                        <ErrorMessage
                                          name="pa_address_2"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Country{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="pa_country"
                                          id="pa_country"
                                          onClick={event => {
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            if (event.target.value !== "") {
                                              values.pa_country =
                                                event.target.value;
                                              values.stateListForPer = [];
                                              values.cityListForPer = [];
                                              RegionManagementService.getState(
                                                event.target.value,
                                                res => {
                                                  values.stateListForPer =
                                                    res.responseListObject;
                                                }
                                              );
                                            }
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.pa_country &&
                                              touched.pa_country
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          {this.state.countryListForPer.map(
                                            team => (
                                              <option
                                                key={team.countryId}
                                                value={team.countryId}
                                              >
                                                {pascalCase(team.countryName)}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          name="pa_country"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          State{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="pa_state"
                                          id="pa_state"
                                          onClick={event => {
                                            LoginService.TokenUser(res => {
                                              localStorage.setItem(
                                                "jwt-token",
                                                res.responseObject
                                              );
                                            });
                                            if (event.target.value !== "") {
                                              values.pa_state =
                                                event.target.value;
                                              RegionManagementService.getCity(
                                                "stateId=" + values.pa_state,
                                                res => {
                                                  values.cityListForPer =
                                                    res.responseListObject;
                                                }
                                              );
                                            }
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.pa_state && touched.pa_state
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          {(values.stateListForPer || []).map(
                                            team => (
                                              <option
                                                key={team.stateId}
                                                value={team.stateId}
                                              >
                                                {pascalCase(team.stateName)}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          name="pa_state"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          City{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="pa_city"
                                          id="pa_city"
                                          onClick={event => {
                                            if (
                                              event.target.value !== undefined
                                            ) {
                                              values.pa_city = event.target.value;
                                            }
                                          }}
                                          className={
                                            "form-control" +
                                            (errors.pa_city && touched.pa_city
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">--Select--</option>
                                          {(values.cityListForPer || []).map(
                                            team => (
                                              <option
                                                key={team.cityId}
                                                value={team.cityId}
                                              >
                                                {pascalCase(team.cityName)}
                                              </option>
                                            )
                                          )}
                                        </Field>
                                        <ErrorMessage
                                          name="pa_city"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>

                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Pin{" "}
                                          <span className="validation"> *</span>{" "}
                                        </label>
                                        <Field
                                          type="text"
                                          name="pa_postal_code"
                                          id="pa_postal_code"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.pa_postal_code &&
                                              touched.pa_postal_code
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Postal Code"
                                        />
                                        <ErrorMessage
                                          name="pa_postal_code"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>

                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                                <a href="">
                                  <button
                                    type="submit"
                                    class="btn-4 pull-right"
                                    onClick={async () => {
                                      const curErrors = await formikProps.validateForm();
                                      const curErrorsKeys = Object.keys(
                                        curErrors
                                      );

                                      if (curErrorsKeys.length) {
                                        // Assuming curErrorsKeys[0] identifies the first failing field, use that to
                                        // find the correct DOM element to call .focus() on.
                                        //
                                        // Since I set the id attribute on all my form's <input>s to match the error
                                        // keys, I just use document.getElementById(), but of course you could use refs
                                        const el = document.getElementById(
                                          curErrorsKeys[0]
                                        );

                                        if (el) el.focus();
                                      }
                                    }}
                                  >
                                    Submit
                                </button>
                                </a>
                                <a href="#">
                                  <button
                                    type="button"
                                    class="btn-4 mr-20 pull-right"
                                    onClick={this.handleCancel}
                                  >
                                    Cancel
                                </button>
                                </a>
                              </div>
                            </div>
                          </main>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </section>
              <Finxfooter />
            </React.Fragment>
          )}
      />
    );
  }
}

export default AdminProfileEdit;
