import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DashboardHeader from "../dashboardheader/dashboardheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import RestDataSource from "../../services/restdatasource";
import { LoginService } from "../../services/login.service";
import { RegisterUserService } from "../../services/registration.service";
import { Global_var } from "../../global/global_var";
import $ from "jquery";
import { ProductService } from "../../services/product.service";
//import { success, successNotification } from "../notification/notifications";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import {
  success,
  warning,
  successNotification,
  error,
  errorNotification,
  warningNotification
} from "../notification/notifications";

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ExcelService } from "../Excel/excel";

class ProductListLandingPage extends Component {
  constructor(props) {

    super(props);
    this.state = {
      filtered: [],
      productID: "",
      productName: "",
      parentProductID: null,
      partnerID: "",
      partner: "",
      isParentProduct: "",
      // buid: 2,
      productDescription: " ",
      productType: "",
      productCode: "",
      productPrice: "",
      isActive: "",
      createdBy: "",
      createdDate: "",
      // lastModifiedBy: "",
      // lastModifiedDate: "",
      productLink: "",
      productAnchor: null,
      productSubtitle: null,
      productLanguage: null,
      providerProductID: null,
      productVideourl: null,
      hasModules: null,
      productThumbnail: "",
      productgriddata: this.props.productDetailsAll,
      loading: true,
    };

  }

  componentDidMount() {

    //console.log("componentDidMount Data : " + this.props.productType);
    this.setState({
      productgriddata: this.props.productDetailsAll, loading: true
    })

    if (localStorage.getItem('reload') == "true") {

      localStorage.setItem('reload', "false")
      // window.location.reload()
      this.props.fetchProductListData();

    }

    this.setState({ loading: false });
  }


  filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true
  //row[id] !== undefined ? String(row[id]) === String(filter.value) : true

  handleRole = event => {
    this.setState({ roleType: event.target.value });
  };
  handleClick = value => {

    let productID = "productID=" + value.productID

    //187422
    if (value.productID !== 187422) {
      ProductService.fetchLearningPathParentProductIDAssignUser(productID,
        cb => {

          if (cb.responseListObject.length !== 0) {

            warning("Product could not be modified since product is assigned to learner(s).", {
              ...warningNotification,
              container: "top-center"
            })
          } else {
            this.props.history.push("/edit-course", { product: value });
          }
        });
    }
    else {
      this.props.history.push("/edit-course", { product: value });
    }
  };

  handleaddUsersCourse = (props) => {
    //  var currpage = window.location.href;
    //       //alert(currpage.substring(currpage.lastIndexOf('/') + 1))
    //       if (currpage.substring(currpage.lastIndexOf('/') + 1) == 'course-list' || currpage.substring(currpage.lastIndexOf('/') + 1) == 'course-list#') {
    //         localStorage.setItem("profilebackto", 'course-list')
    //       }
    //       else {
    //         localStorage.setItem("profilebackto", 'admin-dashboard')

    //       }
    // this.props.history.push("/userbulkupload")
    this.props.history.push("/add-course-learner", { product: props })
  }

  handleModulesClick = value => {
    //console.log("values" + (value.productID))
    localStorage.setItem("selectedgridproduct", value.productID);

    // setTimeout( function(){ //console.log(localStorage.getItem('selectedgridproduct')) }, 2000);
    this.props.history.push("/modules-list", { product: value });
  };

  exportToCSV = () => {

    let modifiedData = [];
    let data = this.state.respnoseData;

    data.forEach(element => {
      var data1 = {
        // Name : element.name,
        // UserLoginName: element.userLoginName,
        // MobileNumber: element.mobileNumber,
        // EmailId: element.emailId,
        // ActivateDate: element.activateDate,
        // ExpiryDate: element.expiryDate,
        // CreatedBy: element.createdBy,
        // CreatedDate: element.createdDate,
        // LastModifiedBy: element.lastModifiedBy,
        // LastModifiedDateTime: element.lastModifiedDateTime,
        // IsActive: element.isActive,
        // LastLoginDateTime: element.lastLoginDateTime,

      }
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, 'FC-ProductList-');

  };

  stateonpagechange = (props) => {
    this.setState({
      productgriddata: this.props.productDetailsAll
    })

  }

  stateonfilterchange = (props) => {
    this.setState({
      productgriddata: this.props.productDetailsAll,

    })

  }

  handleStatus = (event, values) => {
    // 
    LoginService.TokenUser(res => {

      if (res.status === "success") {

        localStorage.setItem("jwt-token", res.responseObject);

        let data = {
          productID: values.productID,
          productName: values.productName,
          isActive: values.isActive == 1 ? 0 : values.isActive == 0 ? 1 : ''
        }

        let statuss = (values.isActive == 1) ? 0 : 1
        let productIDIsActive = "productID=" + values.productID + "&" + "isActive="
          + statuss
        ProductService.deleteIsactiveInactive(
          productIDIsActive,
          cb => {

            if (cb.status === "success") {
              success(
                " Status updated successfully!",
                successNotification
              );
              // window.location.reload()
              this.props.fetchProductListData();

            }
            else {
              this.setState({ loading: false });
              warning(cb.reasonText ? cb.reasonText : cb.status, warningNotification);

            }
          });

      }
    });
  };
  handlech = () => {

  }

  render() {
    //console.log("PRODUCT LIST DATA", this.props.productDetailsAll);

    return (
      <React.Fragment>
        {this.state.loading && (
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />
        )}
        <section class="dashboard-tab user-tab pt-80 mb-50">
          <div class="padding-rl-50">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="table-header">
                    <h3>
                      <div class="row">
                        <div class="col-md-8 col-sm-6 col-xs-8">
                          <span class="left-icon">
                            <i class="fa fa-list" aria-hidden="true"></i>
                          </span>{" "}
                          Course List
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-4">
                          {/* <span class="right-icon">
                            <a>
                            <i onClick={() => this.exportToCSV ( )}
                           
                                class="fa fa-file-excel-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span> */}
                          {/* <span class="right-icon">
                            <a href="/userbulkupload">
                              <i class="fa fa-upload" aria-hidden="true"></i>
                            </a>
                          </span> */}
                          <span class="right-icon">
                            <a title="Add Course" href="/add-course" >
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div class="bg-box">
                    <div class="table-responsive">
                      <ReactTable
                        minRows={2}
                        data={this.props.productDetailsAll}
                        //data={this.state.productgriddata ? this.state.productgriddata :  this.props.productDetailsAll }
                        //data={this.state.productgriddata == null ? this.props.productDetailsAll : this.state.productgriddata != null ? this.state.productgriddata : this.props.productDetailsAll  }
                        filtered={this.state.filtered}
                        //onFilteredChange={filtered => {this.setState({ filtered });}}	
                        onFilteredChange={filtered => {
                          this.setState({
                            filtered: filtered,
                            productgriddata: this.props.productDetailsAll,
                          });
                        }}
                        className="-striped -highlight"
                        filterable
                        onPageChange={this.stateonpagechange}
                        defaultFilterMethod={this.filterCaseInsensitive}
                        ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                        columns={[
                          {
                            columns: [
                              {
                                Header: "Course Name",
                                id: "productName",
                                accessor: d => d.productName
                              },

                              {
                                Header: "Code",
                                id: "productCode",
                                accessor: d =>
                                  d.productCode
                              },

                              {
                                Header: "Type",
                                id: "productType",
                                accessor: d => d.productType,

                                Cell: props => {
                                  let prdtype = JSON.parse(localStorage.getItem('productTypeedit'))
                                  let name = this.props.productType ? this.props.productType.map(item => (
                                    item.codeValueId == props.original.productType ? item.codeValue : ''
                                  )) : prdtype.map(item => (
                                    item.codeValueId == props.original.productType ? item.codeValue : ''
                                  ));
                                  return (
                                    <div>
                                      {name}
                                    </div>
                                  );
                                }
                              },

                              // {
                              //   Header: "Partner",
                              //   id: "partner",
                              //   accessor: d => d.partner,
                              //   Cell: props => {
                              //     let name = this.props.partner ? this.props.partner.map(item => (
                              //       item.partnerID == props.original.partnerID ? item.partnerName : ''
                              //     )) : "";
                              //     return (
                              //       <div>
                              //         {name}
                              //       </div>
                              //     );
                              //   }
                              // },

                              {
                                Header: "Price",
                                id: "productPrice",
                                filterable: true,
                                Cell: row => (
                                  <div style={{ textAlign: "right" }}>{row.value}</div>
                                ),
                                accessor: d =>
                                  (new Intl.NumberFormat('en-IN', {
                                    style: 'currency',
                                    currency: 'INR'
                                  }).format(d.productPrice) || '').toString()
                              },

                              {
                                Header: "Active",
                                id: "isActive",
                                filterable: false,
                                sortable: false,
                                Cell: props => {

                                  return (
                                    <div>

                                      <Toggle
                                        icons={false}
                                        defaultChecked={
                                          props.original.isActive == 1
                                            ? true
                                            : false
                                        }
                                        onChange={e =>
                                          this.handleStatus(e, props.original)
                                        }
                                      />

                                    </div>

                                  );
                                }


                              },

                              {
                                Header: "Actions",
                                id: "action",
                                filterable: false,
                                sortable: false,
                                Cell: props => {
                                  return (
                                    <div>
                                      <a class="mar-left">
                                        {//props.original.isActive == 1 ?
                                          <i
                                            title="Edit Course"
                                            className="fa fa-edit fa-2x "
                                            aria-hidden="true"
                                            onClick={() =>
                                              this.handleClick(props.original)
                                            }
                                          >
                                          </i>

                                          //: ''
                                        }
                                      </a>
                                      <a class="mar-left">
                                        {props.original.isActive == 1 && props.original.hasModules > 0 ?
                                          <i
                                            title="Add Modules"
                                            class="fa fa-eye fa-rx"
                                            onClick={() => this.handleModulesClick(props.original)}>
                                          </i> : ' '}
                                      </a>
                                      <a class="mar-left">
                                        {props.original.isActive == 1 ?
                                          <i
                                            title="Add Users"
                                            className="fa fa-plus fa-2x"
                                            aria-hidden="true"
                                            onClick={() =>
                                              this.handleaddUsersCourse(props.original)
                                            }>
                                          </i> : ' '}
                                      </a>
                                    </div>

                                  );


                                }
                              },

                              // {
                              //   // Header: "Modules",
                              //   id: "modules",
                              //   sortable: false,
                              //   filterable: false,
                              //   //accessor: d => d.hasModules,
                              //   Cell: props => {

                              //     let name = props.original.hasModules > 0 ?
                              //       //props.original.isActive == 1 ?
                              //       <a class="mar-left">
                              //         <i class="fa fa-eye fa-rx" onClick={() => this.handleModulesClick(props.original)}>
                              //         </i>
                              //       </a> : ''; //: " ";
                              //     //  <a class="mar-left"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                              //     return (
                              //       <div>
                              //         {name}
                              //       </div>)
                              //   }
                              // },
                              // {
                              //   Header: "Assign Users",
                              //   id: "addcourse",
                              //   filterable: false,
                              //   sortable: false,
                              //   Cell: props => {

                              //     return (
                              //       props.original.isActive == 1 ?
                              //         <a class="mar-left">
                              //           <i
                              //             className="fa fa-plus fa-2x"
                              //             aria-hidden="true"
                              //             onClick={() =>
                              //               this.handleaddUsersCourse(props.original)
                              //             }
                              //           ></i>

                              //         </a> : ''
                              //     )
                              //   }
                              // }
                              // {
                              //   Header: "Assign Users",
                              //   id: "Assignuser",
                              //   filterable: false,
                              //   Cell: props => {
                              //     return (
                              //       <a class="mar-left">
                              //         <i
                              //           class="fa fa-eye fa-rx"
                              //           aria-hidden="true"
                              //           onClick={() =>
                              //             this.handlech()
                              //           }
                              //         ></i>

                              //       </a>
                              //     );
                              //   }
                              // },


                            ]
                          }
                        ]}
                        defaultPageSize={10}
                        style={{
                          width: "100%",
                          maxHeight: "500px"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ProductListLandingPage;
