import {
  FETCH_LEARNER_PENDING,
  FETCH_LEARNER_SUCCESS,
  FETCH_LEARNER_ERROR
} from "../action/learnerAction";

const initialState = {
  pending: false,
  learners: [],
  error: null
};

const learnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEARNER_PENDING:
      return {
        ...state,
        pending: true,
        err: false
      };
    case FETCH_LEARNER_SUCCESS:
      return {
        ...state,
        pending: false,
        err: false,
        learners: action.payload
      };
    case FETCH_LEARNER_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default learnersReducer;
export const getLearners = state => state.learners;
export const getLearnersPending = state => state.pending;
export const getLearnersError = state => state.error;
