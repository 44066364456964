import React from "react";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import { Pie } from "react-chartjs-2";
import { Carousel } from "react-responsive-carousel";

class Mycarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testData: {},
      courseData: {},
      displayTitle: "true",
      displayLegend: true,
      legendPosition: "right"
    };
  }
  render() {
    return (
      <Carousel showThumbs={false}>
        <div>
          <h3 className="learner-title">Test Overview</h3>
          <h5 className="progress-title">
            Total Test ={" "}
            {(this.props.learnerProgressOverview || "").testTotal || 0}
          </h5>
          <Pie
            data={{
              labels: ["Initiated", "InProgress", "Completed"],
              datasets: [
                {
                  data: [
                    (this.props.learnerProgressOverview || "").testInitiated,
                    (this.props.learnerProgressOverview || "").testInProgress ||
                    "",
                    (this.props.learnerProgressOverview || "").testCompleted ||
                    ""
                  ],
                  backgroundColor: [
                    "rgb(0, 82, 143)",
                    "rgb(235, 140, 46)",
                    "rgb(105, 161, 63)"
                  ]
                }
              ]
            }}
            width={75}
            height={60}
            options={{
              //   title: {
              //     display: "this.state.displayTitle",
              //     fontSize: 50
              //   },
              legend: {
                display: this.state.displayLegend,
                position: this.state.legendPosition
              }
            }}
          />
        </div>
        <div>
          <h3 className="learner-title">Course Overview</h3>
          <h5 className="progress-title">
            Total Course ={" "}
            {(this.props.learnerProgressOverview || "").courseTotal || 0}
          </h5>
          <Pie
            data={{
              labels: ["Initiated", "InProgress", "Completed"],
              datasets: [
                {
                  data: [
                    (this.props.learnerProgressOverview || "").courseInitiated,
                    (this.props.learnerProgressOverview || "")
                      .courseInProgress || "",
                    (this.props.learnerProgressOverview || "")
                      .courseCompleted || ""
                  ],
                  backgroundColor: [
                    "rgb(0, 82, 143)",
                    "rgb(235, 140, 46)",
                    "rgb(105, 161, 63)"
                  ]
                }
              ]
            }}
            width={75}
            height={60}
            options={{
              //   title: {
              //     display: "this.state.displayTitle",
              //     fontSize: 50
              //   },
              legend: {
                display: this.state.displayLegend,
                position: this.state.legendPosition
              }
            }}
          />
        </div>
      </Carousel>
    );
  }
}

export default Mycarousel;
