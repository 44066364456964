// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import DashboardHeader from '../../components/dashboardheader/dashboardheader';
import Finxfooter from '../../containers/finxfooter/finxfooter';
import Axios from 'axios';
import RestDataSource from '../../services/restdatasource';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class CheckoutPaytm extends Component {
    constructor(props) {
        super(props);

    }


    componentDidMount() {
        setTimeout(() => {
            document.paytm_form.submit();
        }, 2000);
    }

    render() {

        return (
            <React.Fragment >
                {ReactHtmlParser(this.props.location.state.paytm)}

            </React.Fragment>

        );
    }

}

export default CheckoutPaytm;