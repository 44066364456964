import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import {
  getproductDetailsAll,
  getproductDetailsAllPending,

} from "../../reducer/productDetailsAllReducer";
import { getProductType } from "../../reducer/productProductTypeReducer";
import { getPartner } from "../../reducer/productPartnerReducer";
import { getStatus } from "../../reducer/productStatusReducer";
// import { getHasModule } from "../../reducer/productHasModuleReducer";
import EditProduct from "../../components/product/editProduct";
import { bindActionCreators } from "redux";
import ProductListLandingPage from "../../components/product/productList";
//import {fetchProductDetailsAll} from "../../services/fetchLearnerDashboard.service";
import { fetchProductTypeData, fetchPartnerData, fetchStatusData, fetchHasModuleData } from "../../services/fetchLearnerDashboard.service";


import { LoginService } from "../../services/login.service";
import { getPersonnelAddressData } from "../../reducer/userProfileReducer";
import { RegisterUserService } from "../../services/registration.service";
import { fetchRoles } from "../../services/fetchRoles";
import { getRoles } from "../../reducer/roleReducer";

import { ProductService } from "../../services/product.service";

import AdminHeader from "../../components/dashboardheader/adminheader";
import $ from "jquery";
import { from } from "linq";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchLearnerDashboard.service";


const mapStateToProps = state => ({
  productDetailsAll: getproductDetailsAll(state.productDetailsAllReducer),
  productDetailsAllPending: getproductDetailsAllPending(state.productDetailsAllReducer),
  addressData: getPersonnelAddressData(state.userProfileReducers),

  productType: getProductType(state.productProductTypeReducer),
  partner: getPartner(state.productPartnerReducer),
  status: getStatus(state.productStatusReducer),
  //hasModule: getHasModule(state.productHasModuleReducer),
  roles: getRoles(state.roleReducer),
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),


});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      //fetchProductList: fetchProductList,

      fetchPartnerData: fetchPartnerData,
      fetchProductTypeData: fetchProductTypeData,
      fetchStatusData: fetchStatusData,
      // fetchHasModuleData: fetchHasModuleData,
      fetchRoles: fetchRoles,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const productEdit = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        productType: [],
        partner: [],
        status: [],
        hasModule: [],
      };
    }

    componentDidUpdate() {

      // $(document).ready(function() {
      //   $("#myBtn").click(function() {
      //     $("#mySidenav").toggleClass("opensidenav");
      //   });
      // });
      // var dropdown = document.getElementsByClassName("dropdown-btn");
      // var i;

      // for (i = 0; i < dropdown.length; i++) {

      //   dropdown[i].addEventListener("click", function () {
      //     this.classList.toggle("active");
      //     var dropdownContent = this.nextElementSibling;
      //     if (dropdownContent.style.display === "block") {
      //       dropdownContent.style.display = "none";
      //     } else {
      //       dropdownContent.style.display = "block";
      //     }
      //   });
      // }
    }

    componentDidMount() {

      const {
        fetchProductDetailsAll,
        fetchRoles,
        fetchProductTypeData,
        fetchPartnerData,
        fetchAlertNotificationEvents,
        fetchNotificationEvents,
      } = this.props;

      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
        let partyID = localStorage.getItem("userid-Token");
        fetchAlertNotificationEvents();
        fetchNotificationEvents("partyId=" + partyID);
      });




      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);

          fetchProductTypeData('', res => {
            //console.log("PRODUCT TYPE DATA" , res);
          });
        }
      });
      LoginService.TokenUser(res => {

        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);


          // fetchRoles();
        }
      });

      LoginService.TokenUser(res => {

        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);

          fetchPartnerData(res => {
            //console.log("PARTNER DATA", res);
          });

        }
      });

      LoginService.TokenUser(res => {

        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);

          fetchStatusData(res => {
            //console.log("Status DATA", res);
          });
        }
      });

    }

    render() {

      const {
        productDetailsAll,
        productDetailsAllPending,
        fetchUserAddressData,
        productType,
        partner,
        status,
        hasModule,
        fetchProductTypeData,

        roles,
        alertNotificationEvents,
        pendingAlertNotificationEvents,
        errorAlertNotigicationEvents,
        notificationEvents,
        pendingNotificationEvents,
        errorNotificationEvents,
      } = this.props;

      //console.log("productType",productType);
      //console.log("partner" , partner);
      //console.log("status" , status);
      //console.log("PRODUCT DETAILS ALL : ", productDetailsAll);

      return (
        <React.Fragment>
          <AdminHeader
            //alertNotificationEvents={alertNotificationEvents}
            //notificationEvents={notificationEvents}
            {...this.props}
          />
          <EditProduct
            productDetailsAll={productDetailsAll}
            productType={productType}
            fetchProductTypeData={fetchProductTypeData}
            partner={partner}
            status={status}
            // roles={roles}
            {...this.props}
          />
          <Finxfooter />
        </React.Fragment>
      );
    }

  }
);

export default productEdit;
