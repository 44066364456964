import React from 'react';
import socailstreaming from '../../assets/images/social-stream.png';


class MySocialStreaming extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                    <h3 className="learner-title">Social Stream</h3>
                    <div className="social-stream">
                        <img src={socailstreaming} className="img-responsive" />
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default MySocialStreaming;