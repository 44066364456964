import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import {
  getproductDetailsAll,
  getproductDetailsAllPending
} from "../../reducer/productDetailsAllReducer";
import { getProductType } from "../../reducer/productProductTypeReducer";
import { getPartner } from "../../reducer/productPartnerReducer";
import { getStatus } from "../../reducer/productStatusReducer";
// import { getHasModule } from "../../reducer/productHasModuleReducer";

import { bindActionCreators } from "redux";
import ProductListLandingPage from "../../components/product/productList";
//import {fetchProductDetailsAll} from "../../services/fetchLearnerDashboard.service";
import {
  fetchProductListData,
  fetchProductTypeData,
  fetchPartnerData,
  fetchStatusData,
  fetchHasModuleData
} from "../../services/fetchLearnerDashboard.service";

import { LoginService } from "../../services/login.service";
import { getPersonnelAddressData } from "../../reducer/userProfileReducer";
import { RegisterUserService } from "../../services/registration.service";
import { fetchRoles } from "../../services/fetchRoles";
import { getRoles } from "../../reducer/roleReducer";

import { ProductService } from "../../services/product.service";

import AdminHeader from "../../components/dashboardheader/adminheader";
import $ from "jquery";
import { from } from "linq";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchLearnerDashboard.service";


const mapStateToProps = state => ({
  productDetailsAll: getproductDetailsAll(state.productDetailsAllReducer),
  productDetailsAllPending: getproductDetailsAllPending(
    state.productDetailsAllReducer
  ),
  addressData: getPersonnelAddressData(state.userProfileReducers),

  productType: getProductType(state.productProductTypeReducer),
  partner: getPartner(state.productPartnerReducer),
  status: getStatus(state.productStatusReducer),
  //hasModule: getHasModule(state.productHasModuleReducer),
  roles: getRoles(state.roleReducer),

  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProductListData: fetchProductListData,
      fetchPartnerData: fetchPartnerData,
      fetchProductTypeData: fetchProductTypeData,
      fetchStatusData: fetchStatusData,
      // fetchHasModuleData: fetchHasModuleData,
      fetchRoles: fetchRoles,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const productList = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        productType: [],
        partner: [],
        status: [],
        hasModule: []
      };
    }

    componentDidUpdate() { }

    componentDidMount() {

      let partyID = localStorage.getItem("userid-Token");
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
      });


      const {
        fetchProductListData,
        fetchRoles,
        fetchProductTypeData,
        fetchStatusData,
        fetchPartnerData,
        fetchAlertNotificationEvents,
        fetchNotificationEvents,
      } = this.props;

      fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID);

      LoginService.TokenUser(res => {
        if (res.status === "success") {
          sessionStorage.setItem("jwt-token", res.responseObject);
          let productType = "codeTypeName=product_type";
          fetchProductTypeData(productType, res => {
            //console.log("PRODUCT TYPE DATA", res);
          });
          //   fetchProductTypeData( res=>{
          //     //console.log("PRODUCT TYPE DATA" , res);
          // });
        }
      });
      LoginService.TokenUser(res => {

        if (res.status === "success") {
          sessionStorage.setItem("jwt-token", res.responseObject);

          fetchProductListData();


          // fetchRoles();
        }
      });

      LoginService.TokenUser(res => {

        if (res.status === "success") {
          sessionStorage.setItem("jwt-token", res.responseObject);

          fetchPartnerData(res => {
            //console.log("PARTNER DATA", res);
          });
        }
      });

      LoginService.TokenUser(res => {

        if (res.status === "success") {
          sessionStorage.setItem("jwt-token", res.responseObject);

          fetchStatusData(res => {
            //console.log("STATUS DATA", res);
          });
        }
      });
    }

    render() {

      const {
        productDetailsAll,
        productDetailsAllPending,
        fetchUserAddressData,
        productType,
        partner,
        status,
        hasModule,
        alertNotificationEvents,
        pendingAlertNotificationEvents,
        errorAlertNotigicationEvents,
        notificationEvents,
        pendingNotificationEvents,
        errorNotificationEvents,
      } = this.props;

      //console.log("alertNotificationEvents",this.props.alertNotificationEvents)
      //console.log("notificationEvents",this.props.notificationEvents)
      //console.log("productType", productType);

      localStorage.setItem('productTypeedit', JSON.stringify(productType));
      localStorage.setItem('statusedit', JSON.stringify(status));

      //console.log("partner", partner);
      //console.log("status", status);
      //console.log("PRODUCT DETAILS ALL : ", productDetailsAll);
      if (!productDetailsAll.length && !productType.length && !partner.length && !status.length) {
        return null;
      } else {
        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <ProductListLandingPage
              productDetailsAll={productDetailsAll}
              fetchProductListData={fetchProductListData}
              productType={productType}
              partner={partner}
              status={status}
              // roles={roles}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }
    }
  }
);

export default productList;
