import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchAdminUser } from "../../services/admin.service";
import AdminProfileEdit from "../../components/admin/adminEditUser";
import { RegisterUserService } from "../../services/registration.service";
import { getPersonnelAddressData } from "../../reducer/userProfileReducer";
import { fetchRoles } from "../../services/fetchRoles";
import { getRoles } from "../../reducer/roleReducer";
import Adminheader from "../../components/dashboardheader/adminheader";
import $ from "jquery";
import { LoginService } from "../../services/login.service";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchLearnerDashboard.service";


const mapStateToProps = state => ({
  addressData: getPersonnelAddressData(state.userProfileReducers),
  roles: getRoles(state.roleReducer),
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),
  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserAddressData: RegisterUserService.getUserAddress,
      fetchRoles: fetchRoles,
      fetchAdminUser: fetchAdminUser,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const adminEdit = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      //console.log("PROPS DATA :",this.props)
      //console.log(this.props)
    }

    componentDidMount() {

      const { fetchRoles, fetchAlertNotificationEvents,
        fetchNotificationEvents, } = this.props;
      LoginService.TokenUser(res => {

        if (res.status === "success") {
          let partyID = localStorage.getItem("userid-Token");
          fetchAlertNotificationEvents();
          fetchNotificationEvents("partyId=" + partyID);
        }
      });




      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          fetchRoles();
        }
      });
    }

    render() {
      const {
        fetchUserAddressData,
        addressData,
        roles,
        fetchAdminUser,
        alertNotificationEvents,
        pendingAlertNotificationEvents,
        errorAlertNotigicationEvents,
        notificationEvents,
        pendingNotificationEvents,
        errorNotificationEvents,
      } = this.props;

      return (

        <React.Fragment>
          <Adminheader
            //alertNotificationEvents={alertNotificationEvents}
            //notificationEvents={notificationEvents}
            {...this.props}
          />
          <AdminProfileEdit
            getAdminAddress={fetchUserAddressData}
            addressData={addressData.responseListObject}
            roles={roles}
            fetchAdminUser={fetchAdminUser}
            //alertNotificationEvents={alertNotificationEvents}
            //notificationEvents={notificationEvents}
            {...this.props}
          />
          <Finxfooter />
        </React.Fragment>
      );
    }
  }
);

export default adminEdit;
