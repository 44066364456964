/*jshint esversion: 6 */
/* eslint-disable */
import App from '../src/containers/App';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';

import { BrowserRouter } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import ReactGA from 'react-ga';
ReactGA.initialize('G-K34T6CKWS5');

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <div className="app-container">
                <ReactNotification />
            </div>
            <App />
        </BrowserRouter>
    </Provider>
)


ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
