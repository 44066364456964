import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginService } from "../../services/login.service";
import { AssessmentReport } from "../../services/fetchAssessmentReport";
import * as Yup from "yup";
import * as moment from "moment";
import { ExcelService } from "../Excel/excel";

export class assessmentReport extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      today: moment(todaydate).format("YYYY-MM-DD"),
      data: [],
      adminProfileUser: this.props.adminProfileUser,
    };
    this.showAll = this.showAll.bind(this);
  }

  componentDidMount() {
    this.componentDetails(this.props.assessmentReportData, false);
    this.setState({ loading: false });
  }
  componentDetails(assessmentReportData, IsExport) {
    let modifiedData = [];
    let modifiedData1 = [];
    let modifiedData2 = [];
    for (let index = 0; index < assessmentReportData.length; index++) {
      const element = assessmentReportData[index];
      for (let index = 0; index < this.state.adminProfileUser.length; index++) {
        const ele = this.state.adminProfileUser[index];

        if (element.learnerPartyID == ele.partyID) {
          
          let datamodified = {
            assessmentID: element.assessmentID,
            learnerID: element.learnerPartyID
              ? element.learnerPartyID
              : element.learnerID,
            learnerName: ele.name,
            learnerEmail: ele.emailId,
            productID: element.productEntity
              ? element.productEntity.productID
              : element.productID,
            productName: element.productEntity
              ? element.productEntity.productName
              : element.productName,
            productPrice: element.productEntity
              ? element.productEntity.productPrice
              : element.productPrice,
            createdDate: element.createdDate,
            isActive: element.isActive,
            isScoreUpdated:
              element.isScoreUpdated === 0 ? "In-Progress" : "Completed",
            attemptNumberReportURL: element.attemptNumberReportURL,
            attemptNumberScore: element.attemptNumberScore,
          };
          modifiedData1.push(datamodified);
        }
      }
    }
    // console.log("modifiedData1",modifiedData1)
    console.log("assessmentReportData", assessmentReportData);
    console.log("this.props.adminProfileUser", this.props.adminProfileUser);

    if (!IsExport) {
      this.setState({ data: modifiedData1 });
    } else {
      let modifiedData = [];
      this.state.data.forEach((element) => {
        var data1 = {
          Assessment_ID: element.assessmentID,
          Learner_ID: element.learnerID,
          Learner_Name: element.learnerName,
          Product_Name: element.productName,
          Product_Price: (
            new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
            }).format(element.productPrice) || ""
          ).toString(),
          Created_Date: (element.createdDate || "").split(" ")[0],
          Assessment_Status:
            element.isScoreUpdated === 0 ? "In-Progress" : "Completed" || "",
          attemptNumberReportURL: element.attemptNumberReportURL,
          attemptNumberScore: element.attemptNumberScore,
        };
        modifiedData.push(data1);
      });

      ExcelService.ExcelData(modifiedData, "Assessment_Report-");
    }
  }
  showAll() {
    //this.setState({ data: this.props.assessmentReportData });
    this.componentDetails(this.props.assessmentReportData, false);
  }
  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(value.toLowerCase())
      : true;
  };
  exportToCSV = () => {
    this.componentDetails(this.state.data, true);
  };

  filterCfxReport = (emailId, productID) => {
    this.props.history.push("/CfxAssessmentReport/" + emailId +"/"+productID);
  };
  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          startDate: "",
          endDate: "",
        }}
        onSubmit={(values) => {
          this.setState({ loading: true });
          LoginService.TokenUser((res) => {
            localStorage.setItem("jwt-token", res.responseObject);
            let data = {
              startDate: values.startDate
                ? moment(values.startDate).format("DD/MM/YYYY")
                : values.endDate
                ? moment(new Date()).format("DD/MM/YYYY")
                : "",
              endDate: values.endDate
                ? moment(values.endDate).format("DD/MM/YYYY")
                : values.startDate
                ? moment(new Date()).format("DD/MM/YYYY")
                : "",
            };
            let params =
              "limit=-1&fromDate=" +
              data.startDate +
              "&toDate=" +
              data.endDate +
              "&orderDir=asc";
            AssessmentReport.fethAssessmentReport(params, (res) => {
              if (res.status === "success") {
                this.setState({ loading: true });
                this.componentDetails(res.responseListObject, false);
                this.setState({ loading: false });
              } else {
                this.setState({
                  loading: false,
                  data: [],
                });
              }
            });
          });
        }}
        render={({ handleSubmit, handleChange }) => (
          <React.Fragment>
            <section class="dashboard-tab user-tab pt-80 mb-50">
              <div class="padding-rl-50">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-8 col-sm-6 col-xs-8">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              Assessment Report
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-4">
                              <span class="right-icon">
                                <a>
                                  <i
                                    onClick={() => this.exportToCSV()}
                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <Form onSubmit={handleSubmit} className="">
                          <div class="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                    <h5 className="filter-heading">
                                      Search Criteria
                                    </h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Start Date</label>
                                      <Field
                                        type="date"
                                        name="startDate"
                                        max={this.state.today}
                                        id="startDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="startDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>End Date</label>
                                      <Field
                                        type="date"
                                        name="endDate"
                                        max={this.state.today}
                                        id="endDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="endDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 mt-40">
                                      <div className="row">
                                        <button
                                          className="btn-5 mr-20 ml-20"
                                          type="submit"
                                        >
                                          Search
                                        </button>
                                        <button
                                          className="btn-5"
                                          type="reset"
                                          onClick={this.showAll}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                        <div class="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.data || []}
                            filterable
                            ref={(refReactTable) => {
                              this.refReactTable = refReactTable;
                            }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "Assessment ID",
                                    id: "assessmentID",
                                    accessor: (d) =>
                                      (d.assessmentID || "").toString(),
                                  },
                                  {
                                    Header: "Learner ID",
                                    id: "learnerID",
                                    accessor: (d) =>
                                      (d.learnerID || "").toString(),
                                  },
                                  {
                                    Header: "Learner Name",
                                    id: "learnerName",
                                    accessor: (d) =>
                                      (d.learnerName || "").toString(),
                                    // ((d.productEntity || "").learnerName || '').toString()
                                  },
                                  // {
                                  //   Header: "Product ID",
                                  //   id: "productID",
                                  //   accessor: d => (d.productID || '').toString()
                                  //   // ((d.productEntity || "").productID || '').toString()
                                  // },
                                  {
                                    Header: "Product Name",
                                    id: "productName",
                                    accessor: (d) =>
                                      (d.productName || "").toString(),
                                    // ((d.productEntity || "").productName || '').toString()
                                  },

                                  // {
                                  //   Header: "Product Price",
                                  //   id: "prodcutprice",
                                  //   Cell: row => (
                                  //     <div style={{ textAlign: "right" }}>
                                  //       {row.value}
                                  //     </div>
                                  //   ),
                                  //   accessor: d =>
                                  //     (
                                  //       new Intl.NumberFormat("en-IN", {
                                  //         style: "currency",
                                  //         currency: "INR"
                                  //       }).format(d.productPrice) || ""
                                  //     ).toString()
                                  //   // (((d.productEntity || "").productPrice || '').toString())
                                  // },
                                  {
                                    Header: "Assessment Status",
                                    id: "isScoreUpdated",
                                    accessor: (d) => d.isScoreUpdated,
                                    // ((d.productEntity || "").productName || '').toString()
                                  },
                                  {
                                    Header: "Score",
                                    id: "attemptNumberScore",
                                    accessor: (d) =>
                                      (d.attemptNumberScore || "").toString(),
                                  },
                                  {
                                    Header: "Report",
                                    id: "attemptNumberReportURL",
                                    filterable: false,
                                    accessor: (d) =>
                                      d.attemptNumberReportURL
                                        ? d.attemptNumberReportURL
                                        : "",
                                    Cell: (row: any) => {
                                      if (
                                        row.original.attemptNumberReportURL !=
                                        null
                                      ) {
                                        return (
                                          <div>
                                            <a
                                              href={
                                                row.original
                                                  .attemptNumberReportURL
                                              }
                                              target="_blank"
                                              className="download"
                                              download={
                                                row.original
                                                  .attemptNumberReportURL
                                              }
                                            >
                                              Download{" "}
                                            </a>
                                          </div>
                                        );
                                      } else if (row.original.productID === 1) {
                                        return (
                                          <div>
                                            <a
                                              onClick={() => {
                                                this.filterCfxReport(
                                                  row.original.learnerEmail,
                                                  row.original.productID
                                                );
                                              }}
                                              target="_blank"
                                              className="download"
                                            >
                                              Download{" "}
                                            </a>
                                          </div>
                                        );
                                      } else {
                                        return "";
                                      }
                                    },
                                  },

                                  {
                                    Header: "Created Date",
                                    id: "createdDate",

                                    accessor: (d) =>
                                      d.createdDate ? d.createdDate : " ",
                                  },
                                ],
                              },
                            ]}
                            defaultPageSize={10}
                            style={{
                              width: "100%",
                              maxHeight: "500px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

export default assessmentReport;
