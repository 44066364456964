// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import RestDataSource from "../../services/restdatasource";
import { LoginService } from "../../services/login.service";
import {
  success,
  successNotification,
  error,
  errorNotification,
  warning,
  warningNotification
} from "../notification/notifications";
import * as moment from "moment";
import $ from "jquery";

class AddRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleName: "",
      description: "",
      isActive: "",
      activateDate: "",
      expiryDate: "",
      suspendStartDate: "",
      suspendEndDate: ""
    };
  }

  render() {
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            roleName: "",
            description: "",
            isActive: "",
            activateDate: "",
            expiryDate: "",
            suspendStartDate: "",
            suspendEndDate: ""
          }}
          validationSchema={Yup.object().shape({
            roleName: Yup.string()
              .min(3, "Too Short!")
              .max(50, "Too Long!")
              .required("You must specify a Role Name"),

            activateDate: Yup.date().required(
              "You must specify a Activate Date"
            ),
            expiryDate: Yup.date().required("You must specify a Expiry Date"),
            suspendStartDate: Yup.date().required(
              "You must specify a Suspend Start Date"
            ),
            suspendEndDate: Yup.date().required(
              "You must specify a Suspend End Date"
            ),
            description: Yup.string()
              .min(10, "Description must be minimum of 10 Characters!")
              .max(100, "Description must be maximum of 100 Characters!"),
            isActive: Yup.string().required("You must specify a Status"),
          })}
          onReset={(values, { resetForm }) => {
            resetForm();
          }}
          onSubmit={values => {
            console.log("Values : ", values)
            var activateDate = values.activateDate.replace(/-/g, "/");
            var activateDate1 = activateDate.split("/");
            var activateDate2 =
              activateDate1[2] +
              "/" +
              activateDate1[1] +
              "/" +
              activateDate1[0];

            var expiryDate = values.expiryDate.replace(/-/g, "/");
            var expiryDate1 = expiryDate.split("/");
            var expiryDate2 =
              expiryDate1[2] + "/" + expiryDate1[1] + "/" + expiryDate1[0];

            var suspendStartDate = values.suspendStartDate.replace(/-/g, "/");
            var suspendStartDate1 = suspendStartDate.split("/");
            var suspendStartDate2 =
              suspendStartDate1[2] +
              "/" +
              suspendStartDate1[1] +
              "/" +
              suspendStartDate1[0];

            var suspendEndDate = values.suspendEndDate.replace(/-/g, "/");
            var suspendEndDate1 = suspendEndDate.split("/");
            var suspendEndDate2 =
              suspendEndDate1[2] +
              "/" +
              suspendEndDate1[1] +
              "/" +
              suspendEndDate1[0];

            this.setState({
              roleName: values.roleName.trim(),
              description: values.description,
              activateDate: activateDate2 ? activateDate2 + " 00:00:00" : "",
              expiryDate: expiryDate2 ? expiryDate2 + " 00:00:00" : "",
              suspendStartDate: suspendStartDate2 ? suspendStartDate2 + " 00:00:00" : "",
              suspendEndDate: suspendEndDate2 ? suspendEndDate2 + " 00:00:00" : "",
              isActive: values.isActive
            });

            //console.log(this.state)

            LoginService.TokenUser(res => {
              if (res.status === "success") {
                localStorage.setItem("jwt-token", res.responseObject);

                this.props.addRole(this.state, cb => {
                  if (cb.status === "success") {
                    success(
                      values.roleName +
                      " " +
                      "has been created on Dap successfully",
                      successNotification
                    );
                    this.props.fetchRoles();
                    $(".modal-backdrop").remove();
                  } else {
                    error(cb.reasonText, {
                      ...warningNotification,
                      container: "top-center"
                    });
                    localStorage.setItem("reload", "true");
                    window.location.reload();
                    this.props.fetchRoles();
                  }
                });
              }
            });
          }}
          render={({
            handleReset,
            isValid,
            errors,
            touched,
            handleSubmit,
            handleChange,
            ...formikProps
          }) => (
              <React.Fragment>
                <Form
                  className="modal fade"
                  onKeyDown={onKeyDown => {
                    if (
                      (onKeyDown.charCode || onKeyDown.keyCode) === 13 &&
                      onKeyDown.target.name != "isActive" &&
                      onKeyDown.target.name != "submitForm" &&
                      onKeyDown.target.name != "cancelForm"
                    ) {
                      onKeyDown.preventDefault();
                    }
                  }}
                  id="add-role"
                  tabindex="-1" role="dialog" aria-labelledby="modalLabel"    data-keyboard="true" aria-hidden="true"
                >
                  <div class="modal-dialog modal-md" role="document">
                    <div class="modal-content">
                      <button
                        type="reset"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <div class="modal-body">
                        <div class="row">
                          <section class="add-role">
                            <div class="form-group">
                              <div class="col-md-6 col-sm-12 col-xs-12">
                                <label>Role Name
                              <span class="validation"> *</span>
                                </label>
                                <Field
                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.roleName && touched.roleName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name="roleName"
                                  id="roleName"
                                  placeholder="Role Name"
                                />
                                <ErrorMessage
                                  name="roleName"
                                  component="div"
                                  className="validation"
                                />
                              </div>
                              <div class="col-md-6 col-sm-12 col-xs-12">
                                <label>Activate Date:
                              <span class="validation"> *</span>
                                </label>
                                <Field
                                  type="date"
                                  name="activateDate"
                                  id="activateDate"
                                  className={
                                    "form-control" +
                                    (errors.activateDate && touched.activateDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="activateDate"
                                  component="div"
                                  className="validation"
                                />
                              </div>
                              <div class="col-md-12 col-sm-12 col-xs-12"></div>
                              <div class="col-md-6 col-sm-12 col-xs-12">
                                <label>Expiry Date:
                              <span class="validation"> *</span>
                                </label>
                                <Field
                                  type="date"
                                  name="expiryDate"
                                  id="expiryDate"
                                  className={
                                    "form-control" +
                                    (errors.expiryDate && touched.expiryDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="expiryDate"
                                  component="div"
                                  className="validation"
                                />
                              </div>
                              <div class="col-md-6 col-sm-12 col-xs-12">
                                <label>Suspend Start Date:
                              <span class="validation"> *</span>
                                </label>
                                <Field
                                  type="date"
                                  name="suspendStartDate"
                                  id="suspendStartDate"
                                  className={
                                    "form-control" +
                                    (errors.suspendStartDate &&
                                      touched.suspendStartDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="suspendStartDate"
                                  component="div"
                                  className="validation"
                                />
                              </div>
                              <div class="col-md-12 col-sm-12 col-xs-12"></div>
                              <div class="col-md-6 col-sm-12 col-xs-12">
                                <label>Suspend End Date:
                              <span class="validation"> *</span>
                                </label>
                                <Field
                                  type="date"
                                  name="suspendEndDate"
                                  id="suspendEndDate"
                                  className={
                                    "form-control" +
                                    (errors.suspendEndDate &&
                                      touched.suspendEndDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="suspendEndDate"
                                  component="div"
                                  className="validation"
                                />
                              </div>
                              <div class="col-md-6 col-sm-12 col-xs-12">
                                <label>Description
                              {/* <span class="validation"> *</span> */}
                                </label>
                                <Field
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.description && touched.description
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name="description"
                                  id="description"
                                  placeholder="Description"
                                />
                                <ErrorMessage
                                  name="description"
                                  component="div"
                                  className="validation"
                                />
                              </div>
                              <div class="col-md-12 col-sm-12 col-xs-12"></div>
                              <div class="col-md-6 col-sm-12 col-xs-12">
                                <label>
                                  Status{" "}
                                  <span className="validation">
                                    {" "}
                                    *
                                  </span>
                                </label>
                                <Field
                                  as="select"
                                  className={
                                    "form-control" +
                                    (errors.isActive && touched.isActive
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name="isActive"
                                  id="isActive"

                                >
                                  <option value="">--Select--</option>
                                  <option value="1">Active</option>
                                  <option value="0">Inactive</option>
                                </Field>
                                <ErrorMessage
                                  name="isActive"
                                  component="div"
                                  className="validation"
                                />
                              </div>
                            </div>
                          </section>

                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <button
                              type="submit"
                              name="submitForm"
                              class="btn-5 mt-30 pull-right"
                              onClick={async () => {
                                const curErrors = await formikProps.validateForm();
                                const curErrorsKeys = Object.keys(curErrors);

                                if (curErrorsKeys.length) {
                                  // Assuming curErrorsKeys[0] identifies the first failing field, use that to
                                  // find the correct DOM element to call .focus() on.
                                  //
                                  // Since I set the id attribute on all my form's <input>s to match the error
                                  // keys, I just use document.getElementById(), but of course you could use refs
                                  const el = document.getElementById(
                                    curErrorsKeys[0]
                                  );

                                  if (el) el.focus();
                                }
                              }}
                            >
                              Submit
                          </button>
                            <button
                              type="button"
                              name="cancelForm"
                              id="cancel"
                              class="btn-5 mr-20 mt-30 pull-right"
                              data-dismiss="modal"
                              onClick={handleReset}
                            >
                              Cancel
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </React.Fragment>
            )}
        />
      </React.Fragment>
    );
  }
}

export default AddRole;
