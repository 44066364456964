/*jshint esversion: 6 */

import React, { Component } from 'react';
import Routes from '../components/routes';
import ErrorBoundary from '../components/errorboundary/errorboundary';
import axios from 'axios';
import IdleTimer from 'react-idle-timer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";
import BrowserErrorBoundary from '../components/errorboundary/browsererror';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: '',
      reasonCode: '',
      reasonText: '',
      loading: 'false',
      token: '',
      jtitoken: '',
      timeout: 1000 * 1200 * 1,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      flag: true
    }


    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);


    axios.defaults.headers.common['applicationid'] = '11101';
    axios.defaults.headers.common['buid'] = '2';
    axios.defaults.headers.common['subbuid'] = '1';
    axios.defaults.headers.common['environment'] = 'dev';
    axios.defaults.headers.common['tokentype'] = 'jti';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['issuer'] = 'Dap';
    axios.defaults.headers.common['userlogin'] = 'pramila@gmail.com';

    if (localStorage.getItem('jti-token') !== '' && localStorage.getItem('jti-token') !== null) {
      axios.defaults.headers.common['jtitoken'] = localStorage.getItem('jti-token');
    }
    if (localStorage.getItem('jwt-token') !== '' && localStorage.getItem('jwt-token') !== null) {
      axios.defaults.headers.common['token'] = localStorage.getItem('jwt-token');
    }
  }


  _onAction(e) {
    //console.log('user did something', e)
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    //console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    //console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      //alert("redirecting to login");
      window.location.href = "/?sessiontimeout=true"

    } else {
      this.setState({ showModal: true })
      this.idleTimer.reset();
      this.setState({ isTimedOut: true })
    }

  }
  componentDidMount() {
    
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/)
      if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    var data = {
      name: M[0],
      version: M[1]
    };
    console.log("Browser Name and Version:" + JSON.stringify(data))
    // if(data.name.toLowerCase()==="chrome" && Number(data.version)<=87){
    //   this.setState({flag:false})
    // }
    if (data.name.toLowerCase() === "safari" && Number(data.version) <= 9.1) {
      this.setState({ flag: false })
    }

  }

  render() {
    const { match } = this.props
    if (this.state.flag) {
      return (

        <ErrorBoundary>
          <React.Fragment>
            <Routes {...this.state} />
          </React.Fragment>
        </ErrorBoundary>

      )
    }
    else {
      return (
        <BrowserErrorBoundary />

        // <h1>Not  supported</h1>

      )
    }
  }
}

export default App;
