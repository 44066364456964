import { FETCH_ATTEMPTEDTEST_LIST_ACTION_PENDING, FETCH_ATTEMPTEDTEST_LIST_ACTION_SUCCESS, FETCH_ATTEMPTEDTEST_LIST_ACTION_ERROR } from '../action/reattemptAction';

const initialState = {
    
    pending: false,
    attemptedList: [],
    error: null
}

const reattemptedReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_ATTEMPTEDTEST_LIST_ACTION_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_ATTEMPTEDTEST_LIST_ACTION_SUCCESS:
            return {
                ...state,
                pending: false,
                attemptedList: action.payload,
              
            }
        case FETCH_ATTEMPTEDTEST_LIST_ACTION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        default:
            return state;
    }
}
export default reattemptedReducer;
export const getAttemptedList = state => state.attemptedList;
export const getAttemptedListPending = state => state.pending;
export const getAttemptedListeError = state => state.error;
