import { Global_var } from '../global/global_var';
import RestDataSource from './restdatasource';
import { fetchUserRoleEmailError,fetchUserRoleEmailPending,fetchUserRoleEmailSuccess } from '../action/OtherRoleAccessEMailAction';

// export const UserRoleAccessService = {
//     fetchUserRoleAccess,
//}

export function fetchEmailBasedonRole(userRoleDetails) {
     let url = Global_var.BASEURL +  Global_var.URL_LEARNER_ROLE_USER_DETAILS;
    // return new RestDataSource(url, fnError).Store(userRoleDetails, (res) => fn(res.data));
    return dispatch => {
        var respData = [];
                
        dispatch(fetchUserRoleEmailPending());
        dispatch(fetchUserRoleEmailSuccess(respData));

        return new RestDataSource(url, err =>
            dispatch(fetchUserRoleEmailError(err))
        ).Store(userRoleDetails, (res) => {
            if (res != null) {
                if (res.headers["jtitoken"] != null) {
                    localStorage.setItem("jti-token", res.headers.jtitoken);
                }
                //loop
                
                var data = res.data.responseObject.data
                for(var i=0; i<data.length; i++){
                    var elementJSON = {}
                    elementJSON["value"] = data[i].emailId
                    elementJSON["label"] = data[i].emailId
                    elementJSON["partyid"] = data[i].partyID
                    elementJSON["name"] = "ToEMailId"
                    elementJSON["id"] = "ToEMailId"
                    respData.push(elementJSON)
                }
                dispatch(fetchUserRoleEmailSuccess(respData));
            }
        });
    };
}

