import React from "react";
// import progressoverview from "../../assets/images/progress-overview.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DemoCarousel from "../carousel/carousel";
import FunnelChart from '../carousel/Funnel Chart';

class MyProgress extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
          <h3 className="learner-title">My Progress Overview</h3>
          <div className="progress-overview">
            {/* <DemoCarousel learnerProgressOverview={this.props.learnerProgressOverview}/> */}
            <FunnelChart  />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MyProgress;
