
export const FETCH_PREASSESSMENT_INSTRUCTION = 'FETCH_PREASSESSMENT_INSTRUCTION';
export const FETCH_PREASSESSMENT_TERMS_CONDITION = 'FETCH_PREASSESSMENT_TERMS_CONDITION';

export function fetchAssessmentTermsAndCondition(data) {
    return {
        type: FETCH_PREASSESSMENT_TERMS_CONDITION,
        payload: data
    }
}

export function fetchAssessmentInstruction(data) {
    return {
        type: FETCH_PREASSESSMENT_INSTRUCTION,
        payload: data
    }
}