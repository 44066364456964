import { FETCH_USER_ROLE_WISE_EMAIL_PENDING, FETCH_USER_ROLE_WISE_EMAIL_SUCCESS, FETCH_USER_ROLE_WISE_EMAIL_ERROR } from '../action/OtherRoleAccessEMailAction';

const initialState = {
    pending: false,
    EmailDetails: [],
    error: null
}

const OtherRoleAccessEMailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_ROLE_WISE_EMAIL_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_USER_ROLE_WISE_EMAIL_SUCCESS:
            return {
                ...state,
                pending: false,
                EmailDetails: action.payload
            }
        case FETCH_USER_ROLE_WISE_EMAIL_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default OtherRoleAccessEMailReducer;
export const getOtherUserEmailDetails = state => state.EmailDetails;
export const getOtherUserEmailDetailsPending = state => state.pending;
export const getOtherUserEmailDetailsError = state => state.error;