import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import saveAs from 'save-as'
import { ReattemptTestService } from "../../services/reattemptTest.service"
import JSZipUtils from 'jszip-utils'
import Finxlogo from "../../assets/images/dapIcon.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select, { createFilter } from 'react-select';
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import jsPDF from "jspdf";
import { ExcelService } from "../Excel/excel";
import Modal from "react-responsive-modal";
import ProgressBar from 'react-bootstrap/ProgressBar'
import "../preAssessment/preAssessment.css"
import Finxfooter from "../../containers/finxfooter/finxfooter";


import "jspdf-autotable";
import DashboardHeader from "../dashboardheader/dashboardheader";
var JSZip = require("jszip");

class ViewAssessment extends Component {
    constructor(props) {
        super(props);
        if (localStorage.getItem('login') !== null) {
            this.state = {

                Data: [],
                display: 'none',
                signuptabName: "tab active",
                logintabName: "tab",
                Name: ""

            }

        }
        else {
            window.location.href = '/';
        }
    }
    componentDidMount() {
        this.setState({
            Name: this.props.location.state.data.firstName + " " + this.props.location.state.data.lastName
        })
        document.getElementById('behavioural').style.display = "none";
        // console.log(this.props.location.state.learnerID)
        ReattemptTestService.getAssessmentResult(
            "productID=187422&firstName=&lastName=&fromDate=&toDate=&emailId=&score=0&Quatlets=&learnerPartyID=" + this.props.location.state.data.learnerPartyID + "&learnerID=" + this.props.location.state.data.learnerID,
            // + this.props.location.state.data.learnerPartyID + "&learnerID=" + this.props.location.state.data.learnerID,
            res => {
                let data1 = []
                data1.push(res.responseListObject[0])
                this.setState({
                    Data: res.responseListObject,
                    // countryListForPer: res.responseListObject
                })


                //    );


            }
        );


    }




    switchTab = (evt, inp) => {

        //  var i, tabcontent, tablinks;
        // tabcontent = document.getElementsByClassName("tabcontent");
        if (inp == "factual") {
            this.setState({ display: 'none' })
            this.setState({ signuptabName: "tab active" })
            this.setState({ logintabName: "tab" })
            document.getElementById('behavioural').style.display = "none";
            document.getElementById('factual').style.display = "block";
            evt.currentTarget.className += " active";
        }
        else {
            this.setState({ display: 'block' })
            this.setState({ signuptabName: "tab" })
            this.setState({ logintabName: "tab active" })
            document.getElementById('factual').style.display = "none";
            document.getElementById('behavioural').style.display = "block";
            evt.currentTarget.className += " active";
        }

    }


    render() {


        return (
            <React.Fragment>

                <PopUpPortal
                    HidePopup={this.state.loading}
                    IsVisible={this.state.loading}
                />
                <DashboardHeader
                    // alertNotificationEvents={this.props.alertNotificationEvents}
                    // notificationEvents={this.props.notificationEvents}
                    // adminUser={attemptedList}
                    //adminProfileUser={this.props.adminProfileUser}
                    {...this.props}

                />
                <section className="learner-dashboard mb-50" id="learner-dashboard">
                    <div class="padding-rl-50">
                        <div class="container-fluid">

                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-50 mb-10">
                                <div>
                                    <h3><span>Name: </span>{this.state.Name}</h3>
                                </div>
                                <br />
                                <h3 className="learner-title">FEAT Summary</h3>
                                <div className="progress-box">
                                    <div class="progress" >

                                        <div class="progress-bar progress-bar-success" role="progressbar" style={{ "width": "25%" }}>
                                            {this.state.Data.length > 0 && this.state.Data[0].resultCategory} Quartlet
                        </div>
                                        <div class="progress-bar progress-bar-info" role="progressbar" style={{ "width": "25%" }}>
                                            Total Score {this.state.Data.length > 0 && this.state.Data[0].totalScore}
                                        </div>
                                        <div class="progress-bar progress-bar-danger" role="progressbar" style={{ "width": "25%" }}>
                                            Factual Section Score {this.state.Data.length > 0 && this.state.Data[0].factualScore}
                                        </div>
                                        <div class="progress-bar progress-bar-warning" role="progressbar" style={{ "width": "25%" }}>
                                            Behavioural Section Score {this.state.Data.length > 0 && this.state.Data[0].behaviouralScore}
                                        </div>
                                    </div>
                                </div>
                                <h3 className="learner-title">FEAT Answer Sheet</h3>
                                <div className="preassessmentDetailBox">
                                    <div className="pre-detail-tab">
                                        <ul class="tab-group" id="login-reg-tab">
                                            <li class={this.state.signuptabName}><a href="#factual" onClick={(e) => {
                                                this.switchTab(e, 'factual')
                                            }} >Factual</a></li>
                                            <li class={this.state.logintabName}><a href="#behavioural" onClick={(e) => {
                                                this.switchTab(e, 'behavioural')
                                            }} >Behavioural</a></li>

                                        </ul>
                                    </div>
                                    <section class="" id="factual">
                                        <div class="">
                                            <div className="container-fluid" style={
                                                {
                                                    "padding": 15,
                                                    "width": "100%",
                                                    "height": 500,
                                                    "overflow": "auto",

                                                }
                                            }>
                                                <div class="row">

                                                    {this.state.Data.length > 0 && this.state.Data[0].factualQA.map((question, index) => {
                                                        return (
                                                            <div class="boxbg gridChange assess-body">
                                                                <div class="question-style"> Question No::{index + 1} </div>
                                                                <div class="question">{question.questionText}</div>
                                                                <form className="radioflex">
                                                                    <fieldset
                                                                    //onChange={this.handleSelectOption.bind(this)}
                                                                    >

                                                                        <dd id="assessmentOption">

                                                                            <input className="radio-circle" type="radio"
                                                                                //onChange={this.handleChange}
                                                                                checked={true}
                                                                            //  id={option.testQBankId} name={option.testQBankId} value={[option.testQBankId, option.testQBankOptionId]}

                                                                            />
                                                                            <label className="radio-text" >{question.questionOptionText}</label>
                                                                            <label className="alpabate" ></label>
                                                                        </dd>

                                                                    </fieldset>
                                                                </form>
                                                            </div>

                                                        )
                                                    })}


                                                </div>

                                            </div>
                                        </div>
                                    </section>
                                    <section class="" id="behavioural">

                                        <div class="">
                                            <div class="container-fluid" className="container-fluid" style={
                                                {
                                                    "padding": 15,
                                                    "width": "100%",
                                                    "height": 500,
                                                    "overflow": "auto",

                                                }
                                            }>
                                                <div class="row">

                                                    {this.state.Data.length > 0 && this.state.Data[0].behaviouralQA.map((question, index) => {
                                                        return (
                                                            <div class="boxbg gridChange assess-body">
                                                                <div class="question-style"> Question No::{index + 1} </div>
                                                                <div class="question">{question.questionText}</div>
                                                                <form className="radioflex">
                                                                    <fieldset
                                                                    //onChange={this.handleSelectOption.bind(this)}
                                                                    >

                                                                        <dd id="assessmentOption">

                                                                            <input className="radio-circle" type="radio"
                                                                                //onChange={this.handleChange}
                                                                                checked={true}
                                                                            //  id={option.testQBankId} name={option.testQBankId} value={[option.testQBankId, option.testQBankOptionId]}

                                                                            />
                                                                            <label className="radio-text" >{question.questionOptionText}</label>
                                                                            <label className="alpabate" ></label>
                                                                        </dd>

                                                                    </fieldset>
                                                                </form>
                                                            </div>

                                                        )
                                                    })}


                                                </div>

                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Finxfooter />
            </React.Fragment>
        );
    }
}

export default ViewAssessment;
