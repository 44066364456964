export const FETCH_LEARNER_PAYMENT_HISTORY = "FETCH_LEARNER_PAYMENT_HISTORY";
export const FETCH_LEARNER_PAYMENT_HISTORY_PENDING =
  "FETCH_LEARNER_PAYMENT_HISTORY_PENDING";
export const FETCH_LEARNER_PAYMENT_HISTORY_ERROR =
  "FETCH_LEARNER_PAYMENT_HISTORY_ERROR";

export function fetchLearnerPaymentsHistoryPending() {
  return {
    type: FETCH_LEARNER_PAYMENT_HISTORY_PENDING
  };
}

export function fetchLearnerPaymentsHistorySuccess(learnerPaymentsHistoryData) {
  return {
    type: FETCH_LEARNER_PAYMENT_HISTORY,
    payload: learnerPaymentsHistoryData
  };
}

export function fetchLearnerPaymentsHistoryError(error) {
  return {
    type: FETCH_LEARNER_PAYMENT_HISTORY_ERROR,
    error: error
  };
}
