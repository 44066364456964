export const FETCH_LEARNER_ENQUIRY_PENDING = "FETCH_LEARNER_ENQUIRY_PENDING";
export const FETCH_LEARNER_ENQUIRY_SUCCESS = "FETCH_LEARNER_ENQUIRY_SUCCESS";
export const FETCH_LEARNER_ENQUIRY_ERROR = "FETCH_LEARNER_ENQUIRY_ERROR";

export function fetchLearnerEnquiryPending() {
  return {
    type: FETCH_LEARNER_ENQUIRY_PENDING
  };
}

export function fetchLearnerEnquirySuccess(learner_enquiry) {
  return {
    type: FETCH_LEARNER_ENQUIRY_SUCCESS,
    payload: learner_enquiry
  };
}

export function fetchLearnerEnquiryError(error) {
  return {
    type: FETCH_LEARNER_ENQUIRY_ERROR,
    error: error
  };
}
