import { Global_var } from '../global/global_var';
import RestDataSource from './restdatasource';
import { LoginService } from './login.service';

export const CourserAndModuleService = {
    fetchCourseModuleDetailsByPartyID,
    fetchModuleDetailsByProductID
};

function fetchCourseModuleDetailsByPartyID(ProductById, fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_MODULE_COURSE_PRD_PARTY;
    return new RestDataSource(url, fnError).GetOneByParam(ProductById, (res) => {
        if (res != null) {
            if (res.headers["jtitoken"] != null) {
                localStorage.setItem('jti-token', res.headers.jtitoken);
            }
            fn(res.data);
        }
    });
}

function fetchModuleDetailsByProductID(ProductById, fn, fnError) {
    var url = Global_var.BASEURL + Global_var.URL_MODULE_BY_PRODUCT;
    return new RestDataSource(url, fnError).GetOneByParam(ProductById, (res) => {
        if (res != null) {
            if (res.headers["jtitoken"] != null) {
                localStorage.setItem('jti-token', res.headers.jtitoken);
            }
            fn(res.data);
        }
    });
}
