import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ReattemptTestService } from "../../services/reattemptTest.service"
import { LookUpService } from "../../services/lookupService"
import { fetchProcessType } from "../../services/fetchLearners";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RegionManagementService } from "../../services/regionManagement.service";
import { LoginService } from "../../services/login.service";
import { RegisterUserService } from "../../services/registration.service"

import {
    success, successNotification, error, errorNotification, warning,
    warningNotification,
} from "../notification/notifications";
import './table.css'
import * as moment from "moment";
import { Global_var } from '../../global/global_var';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const downloadAdmit = (props) => {
    var data = JSON.parse(localStorage.getItem("PersonnelData"))
    let tempdata = {
        "workflowId": data.responseObject.learnerID,

        "history": true
    }
    ReattemptTestService.getStatuscompleted(tempdata, res => {

        let admitCardDocId = res.data.responseListObject[0].additionalDetails.docId
        RegisterUserService.downloadDocumet("documentId=" + admitCardDocId + "&bucketTag=" + Global_var.CONST_BUCKET_NAME, res1 => {

            window.location.href = res1.responseObject.documentUrl
            window.$('#downloadAdmitCard').modal('hide')
        })


    })
}

export default function ShowVerticalLinearStepper(props) {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = React.useState([]);
    const [learnerData] = React.useState(props);
    const [processTypeData, setProcessTypeData] = React.useState([]);
    const [stateListForCo, setStateList] = React.useState([]);
    const [stateListForCo1, setStateList1] = React.useState(0);
    const [getGridData, setGridData] = React.useState([]);
    const [CurrentState, setCurrentState] = React.useState([]);

    React.useEffect(() => {
        // let Name1 = localStorage.getItem("showstatusName")
        //   alert(Name1)
        //  window.$('#downloadAdmitCard').modal('show')
        //  window.$('#statusPopup').modal('show')
        var data2 = localStorage.getItem("PersonnelData")

        let result = ["REGISTERED", "PROFILE COMPLETED", "FEAT COMPLETED", "NISM VA SUBSCRIBED", "NISM VA PAYMENT COMPLETED", "NISM PROFILE CREATED",
            "NISM EXAM DATE CONFIRMED", "ADMIT CARD AVAILABLE", "NISM VA CERTIFICATION", "KYD-ARN APPLIED",
            "KYD-ARN ACCEPTED", "UNDER EMPANELMENT", "EMPANELLED"]


        setSteps(result)
        let tempdata = {
            "workflowId": learnerData.personnelProfileData.learnerID,
            // "204092",
            "history": true
        }
        ReattemptTestService.getStatuscompleted(tempdata, res => {

            //
            let Name = localStorage.getItem("showstatusName")

            //  let result1=["REGISTERED","APPLY FOR FEAT","FEAT COMPLETED"]
            // localStorage.setItem("currentState", res.data.responseListObject[0].state.applicationStatus)
            setActiveStep(result.indexOf(res.data.responseListObject[0].state.applicationStatus) + 1)
            if (res.data.responseListObject[0].state.applicationStatus == "NISM PROFILE CREATED") {
                document.getElementById('dateLocationPopup').style.display = "block";
                document.getElementById('certificateDownloadpopup').style.display = "none";
                document.getElementById('kydpopup').style.display = "none";

            }
            else if (res.data.responseListObject[0].state.applicationStatus == "ADMIT CARD AVAILABLE") {
                if (res.data.responseListObject[0].additionalDetails.flag === false) {
                    document.getElementById('certificateDownloadpopup').style.display = "block";
                    document.getElementById('dateLocationPopup').style.display = "none";
                    document.getElementById('kydpopup').style.display = "none";
                }
                else {
                    document.getElementById('certificateDownloadpopup').style.display = "none";
                    document.getElementById('dateLocationPopup').style.display = "none";
                    document.getElementById('kydpopup').style.display = "none";
                }



            }
            else if (res.data.responseListObject[0].state.applicationStatus == "KYD-ARN APPLIED") {
                document.getElementById('dateLocationPopup').style.display = "none";
                document.getElementById('certificateDownloadpopup').style.display = "none";
                document.getElementById('kydpopup').style.display = "block";
                //document.getElementById('kydpopup').style.display = "none";
            }
            else {
                document.getElementById('dateLocationPopup').style.display = "none";
                document.getElementById('certificateDownloadpopup').style.display = "none";
                document.getElementById('kydpopup').style.display = "none";
            }

        }
        )
        LoginService.TokenUser(res => {
            localStorage.setItem("jwt-token", res.responseObject);
        });

        RegionManagementService.getState(
            "countryId=1",
            //+ this.state.corcountryId,
            res => {

                //   console.log("resaaaaaa")
                setStateList(res.responseListObject)
                setStateList1(12345)

                // console.log(stateListForCo1)
            }
        );



    }, []);
    // let dateLocation = localStorage.getItem("getDateLocationData")
    // console.log(JSON.parse(dateLocation))
    return (
        <div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mystatus">
                {/* <h3 className="learner-title">My Status</h3> */}
                <div className="my-status">
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} orientation="vertical" >
                            {steps && steps.map((label, index) => (

                                <Step key={label} onClick={e => {
                                    //  alert(label)
                                    if (label == "NISM PROFILE CREATED") {
                                        document.getElementById('dateLocationPopup').style.display = "block";
                                        document.getElementById('certificateDownloadpopup').style.display = "none";
                                        document.getElementById('kydpopup').style.display = "none";
                                    }
                                    else if (label == "ADMIT CARD AVAILABLE") {
                                        document.getElementById('dateLocationPopup').style.display = "none";
                                        document.getElementById('certificateDownloadpopup').style.display = "block";
                                        document.getElementById('kydpopup').style.display = "none";
                                    }
                                    else if (label == "KYD-ARN APPLIED") {
                                        document.getElementById('dateLocationPopup').style.display = "none";
                                        document.getElementById('certificateDownloadpopup').style.display = "none";
                                        //    document.getElementById('kydpopup').style.display = "block";
                                        //document.getElementById('kydpopup').style.display = "none";
                                    }

                                    else {
                                        document.getElementById('dateLocationPopup').style.display = "none";
                                        document.getElementById('certificateDownloadpopup').style.display = "none";
                                        document.getElementById('kydpopup').style.display = "none";
                                    }
                                }} >
                                    <StepLabel >{label}</StepLabel>
                                    {/* <StepContent>
           
              <div className={classes.actionsContainer}>
               
                {activeStep==5? 
                <div>
                  <Button
                    color="blue"
                    className={classes.button}
                    onClick={e=>{
                      window.$('#downloadAdmitCard').modal('show')
                    }}
                  >
                 Admit card available
                   
                  </Button>
                 
                </div>
                 :''}
              </div>
            </StepContent> */}
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </div>
            </div>












        </div>
    );
}
