import React from 'react';
import Finxheader from '../finxheader/finxheader';
import Finxfooter from '../finxfooter/finxfooter';
import FinxFooterLinks from "../finxfooter/finxfooterlinks"

export default class Cookies extends React.Component {
    constructor(props) {
        super(props);
      
    }


    render() {
        return (
            <React.Fragment>
                <section className="cookies-policy pt-50 mb-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <h1 className="text-center">Cookies Policy</h1>
                                <p>Cookies are text files containing small amounts of information, which your computer or mobile device downloads when you visit a website. When you return to websites — or visit other websites that use the same cookies — they recognize
                        these cookies and therefore your browsing device.</p>
                                <p>Cookies help us understand how this website is being used. For example, letting you navigate between pages efficiently, remembering your preferences, and generally improving your browsing experience. Cookies also help in ensuring that
                        the marketing you see online is more relevant to you and your interests.</p>
                                <p>By using this website, you agree that we can place cookies on your device. Some of our services will not function if your browser or device does not accept our cookies.</p>
                                <p>In cases where we have a presence on a third party site, such as a page or handle on a social media site, that third party’s privacy policy and terms of use will govern over this Policy, unless specifically stated otherwise.</p>
                                <p>The following types of cookies may be used in this website as may be required from time to time:</p>
                                <h5> STRICTLY NECESSARY COOKIES:</h5>
                                <p>These cookies are essential, as they enable you to move around the website and use its features, such as accessing secure areas. Without these cookies, services you’ve asked for can’t be provided. These cookies don’t gather information
                        about you that is used for marketing or remembering where you’ve been on the internet.</p>
                                <h5>PERFORMANCE COOKIES:</h5>
                                <p>These cookies collect information about how you use a website, For example, which pages you visit most often, whether you get error messages from certain pages, etc. Performance cookies doesn’t gather information that identifies or
                                    reveals you. All information collected by these cookies is anonymous and is used to improve the website. These cookies are not used to target you with online marketing. Through these cookies, we can learn how our website is performing
                        and make relevant improvements that could better your browsing experience.</p>
                                <h5>FUNCTIONALITY COOKIES:</h5>
                                <p>These cookies allow a website to remember the choices you make and tailor it to provide enhanced features and content for you. Without these cookies, a website cannot remember the choices you’ve previously made or personalize your
                        browsing experience.</p>
                                <h5>TARGETING COOKIES:</h5>
                                <p>These cookies are used to tailor marketing to you and your interests. They are also used to limit the number of times you see an advertisement and help in measuring the effectiveness of the advertising campaign. These cookies remember
                                    that you have visited a website and this information may be shared with other organizations such as advertisers. Although these cookies can track your visits to other websites, they don’t usually know who you are. Without these
                        cookies, online advertisements will be less relevant to you and your interests.</p>
                                <p>This policy may be amended from time to time and the latest policy will be posted on this page.</p>
                            </div>
                        </div>
                    </div>
                </section>
            <FinxFooterLinks />
            </React.Fragment>
    
        );
    }
}



