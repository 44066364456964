import RestDataSource from './restdatasource';
import { Global_var } from '../global/global_var';

export const FetchAlertService = {
  fetchAlert
};

function fetchAlert(fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_ALERTS_NOTIFICATION_EVENTS;

  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}



