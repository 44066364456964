export const FETCH_ENROLLMENT_HISTORY = "FETCH_ENROLLMENT_HISTORY";
export const FETCH_ENROLLMENT_HISTORY_PENDING =
  "FETCH_ENROLLMENT_PENDING";
export const FETCH_ENROLLMENT_HISTORY_ERROR =
  "FETCH_ENROLLMENT_HISTORY_ERROR";

export function fetchEnrollmentHistoryPending() {
  return {
    type: FETCH_ENROLLMENT_HISTORY_PENDING
  };
}

export function fetchEnrollmentHistorySuccess(learnerEnrollmentHistoryData) {
  return {
    type: FETCH_ENROLLMENT_HISTORY,
    payload: learnerEnrollmentHistoryData
  };
}

export function fetchEnrollmentHistoryError(error) {
  return {
    type: FETCH_ENROLLMENT_HISTORY_ERROR,
    error: error
  };
}
