import { FETCH_USER_ROLE_ACCESS_MANAGEMENT_PENDING, FETCH_USER_ROLE_ACCESS_MANAGEMENT_SUCCESS, FETCH_USER_ROLE_ACCESS_MANAGEMENT_ERROR } from '../action/roleAccessAction';

const initialState = {
    pending: false,
    userRoleAccess: [],
    error: null
}

const roleAccessReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_ROLE_ACCESS_MANAGEMENT_PENDING:
            return {
                ...state,
                pending: true
            }
        case FETCH_USER_ROLE_ACCESS_MANAGEMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                userRoleAccess: action.payload
            }
        case FETCH_USER_ROLE_ACCESS_MANAGEMENT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default roleAccessReducer;
export const getUserRoleAcess = state => state.userRoleAccess;
export const getUserRoleAcessPending = state => state.pending;
export const getUserRoleAcessError = state => state.error;