import { Global_var } from '../global/global_var';
import RestDataSource from './restdatasource';
import { LoginService } from './login.service';

export const PaymentService = {
    Makepayment,
};

function Makepayment(payobject, fn, fnError) {
   // alert("payservice")
    LoginService.TokenUser(res => {
        if (res.status === 'success') {

            localStorage.setItem('jwt-token', res.responseObject);
          //  var url = "http://172.16.16.99:9003" + Global_var.URL_DO_Payment;
             var url = Global_var.BASEURL + Global_var.URL_DO_Payment;

            //var mobilevalidat = JSON.stringify(ProductByName);
            return new RestDataSource(url, fnError).Store(payobject, (res) => {
                if (res != null) {
                    if (res.headers["jtitoken"] != null) {
                        localStorage.setItem('jti-token', res.headers.jtitoken);
                    }
                    fn(res);
                }
            });
        }
    }, fnError);

}





