import {
  FETCH_LEARNER_ENQUIRY_PENDING,
  FETCH_LEARNER_ENQUIRY_SUCCESS,
  FETCH_LEARNER_ENQUIRY_ERROR
} from "../action/learnerEnquiryAction";

const initialState = {
  pending: false,
  learnerEnquiry: [],
  error: null
};

const learnerEnquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEARNER_ENQUIRY_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_LEARNER_ENQUIRY_SUCCESS:
      return {
        ...state,
        pending: false,
        learnerEnquiry: action.payload
      };
    case FETCH_LEARNER_ENQUIRY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };
    default:
      return state;
  }
};
export default learnerEnquiryReducer;
export const getLearnerEnquiry = state => state.learnerEnquiry;
export const getLearnerEnquiryPending = state => state.pending;
export const getLearnerEnquiryError = state => state.error;
