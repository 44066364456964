import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import { getCalenderEvents, getCalenderEventsPending } from "../../reducer/calenderEventsReducer";
// import {
//   getAdminUser,
//   getAdminUserPending
// } from "../../reducer/adminUserReducer";
import { getAdminUserProfile, getAdminUserProfilePending, getAdminUserProfileError } from "../../reducer/adminProfileUserReducer";
import { bindActionCreators } from "redux";
import { fetchAdminUser } from "../../services/admin.service";
import AdminListLandingPage from "../../components/admin/adminList";
import { LoginService } from "../../services/login.service";
import { getPersonnelAddressData } from "../../reducer/userProfileReducer";
import { RegisterUserService } from "../../services/registration.service";
import { fetchRoles } from "../../services/fetchRoles";
import { getRoles } from "../../reducer/roleReducer";
import AdminHeader from "../../components/dashboardheader/adminheader";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import $ from "jquery";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer"
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchLearnerDashboard.service";


const mapStateToProps = state => ({
  adminProfileUser: getAdminUserProfile(state.adminUserProfileReducer),
  pending: getAdminUserProfilePending(state.adminUserProfileReducer),
  addressData: getPersonnelAddressData(state.userProfileReducers),
  roles: getRoles(state.roleReducer),
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAdminUser: fetchAdminUser,
      fetchUserAddressData: RegisterUserService.getUserAddress,
      fetchRoles: fetchRoles,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const adminList = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {
      const { fetchAdminUser, fetchRoles, fetchAlertNotificationEvents, fetchNotificationEvents } = this.props;
      let partyID = localStorage.getItem("userid-Token");

      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          fetchAdminUser("limit=-1");
          fetchAlertNotificationEvents();
          fetchNotificationEvents("partyId=" + partyID);
          // fetchRoles();
        }
      });
    }

    render() {

      const { adminProfileUser, pending, fetchUserAddressData, alertNotificationEvents, pendingAlertNotificationEvents,
        notificationEvents, pendingNotificationEvents
      } = this.props;
      console.log("pending", this.props.adminProfileUser)
      if (!pending) {
        if (!pending && !pendingNotificationEvents && !pendingAlertNotificationEvents) {
          return (
            <React.Fragment>
              <AdminHeader
                //alertNotificationEvents={this.props.alertNotificationEvents}
                //notificationEvents={this.props.notificationEvents}
                adminUser={this.props.adminProfileUser}
                //adminProfileUser={this.props.adminProfileUser}
                {...this.props}

              />
              <AdminListLandingPage
                //adminProfileUser={admin}
                adminUser={adminProfileUser}
                getAdminAddress={fetchUserAddressData}
                fetchAdminUser={fetchAdminUser}
                //alertNotificationEvents={this.props.alertNotificationEvents}
                //notificationEvents={this.props.notificationEvents}
                {...this.props}
              />
              <Finxfooter />
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />

              <AdminHeader
                //alertNotificationEvents={this.props.alertNotificationEvents}
                //notificationEvents={this.props.notificationEvents}
                adminUser={adminProfileUser}
                //adminProfileUser={this.props.adminProfileUser}
                {...this.props}

              />
              <AdminListLandingPage
                //adminProfileUser={admin}
                adminProfileUser={adminProfileUser}
                getAdminAddress={fetchUserAddressData}
                fetchAdminUser={fetchAdminUser}
                {...this.props}
              />
              <Finxfooter />
            </React.Fragment>
          );
        }
      } else {
        return (<React.Fragment>
          <AdminHeader
            //alertNotificationEvents={this.props.alertNotificationEvents}
            //notificationEvents={this.props.notificationEvents}
            adminProfileUser={this.props.pending}
            //adminProfileUser={this.props.adminProfileUser}
            {...this.props}

          />
        </React.Fragment>)
      }




      // if (pending) {
      //   return (
      //     <React.Fragment>
      //       <PopUpPortal
      //         HidePopup={this.state.loading}
      //         IsVisible={this.state.loading}
      //       />

      //       <AdminHeader 

      //       />
      //       <AdminListLandingPage
      //         adminProfileUser={adminProfileUser}
      //         getAdminAddress={fetchUserAddressData}
      //         fetchAdminUser={fetchAdminUser}
      //         {...this.props}
      //       />
      //       <Finxfooter />
      //     </React.Fragment>
      //   );
      // } else {
      //   return (
      //     <React.Fragment>
      //       <AdminHeader
      //        //alertNotificationEvents={this.props.location.state.alerts}
      //        {...this.props}
      //        //notificationEvents={this.props.location.state.notification}
      //        {...this.props}
      //       />
      //       <AdminListLandingPage
      //         adminProfileUser={adminProfileUser}
      //         getAdminAddress={fetchUserAddressData}
      //         fetchAdminUser={fetchAdminUser}
      //         {...this.props}
      //       />
      //       <Finxfooter />
      //     </React.Fragment>
      //   );
      // }
    }
  }
);

export default adminList;
