import {
  fetchLearnerPending,
  fetchLearnerSuccess,
  fetchLearnerError
} from "../action/learnerAction";
import {
  fetchGdpiStatusPending,
  fetchGdpiStatusSuccess,
  fetchGdpiStatusError
} from "../action/gdpiStatusAction";
import RestDataSource from "./restdatasource";
import { Global_var } from "../global/global_var";
import { LoginService } from "./login.service";

export function fetchLearners() {
  let url = Global_var.BASEURL + Global_var.URL_GDPI_LEARNER;
  return dispatch => {
    dispatch(fetchLearnerPending());

    return new RestDataSource(url, err =>
      dispatch(fetchLearnerError(err.message))
    ).GetData(res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchLearnerSuccess(res.data.responseListObject));
      }
    });
  };
}

export function initateGDPI(data, fn, fnError) {
  
  let url = Global_var.BASEURL + Global_var.URL_GDPI_LEARNER;
  return new RestDataSource(url, fnError).Store(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

export function updateGdpiScore(data, cb) {
  LoginService.TokenUser(res => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      let url = Global_var.BASEURL + Global_var.URL_GDPI_LEARNER;
      return new RestDataSource(url).Update(data, res => {
        if (res != null) {
          if (res.headers["jtitoken"] != null) {
            localStorage.setItem("jti-token", res.headers.jtitoken);
          }
          cb(res.data);
        }
      });
    }
  });
}

export function fetchGdpiStatus() {
  let parameter= "codeTypeName=gdpi_status";
  var url = Global_var.BASEURL + Global_var.URL_GETLOOKUP_BY_CD_TYPE_NAME;
  return dispatch => {
    dispatch(fetchGdpiStatusPending());
    return new RestDataSource(url, err =>
      dispatch(fetchGdpiStatusError(err.message))
    ).GetOneByParam(parameter,res => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        dispatch(fetchGdpiStatusSuccess(res.data.responseListObject));
      }
    });
  };
}

export function updateLearnerAssessment(data, fn, fnError) {
  
  let url = Global_var.BASEURL + Global_var.URL_ASSESSMENT_UPDATION;
  return new RestDataSource(url, fnError).Update(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// let url = Global_var.BASEURL + Global_var.URL_GDPI_Learner;
// return dispatch => {
//     return new RestDataSource(url, (err) => dispatch(fetchLearnerError(err.message)))
//         .Store(data, res => {
//             if (res != null) {
//                 if (res.headers["jtitoken"] != null) {
//                     localStorage.setItem('jti-token', res.headers.jtitoken);
//                 }
//                 fn(res.data);
//                 dispatch(fetchLearnerSuccess(res.data));

//             }
//         });
// };


export function fetchProcessType(TypeId, fn, fnError) {
  //let parameter="learnerID=198629"
 //var url='http://192.168.12.113:8061/api/v1/learner'
 var url = Global_var.BASEURL + Global_var.URL_USERDATA;
  return new RestDataSource(url, fnError).GetOneByParam(TypeId, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}



export function getAMCMaster(fn, fnError) {
  //var url='http://192.168.10.107:8052/api/v1/register/getAMCMaster';
  
 var url = Global_var.BASEURL + Global_var.URL_GETAMCMASTER;
  return new RestDataSource(url, fnError).GetData(res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
