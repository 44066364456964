import React, { Component } from "react";
import Finxheader from '../finxheader/finxheader';
import Finxfooter from '../finxfooter/finxfooter';
import FinxFooterLinks from "../finxfooter/finxfooterlinks"
import $ from "jquery";

export default class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
            data: [
                { id: "tab1 faqbtn", name: "About CFX Program", info: <div  class="tabcontent">
                <div class="">
                  <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab1a">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What is the duration of the Programme?
                          </h4>
                        </div>
                      </a>
                      <div id="tab1a" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              Chartered Financial Expert (CFX) is a 6-month programme, of which the initial
                              3 months comprise of classroom + digital training that includes practical
                              assignments followed by 3 months of full-time paid internship with marquee
                              BFSI corporates which will provide the learner with real time exposure to
                              working in a professional environment
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab1b">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What are the salient features of the Programme?
                          </h4>
                        </div>
                      </a>
                      <div id="tab1b" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              <ol>
                                <li>The Programme provides exposure to the BFSI industry, along with relevant practical
                                  skilling and a strong technical foundation</li>
                                <li>Regulatory certifications in the realm of Mutual Funds, Broking and Insurance</li>
                                <li>Chartered Financial Expert (CFX) makes candidates job / entrepreneurship-ready in order
                                  to kickstart a career in the BFSI sector</li>
                                <li>Assured paid internships for 3 months, for all learners who are enrolled for the
                                  Programme</li>
                                <li>Trained by industry experts who possess decades of experience in the BFSI sector</li>
                              </ol>
                            </div>
                          </div>
    
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab1c">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Who issues the Chartered Financial Expert (CFX) Certification?
                          </h4>
                        </div>
                      </a>
                      <div id="tab1c" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              The Chartered Financial Expert Certification is jointly issued by Dap, along with National
                              Institute of Securities Markets (NISM), NSE Academy & Prin. L N Welingkar Institute of
                              Management Development and Research (WeSchool).We also have representatives from our
                              Co-certification Partners, BFSI Industry Bodies – Indian Banks Association (IBA), Life
                              Insurance Council (LI Council), General Insurance Council (GI Council) & Association of Mutual
                              Funds of India (AMFI), and the Industry corporate representatives on the Governing Council of
                              Chartered Financial Expert (CFX).
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab1d">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What certification will be awarded to me upon successful completion of the Programme?
                          </h4>
                        </div>
                      </a>
                      <div id="tab1d" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              All learners who successfully complete the 6-month training and receive a satisfactory
                              performance review from the BFSI Corporate (internship partner) will receive the Chartered
                              Financial Expert certificate.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab1e">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What are the fees for the Programme?
                          </h4>
                        </div>
                      </a>
                      <div id="tab1e" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              The fees for the Programme amount to INR 75,000. Inclusive of the stipend, the net programme
                              fees would approximately amount to INR 30,000.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab1f">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Why should colleges for the program?
                          </h4>
                        </div>
                      </a>
                      <div id="tab1f" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              <ol>
                                <li>Industry exposure for the learner and engagement with eminent industry experts</li>
                                <li>Enhancement of NAAC accreditation factors</li>
                                <li>Functional skilling on entrepreneurship & sales for learners of your college</li>
                                <li>Association with industry leaders in BFSI</li>
                                <li>Co-branded certification for learners</li>
                                <li>Entrepreneurship track for candidates, which promotes entrepreneurship and grooms
                                  entrepreneurial talent among your learners</li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab1g">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Why should corporates partner for the program?
                          </h4>
                        </div>
                      </a>
                      <div id="tab1g" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              <ol>
                                <li>Continuous resource availability in the form of a young workforce</li>
                                <li>Higher retention & higher productivity among the workforce</li>
                                <li>Job ready skilled workforce</li>
                                <li>Customized corporate induction-training</li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab1h">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What certification will be awarded to me upon successful completion of the Programme?
                          </h4>
                        </div>
                      </a>
                      <div id="tab1h" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              All learners who successfully complete the 6-month training and receive a satisfactory
                              performance review from the BFSI Corporate (internship partner) will receive the Chartered
                              Financial Expert certificate.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>},
                { id: "tab2 faqbtn", name: "Course Design", info:  <div  class="tabcontent">
                <div class="">
                  <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab2a">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What will be the medium of delivery for the course?
                          </h4>
                        </div>
                      </a>
                      <div id="tab2a" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              The Programme offers a blended-learning approach to training, which comprises of classroom
                              training along with training using the digital & phygital mediums.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab2b">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Where will the Programme be conducted?
                          </h4>
                        </div>
                      </a>
                      <div id="tab2b" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              The classroom training of the Programme will be conducted after your regular college timings
                              in your college or the nearest neighbouring college.
                            </div>
                          </div>
    
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab2c">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What are the class timings?
                          </h4>
                        </div>
                      </a>
                      <div id="tab2c" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              The classroom training of the Programme will be decided in collaboration with the college
                              based on the regular study schedule of the learners.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab2d">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Which aspects will be covered as a part of the Programme?
                          </h4>
                        </div>
                      </a>
                      <div id="tab2d" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              <ol>
                                <li>Regulatory Certifications – NISM Series VA, NISM Series VII & IC-38</li>
                                <li>Key Economic Concepts & Financial Mathematics</li>
                                <li>Understanding Products from all BFSI Domains – Saving, Protection, Investment & Lending
                                </li>
                                <li>Behavioural Skills</li>
                                <li>Selling Skills & Sales Process</li>
                                <li>Actual sales projects</li>
                                <li>Paid internship with marquee BFSI Corporate</li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab2e">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Who will impart the classroom training of the Programme?
                          </h4>
                        </div>
                      </a>
                      <div id="tab2e" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              The classroom training will be imparted by seasoned trainers & practitioners of the BFSI
                              industry, who possess several years of experience.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab2f">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            When will the Chartered Financial Expert (CFX) certificate be issued?
                          </h4>
                        </div>
                      </a>
                      <div id="tab2f" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              The Chartered Financial Expert (CFX) charter will be awarded upon successful completion of the
                              6 months programme that includes 3 months training with Dap and a 3 month On-Job Training
                              subject to satisfactory review from the BFSI Corporate during the paid internship.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> },
                { id: "tab3 faqbtn", name: "Selection Process", info: <div  class="tabcontent">
                <div class="">
                  <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab3a">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Who is eligible for Chartered Financial Expert (CFX)?
                          </h4>
                        </div>
                      </a>
                      <div id="tab3a" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              Learner in final year of graduation across any stream or who have graduated in the previous
                              year are eligible to pursue Chartered Financial Expert (CFX).
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab3b">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What are the selection criteria for the Programme?
                          </h4>
                        </div>
                      </a>
                      <div id="tab3b" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              <ol>
                                <li>FEAT – Dap Entrepreneurship Assessment Test</li>
                                <li>Group Discussion</li>
                                <li>Personal Interview</li>
                                <li>Academic Qualifications</li>
                              </ol>
                            </div>
                          </div>
    
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>},
                { id: "tab4 faqbtn", name: "FEAT", info:  <div  class="tabcontent">
                <div class="">
                  <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab4a">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What is FEAT?
                          </h4>
                        </div>
                      </a>
                      <div id="tab4a" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              FEAT – Dap Entrepreneurship Assessment Test is a 60-minute online psychometric assessment
                              test which assesses the entrepreneurship quotient and sales orientation of the candidate.
                              <br />
                              FEAT is a pre-requisite for any candidate who wishes to pursue Chartered Financial Expert
                              (CFX).
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab4b">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Who is eligible for FEAT?
                          </h4>
                        </div>
                      </a>
                      <div id="tab4b" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              Any candidate who wishes to recognize their skillsets and capabilities to commence the pathway
                              to being a successful entrepreneur / professional. FEAT can help any candidate in
                              understanding their behavioural and cognitive strengths and potential areas of improvement.
                            </div>
                          </div>
    
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab4c">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What are the benefits of taking the FEAT?
                          </h4>
                        </div>
                      </a>
                      <div id="tab4c" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              <ol>
                                <li>Know your entrepreneurship quotient & sales orientation</li>
                                <li>Understand your strengths and weaknesses in pursuit of a successful career</li>
                                <li>Know the fitment and suitability with regards to your career-roles</li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab4d">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            When can I take the FEAT?
                          </h4>
                        </div>
                      </a>
                      <div id="tab4d" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              FEAT is an online psychometric test which is available all year-round and can be taken on PC /
                              laptop with an active internet connection.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab4e">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What are the fees for taking the FEAT?
                          </h4>
                        </div>
                      </a>
                      <div id="tab4e" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              FEAT is charged at a nominal fee of INR 1000 per test.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> },
                { id: "tab5 faqbtn", name: "Internship", info: <div  class="tabcontent">
                <div class="">
                  <div class="panel-group" id="accordion">
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab5a">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Is the internship assured as a part of the Programme?
                          </h4>
                        </div>
                      </a>
                      <div id="tab5a" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              Yes. Upon successfully clearing the selection process, the candidate will be assigned to a
                              corporate. In effect, upon the commencement of the Chartered Financial Expert (CFX) Programme,
                              the candidate will receive all the joining details with respect to the internship.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab5b">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            What is the duration of the internship?
                          </h4>
                        </div>
                      </a>
                      <div id="tab5b" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              The candidate will undertake the internship with his / her designated corporate for a period
                              of 3 months.
                            </div>
                          </div>
    
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab5c">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Is the internship compulsory?
                          </h4>
                        </div>
                      </a>
                      <div id="tab5c" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              Yes. The candidate will be awarded the Chartered Financial Expert (CFX) charter only upon
                              successful completion of the practical training as well as a satisfactory review from the
                              corporate to which he / she is assigned.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab5d">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Will I be paid as a part of the internship?
                          </h4>
                        </div>
                      </a>
                      <div id="tab5d" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              The candidate will receive a monthly stipend as a part of their internship.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="panel panel-default">
                      <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#tab5e">
                        <div class="panel-heading">
                          <h4 class="panel-title">
                            Does the course help with placements?
                          </h4>
                        </div>
                      </a>
                      <div id="tab5e" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="col-lg-12 col-sm-12 col-12">
                            <div class="notification-body">
                              Chartered Financial Expert provides an in-built internship of 3 months with marquee BFSI
                              corporates. The final placement will depend on the corporate’s assessment of the candidate
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> },

            ]
        };


       
    }

    componentDidMount(){

        $(document).ready(function() {
            $(".tablinks:first-child").addClass("active")
			
            $(".tablinks").click(function() {
                $(".tablinks").removeClass("active")
                $(this).addClass("active")
				
            });
        });

    }

    handleClick(currentTab) {
        this.setState({ currentTab });
		
		$("a.accordion-toggle,.panel-collapse").attr("aria-expanded", false)
		$(".panel-collapse").removeClass("in")
		 setTimeout(function(){
		//$(".panel-group .panel:first-child a.accordion-toggle,.panel-group .panel:first-child div.panel-collapse").attr("aria-expanded", true)
		//$(".panel-group .panel:first-child div.panel-collapse").addClass("in")
		//$(".panel-group .panel:first-child div.panel-collapse").trigger("dblclick")
		}, 1000);
    }

    render() {
        return (
            <div>
                <section id="faq" class="disclaimer pt-50 mb-50">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h1 class="text-center">FaQ</h1>
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                                    <div class="tab">
                                        {this.state.data.map((button, i) => (
                                       <button  key={button.name} className="tablinks" onClick={() => this.handleClick(i)}>{button.name}</button>
                                        )
                                        )
                                        }
                                    </div>
                                </div>
                                <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                                    <div class="tabcontent">
                                        {this.state.currentTab !== -1 &&
                                            <React.Fragment>
                                                {this.state.data[this.state.currentTab].info}
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <FinxFooterLinks />
            </div>

        )
    }
}




