import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { success, successNotification } from "../notification/notifications";
import * as moment from "moment";
import { ExcelService } from "../Excel/excel";

class SentEmailList extends Component {
    constructor(props) {
        super(props);
        //console.log("bulkl", this.props.alertNotificationEvents)

        //    //console.log("enquiry", this.props.learnerEnquiry)

    }
    filterCaseInsensitive = ({ id, value }, row) =>
        row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    handleRole = event => {
        this.setState({ roleType: event.target.value });
    };



    exportToCSV = () => {

        let modifiedData = [];
        let data = this.props.learnerEnquiry;

        data.forEach(element => {
            var data1 = {
                Full_Name: element.fullName,
                Email_ID: element.emailId,
                CC: element.ccemailList,
                Subject: element.enquirySubject,
                Message: element.enquiryMessage,
                Email_Send_Date: moment(element.createdDate, 'DD/MM/YYYY ,hh:mm:ss').format('DD/MM/YYYY')

            }
            modifiedData.push(data1);
        });

        ExcelService.ExcelData(modifiedData, 'SentEmail_Report-');

    };

    render() {
        //console.log("PROPS: ", this.props.adminUser);
        return (
            <React.Fragment>
                <section class="dashboard-tab user-tab pt-80 mb-50">
                    <div class="padding-rl-50">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <div class="table-header">
                                        <h3>
                                            <div class="row">
                                                <div class="col-md-8 col-sm-6 col-xs-8">
                                                    <span class="left-icon">
                                                        <i class="fa fa-list" aria-hidden="true"></i>
                                                    </span>{" "}
                                                    Send Email List
                                                   </div>
                                                <div class="col-md-4 col-sm-6 col-xs-4">
                                                    <span class="right-icon">
                                                        <a>
                                                            <i onClick={() => this.exportToCSV()}

                                                                class="fa fa-file-excel-o"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </a>
                                                    </span>
                                                </div>
                                                <div class="col-md-4 col-sm-6 col-xs-12">
                                                </div>
                                            </div>
                                        </h3>
                                    </div>
                                    <div class="bg-box">
                                        <div class="table-responsive">
                                            <ReactTable
                                                minRows={2}
                                                data={this.props.learnerEnquiry.filter(
                                                    data => data.fullName !== null && data.enquiryMessage !== null
                                                        && data.enquirySubject !== null && data.emailID !== null
                                                )}
                                                className="-striped -highlight"
                                                filterable
                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                columns={[
                                                    {
                                                        columns: [
                                                            {
                                                                Header: "Full Name",
                                                                id: "fullName",
                                                                accessor: d => d.fullName
                                                            },

                                                            {
                                                                Header: "Email ID",
                                                                id: "emailId",
                                                                accessor: d =>
                                                                    d.emailID
                                                            },

                                                            {
                                                                Header: "CC",
                                                                id: "ccemailList",
                                                                accessor: d => d.ccemailList
                                                            },
                                                            {
                                                                Header: "Subject",
                                                                id: "subject",
                                                                accessor: d => d.enquirySubject
                                                            },
                                                            {
                                                                Header: "Message",
                                                                id: "message",
                                                                accessor: d => d.enquiryMessage,
                                                                style: { 'white-space': 'unset' }
                                                            },

                                                            {
                                                                Header: "Email Send Date",
                                                                id: "sentDate",
                                                                accessor: d => moment(d.createdDate, 'DD/MM/YYYY ,hh:mm:ss').format('DD/MM/YYYY')
                                                                // moment(d.createdDate).format('DD/MM/YYYY')
                                                            }

                                                        ]
                                                    }
                                                ]}
                                                defaultPageSize={10}
                                                style={{
                                                    width: "100%",
                                                    maxHeight: "500px"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
            </React.Fragment>
        );
    }
}

export default SentEmailList;
