import { Global_var } from '../global/global_var';
import RestDataSource from './restdatasource';
import { fetchUserRoleAccessPending, fetchUserRoleAccessError, fetchUserRoleAccessSuccess } from '../action/roleAccessAction';

// export const UserRoleAccessService = {
//     fetchUserRoleAccess,
//}

export function fetchUserRoleAccess(userRoleDetails) {
     //let url = 'http://192.168.12.113:9024' + Global_var.URL_ROLE_ACCESS_MANAGEMENT;
    let url = Global_var.BASEURL + Global_var.URL_ROLE_ACCESS_MANAGEMENT;
    // return new RestDataSource(url, fnError).Store(userRoleDetails, (res) => fn(res.data));
    return dispatch => {
        dispatch(fetchUserRoleAccessPending());
        return new RestDataSource(url, err =>
            dispatch(fetchUserRoleAccessError(err))
        ).Store(userRoleDetails, (res) => {
            if (res != null) {
                if (res.headers["jtitoken"] != null) {
                    localStorage.setItem("jti-token", res.headers.jtitoken);
                }

                dispatch(fetchUserRoleAccessSuccess(res.data.responseListObject));
            }
        });
    };
}

