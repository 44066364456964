import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import DapTour from '../../assets/video/Dap-Tour.mp4';
//import FinxPPS from '../../assets/video/Tour Video PPT.pps';
import ReactPlayer from 'react-player';
import DashboardHeader from '../dashboardheader/dashboardheader';
import Finxfooter from '../../containers/finxfooter/finxfooter';
import userprofile from '../userprofile/userProfileLandingPage';
import { RegisterUserService } from '../../services/registration.service';
import { LoginService } from '../../services/login.service';
import Adminheader from '../dashboardheader/adminheader';

//import { hashHistory } from 'react-router;'

class MyTour extends React.Component {

    constructor(props) {
        super(props);
        //console.log("propsssss: "+ this.props.personnelData)
        //console.log(this.props)
    }

    componentDidMount = () => {
        //alert(localStorage.getItem('userid-Token'))
    }

    gotologin = () => {

        window.location.href = '/'


    }
    onEnded = () => {

        var personaldata = localStorage.getItem("PersonnelData") !== null ? JSON.parse(localStorage.getItem("PersonnelData")) : this.props.personnelData;

        //console.log(personaldata)
        if (personaldata.responseObject["isTourCompleted"] === 0) {
            var data = {
                learnerID: personaldata.responseObject["learnerID"],
                isTourCompleted: 1
            }
            //console.log('my tour', data);
            LoginService.TokenUser(res => {
                if (res.status === "success") {
                    localStorage.setItem("jwt-token", res.responseObject);

                    RegisterUserService.updateMyTour(data, (res) => {
                        //console.log(res);
                        if (res.status === 'success') {
                            this.props.history.push('/userprofile')
                        }
                        else {
                            this.props.history.push('/learnerDashboard')
                        }

                    }, (err) => { console.log(err); })
                }
            });
            // this.props.history.push("/");
        } else {
            if (JSON.parse(localStorage.getItem('roleId')) === 5) {
                localStorage.setItem('reload', 'true');
                window.location.href = '/learnerDashboard'
            } else {
                localStorage.setItem('reload', 'true');
                window.location.href = '/admin-dashboard'
            }
        }

    }
    render() {
        return (
            <React.Fragment>
                {JSON.parse(localStorage.getItem('roleId')) === 5 ?
                    <DashboardHeader {...this.props} />
                    :
                    <Adminheader {...this.props} />
                }

                <section class="">
                    <div style={{ background: '#000', }} className="mb-50">
                        <ReactPlayer url={DapTour} onEnded={this.onEnded}
                            playing width="100%" position="absolute"
                            z-index="-1"
                            top="0"
                            left="0"
                            height="100vh" />
                    </div>
                    {/* <div class="welcome">
                        <button type="submit" class="btn-1 mt-30"><a href="#" onClick={this.tourHandler} >Take a Tour</a></button>
                    </div> */}
                </section>
                <div class="skip-button">
                    {(localStorage.getItem('userid-Token') === null) ? <button type="submit" class="btn-9 pull-left" onClick={this.gotologin}>Skip Tour</button> : <button type="submit" class="btn-9 pull-left" onClick={this.onEnded} >Skip Tour</button>}
                </div>
                <Finxfooter />
            </React.Fragment>
        )
    }
}
export default MyTour;


//<ReactPlayer url={DapTour} onEnded={this.onEnded(this.props)}

{/* <React.Fragment>
<DashboardHeader />
<div style={{ background: '#000' }}>
    <ReactPlayer url={DapTour} playing fluid={false} width="100%" height="550px" />
    <button type="button" className="btn-5 pull-right mr-20"><a href="">Close</a></button>
    <Finxfooter />
</div>
</React.Fragment> */}