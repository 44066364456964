export const FETCH_USER_ROLE_ACCESS_MANAGEMENT_PENDING = 'FETCH_USER_ROLE_ACCESS_MANAGEMENT_PENDING';
export const FETCH_USER_ROLE_ACCESS_MANAGEMENT_SUCCESS = 'FETCH_USER_ROLE_ACCESS_MANAGEMENT_SUCCESS';
export const FETCH_USER_ROLE_ACCESS_MANAGEMENT_ERROR = 'FETCH_USER_ROLE_ACCESS_MANAGEMENT_ERROR';

export function fetchUserRoleAccessPending() {
    return {
        type: FETCH_USER_ROLE_ACCESS_MANAGEMENT_PENDING
    }
}

export function fetchUserRoleAccessSuccess(roles) {
    return {
        type: FETCH_USER_ROLE_ACCESS_MANAGEMENT_SUCCESS,
        payload: roles
    }
}

export function fetchUserRoleAccessError(error) {
    return {
        type: FETCH_USER_ROLE_ACCESS_MANAGEMENT_ERROR,
        error: error
    }
}