import React, { Component } from "react";
import { connect } from "react-redux";
import Assessment from "../../components/report/assessmentReport";
import {
  assessmentReportError,
  assessmentReport,
  assessmentReportPending
} from "../../reducer/assessmentReportReducer";
import {
  cfxAssessmentReportError,
  cfxAssessmentReport,
  cfxAssessmentReportPending
} from "../../reducer/cfxAssessmentReportReducer";
import { bindActionCreators } from "redux";
import { getAssessmentReport } from "../../services/fetchLearnerDashboard.service";
import { LoginService } from "../../services/login.service";
import AdminHeader from "../../components/dashboardheader/adminheader";
import FinxFooter from "../finxfooter/finxfooter";
import { fetchAdminUser } from "../../services/admin.service";
import { getAdminUserProfile, getAdminUserProfilePending, getAdminUserProfileError } from "../../reducer/adminProfileUserReducer";
import { PopUpPortal } from "../popups/PopUpPortal";

const mapStateToProps = state => ({

  assessmentReport: assessmentReport(state.assessmentReportReducer),
  pendingassessmentReport: assessmentReportPending(state.assessmentReportReducer),
  errorassessmentReport: assessmentReportError(state.assessmentReportReducer),

  adminProfileUser: getAdminUserProfile(state.adminUserProfileReducer),
  pending: getAdminUserProfilePending(state.adminUserProfileReducer),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAssessmentReport: getAssessmentReport,
      fetchAdminUser: fetchAdminUser,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const assessmentLanding = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
      });
      const { fetchAssessmentReport, fetchAdminUser } = this.props;
      //fetchCfxAssessmentReport('email=derekashwin19@gmail.com')
      fetchAssessmentReport("limit=" + -1);
      //fetchCfxAssessmentReport("limit=" + -1);

      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          fetchAdminUser("limit=-1");

        }
      });

    }

    render() {
      const { assessmentReport, pendingassessmentReport, pending, adminProfileUser } = this.props;
      ////console.log("assessmentReport",this.props.assessmentReport)
      if (!pendingassessmentReport && !pending) {
        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={this.props.location.state !== undefined ? this.props.location.state.alerts || [] : []}
              //notificationEvents={this.props.location.state !== undefined ? this.props.location.state.notification || [] : []}
              {...this.props}
            />
            <Assessment
              adminProfileUser={adminProfileUser}
              assessmentReportData={assessmentReport}
              {...this.props}
            />
            <FinxFooter />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />
          </React.Fragment>
        )
      }
    }
  }
);

export default assessmentLanding;
