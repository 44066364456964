import {
  FETCH_LEARNER_PAYMENT_HISTORY_PENDING,
  FETCH_LEARNER_PAYMENT_HISTORY,
  FETCH_LEARNER_PAYMENT_HISTORY_ERROR
} from "../action/fetchLearnerPaymentHistory";

const initialState = {
  pending: false,
  learnersPaymentHistory: [],
  error: null
};

const learnersPaymentHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEARNER_PAYMENT_HISTORY_PENDING:
      return {
        ...state,
        pending: true
      };
    case FETCH_LEARNER_PAYMENT_HISTORY:
      return {
        ...state,
        pending: false,
        err: false,
        learnersPaymentHistory: action.payload
      };
    case FETCH_LEARNER_PAYMENT_HISTORY_ERROR:
      return {
        ...state,
        pending: false,
        err: true,
        error: action.error
      };
    default:
      return state;
  }
};
export default learnersPaymentHistoryReducer;

export const getLearnersPaymentHistory = state => state.learnersPaymentHistory;
export const getLearnersPaymentHistoryPending = state => state.pending;
export const getLearnersPaymentHistoryError = state => state.error;
