import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import {
  getproductDetailsAll,
  getproductDetailsAllPending
} from "../../reducer/productDetailsAllReducer";
import { getProductType } from "../../reducer/productProductTypeReducer";
import { getPartner } from "../../reducer/productPartnerReducer";
// import { getStatus } from "../../reducer/productStatusReducer";
// import { getHasModule } from "../../reducer/productHasModuleReducer";

import { getModuleList } from "../../reducer/modulesListReducer";


import { bindActionCreators } from "redux";
import ModulesListLandingPage from "../../components/modules/modulesList";
//import {fetchProductDetailsAll} from "../../services/fetchLearnerDashboard.service";
import {
  fetchProductListData,
  fetchProductTypeData,
  fetchPartnerData,
  fetchModulesData
} from "../../services/fetchLearnerDashboard.service";

import { LoginService } from "../../services/login.service";
import { getPersonnelAddressData } from "../../reducer/userProfileReducer";
import { RegisterUserService } from "../../services/registration.service";
import { fetchRoles } from "../../services/fetchRoles";
import { getRoles } from "../../reducer/roleReducer";

import { ProductService } from "../../services/product.service";

import AdminHeader from "../../components/dashboardheader/adminheader";
import $ from "jquery";
import { from } from "linq";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchLearnerDashboard.service";


const mapStateToProps = state => ({
  productDetailsAll: getproductDetailsAll(state.productDetailsAllReducer),
  productType: getProductType(state.productProductTypeReducer),
  partner: getPartner(state.productPartnerReducer),
  modules: getModuleList(state.modulesListReducer),
  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProductListData: fetchProductListData,
      fetchPartnerData: fetchPartnerData,
      fetchProductTypeData: fetchProductTypeData,
      fetchRoles: fetchRoles,

      fetchModulesData: fetchModulesData,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const modulesList = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        productType: [],
        partner: [],
        status: [],
        hasModule: [],
        // modules: []
      };
    }

    componentDidMount() {

      const {
        fetchProductListData,
        fetchRoles,
        fetchProductTypeData,
        fetchPartnerData,
        fetchModulesData,
        modules,
        fetchAlertNotificationEvents,
        fetchNotificationEvents,
      } = this.props;

      let partyID = localStorage.getItem("userid-Token");
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
      });

      fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID);


      LoginService.TokenUser(res => {
        if (res.status === "success") {

          localStorage.setItem("jwt-token", res.responseObject);
          let productType = "codeTypeName=product_type";
          fetchProductTypeData(productType, res => {
            //console.log("PRODUCT TYPE DATA", res);
          });
          //   fetchProductTypeData( res=>{
          //     //console.log("PRODUCT TYPE DATA" , res);
          // });
        }
      });
      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          fetchProductListData();
          fetchModulesData();
        }
      });

      LoginService.TokenUser(res => {

        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);

          fetchPartnerData(res => {
            //console.log("PARTNER DATA", res);
          });
        }
      });
    }

    render() {

      const {
        productDetailsAll,
        productDetailsAllPending,
        fetchUserAddressData,
        productType,
        partner,
        status,
        hasModule,
        modules,
        roles,
        alertNotificationEvents,
        pendingAlertNotificationEvents,
        errorAlertNotigicationEvents,
        notificationEvents,
        pendingNotificationEvents,
        errorNotificationEvents,
      } = this.props;

      //console.log("productType", productType);
      //console.log("partner", partner);
      //console.log("PRODUCT DETAILS ALL : ", productDetailsAll);
      //console.log("MODULES DATA", modules);

      if (!productDetailsAll.length && !productType.length && !partner.length && !modules.length) {
        return null;
      } else {
        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <ModulesListLandingPage
              productDetailsAll={productDetailsAll}
              fetchProductListData={fetchProductListData}
              fetchModulesData={fetchModulesData}
              productType={productType}
              partner={partner}
              modules={modules}
              {...this.props}
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }
    }
  }
);

export default modulesList;
