import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as moment from "moment";

export const ExcelService = {
    ExcelData
};

function ExcelData(data, fileName) {
    let todayDate = moment(new Date()).format('DD-MM-YYYY');
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(data);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const datas = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(datas, fileName + todayDate + fileExtension);
    //return;


}