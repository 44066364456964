import React from "react";
import { LoginService } from "../../services/login.service";
import AdminProfile from "../../containers/admin/admin-user";
import ProfileImage from "../../assets/images/user_profile.jpg";
import { Link, NavLink } from "react-router-dom";
import {
  sessionerror,
  sessionNotification,
  warningNotification,
  warning,
  error,
  errorNotification,
  success,
  successNotification
} from "../../components/notification/notifications";
import AdminProfileComplitionBar from "./adminProfileComplitionBar";
import { RegisterUserService } from "../../services/registration.service";
//import { logout } from "../../services/logout.service";



class NewAdminProfile extends React.Component {
  constructor(props) {
    super(props);
    //console.log("ppppppppp",this.props.alertNotificationEvents)
    //console.log("In Mayure", this.props.profileName);
  }

  loginHandler = event => {
    event.preventDefault();
    //
    this.setState({ loading: true });
    if (localStorage.getItem("login") !== null) {
      var data = {
        userName: JSON.parse(localStorage.getItem("login") || [])["userName"],
        userId: JSON.parse(localStorage.getItem("login") || [])["userId"]
      };

      LoginService.LogoutUser(data, false, res => {
        if (res.status === "success") {
          // let subscribe=localStorage.getItem('nwesLetterEmailId')
          // localStorage.clear();
          // if(subscribe)
          // {
          //   localStorage.setItem('nwesLetterEmailId','SUBSCRIBED')
          // }
          localStorage.setItem("windowloggedout", false);
          window.location.href = "/";

          //this.props.history.push('/');
        } else {
          warning(res.reasonText, warningNotification);
        }
      });
    } else {
      window.location.href = "/";
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <h3 className="learner-title">My Profile</h3>
          <div className="profile-details">
            <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 padding-0">
              <div className="profile-icon">
                <img
                  src={ProfileImage}
                  width="70"
                  height="70"
                  className="img-circle"
                />
              </div>
            </div>
            <div className="col-md-10 col-lg-10 col-sm-10 col-xs-10 padding-0">
              <div className="profile-summary">
                <div className="col-md-12 col-sm-12">
                  <h3 className="user-name">
                    {this.props.adminUser !== null
                      ? this.props.adminUser.firstName +
                      " " +
                      this.props.adminUser.lastName
                      : (window.location.href = "/")}
                  </h3>
                </div>
                <div className="col-md-12 col-sm-12">
                  <h5 className="user-contact-details">
                    {this.props.adminUser.contactEmail}
                  </h5>
                  <h5 className="user-contact-details">
                    {this.props.adminUser.contactNo}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
              <div className="border-bottom"></div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div class="badge-certificate">
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                  <h3 class="badge-details">
                    <i class="fa fa-id-badge" aria-hidden="true"></i> Badges
                  </h3>
                  <span class="badge-number">0</span>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                  <h3 class="badge-details">
                    <i class="fa fa-certificate" aria-hidden="true"></i>{" "}
                    Certificates
                  </h3>
                  <span class="badge-number">0</span>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="badge-certificate">
                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <h3 className="badge-details">Profile Completion</h3>
                  <AdminProfileComplitionBar
                    adminProfileData={this.props.profileName}
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <div className="profile-link">
                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                  <a href="/changepassword">Change Password</a>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                  <Link
                    to={{
                      pathname: "/admin-profile-edit",
                      query: {
                        getAdminAddress: this.props.getAdminAddress,
                        adminProfileData: this.props.profileName,
                        alertNotificationEvents: this.props
                          .alertNotificationEvents,
                        notificationEvents: this.props.notificationEvents
                      }
                    }}
                  >
                    My Profile
                  </Link>
                </div>
                <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4">
                  <a href="#" onClick={this.loginHandler}>
                    Sign Out
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewAdminProfile;
