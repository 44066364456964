import React, { Component } from "react";
import { connect } from "react-redux";
import MyEnrollment from "../../components/report/enrollmentreport";
import {
  getLearnersEnrollmentHistoryError,
  getLearnersEnrollmentHistory,
  getLearnersEnrollmentHistoryPending
} from "../../reducer/learnerEnrollmentReducer";
import { bindActionCreators } from "redux";
import { getEnrollmentHistory } from "../../services/fetchLearnerDashboard.service";
import { LoginService } from "../../services/login.service";
import AdminHeader from "../../components/dashboardheader/adminheader";
import FinxFooter from "../finxfooter/finxfooter";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";

const mapStateToProps = state => ({
  learnerEnrollmentHistory: getLearnersEnrollmentHistory(
    state.learnersEnrollmentReducer
  ),
  pendinglearnerEnrollmentHistory: getLearnersEnrollmentHistoryPending(
    state.learnersEnrollmentReducer
  ),
  errorlearnerEnrollmentHistory: getLearnersEnrollmentHistoryError(
    state.learnersEnrollmentReducer
  ),

});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEnrollmentHistoryData: getEnrollmentHistory,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const enrollmentLanding = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {

      //console.log("container")
      LoginService.TokenUser(res => {
        localStorage.setItem("jwt-token", res.responseObject);
      });
      const { fetchEnrollmentHistoryData } = this.props;
      // fetchLearnerPaymentData();

      fetchEnrollmentHistoryData("limit=" + -1);
    }

    render() {

      const {
        learnerEnrollmentHistory,
        pendinglearnerEnrollmentHistory
      } = this.props;

      ////console.log("learnerEnrollmentHistory",this.props.learnerEnrollmentHistory)
      if (!pendinglearnerEnrollmentHistory) {
        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={this.props.location.state !== undefined ? this.props.location.state.alerts || [] : []}
              //notificationEvents={this.props.location.state !== undefined ? this.props.location.state.notification || [] : []}
              {...this.props}
            />
            <MyEnrollment learnerEnrollmentHistoryData={learnerEnrollmentHistory}{...this.props} />
            <FinxFooter />
          </React.Fragment>
        );
      } else {
        return (
        <React.Fragment>
           <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />
        <FinxFooter />
      </React.Fragment>
        )}
    }
  }
);

export default enrollmentLanding;
