export const FETCH_USER_ROLE_WISE_EMAIL_PENDING = 'FETCH_USER_ROLE_WISE_EMAIL_PENDING';
export const FETCH_USER_ROLE_WISE_EMAIL_SUCCESS = 'FETCH_USER_ROLE_WISE_EMAIL_SUCCESS';
export const FETCH_USER_ROLE_WISE_EMAIL_ERROR = 'FETCH_USER_ROLE_WISE_EMAIL_ERROR';

export function fetchUserRoleEmailPending() {
    return {
        type: FETCH_USER_ROLE_WISE_EMAIL_PENDING
    }
}

export function fetchUserRoleEmailSuccess(EmailDetails) {
    return {
        type: FETCH_USER_ROLE_WISE_EMAIL_SUCCESS,
        payload: EmailDetails
    }
}

export function fetchUserRoleEmailError(error) {
    return {
        type: FETCH_USER_ROLE_WISE_EMAIL_ERROR,
        error: error
    }
}