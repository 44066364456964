import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik, Form, Field, ErrorMessage } from "formik";
// // import { UserManagementService } from "../../services/UserManagementser";
// import {UserManagementser} from "../../services/userManagementService"
import { LoginService } from "../../services/login.service";
import * as moment from "moment";
import * as Yup from "yup";
import { EnrollmentService } from "../../services/enrollmentservice";
import { ExcelService } from "../Excel/excel";

export class userManagement extends Component {
  constructor(props) {
    super(props)
    var todaydate = new Date();
    this.state = {
      today: moment(todaydate).format("YYYY-MM-DD"),
      data: this.props.successUserActivityData,
      roleId: 0,
      loading: false,
    };
    this.showAll = this.showAll.bind(this);
  }

  exportToCSV = () => {

    let modifiedData = [];
    let data = this.state.data;

    data.forEach(element => {
      var data1 = {
        Name: element.name,
        Role: element.role,
        Module: element.module,
        Event: element.event,
        Parent_Action: element.parentAction,
        Sub_Action: element.subAction,
        Action_Date_Time: element.actionDateTime.split(' ')[0],

      }
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, 'UserActivity_Report-');

  };

  showAll() {
    this.setState({ data: this.props.successUserActivityData });
  }
  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined ? String(row[id].toLowerCase()).includes(value.toLowerCase()) : true
  }


  render() {
    return (
      <Formik
        initialValues={{
          paymentStatus: "",
          startDate: "",
          endDate: ""
        }}
        validationSchema={Yup.object().shape({
          startDate: Yup.string(),//.required("You must specify the start date"),
          endDate: Yup.lazy(key => {
            return Yup.string()
              .default(() => "")
            // .required("You must specify the end date")
            // .when("startDate", (startDate, schema) => {
            //   return schema.test({
            //     test: EndIntensity => !!startDate && EndIntensity >= startDate,
            //     message: "End date should be greater than or equal tostart date"
            //   });
            // });
          })
        })}
        onSubmit={(values) => {
          this.setState({ loading: true });
          LoginService.TokenUser(res => {
            localStorage.setItem("jwt-token", res.responseObject);
            let data = {
              startDate: values.startDate
                ? moment(values.startDate).format("DD/MM/YYYY")
                : values.endDate
                  ? moment(new Date()).format("DD/MM/YYYY")
                  : "",
              endDate: values.endDate
                ? moment(values.endDate).format("DD/MM/YYYY")
                : values.startDate
                  ? moment(new Date()).format("DD/MM/YYYY")
                  : "",
            };
            let params = "limit="+-1+"&fromDate=" + data.startDate + "&toDate=" + data.endDate + "&orderDir=asc"

            EnrollmentService.Usermanagement(params,
              res => {

                if (res.status === "success") {
                  this.setState({
                    loading: false,
                    data: res.responseListObject,
                  })

                }
                else {
                  this.setState({
                    loading: false,
                    data: []
                  });
                }
              }
            );

          });
        }}
        render={({ handleSubmit, handleChange }) => (
          <React.Fragment>
            <section class="dashboard-tab user-tab pt-80 mb-50">
              <div class="padding-rl-50">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-8 col-sm-6 col-xs-6">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              User Activity
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12">
                              <span class="right-icon">
                                <a>
                                  <i onClick={() => this.exportToCSV()}

                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <Form onSubmit={handleSubmit} className="">
                          <div class="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-4 pl pr">
                                    <h5 className="filter-heading">
                                      Search Criteria
                                      </h5>
                                  </div>
                                  <div className="row">
                                    {/*<div className="col-md-3 col-sm-4 col-xs-12">
                                       <label>Status</label>
                                        <Field
                                          as="select"
                                          name="paymentStatus"
                                          class="form-control"
                                          onChange={handleChange}
                                        >
                                          <option value="selected">
                                            Select Status
                                          </option>
                                          <option value="Txn Success">
                                            Success
                                          </option>
                                          <option value="TXN_FAILURE">
                                            Fail
                                          </option>
                                          <option value="Initiated">
                                            Initiate
                                          </option>
                                        </Field>
                                      </div>  */}
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Start Date</label>
                                      <Field
                                        type="date"
                                        name="startDate"
                                        max={this.state.today}
                                        id="startDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="startDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-3 col-sm-4 col-xs-12">
                                      <label>End Date</label>
                                      <Field
                                        type="date"
                                        name="endDate"
                                        max={this.state.today}
                                        id="endDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="endDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12 mt-40">
                                      <div className="row">
                                        <button
                                          className="btn-5 mr-20 ml-20"
                                          type="submit"
                                        >
                                          Search
                                          </button>

                                        <button
                                          className="btn-5"
                                          type="reset"
                                          onClick={this.showAll}
                                        >
                                          Reset
                                          </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                        <div class="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.data || []}
                            filterable
                            ref={(refReactTable) => { this.refReactTable = refReactTable; }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            // defaultSorted={[
                            //   {
                            //     id: "actionDateTime",
                            //     desc: true
                            //   }
                            // ]}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "Name",
                                    id: "name",
                                    accessor: d => d.name
                                  },

                                  {
                                    Header: "Role",
                                    id: "role",
                                    accessor: d => d.role
                                  },
                                  {
                                    Header: "Module",
                                    id: "module",
                                    accessor: d => d.module
                                  },
                                  {
                                    Header: "Event",
                                    id: "event",
                                    accessor: d => d.event
                                  },
                                  {
                                    Header: "ParentAction",
                                    id: "parentAction",
                                    accessor: d => d.parentAction
                                  },
                                  {
                                    Header: "SubAction",
                                    id: "subAction",
                                    accessor: d => d.subAction
                                  },
                                  {
                                    Header: "ActionDateTime",
                                    id: "actionDateTime",
                                    accessor: d => d.actionDateTime
                                  },
                                ]
                              }
                            ]}
                            defaultPageSize={10}

                            style={{
                              width: "100%",
                              maxHeight: "500px"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

export default userManagement;
