import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  success,
  warning,
  successNotification,
  error,
  errorNotification,
  warningNotification
} from "../notification/notifications";

import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import {
  getCalenderEvents,
  getCalenderEventsPending
} from "../../reducer/calenderEventsReducer";
import { bindActionCreators } from "redux";
import { fetchAdminUser } from "../../services/admin.service";
import { ProductService } from "../../services/product.service";
//import AdminListLandingPage from "../../components/admin/adminList";
import { LoginService } from "../../services/login.service";
import { getPersonnelAddressData } from "../../reducer/userProfileReducer";
import { RegisterUserService } from "../../services/registration.service";
import { fetchRoles } from "../../services/fetchRoles";
import { getRoles } from "../../reducer/roleReducer";
import AdminHeader from "../../components/dashboardheader/adminheader";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import $ from "jquery";
import {
  getAlertNotificationEvents,
  getAlertNotificationEventsPending,
  getAlertNotificationEventsError
} from "../../reducer/alertsNotificationReducer";
import {
  getNotificationEvents,
  getNotificationEventsPending,
  getNotificationEventsError
} from "../../reducer/notificationReducer";
import {
  fetchAlertNotificationEvents,
  fetchNotificationEvents,
  fetchProductDetailsByPartyId
} from "../../services/fetchLearnerDashboard.service";

class AdminAddProductUser extends Component {
  constructor(props) {
    super(props);

    //console.log("PROPS admin : " + JSON.stringify(props));
    var todaydate = new Date();
    //alert("Userparty ID "+ this.props.history.location.state.role.partyID)
    if (localStorage.getItem("login") !== null) {
      this.state = {
        today: moment(todaydate).format("YYYY-MM-DD"),
        roleType: "",
        roleId: "",
        role: "",
        learnerID: "",
        loading: false,
        myselectedusers: [],
        myProduct: this.props.history.location.state.product,
        filtered: [],
        assignedUsers: [],
        unassignedUsers: []
      };
    } else {
      window.location.href = "/";
    }
  }

  handleCancel = () => {
    this.props.history.goBack('-1');
    //this.props.history.push("/course-list");
  };

  addcoursestousers = () => {
    var selectedproducts = [];
    var CheckoutArray = [];

    $(".dual-list.list-right ul.list-group li").each(function () {
      if (!$(this).hasClass("disabled")) {
        //alert($(this).find('span.selectedprodid').text())
        selectedproducts.push(
          $(this)
            .find("span.selectedprodid")
            .text()
        );
      }
    });
    //console.log(selectedproducts);
    if (selectedproducts.length > 0) {
      selectedproducts.forEach(element => {
        let ele = element.split("¥");
        let CheckoutObj = {
          learnerPartyID: ele[0],
          productID: this.state.myProduct.productID,
          productName: this.state.myProduct.productName,
          paymentID: 0,
          firstName: ele[1] !== undefined ? ele[1] !== 'undefined' ? ele[1] : ' ' : ' ',
          middleName: ele[2] !== undefined ? ele[2] !== 'undefined' ? ele[2] : ' ' : ' ',
          lastName: ele[3] !== undefined ? ele[3] !== 'undefined' ? ele[3] : ' ' : ' ',
          gender: 1,
          emailID: ele[4] !== undefined ? ele[4] !== 'undefined' ? ele[4] : "nowhere@nowhere.com" : "nowhere@nowhere.com",
          mobileNo: ele[5] !== undefined ? ele[5] !== 'undefined' ? ele[5] : "9999999999" : "9999999999",
          contactNo: "",
          dob:
            ele[6] !== undefined
              ? ele[6] !== 'undefined' ? ele[6] : moment(new Date()).format("DD/MM/YYYY HH:mm:ss")
              : moment(new Date()).format("DD/MM/YYYY HH:mm:ss")
        };
        CheckoutArray.push(CheckoutObj);
      });
      // let counter = 0;
      // for (let index = 0; index < CheckoutArray.length; index++) {
      //   const element = CheckoutArray[index];
      //   counter = index + 1;
      RegisterUserService.AHRegisterUserBulk(
        CheckoutArray,
        res => {
          if (res.status === "success") {
            success("You have successfully added Product for Learner  ", successNotification);
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else {
            error(res.reasonText, errorNotification);
          }
        },
        error => {
          //console.log(error);
        }
      );
    } else {
      warning("No users selected to add for this Product", warningNotification);
    }
    // }
    // if (counter > 0) {
    //   success("You have scuccessfully added Product for Learner  ", successNotification);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 5000);
    // }
  };

  componentDidMount() {

    var mymappedProductId = [];
    let partyID = "productID=" + this.state.myProduct.productID;
    ProductService.fetchUnassignedUserDetails(
      partyID,
      res => {
        if (res.status == "success") {
          //console.log("Enter here");
          this.setState({
            assignedUsers: res.responseObject.assigned,
            unassignedUsers: res.responseObject.notassigned
          });
        }
      },
      err => {
        console.log(err);
      }
    );

    $(document).ready(function () {
      $(function () {
        $("body").on("click", ".list-group .list-group-item", function () {
          //alert(!($(this).hasClass('disabled')))
          if (!$(this).hasClass("disabled")) {
            $(this).toggleClass("active");
          }
        });
        $(".list-arrows button").click(function () {
          var $button = $(this),
            actives = "";
          if ($button.hasClass("move-left")) {
            actives = $(".list-right ul li.active");
            actives.clone().appendTo(".list-left ul");
            actives.remove();
            $(".list-left ul li").each(function () {
              if ($(this).hasClass("active")) {
                $(this).removeClass('active');
              }
            });
          } else if ($button.hasClass("move-right")) {
            actives = $(".list-left ul li.active");
            actives.clone().appendTo(".list-right ul");
            actives.remove();
            $(".list-right ul li").each(function () {
              if ($(this).hasClass("active")) {
                $(this).removeClass('active');
              }
            });
          }
        });
        $(".dual-list .selector").click(function () {
          var $checkBox = $(this);
          if (!$checkBox.hasClass("selected")) {
            $checkBox
              .addClass("selected")
              .closest(".well")
              .find("ul li:not(.active)")
              .addClass("active");
            $checkBox
              .children("i")
              .removeClass("glyphicon-unchecked")
              .addClass("glyphicon-check");
          } else {
            $checkBox
              .removeClass("selected")
              .closest(".well")
              .find("ul li.active")
              .removeClass("active");
            $checkBox
              .children("i")
              .removeClass("glyphicon-check")
              .addClass("glyphicon-unchecked");
          }
        });
        $('[name="SearchDualList"]').keyup(function (e) {
          var code = e.keyCode || e.which;
          if (code == "9") return;
          if (code == "27") $(this).val(null);
          var $rows = $(this)
            .closest(".dual-list")
            .find(".list-group li");
          var val = $.trim($(this).val())
            .replace(/ +/g, " ")
            .toLowerCase();
          $rows
            .show()
            .filter(function () {
              var text = $(this)
                .text()
                .replace(/\s+/g, " ")
                .toLowerCase();
              return !~text.indexOf(val);
            })
            .hide();
        });
      });
    });
  }

  render() {
    var listdisabled = "";
    const {
      adminUserPending,
      pendingAlertNotificationEvents,
      notificationEvents,
      pendingNotificationEvents
    } = this.props;
    //console.log("adminUserPending", this.props.adminUser);
    if (
      !adminUserPending &&
      !pendingNotificationEvents &&
      !pendingAlertNotificationEvents
    ) {
      return (
        <React.Fragment>
          <AdminHeader
            //alertNotificationEvents={this.props.alertNotificationEvents}
            //notificationEvents={this.props.notificationEvents}
            adminUser={this.props.adminUser}
            {...this.props}
          />
          <section class="dashboard-tab user-tab pt-50 mb-50">
            <div className="container">
              <div className="add-course">
                <div class="row">
                  <div class="col-md-6 col-sm-6 col-xs-6">
                    <span class="left-icon">
                      <p>  Course Name: {this.state.myProduct.productName}</p>
                    </span>{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="dual-list list-left col-md-5 col-sm-12">
                    <div className="well text-right">
                      <div className="row">
                        <div className="col-md-10">
                          <div className="input-group">
                            <span className="input-group-addon glyphicon glyphicon-search"></span>
                            <input
                              type="text"
                              name="SearchDualList"
                              className="form-control"
                              placeholder="search"
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="btn-group">
                            <a
                              className="btn btn-default selector"
                              title="select all"
                            >
                              <i className="glyphicon glyphicon-unchecked"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <ul className="list-group">
                        {this.state.unassignedUsers != null
                          ? this.state.unassignedUsers.map(item => {
                            return (
                              item.isActive === 1 ?
                                <li className="list-group-item">
                                  {item.firstName + " " + item.lastName + " (" + item.emailID + ")"}
                                  <span className="selectedprodid">
                                    {item.learnerPartyID +
                                      "¥" +
                                      item.firstName +
                                      "¥" +
                                      item.middleName +
                                      "¥" +
                                      item.lastName +
                                      "¥" +
                                      item.emailID +
                                      "¥" +
                                      item.mobileNo +
                                      "¥" +
                                      item.dob}
                                  </span>
                                </li> : ''
                            );
                          })
                          : ""}
                      </ul>
                    </div>
                  </div>

                  <div className="list-arrows col-md-2 text-center">
                    <button className="btn btn-default btn-sm move-left">
                      <span className="glyphicon glyphicon-chevron-left"></span>
                    </button>

                    <button className="btn btn-default btn-sm move-right">
                      <span className="glyphicon glyphicon-chevron-right"></span>
                    </button>
                  </div>

                  <div className="dual-list list-right col-md-5 col-sm-12">
                    <div className="well">
                      <div className="row">
                        <div className="col-md-2">
                          <div className="btn-group">
                            <a
                              className="btn btn-default selector"
                              title="select all"
                            >
                              <i className="glyphicon glyphicon-unchecked"></i>
                            </a>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="input-group">
                            <input
                              type="text"
                              name="SearchDualList"
                              className="form-control"
                              placeholder="search"
                            />
                            <span className="input-group-addon glyphicon glyphicon-search"></span>
                          </div>
                        </div>
                      </div>
                      <ul className="list-group">
                        {this.state.assignedUsers != null ? this.state.assignedUsers.map(item => {
                          return (
                            item.isActive === 1 ?
                              <li className="list-group-item">{item.firstName + " " + item.lastName + " (" + item.emailID + ")"}
                                <span className="selectedprodid">
                                  {item.learnerPartyID +
                                    "¥" +
                                    item.firstName +
                                    "¥" +
                                    item.middleName +
                                    "¥" +
                                    item.lastName +
                                    "¥" +
                                    item.emailID +
                                    "¥" +
                                    item.mobileNo +
                                    "¥" +
                                    item.dob}
                                </span>
                              </li> : ''
                          );
                        })
                          : ""}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <button
                    className="btn-5 mr-20 mt-10 pull-right"
                    onClick={this.handleCancel}
                  >
                    <span aria-hidden="true">Cancel</span>
                  </button>
                  <button
                    className="btn-5 mr-20 ml-20 mt-10 pull-right"
                    onClick={this.addcoursestousers}
                  >
                    <span aria-hidden="true">Add Users</span>
                  </button>
                </div>
              </div>
            </div>

            <section class="dashboard-tab user-tab pt-50 mb-50">
              <div class="padding-rl-50">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="table-header">
                        <h3>
                          <div class="row">
                            <div class="col-md-6 col-sm-6 col-xs-6">
                              <span class="left-icon">
                                <i class="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              User Course List
                            </div>
                            <div class="col-md-6 col-sm-6 col-xs-12">
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div class="bg-box">
                        <div class="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.assignedUsers}
                            filtered={this.state.filtered}
                            onFilteredChange={filtered => {
                              this.setState({
                                filtered: filtered,
                                griddata: this.state.assignedUsers
                              });
                            }}
                            className="-striped -highlight"
                            filterable
                            onPageChange={this.stateonpagechange}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            ref={refReactTable => {
                              this.refReactTable = refReactTable;
                            }}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "Learner Id",
                                    id: "learnerID",
                                    accessor: d => d.learnerID
                                  },
                                  {
                                    Header: "Learner Name",
                                    id: "firstName",
                                    accessor: d => d.firstName + " " + d.lastName
                                  },
                                  {
                                    Header: "eMail Id",
                                    id: "emailID",
                                    accessor: d => d.emailID
                                  },
                                  {
                                    Header: "Mobile Number",
                                    id: "mobileNo",
                                    accessor: d => d.mobileNo
                                  }
                                  // {
                                  //     Header: "Product Name",
                                  //     id: "productName",
                                  //     Cell: props => {
                                  //         
                                  //         return (
                                  //             props
                                  //         )
                                  //     }
                                  // },
                                ]
                              }
                            ]}
                          ></ReactTable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <Finxfooter />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />

          <AdminHeader />

          <Finxfooter />
        </React.Fragment>
      );
    }
  }
}
export default AdminAddProductUser;
