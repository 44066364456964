// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import "react-table/react-table.css";
import AdminProfile from "./newAdminProfile";
import AdminProgress from "./adminProgress";
import AdminCalender from "./adminCalender";
import AdminSocialStreaming from "./adminSocialStreaming";
import RecentActivity from "./recentActivity";
import MyCalander from "../learner-dashboard/mycalander";
import $ from "jquery";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";

class Admin extends Component {
  constructor(props) {
    super(props);

    //console.log("admin", this.props.alertNotificationEvents);
    this.state = {
      f_auth: {
        uname: "",
        passwd: ""
      },
      loading: true
    };
  }
  componentDidMount() {

  }

  render() {
    return (
      <React.Fragment>
        <section
          class="dashboard-tab admin-dashboard mb-50"
          id="admin-dashboard"
        >
          <div className="container-fluid">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-50 mb-10">
              <div className="row">
              <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 pl pr">
                <AdminProfile
                  profileName={this.props.adminUser}
                  getAdminAddress={this.props.getAdminAddress}
                  {...this.props}
                />
                </div>
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 pl pr">
                <AdminProgress
                  learnerProgressOverviewData={
                    this.props.learnerProgressOverviewData
                  }
                  learnerProgressOverviewDataPending={
                    this.props.learnerProgressOverviewDataPending
                  }
                />
                </div>
                {this.props.calenderEventsPending ? (
                  <PopUpPortal
                    HidePopup={this.state.loading}
                    IsVisible={this.state.loading}
                  />
                ) : (
                  <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 pl pr">
                    <MyCalander props={this.props.calenderEvents} />
                    </div>
                  )}
              </div>
            </div>

            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10 mb-50">
              <div className="row">
                <RecentActivity
                  learnerProgressOverviewData={
                    this.props.learnerProgressOverviewData
                  }
                  learnerProgressOverviewDataPending={
                    this.props.learnerProgressOverviewDataPending
                  }
                />
                <AdminSocialStreaming
                  learnerEnquiry={this.props.learnerEnquiry}
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Admin;
