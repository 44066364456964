import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginService } from "../../services/login.service";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { AssessmentReport } from "../../services/fetchAssessmentReport";
import * as Yup from "yup";
import * as moment from "moment";
import Adminheader from "../dashboardheader/adminheader";

export class cfxAssessmentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      subTable: [],
      adminProfileUser: this.props.adminProfileUser
    };
  }

  cfxNodeData = (values) => {

    this.setState({ subTable: values.learnernode });
    console.log(this.state.subTable);
  }


  componentDidMount() {
    this.componentDetails(this.props);
    this.setState({ loading: false });
  }
  componentDetails(localprops) {
    LoginService.TokenUser(res => {

      localStorage.setItem("jwt-token", res.responseObject);
      let params = "emailid=dapsachin@test.com"
      // derekashwin19@gmail.com"; //this.props.match.params.id;
      params += "&productid=1"; //this.props.match.params.prodid

      let cfxDataArray = [];
      AssessmentReport.fetchCfxAssessmentReport(params, res => {
        console.log(res);
        res.responseListObject.forEach(element => {
          let cfxData = {
            learnerReportId: element.learnerReportId,
            learneremailid: element.emailid,
            learnerlearningid: element.learningid,
            learnerlearningName: element.learningName,
            learnerlearningProgress: element.learningProgress,
            learnerlearningCompletionDate: element.learningCompletionDate != null ? moment(element.learningCompletionDate).format("DD-MM-YYYY") : "",
            learnerlearningStatus: element.learningStatus,
            learnerlearningType: element.learningType,
            learnerproductid: element.productid,
            learnernode: JSON.parse(element.node),
          }
          cfxDataArray.push(cfxData);
        });
        this.setState({ data: cfxDataArray });
      }, error => {
        console.log(error);
      })

    });
  }

  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(value.toLowerCase())
      : true;
  };


  render() {
    return (
      <React.Fragment>
        <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />

        <Adminheader
          //alertNotificationEvents={this.props.location.state !== undefined ? this.props.location.state.alerts || [] : []}
          //notificationEvents={this.props.location.state !== undefined ? this.props.location.state.notification || [] : []}
          {...this.props}
        />

        <Formik
          enableReinitialize={true}
          render={({ handleSubmit, handleChange }) => (
            <React.Fragment>
              <section class="dashboard-tab user-tab pt-50 mb-50">
                <div class="padding-rl-50">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="table-header">
                          <h3>
                            <div class="row">
                              <div class="col-md-8 col-sm-6 col-xs-8">
                                <span class="left-icon">
                                  <i class="fa fa-list" aria-hidden="true"></i>
                                </span>{" "}
                              CFX Assessment Report
                            </div>
                              <div class="col-md-4 col-sm-6 col-xs-4">
                                <span class="right-icon">
                                  <a>
                                    {/* <i
                                    onClick={() => this.exportToCSV()}
                                    class="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i> */}
                                  </a>
                                </span>
                              </div>
                            </div>
                          </h3>
                        </div>
                        <div class="bg-box">
                          <div class="table-responsive">
                            <ReactTable
                              minRows={2}
                              data={this.state.data || []}
                              filterable
                              ref={refReactTable => {
                                this.refReactTable = refReactTable;
                              }}
                              defaultFilterMethod={this.filterCaseInsensitive}
                              columns={[
                                {
                                  columns: [
                                    {
                                      Header: "Learning ID",
                                      id: "learnerlearningid",
                                      accessor: d =>
                                        (d.learnerlearningid || "").toString()
                                    },
                                    {
                                      Header: "Learning Name",
                                      id: "learnerlearningName",
                                      accessor: d =>
                                        (d.learnerlearningName || "").toString()
                                    },
                                    // {
                                    //   Header: "Product ID",
                                    //   id: "productID",
                                    //   accessor: d => (d.productID || '').toString()
                                    //   // ((d.productEntity || "").productID || '').toString()
                                    // },
                                    {
                                      Header: "Score",
                                      id: "learnerlearningProgress",
                                      accessor: d =>
                                        (d.learnerlearningProgress || "").toString()
                                      // ((d.productEntity || "").productName || '').toString()
                                    },
                                    {
                                      Header: "Status",
                                      id: "learnerlearningStatus",
                                      accessor: d => (d.learnerlearningStatus || "").toString()
                                      // ((d.productEntity || "").productName || '').toString()
                                    },
                                    {
                                      Header: "Completion Date",
                                      id: "learningCompletionDate",
                                      accessor: d =>
                                        (d.learningCompletionDate || "").toString()
                                    },
                                    {
                                      Header: "Module Details",
                                      id: "learningNode",
                                      filterable: false,
                                      sortable: false,
                                      Cell: props => {
                                        return (
                                          <div>
                                            <a class="mar-left">
                                              {
                                                //props.original.isActive == 1 ?
                                                <i
                                                  title="G-CUBE"
                                                  className="fa fa-paper-plane"
                                                  aria-hidden="true" data-toggle="modal"
                                                     data-keyboard="true"
                                                  data-target="#modal-GCube-Score"
                                                  onClick={() =>
                                                    this.cfxNodeData(props.original)
                                                  }
                                                ></i>
                                                //: ''
                                              }
                                            </a>
                                          </div>
                                        );
                                      }
                                    },

                                    // {
                                    //   Header: "Course Details",
                                    //   id: "learnernode",
                                    //   accessor: d =>
                                    //     d.learnernode
                                    //       ? d.learnernode
                                    //       : ""                                    
                                    // },
                                  ]
                                }
                              ]}
                              defaultPageSize={10}
                              style={{
                                width: "100%",
                                maxHeight: "500px"
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal fade" id="modal-GCube-Score" tabIndex="-1" role="dialog" aria-labelledby="modalLabel"    data-keyboard="true" aria-hidden="true">
                  <div class="modal-dialog" style={{
                    width: "70%"
                  }} >
                    <div class="modal-content">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <Formik
                        enableReinitialize={true}

                        // onSubmit={(values, actions) => {
                        //   //console.log("Submitted :" + JSON.stringify(values));
                        // }}
                        onSubmit={(fields, { resetForm }) => {
                          //console.log("field", fields.subject)

                        }}

                        render={({
                          errors, touched, handleSubmit
                        }) => (
                            <React.Fragment>
                              <Form onSubmit={handleSubmit} className="form-group">
                                <section class="updateGDPI">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                          <div class="bg-box">
                                            <div class="table-responsive">
                                              <ReactTable
                                                minRows={2}
                                                data={this.state.subTable || []}
                                                filterable
                                                ref={refReactTable => {
                                                  this.refReactTable = refReactTable;
                                                }}
                                                defaultFilterMethod={this.filterCaseInsensitive}
                                                columns={[
                                                  {
                                                    columns: [
                                                      {
                                                        Header: "Learning Name",
                                                        id: "learnernodeName",
                                                        accessor: d =>
                                                          (d.nodeName || "").toString()
                                                      },
                                                      {
                                                        Header: "Score",
                                                        id: "nodescore",
                                                        accessor: d =>
                                                          (d.score || "").toString()
                                                        // ((d.productEntity || "").productName || '').toString()
                                                      },
                                                      {
                                                        Header: "Status",
                                                        id: "nodeStatus",
                                                        accessor: d => (d.nodeStatus || "").toString()
                                                        // ((d.productEntity || "").productName || '').toString()
                                                      },
                                                      {
                                                        Header: "Node Type",
                                                        id: "nodeType",
                                                        accessor: d =>
                                                          (d.nodeType || "").toString()
                                                      },
                                                      {
                                                        Header: "Completion Date",
                                                        id: "nodeLastAccessDate",
                                                        accessor: d =>
                                                          (moment(d.nodeLastAccessDate).format("DD-MM-YYYY") || "").toString()
                                                      },

                                                    ]
                                                  }
                                                ]}
                                                defaultPageSize={10}
                                                style={{
                                                  width: "100%",
                                                  maxHeight: "500px"
                                                }}
                                              />
                                            </div>
                                          </div>

                                        </div>
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                                          <button
                                            type="button"
                                            id="close"
                                            class="btn-4 mr-20 pull-right"
                                            data-dismiss="modal" aria-label="Close"
                                          >
                                            Cancel
                                    </button>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </Form>
                            </React.Fragment>
                          )}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default cfxAssessmentReport;
