// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";
import RestDataSource from "../../services/restdatasource";
import { editRoles } from "../../services/fetchRoles";
import { LoginService } from "../../services/login.service";
import {
  success,
  successNotification,
  error,
  errorNotification,
  warning,
  warningNotification
} from "../notification/notifications";
import $ from "jquery";

class EditRole extends Component {
  constructor(props) {

    super(props);
    this.state = {
      roleId: "",
      roleName: "",
      description: "",
      isActive: "",
      activateDate: "",
      expiryDate: "",
      suspendStartDate: "",
      suspendEndDate: "",
      statusList: this.props.role.isActive ? this.props.role.isActive : "",
    };
    //console.log("This.props.role", this.state);
  }

  componentDidMount() {

    //console.log("This.props.role", this.props.role);
  }

  render() {
    if (this.props.role.activateDate) {
      var activateDt = this.props.role.activateDate.split(" ");
      var activateDate = activateDt[0].split("/");
      activateDate =
        activateDate[2] + "-" + activateDate[1] + "-" + activateDate[0];
    }
    if (this.props.role.expiryDate) {
      var expiryDt = this.props.role.expiryDate.split(" ");
      var expiryDate = expiryDt[0].split("/");
      expiryDate = expiryDate[2] + "-" + expiryDate[1] + "-" + expiryDate[0];
    }
    if (this.props.role.suspendStartDate) {
      var suspendStartDt = this.props.role.suspendStartDate.split(" ");
      var suspendStartDate = suspendStartDt[0].split("/");
      suspendStartDate =
        suspendStartDate[2] +
        "-" +
        suspendStartDate[1] +
        "-" +
        suspendStartDate[0];
    }
    if (this.props.role.suspendEndDate) {
      var suspendEndDt = this.props.role.suspendEndDate.split(" ");
      var suspendEndDate = suspendEndDt[0].split("/");
      suspendEndDate =
        suspendEndDate[2] + "-" + suspendEndDate[1] + "-" + suspendEndDate[0];
    }
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={{
            roleName: this.props.role.roleName,
            description: this.props.role.description,
            isActive: this.props.role.isActive,
            activateDate: activateDate,
            expiryDate: expiryDate,
            suspendStartDate: suspendStartDate,
            suspendEndDate: suspendEndDate,
            statusList: this.props.role.isActive,
          }}
          validationSchema={Yup.object().shape({
            roleName: Yup.string()
              .min(3, "Too Short!")
              .max(50, "Too Long!")
              .required("You must specify a Role Name"),
            // .matches(/^[A-Za-z]+$/i, "Alphanumeric role name is not allowed"),
            activateDate: Yup.date().required("You must specify a Activate Date"),
            expiryDate: Yup.date().required("You must specify a Expiry Date"),
            suspendStartDate: Yup.date().required(
              "You must specify a Suspend Start Date"
            ),
            suspendEndDate: Yup.date().required("You must specify a Suspend End Date"),
            description: Yup.string()
              .min(10, "Description must be minimum of 10 Characters!")
              .max(100, "Description must be maximum of 100 Characters!")
          })}
          // onReset={(values, { resetForm }) => {
          //   resetForm();
          // }}
          onSubmit={values => {
            if (values.activateDate) {

              var activateDate = values.activateDate.split('-');
              activateDate =
                activateDate[2] + "/" + activateDate[1] + "/" + activateDate[0];
            } else {
              activateDate = "";
            }
            if (values.expiryDate) {
              var expiryDate = values.expiryDate.split('-');
              expiryDate =
                expiryDate[2] + "/" + expiryDate[1] + "/" + expiryDate[0];
            } else {
              expiryDate = "";
            }
            if (values.suspendStartDate) {
              var suspendStartDate = values.suspendStartDate.split('-');
              suspendStartDate =
                suspendStartDate[2] +
                "/" +
                suspendStartDate[1] +
                "/" +
                suspendStartDate[0];
            } else {
              suspendStartDate = "";
            }
            if (values.suspendEndDate) {
              var suspendEndDate = values.suspendEndDate.split('-');
              suspendEndDate =
                suspendEndDate[2] +
                "/" +
                suspendEndDate[1] +
                "/" +
                suspendEndDate[0];
            } else {
              suspendEndDate = "";
            }
            this.setState({
              roleId: this.props.role.roleId,
              roleName: values.roleName,
              activateDate: activateDate + " 00:00:00",
              expiryDate: expiryDate + " 00:00:00",
              suspendStartDate: suspendStartDate + " 00:00:00",
              suspendEndDate: suspendEndDate + " 00:00:00",
              description: values.description,
              isActive: values.isActive == "1" ? 1 : 0,
              statusList: values.statusList,
            });
            LoginService.TokenUser(res => {
              if (res.status === "success") {

                localStorage.setItem("jwt-token", res.responseObject);
                this.props.editRole(this.state, cb => {

                  if (cb.status === "success") {

                    success(
                      "Role has been updated successfully",
                      successNotification
                    );
                    localStorage.setItem("reload", "true");
                    this.props.fetchRoles();
                    $(".modal-backdrop").remove();
                  } else {
                    error(cb.reasonText, {
                      ...warningNotification,
                      container: "top-center"
                    });
                    localStorage.setItem("reload", "true");
                    this.props.fetchRoles();
                  }
                });
              }
            });
          }}
          render={({
            handleReset,
            isValid,
            errors,
            touched,
            handleSubmit,
            ...formikProps
          }) => (
            <React.Fragment>
              <Form
                onKeyDown={onKeyDown => {
                  if (
                    (onKeyDown.charCode || onKeyDown.keyCode) ===
                    13 &&
                    onKeyDown.target.name != "isActive" &&
                    onKeyDown.target.name != "submitForm" &&
                    onKeyDown.target.name != "cancelForm"
                  ) {
                    onKeyDown.preventDefault();
                  }
                }}
                className="modal fade"
                id="edit-role"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-md" role="document">
                  <div class="modal-content">
                    <button
                      type="reset"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                      <div class="row">
                        <section class="add-role">
                          <div class="form-group">
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Role Name
                              <span class="validation"> *</span>
                              </label>
                              <Field
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.roleName && touched.roleName
                                    ? " is-invalid"
                                    : "")
                                }
                                name="roleName"
                                id="roleName"
                                placeholder="Role Name"
                              />
                              <ErrorMessage
                                name="roleName"
                                component="div"
                                className="validation"
                              />
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Activate Date:
                              <span class="validation"> *</span>
                              </label>
                              <Field
                                type="date"
                                name="activateDate"
                                id="activateDate"
                                className={
                                  "form-control" +
                                  (errors.activateDate && touched.activateDate
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="activateDate"
                                component="div"
                                className="validation"
                              />
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12"></div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Expiry Date:
                              <span class="validation"> *</span>
                              </label>
                              <Field
                                type="date"
                                name="expiryDate"
                                id="expiryDate"
                                className={
                                  "form-control" +
                                  (errors.expiryDate && touched.expiryDate
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="expiryDate"
                                component="div"
                                className="validation"
                              />
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Suspend Start Date:
                              <span class="validation"> *</span>
                              </label>
                              <Field
                                type="date"
                                name="suspendStartDate"
                                id="suspendStartDate"
                                className={
                                  "form-control" +
                                  (errors.suspendStartDate &&
                                    touched.suspendStartDate
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="suspendStartDate"
                                component="div"
                                className="validation"
                              />
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12"></div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Suspend End Date:
                              <span class="validation"> *</span>
                              </label>
                              <Field
                                type="date"
                                name="suspendEndDate"
                                id="suspendEndDate"
                                className={
                                  "form-control" +
                                  (errors.suspendEndDate &&
                                    touched.suspendEndDate
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="suspendEndDate"
                                component="div"
                                className="validation"
                              />
                            </div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Description
                              <span class="validation"> *</span> </label>
                              <Field
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.description && touched.description
                                    ? " is-invalid"
                                    : "")
                                }
                                name="description"
                                id="description"
                                placeholder="Description"
                              />
                              <ErrorMessage
                                name="description"
                                component="div"
                                className="validation"
                              />
                            </div>
                            <div class="col-md-12 col-sm-12 col-xs-12"></div>
                            <div class="col-md-6 col-sm-12 col-xs-12">
                              <label>Status
                              <span class="validation"> *</span>
                              </label>
                              <Field
                                as="select"
                                name="isActive"
                                id="isActive"
                                className={
                                  "form-control" +
                                  (errors.isActive && touched.isActive
                                    ? " is-invalid"
                                    : "")
                                }
                              >
                                {this.props.role.isActive == 1} ? <option value="1">Active</option>
                                    :  {this.props.role.isActive == 0} ? <option value="0">Inactive</option> : ''

                                </Field>
                            </div>
                          </div>
                        </section>

                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <button
                            type="submit"
                            name="submitForm"
                            class="btn-5 mt-30 pull-right"
                            onClick={async () => {
                              const curErrors = await formikProps.validateForm();
                              const curErrorsKeys = Object.keys(curErrors);

                              if (curErrorsKeys.length) {
                                // Assuming curErrorsKeys[0] identifies the first failing field, use that to
                                // find the correct DOM element to call .focus() on.
                                //
                                // Since I set the id attribute on all my form's <input>s to match the error
                                // keys, I just use document.getElementById(), but of course you could use refs
                                const el = document.getElementById(
                                  curErrorsKeys[0]
                                );

                                if (el) el.focus();
                              }
                            }}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            name="cancelForm"
                            class="btn-5 mr-20 mt-30 pull-right"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default EditRole;
