import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import { addAdminUser, editAdminUser } from "../../services/admin.service";
import FileBase64 from "react-file-base64";
import {
  success,
  warning,
  successNotification,
  error,
  errorNotification
} from "../notification/notifications";
import RestDataSource from "../../services/restdatasource";
import { LoginService } from "../../services/login.service";
import { RegionManagementService } from "../../services/regionManagement.service";
import { Global_var } from "../../global/global_var";
import { RegisterUserService } from "../../services/registration.service";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import $ from "jquery";
import ReactPlayer from "react-player";
import { pascalCase } from "change-case";
import { DropdownButton } from "react-bootstrap";

class AdminProfileEdit extends Component {
  constructor(props) {
    super(props);

    console.log("this.props.adminUser", this.props);
    var todaydate = new Date();

    if (localStorage.getItem("login") !== null) {
      this.state = {
        today: moment(todaydate).format("YYYY-MM-DD"),
        roleType: "",
        roleId: "",
        show: false,
        plus: true,
        submit: false,
        addressFlag: "",
        role: "",
        learnerID: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailID: "",
        mobileNo: "",
        dob_1: "",
        gender: "",
        panNumber: "",
        aadharNumber: "",
        contactNo: "",
        secondaryEmailID: "",
        isActive: "",
        expiryDate: "",
        lastModifiedDate: "",
        peraddress: "",
        corsAddress: "",
        ca_address_id: "",
        ca_address_1: "",
        ca_address_2: "",
        ca_country: "",
        ca_state: "",
        ca_city: "",
        ca_postal_code: "",
        pa_address_id: "",
        pa_address_1: "",
        pa_address_2: "",
        pa_country: "",
        pa_state: "",
        pa_city: "",
        pa_postal_code: "",
        fatherName: "",
        dob_2: "",
        dob_3: "",
        company_name_1: "",
        mother_name: "",
        company_name_2: "",
        employment_type_1: "",
        employment_type_2: "",
        dob_2_validate_message: "",
        dob_3_validate_message: "",
        resumeDocumentURL: "",
        resumeVideoURL: "",
        profilePhotoURL: "",
        aadharCardURL: "",
        panCardURL: "",
        loading: false,
        countryList: [],
        stateList: [],
        cityList: [],
        countryListForPer: [],
        stateListForPer: [],
        cityListForPer: [],
        employeTypeForParents: [],
        learnerQualification: [],
        createdBy: "",
        createdDate: "",
        lastModifiedBy: "",
        lastModifiedDateTime: "",
        lastLoginDateTime: ""
      };
    } else {
      window.location.href = "/";
    }
  }

  handleCancel = () => {
    this.props.history.goBack('-1');
    //this.props.history.push("/admin-user-list");
  };

  componentWillMount() {
    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
    });
  }

  componentDidMount() {
    console.log(
      " this.props.location.state.role",
      this.props.location.state.role
    );
    // $("#topheader .tab a").on("click", function() {
    //   $("#topheader .tab")
    //     .find("li.active")
    //     .removeClass("active");
    //   $(this)
    //     .parent("li")
    //     .addClass("active");
    // });
    //window.addEventListener("scroll", e => onScroll(e), true);

    // function onScroll(event) {
    //   var scrollPos = $("section#create-user").offset().top;
    //   //console.log("hiiiiiiiii" + scrollPos)

    //   $("section.page-section").each(function () {
    //     var scrollid = $(this).attr("id");
    //     var scrollPos = $("section#" + scrollid).offset().top;
    //     if (scrollPos > 100 && scrollPos < 300) {
    //       console.log("Scroll position :" + scrollid + scrollPos);
    //       $("#topheader .tab li").removeClass("active");
    //       $("a[href='#" + [scrollid] + "']")
    //         .parent("li")
    //         .addClass("active");
    //     }
    //   });
    // }
    window.addEventListener("scroll", e => onScroll(e), true);

    function onScroll(event) {
      var scrollPos = "";
      //var scrollPos = $("section.createusers").offset().top;

      var nav = $("section.createusers");
      if (nav.length) {
        scrollPos = $("section.createusers").offset().top;
      } else {
        scrollPos = "";
      }
      //console.log("hiiiiiiiii" + scrollPos)

      $("section.page-section").each(function () {
        var scrollid = $(this).attr("id");
        var scrollPos = $("section#" + scrollid).offset().top;
        if (scrollPos > 100 && scrollPos < 300) {
          console.log("Scroll position :" + scrollid + scrollPos);
          $("#topheader .tab li").removeClass("active");
          $("a[href='#" + [scrollid] + "']")
            .parent("li")
            .addClass("active");
        }
      });
    }

    if (

      this.props.location.state.role.userRoleMatrixList[0].roleId.roleId == 5
    ) {
      this.setState({

        role: this.props.location.state.role.userRoleMatrixList[0].roleId
          .roleName,
        roleId: this.props.location.state.role.userRoleMatrixList[0].roleId
          .roleId,
        show: true
      });
    } else {
      this.setState({
        role: this.props.location.state.role.userRoleMatrixList[0].roleId
          .roleName,
        roleId: this.props.location.state.role.userRoleMatrixList[0].roleId
          .roleId
      });
    }

    //console.log("0000000000" , this.props.location.state.role.partyID)
    this.dataSource = new RestDataSource(

      Global_var.BASEURL +
      Global_var.URL_FETCH_ADMIN_USER_BY_ROLE_PARTY_ID +
      "?partyID=" +
      this.props.location.state.role.partyID +
      "&roleID=" +
      this.props.location.state.role.userRoleMatrixList[0].roleId.roleId
    );
    LoginService.TokenUser(res => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);

        this.dataSource.GetData(res => {

          if (
            this.props.location.state.role.userRoleMatrixList[0].roleId
              .roleId == 5
          ) {
            if (res.data.responseObject.dob || '') {
              let dob1 = (res.data.responseObject.dob || '').split(" ");
              let dob_1 = (dob1[0] || '').split("/");

              dob_1 = dob_1[2] + "-" + dob_1[1] + "-" + dob_1[0];
              this.setState({ dob_1: dob_1 });
            }
            if (res.data.responseObject.learnerFatherDOB) {
              let dob2 = res.data.responseObject.learnerFatherDOB.split(" ");
              let dob_2 = dob2[0].split("/");
              dob_2 = dob_2[2] + "-" + dob_2[1] + "-" + dob_2[0];
              this.setState({ dob_2: dob_2 });
            }
            if (res.data.responseObject.learnerMotherDOB) {
              let dob3 = res.data.responseObject.learnerMotherDOB.split(" ");
              let dob_3 = dob3[0].split("/");
              dob_3 = dob_3[2] + "-" + dob_3[1] + "-" + dob_3[0];
              this.setState({ dob_3: dob_3 });
            }
          }
          if (this.props.location.state.role.expiryDate) {
            let expiry = this.props.location.state.role.expiryDate.split(" ");
            let expiry1 = expiry[0].split("/");
            let expiry2 = expiry1[2] + "-" + expiry1[1] + "-" + expiry1[0];
            this.setState({ expiryDate: expiry2 });
          }
          this.setState({
            learnerID: res.data.responseObject.learnerID || "",
            mobileNo:
              res.data.responseObject.mobileNo ||
              res.data.responseObject.contactNo ||
              "",
            emailID:
              res.data.responseObject.emailID ||
              res.data.responseObject.contactEmail ||
              "",
            gender: res.data.responseObject.gender || "",
            firstName: res.data.responseObject.firstName || "",
            middleName: res.data.responseObject.middleName || "",
            lastName: res.data.responseObject.lastName || "",
            secondaryEmailID:
              res.data.responseObject.contactEmail2 ||
              res.data.responseObject.secondaryEmailID ||
              "",
            contactNo:
              res.data.responseObject.contactNo2 ||
              res.data.responseObject.contactNo ||
              "",

            fatherName: res.data.responseObject.learnerFatherName || "",
            company_name_1:
              res.data.responseObject.learnerFatherCompanyName || "",
            mother_name: res.data.responseObject.learnerMotherName || "",
            company_name_2:
              res.data.responseObject.learnerMotherCompanyName || "",
            employment_type_1:
              res.data.responseObject.learnerFatherEmployementType || "",
            employment_type_2:
              res.data.responseObject.learnerMotherEmployementType || "",
            learnerQualification:
              res.data.responseObject.learnerQualification || [],
            panNumber: res.data.responseObject.panNumber || "",
            aadharNumber: res.data.responseObject.aadharNumber || "",
            isActive: res.data.responseObject.isActive,
            // expiryDate: !res.data.responseObject.expiryDate ? this.props.location.state.role.expiryDate
            //   : moment(res.data.responseObject.expiryDate).format('MM/DD/YYYY'),
            resumeDocumentURL: res.data.responseObject.resumeDocumentURL || "",
            resumeVideoURL: res.data.responseObject.resumeVideoURL || "",
            profilePhotoURL: res.data.responseObject.profilePhotoURL || "",
            aadharCardURL: res.data.responseObject.aadharCardURL || "",
            panCardURL: res.data.responseObject.panCardURL || "",
            partyID: this.props.history.location.state.role.partyID
              ? this.props.history.location.state.role.partyID
              : res.data.responseObject.partyID
                ? res.data.responseObject.partyID
                : " ",

            // createdBy: this.props.history.location.state.role.createdBy
            //   ? this.props.history.location.state.role.createdBy
            //   : res.data.responseObject.createdBy
            //   ? res.data.responseObject.createdBy
            //   : " ",
            //createdBy: res.data.responseObject.createdBy ||  " ",

            createdDate:


              (this.props.location.state.role.createdDate || '') !== undefined
                ? (this.props.location.state.role.createdDate || '').split(" ")[0]
                : "" || "",
            //lastModifiedBy: res.data.responseObject.lastModifiedBy || "",
            // lastModifiedBy: this.props.history.location.state.role
            //   .lastModifiedBy
            //   ? this.props.history.location.state.role.lastModifiedBy
            //   : res.data.responseObject.lastModifiedBy
            //   ? res.data.responseObject.lastModifiedBy
            //   : " ",
            lastModifiedDateTime:
              this.props.location.state.role.lastModifiedDateTime !== undefined
                ? this.props.location.state.role.lastModifiedDateTime.split(
                  " "
                )[0]
                : "" || "",
            // lastLoginDateTime:
            //   this.props.location.state.role.lastLoginDateTime !== undefined
            //     ? this.props.location.state.role.lastLoginDateTime.split(" ")[0]
            //     : "" || ""
            lastLoginDateTime: this.props.location.state.role
              .lastModifiedDateTime
              ? this.props.location.state.role.lastModifiedDateTime.split(
                " "
              )[0]
              : " "
          });
        });
      }
    });
    RegisterUserService.createdBy(
      "userId=" + this.props.history.location.state.role.createdBy,
      res => {
        this.setState({
          createdBy: (res.responseObject || "").name || this.props.history.location.state.role.createdBy
        });
      }

    );
    RegisterUserService.createdBy(
      "userId=" + this.props.history.location.state.role.lastModifiedBy,
      res => {
        this.setState({
          lastModifiedBy: res.responseObject.name || ""
        });
      }
    );

    LoginService.TokenUser(res => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);
        this.props.getAdminAddress(
          "partyId=" + this.props.location.state.role.partyID,
          res => {
            res.responseListObject.forEach(addr => {
              if (addr.addressType == "permanent") {
                RegionManagementService.getState(
                  "countryId=" + addr.countryId,
                  res => {
                    this.setState({
                      stateListForPer: res.responseListObject
                    });
                  }
                );
                RegionManagementService.getCity(
                  "stateId=" + addr.stateId,
                  res => {
                    this.setState({
                      cityListForPer: res.responseListObject
                    });
                  }
                );
                this.setState({
                  pa_address_id: addr.addressId,
                  pa_address_1: addr.street,
                  pa_address_2: addr.street2,
                  pa_country: addr.countryId,
                  pa_state: addr.stateId,
                  pa_city: addr.cityId,
                  pa_postal_code: addr.postalCode
                });
              }
              if (addr.addressType == "correspondence") {
                RegionManagementService.getState(
                  "countryId=" + addr.countryId,
                  res => {
                    this.setState({
                      stateList: res.responseListObject
                    });
                  }
                );
                RegionManagementService.getCity(
                  "stateId=" + addr.stateId,
                  res => {
                    this.setState({
                      cityList: res.responseListObject
                    });
                  }
                );
                this.setState({
                  ca_address_id: addr.addressId,
                  ca_address_1: addr.street,
                  ca_address_2: addr.street2,
                  ca_country: addr.countryId,
                  ca_state: addr.stateId,
                  ca_city: addr.cityId,
                  ca_postal_code: addr.postalCode
                });
              }
            });
            RegionManagementService.getCountry(res => {
              this.setState({
                countryList: res.responseListObject,
                countryListForPer: res.responseListObject
              });
            });
            RegisterUserService.getEmployeType(res => {
              this.setState({
                employeTypeForParents: res.responseListObject
              });
            });
          }
        );
      }
    });
  }

  render() {
    let dob_2_validate_message = "";
    let dob_3_validate_message = "";
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          mobileNo: this.state.mobileNo,
          emailID: this.state.emailID,
          gender: this.state.gender,
          isActive: this.state.isActive,
          expiryDate: this.state.expiryDate,
          firstName: this.state.firstName,
          middleName: this.state.middleName,
          lastName: this.state.lastName,
          contactNo: this.state.contactNo,
          secondaryEmailID: this.state.secondaryEmailID,
          dob_1: this.state.dob_1,
          panNumber: this.state.panNumber,
          aadharNumber: this.state.aadharNumber,
          ca_address_1: this.state.ca_address_1,
          ca_address_2: this.state.ca_address_2,
          ca_country: this.state.ca_country,
          ca_state: this.state.ca_state,
          ca_city: this.state.ca_city,
          ca_postal_code: this.state.ca_postal_code,
          pa_address_1: this.state.pa_address_1,
          pa_address_2: this.state.pa_address_2,
          pa_country: this.state.pa_country,
          pa_state: this.state.pa_state,
          pa_city: this.state.pa_city,
          pa_postal_code: this.state.pa_postal_code,
          stateList: this.state.stateList,
          cityList: this.state.cityList,
          stateListForPer: this.state.stateListForPer,
          cityListForPer: this.state.cityListForPer,
          fatherName: this.state.fatherName,
          dob_2: this.state.dob_2,
          dob_3: this.state.dob_3,
          company_name_1: this.state.company_name_1,
          mother_name: this.state.mother_name,
          company_name_2: this.state.company_name_2,
          employment_type_1: this.state.employment_type_1,
          employment_type_2: this.state.employment_type_2,
          learnerQualification: this.state.learnerQualification,
          role: this.state.role,
          addressFlag: this.state.addressFlag,
          today: moment(new Date()).format("YYYY-MM-DD"),
          resumeDocumentURL: this.state.resumeDocumentURL,
          resumeVideoURL: this.state.resumeVideoURL,
          profilePhotoURL: this.state.profilePhotoURL,
          aadharCardURL: this.state.aadharCardURL,
          panCardURL: this.state.panCardURL,
          document: [],
          resumeDocumentName: "",
          panCardDocumentName: "",
          aadharCardDocumentName: "",
          documentSizeCheckForProfileCheck: false,
          documentTypeCheckForProfileImage: false,
          documentTypeCheckForResume: false,
          documentSizeCheckForResume: false,
          documentTypeCheckForAadharImage: false,
          documentSizeCheckForVideoCheck: false,
          documentTypeCheckForVideo: false,
          documentSizeCheckForPanCard: false,
          documentSizeCheckForAadharCard: false,
          documentTypeCheckForPANImage: false,
          loading: false,
          downloadResumeAfterSelecting: "",
          resumeDocumentNameDuringEdit: (
            this.state.resumeDocumentURL || ""
          ).substring(
            (this.state.resumeDocumentURL || "").lastIndexOf("/") + 1,
            (this.state.resumeDocumentURL || "").length
          )
        }}
        validationSchema={Yup.object().shape({
          //static validations
          mobileNo: Yup.string()
            .required("You must specify a Mobile Number")
            .matches(/^[6-9]\d{9}$/, "Please enter the valid mobile number"),
          emailID: Yup.string()
            .required("You must specify a Email ID")
            .email("Please enter the valid email ID"),
          gender: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string().required("You must specify a Gender");
              default:
                return Yup.string();
            }
          }),
          firstName: Yup.string().required("You must specify a First Name"),
          middleName: Yup.string(),
          lastName: Yup.string().required("You must specify a Last Name"),
          contactNo: Yup.string().matches(
            /^[6-9]\d{9}$/,
            "Please enter the valid mobile number"
          ),
          secondaryEmailID: Yup.string().email(
            "Please enter the valid email ID"
          ),
          ca_address_1: Yup.string().required("You must specify a Address"),
          ca_address_2: Yup.string(),
          ca_country: Yup.string().required("You must specify a Country"),
          ca_state: Yup.string().required("You must specify a State"),
          ca_city: Yup.string().required("You must specify a City"),
          ca_postal_code: Yup.string()
            .required("You must specify a Pin Code")
            .matches(/^\d{6}$/, "Please enter valid postal code"),
          pa_address_1: Yup.string().required("You must specify a Address"),
          pa_address_2: Yup.string(),
          pa_country: Yup.string().required("You must specify a Country"),
          pa_state: Yup.string().required("You must specify a State"),
          pa_city: Yup.string().required("You must specify a City"),
          pa_postal_code: Yup.string()
            .required("You must specify a Pin Code")
            .matches(/^\d{6}$/, "Please enter valid postal code"),
          dob_2: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string()
                  .default(() => "")
                  .test("dateOfBirth", dob_2_validate_message, value => {
                    if (value !== undefined) {
                      if (key.parent.dob_1 === undefined) {
                        dob_2_validate_message =
                          "please enter learner's date of birth";
                      } else {
                        dob_2_validate_message =
                          "age should be greater than learner's age";
                      }
                    }
                    return value !== undefined
                      ? moment().diff(moment(value), "years") >
                      moment().diff(moment(key.parent.dob_1), "years") - 1
                      : "";
                  });
              default:
                return Yup.string();
            }
          }),

          dob_3: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string()
                  .default(() => "")
                  .test("dateOfBirth", dob_3_validate_message, value => {
                    if (value !== undefined) {
                      if (key.parent.dob_1 === undefined) {
                        dob_3_validate_message =
                          "please enter learner's date of birth";
                      } else {
                        dob_3_validate_message =
                          "age should be greater than learner's age";
                      }
                    }
                    return value !== undefined
                      ? moment().diff(moment(value), "years") >
                      moment().diff(moment(key.parent.dob_1), "years") - 1
                      : "";
                  });
              default:
                return Yup.string();
            }
          }),
          company_name_1: Yup.string(),
          company_name_2: Yup.string(),
          mother_name: Yup.string(),
          employment_type_1: Yup.string(),
          employment_type_2: Yup.string(),
          //dynamic validation starts from here
          // fatherName: Yup.lazy((values, key) => {
          //   switch (key.parent.role) {
          //     case "Learner":
          //       return Yup.string().required(
          //         "You must specify a Father's name"
          //       );
          //     default:
          //       return Yup.string();
          //   }
          // }),
          dob_1: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string()
                  .required("You must specify a Date of Birth")
                  .default(() => "")
                  .test(
                    "dateOfBirth",
                    "age should be greater then 18 years",
                    value => {
                      return moment().diff(moment(value), "years") >= 18;
                    }
                  );
              default:
                return Yup.string();
            }
          }),
          panNumber: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string().matches(
                  /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                  "Please enter the valid PAN number"
                );
              default:
                return Yup.string();
            }
          }),
          aadharNumber: Yup.lazy((values, key) => {
            switch (key.parent.role) {
              case "Learner":
                return Yup.string()
                  .required("You must specify a Aadhar number")
                  .matches(/^\d{12}$/, "Please enter the valid aadhar number");
              default:
                return Yup.string();
            }
          })
        })}
        onSubmit={(values, actions) => {
          console.log("Submitted :" + JSON.stringify(values));
          actions.setFieldValue((values.loading = true));

          if (values.dob_1) {
            var learnerBirthDt = values.dob_1.split(" ");
            var learnerBirthDate = learnerBirthDt[0].split("/");
            learnerBirthDate =
              learnerBirthDate[2] +
              "/" +
              learnerBirthDate[1] +
              "/" +
              learnerBirthDate[0];
          } else {
            learnerBirthDate = "";
          }

          if (values.dob_2) {
            var fatherBirthDt = values.dob_2.split(" ");
            var fatherBirthDate = fatherBirthDt[0].split("/");
            var learnerFatherDOB =
              fatherBirthDate[2] +
              "/" +
              fatherBirthDate[1] +
              "/" +
              fatherBirthDate[0];
          } else {
            learnerFatherDOB = "";
          }

          //for mother birth date
          if (values.dob_3) {
            var motherBirthDt = values.dob_3.split(" ");
            var motherBirthDate = motherBirthDt[0].split("/");
            var learnerMotherDOB =
              motherBirthDate[2] +
              "/" +
              motherBirthDate[1] +
              "/" +
              motherBirthDate[0];
          } else {
            learnerMotherDOB = "";
          }

          let data = {
            roleID: this.state.roleId,
            partyID: this.props.location.state.role.partyID,
            userID: this.props.location.state.role.userId,
            mobileNo: values.mobileNo,
            emailID: values.emailID,
            gender: values.gender,
            isActive: values.isActive,
            expiryDate: values.expiryDate
              ? moment(values.expiryDate).format("DD/MM/YYYY HH:mm:ss")
              : " ",
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            contactNo: values.contactNo,
            secondaryEmailID: values.secondaryEmailID,
            dob: values.dob_1
              ? moment(values.dob_1).format("DD/MM/YYYY HH:mm:ss")
              : " ",
            panNumber: values.panNumber,
            aadharNumber: values.aadharNumber,
            address: [
              {
                addressType: "permanent",
                addressId: this.state.pa_address_id,
                street: values.pa_address_1,
                street2: values.pa_address_2,
                cityId: values.pa_city,
                stateId: values.pa_state,
                countryId: values.pa_country,
                postalCode: values.pa_postal_code
              },
              {
                addressType: "correspondence",
                addressId: this.state.ca_address_id,
                street: values.ca_address_1,
                street2: values.ca_address_2,
                cityId: values.ca_city,
                stateId: values.ca_state,
                countryId: values.ca_country,
                postalCode: values.ca_postal_code
              }
            ],
            learnerFatherName: values.fatherName,
            learnerFatherDOB: values.dob_2
              ? moment(values.dob_2).format("DD/MM/YYYY HH:mm:ss")
              : " ",
            learnerFatherEmployementType: values.employment_type_1,
            learnerFatherCompanyName: values.company_name_1,
            learnerMotherName: values.mother_name,
            learnerMotherDOB: values.dob_3
              ? moment(values.dob_3).format("DD/MM/YYYY HH:mm:ss")
              : "",
            learnerMotherEmployementType: values.employment_type_2,
            learnerMotherCompanyName: values.company_name_2,
            learnerQualification: values.learnerQualification.filter(x => !!x),
            document: values.document,
            resumeDocumentURL: values.resumeDocumentURL,
            profilePhotoURL: values.profilePhotoURL,
            resumeVideoURL: values.resumeVideoURL
          };

          if (this.state.roleId === 5) {
            data = { ...data, learnerID: this.state.learnerID };
            RegisterUserService.updateUserProfile(data, cb => {
              if (cb.status === "success") {
                success(
                  "User profile has been updated on Dap successfully",
                  successNotification
                );
                actions.setFieldValue((values.loading = false));
                this.props.history.push("/admin-user-list");
              } else {
                actions.setFieldValue((values.loading = false));
                error(cb.reasonText, {
                  ...errorNotification,
                  container: "top-center"
                });
              }
            });
          } else {
            editAdminUser(data, cb => {
              if (cb.status === "success") {
                success(
                  " your profile has been updated on Dap successfully",
                  successNotification
                );
                this.props.fetchAdminUser("limit=-1");
                this.props.history.push("/admin-user-list");
              } else {
                error(cb.reasonText, {
                  ...errorNotification,
                  container: "top-center"
                });
              }
            });
          }
        }}
        render={({
          errors,
          touched,
          handleSubmit,
          handleChange,
          values,
          setFieldValue,
          ...formikProps
        }) => (
          <React.Fragment>
            {values.loading && (
              <PopUpPortal
                HidePopup={values.loading}
                IsVisible={values.loading}
              />
            )}
            <section class="dashboard-tab user-profile  pt-55" id="admin-profile">
              <Form
                onSubmit={handleSubmit}
                onKeyDown={onKeyDown => {
                  if (
                    (onKeyDown.charCode || onKeyDown.keyCode) === 13 &&
                    onKeyDown.target.name != "ca_country" &&
                    onKeyDown.target.name != "ca_state" &&
                    onKeyDown.target.name != "ca_city" &&
                    onKeyDown.target.name != "pa_country" &&
                    onKeyDown.target.name != "pa_state" &&
                    onKeyDown.target.name != "pa_city" &&
                    onKeyDown.target.name != "employment_type_1" &&
                    onKeyDown.target.name != "employment_type_2" &&
                    onKeyDown.target.name != "submitForm" &&
                    onKeyDown.target.name != "cancelForm"
                  ) {
                    onKeyDown.preventDefault();
                  }
                }}
                className="form-group"
              >
                <div class="">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                        <div class="profile-left-sidebar">
                          <div class="profile-sidebar">
                            <div class="table-header">
                              <div class="container-fluid">
                                <div id="topheader">
                                  <nav data-scroll-header>
                                    <ul class="tab">
                                      <li>
                                        <a href="#create-userType">User Type</a>
                                      </li>
                                      <li>
                                        <a href="#create-user">
                                          Personal Details
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#create-address">
                                          Address Details
                                        </a>
                                      </li>
                                      {this.state.show ? (
                                        <li>
                                          <a href="#create-parent">
                                            Parent Details
                                          </a>
                                        </li>
                                      ) : (
                                          <div></div>
                                        )}
                                      {this.state.show && (
                                        <li>
                                          <a href="#create-qualification">
                                            Qualification Details
                                          </a>
                                        </li>
                                      )}
                                      {this.state.show && (
                                        <li>
                                          <a href="#create-profile">
                                            Profile Details
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="table-responsive">
                          <table>
                            <tbody>
                              <tr>
                                <td>UserId</td>
                                <td>
                                  <b>{this.state.partyID}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Created By</td>
                                <td>
                                  <b>{this.state.createdBy}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Created On</td>
                                <td>
                                  <b>{this.state.createdDate}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Last Modified By</td>
                                <td>
                                  <b>{this.state.lastModifiedBy}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Last Modified On</td>
                                <td>
                                  <b>{this.state.lastModifiedDateTime}</b>
                                </td>
                              </tr>
                              <tr>
                                <td>Last Logged In</td>
                                <td>
                                  <b>{this.state.lastLoginDateTime}</b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12 pt-130">
                        <main class="content-pos-fixed">
                          <div class="bg-box">
                            <section
                              class="create-userType page-section"
                              id="create-userType"
                            >
                              <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <h2 class="sub-header">User Type</h2>
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Role Type{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="role"
                                          disabled
                                          className={"form-control"}
                                        >
                                          {(this.props.roles || [])
                                            .filter(
                                              role =>
                                                role.roleId != 1 &&
                                                role.isActive == 1
                                            )
                                            .map(role => (
                                              <option
                                                key={role.roleId}
                                                value={role.roleName}
                                              >
                                                {role.roleName}
                                              </option>
                                            ))}
                                        </Field>
                                      </div>

                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Is Active{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          as="select"
                                          name="isActive"
                                          onChange={handleChange}
                                          className={"form-control"}
                                        >
                                          <option value="1">Active</option>
                                          <option value="0">InActive</option>
                                        </Field>
                                      </div>
                                      {/* {this.state.roleId === 5 ? */}
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>User Expiry Date</label>
                                        <Field
                                          type="date"
                                          className="form-control"
                                          min={this.state.today}
                                          onChange={handleChange}
                                          name="expiryDate"
                                        ></Field>
                                      </div>
                                      {/* </div> : ''} */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>

                            <section
                              class="create-user page-section"
                              id="create-user"
                            >
                              <div class="row">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                  <h2 class="sub-header">Personal Details</h2>
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Mobile No{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="mobileNo"
                                          name="mobileNo"
                                          onChange={handleChange}
                                          disabled
                                          className={
                                            "form-control" +
                                            (errors.mobileNo && touched.mobileNo
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Mobile No"
                                        />
                                        <ErrorMessage
                                          name="mobileNo"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Email Id{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="emailID"
                                          id="emailID"
                                          disabled
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.emailID && touched.emailID
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Email ID"
                                        />
                                        <ErrorMessage
                                          name="emailID"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      {this.state.show && (
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Gender{" "}
                                            <span className="validation">
                                              {" "}
                                                *
                                            </span>
                                          </label>
                                          <Field
                                            as="select"
                                            name="gender"
                                            id="gender"
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.gender && touched.gender
                                                ? " is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">--Select--</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                            <option value="3">Other</option>
                                          </Field>
                                          <ErrorMessage
                                            name="gender"
                                            component="div"
                                            className="validation"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          First Name{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="firstName"
                                          id="firstName"
                                          autoComplete="firstName"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.firstName &&
                                              touched.firstName
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="First Name"
                                        />
                                        <ErrorMessage
                                          name="firstName"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>Middle Name</label>
                                        <Field
                                          type="text"
                                          name="middleName"
                                          onChange={handleChange}
                                          autoComplete="middleName"
                                          className={
                                            "form-control" +
                                            (errors.middleName &&
                                              touched.middleName
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Middle Name"
                                        />
                                        <ErrorMessage
                                          name="middleName"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>
                                          Last Name{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="lastName"
                                          autoComplete="lastName"
                                          id="lastName"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.lastName && touched.lastName
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Last Name"
                                        />
                                        <ErrorMessage
                                          name="lastName"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>Secondary Contact No</label>
                                        <Field
                                          type="text"
                                          name="contactNo"
                                          id="contactNo"
                                          autoComplete="contactNo"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.contactNo &&
                                              touched.contactNo
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Mobile Number"
                                        />
                                        <ErrorMessage
                                          name="contactNo"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div class="col-md-4 col-sm-4 col-xs-12">
                                        <label>Secondary Email ID</label>
                                        <Field
                                          type="text"
                                          name="secondaryEmailID"
                                          id="secondaryEmailID"
                                          autoComplete="secondaryEmailID"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.secondaryEmailID &&
                                              touched.secondaryEmailID
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Email ID"
                                        />
                                        <ErrorMessage
                                          name="secondaryEmailID"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      {this.state.show && (
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Birth Date{" "}
                                            <span className="validation">
                                              {" "}
                                                *
                                            </span>
                                          </label>
                                          <Field
                                            type="date"
                                            name="dob_1"
                                            max={this.state.today}
                                            disabled={values.role === "DISTRIBUTOR"}
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.dob_1 && touched.dob_1
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                          <ErrorMessage
                                            name="dob_1"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    {this.state.show && (
                                      <div class="row">
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>Pan No </label>
                                          <Field
                                            type="text"
                                            name="panNumber"
                                            id="panNumber"
                                            disabled={values.role === "DISTRIBUTOR"}
                                            onChange={handleChange}
                                            className={
                                              "form-control" +
                                              (errors.panNumber &&
                                                touched.panNumber
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Pan number"
                                          />
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              PAN is compulsory for nism
                                              registration
                                            </span>
                                          </div>
                                          <ErrorMessage
                                            name="panNumber"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                        <div class="col-md-4 col-sm-4 col-xs-12">
                                          <label>
                                            Aadhar No{" "}
                                            <span className="validation">
                                              {" "}
                                                *
                                            </span>
                                          </label>
                                          <Field
                                            type="text"
                                            name="aadharNumber"
                                            id="aadharNumber"
                                            disabled={
                                              values.role === "DISTRIBUTOR"
                                                ? values.aadharNumber.length >
                                                  12
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            onChange={handleChange}
                                            onBlur={event => {
                                              LoginService.TokenUser(res => {
                                                localStorage.setItem(
                                                  "jwt-token",
                                                  res.responseObject
                                                );
                                              });
                                              RegisterUserService.checkPAN(
                                                "learnerID=" +
                                                this.state.learnerID +
                                                "&panNumber=" +
                                                "&aadharNumber=" +
                                                event.target.value,
                                                res => {
                                                  if (res.status === "fail") {
                                                    setFieldValue(
                                                      (values.aadharNumber = "")
                                                    );
                                                    error(res.reasonText, {
                                                      ...errorNotification,
                                                      container: "top-center"
                                                    });
                                                  }
                                                }
                                              );
                                            }}
                                            className={
                                              "form-control" +
                                              (errors.aadharNumber &&
                                                touched.aadharNumber
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Aadhar number"
                                          />
                                          <ErrorMessage
                                            name="aadharNumber"
                                            className="validation"
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div class="col-md-4 col-sm-4 col-xs-12"></div>
                                    <div class="col-md-12 col-sm-12 col-xs-12"></div>
                                    {this.state.show && (
                                      <div class="row">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Upload PAN</label>
                                          <div className="profile-detail">
                                            <div className="circle">
                                              <img
                                                className="profile-pic"
                                                src={values.panCardURL}
                                              />
                                              <div className="profile">
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="profile-add">
                                            <span className="icon btn-file text-center">
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                              <FileBase64
                                                multiple={true}
                                                name="panCardURL"
                                                onChange={handleChange}
                                                onDone={event => {
                                                  if (
                                                    [
                                                      "image/jpg",
                                                      "image/jpeg",
                                                      "image/png"
                                                    ].indexOf(event[0].type) < 0
                                                  ) {
                                                    setFieldValue(
                                                      (values.documentTypeCheckForPANImage = true)
                                                    );
                                                  } else if (
                                                    event[0].file.size <=
                                                    5000000
                                                  ) {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType: "PanCard",
                                                      documentName:
                                                        event[0].name
                                                    });
                                                    setFieldValue(
                                                      (values.documentTypeCheckForPANImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForPanCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.panCardDocumentName =
                                                        event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.panCardURL =
                                                        event[0].base64)
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      (values.documentSizeCheckForPanCard = true)
                                                    );
                                                  }
                                                }}
                                              />
                                            </span>
                                            <h4>Add PAN</h4>
                                            {/* <span className="icon">
                                              <i
                                                className="fa fa-times-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span> */}
                                            {/* <h4>Remove PAN</h4> */}
                                            <div className="col-md-12 col-sm-12">
                                              <span class="file-size-limit">
                                                Supported Formats: .jpg, .jpeg,
                                                .png only. <br />
                                                  File Size: Max 5 MB
                                              </span>
                                            </div>
                                            {values.documentSizeCheckForPanCard ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:5MB).
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForPANImage ? (
                                              <p style={{ color: "red" }}>
                                                Invalid image format
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                          </div>
                                        </div>
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                          <label>Upload Aadhaar</label>
                                          <div className="profile-detail">
                                            <div className="circle">
                                              <img
                                                className="profile-pic"
                                                src={values.aadharCardURL}
                                              />
                                              <div className="profile">
                                                <i
                                                  className="fa fa-user"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="profile-add">
                                            <span className="icon btn-file text-center">
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                              ></i>
                                              <FileBase64
                                                multiple={true}
                                                name="aadharCardURL"
                                                onChange={handleChange}
                                                onDone={event => {
                                                  if (
                                                    [
                                                      "image/jpg",
                                                      "image/jpeg",
                                                      "image/png"
                                                    ].indexOf(event[0].type) < 0
                                                  ) {
                                                    setFieldValue(
                                                      (values.documentTypeCheckForAadharImage = true)
                                                    );
                                                  } else if (
                                                    event[0].file.size <=
                                                    5000000
                                                  ) {
                                                    values.document.push({
                                                      documentHash:
                                                        event[0].base64,
                                                      documentType:
                                                        "AadharCard",
                                                      documentName:
                                                        event[0].name
                                                    });
                                                    setFieldValue(
                                                      (values.documentTypeCheckForAadharImage = false)
                                                    );
                                                    setFieldValue(
                                                      (values.documentSizeCheckForAadharCard = false)
                                                    );
                                                    setFieldValue(
                                                      (values.aadharCardDocumentName =
                                                        event[0].name)
                                                    );
                                                    setFieldValue(
                                                      (values.aadharCardURL =
                                                        event[0].base64)
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      (values.documentSizeCheckForAadharCard = true)
                                                    );
                                                  }
                                                }}
                                              />
                                            </span>
                                            <h4>Add Aadhaar</h4>
                                            {/* <span className="icon">
                                              <i
                                                className="fa fa-times-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span> */}
                                            {/* <h4>Remove</h4> */}
                                            <div className="col-md-12 col-sm-12">
                                              <span class="file-size-limit">
                                                Supported Formats: .jpg, .jpeg,
                                                .png only. <br />
                                                  File Size: Max 5 MB
                                              </span>
                                            </div>
                                            {values.documentSizeCheckForAadharCard ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:5MB).
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForAadharImage ? (
                                              <p style={{ color: "red" }}>
                                                Invalid image format
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div class="col-md-4 col-sm-4 col-xs-12"></div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <section
                              class="create-address page-section"
                              id="create-address"
                            >
                              <h2 class="sub-header">Address Details</h2>
                              <div class="address-border">
                                <div class="form-group">
                                  <div class="row">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                      <h5 class="address-heading">
                                        Correspondence Address
                                      </h5>
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Address 1{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="ca_address_1"
                                        id="ca_address_1"
                                        autoComplete="ca_address_1"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.ca_address_1 &&
                                            touched.ca_address_1
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Address"
                                      />
                                      <ErrorMessage
                                        name="ca_address_1"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>Address 2</label>
                                      <Field
                                        type="text"
                                        name="ca_address_2"
                                        autoComplete="ca_address_2"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.ca_address_2 &&
                                            touched.ca_address_2
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Address"
                                      />
                                      <ErrorMessage
                                        name="ca_address_2"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Country{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="ca_country"
                                        id="ca_country"
                                        onChange={event => {
                                          LoginService.TokenUser(res => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (event.target.value !== "") {
                                            setFieldValue(
                                              (values.ca_country =
                                                event.target.value)
                                            );

                                            values.stateList = [];
                                            values.cityList = [];
                                            RegionManagementService.getState(
                                              "countryId=" + values.ca_country,
                                              res => {
                                                setFieldValue(
                                                  (values.stateList =
                                                    res.responseListObject)
                                                );
                                              }
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.ca_country &&
                                            touched.ca_country
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="0">--Select--</option>
                                        {this.state.countryList.map(team =>
                                          team.isActive ? (
                                            <option
                                              key={team.countryId}
                                              value={team.countryId}
                                            >
                                              {pascalCase(team.countryName)}
                                            </option>
                                          ) : (
                                              ""
                                            )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="ca_country"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        State{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="ca_state"
                                        id="ca_state"
                                        onChange={event => {
                                          LoginService.TokenUser(res => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (event.target.value !== "") {
                                            setFieldValue(
                                              (values.ca_state =
                                                event.target.value)
                                            );

                                            RegionManagementService.getCity(
                                              "stateId=" + values.ca_state,
                                              res => {
                                                setFieldValue(
                                                  (values.cityList =
                                                    res.responseListObject)
                                                );
                                              }
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.ca_state && touched.ca_state
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="0">--Select--</option>
                                        {(values.stateList || []).map(team => (
                                          <option
                                            key={team.stateId}
                                            value={team.stateId}
                                          >
                                            {pascalCase(team.stateName)}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="ca_state"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        City{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="ca_city"
                                        id="ca_city"
                                        onChange={event => {
                                          if (
                                            event.target.value !== undefined
                                          ) {
                                            setFieldValue(
                                              (values.ca_city =
                                                event.target.value)
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.ca_city && touched.ca_city
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="0">--Select--</option>
                                        {(values.cityList || []).map(team => (
                                          <option
                                            key={team.cityId}
                                            value={team.cityId}
                                          >
                                            {pascalCase(team.cityName)}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="ca_city"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Pin{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="ca_postal_code"
                                        id="ca_postal_code"
                                        autoComplete="ca_postal_code"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.ca_postal_code &&
                                            touched.ca_postal_code
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Postal Code"
                                      />
                                      <ErrorMessage
                                        name="ca_postal_code"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="address-border">
                                <div class="form-group">
                                  <div class="row">
                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                      <div class="row row-no-margin">
                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                          <div class="col-md-9 col-lg-9 col-sm-9 col-xs-12">
                                            <h5 class="address-heading">
                                              Permanent Address{" "}
                                            </h5>
                                          </div>
                                          <div class="col-md-3 col-lg-3 col-sm-3 col-xs-12">
                                            <div class="form-check pull-right">
                                              <Field
                                                className="form-check-input"
                                                type="checkbox"
                                                name="sameAsAbove"
                                                checked={values.addressFlag}
                                                onClick={event => {
                                                  if (event.target.checked) {
                                                    values.stateListForPer =
                                                      values.stateList;
                                                    values.cityListForPer =
                                                      values.cityList;
                                                    values.pa_address_1 =
                                                      values.ca_address_1;
                                                    values.pa_address_2 =
                                                      values.ca_address_2;
                                                    values.pa_country =
                                                      values.ca_country;
                                                    values.pa_state =
                                                      values.ca_state;
                                                    values.pa_city =
                                                      values.ca_city;
                                                    values.pa_postal_code =
                                                      values.ca_postal_code;
                                                    values.addressFlag =
                                                      "checked";
                                                  } else {
                                                    values.pa_address_1 = "";
                                                    values.pa_address_2 = "";
                                                    values.pa_country = "";
                                                    values.pa_state = "";
                                                    values.pa_city = "";
                                                    values.pa_postal_code = "";
                                                    values.addressFlag = "";
                                                    values.stateListForPer = [];
                                                    values.cityListForPer = [];
                                                  }
                                                }}
                                              />
                                              <label className="form-check-label">
                                                Same as above
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Address 1{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="pa_address_1"
                                        id="pa_address_1"
                                        autoComplete="pa_address_1"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.pa_address_1 &&
                                            touched.pa_address_1
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Address"
                                      />
                                      <ErrorMessage
                                        name="pa_address_1"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>Address 2</label>
                                      <Field
                                        type="text"
                                        name="pa_address_2"
                                        autoComplete="pa_address_2"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.pa_address_2 &&
                                            touched.pa_address_2
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Address"
                                      />
                                      <ErrorMessage
                                        name="pa_address_2"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Country{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="pa_country"
                                        id="pa_country"
                                        onChange={event => {
                                          LoginService.TokenUser(res => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (event.target.value !== "") {
                                            setFieldValue(
                                              (values.pa_country =
                                                event.target.value)
                                            );

                                            values.stateListForPer = [];
                                            values.cityListForPer = [];
                                            RegionManagementService.getState(
                                              "countryId=" + values.pa_country,
                                              res => {
                                                values.stateListForPer =
                                                  res.responseListObject;
                                              }
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.pa_country &&
                                            touched.pa_country
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="0">--Select--</option>
                                        {this.state.countryListForPer.map(
                                          team =>
                                            team.isActive ? (
                                              <option
                                                key={team.countryId}
                                                value={team.countryId}
                                              >
                                                {pascalCase(team.countryName)}
                                              </option>
                                            ) : (
                                                ""
                                              )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="pa_country"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        State{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="pa_state"
                                        id="pa_state"
                                        onChange={event => {
                                          LoginService.TokenUser(res => {
                                            localStorage.setItem(
                                              "jwt-token",
                                              res.responseObject
                                            );
                                          });
                                          if (
                                            event.target.value !== undefined
                                          ) {
                                            setFieldValue(
                                              (values.pa_state =
                                                event.target.value)
                                            );

                                            RegionManagementService.getCity(
                                              "stateId=" + values.pa_state,
                                              res => {
                                                values.cityListForPer =
                                                  res.responseListObject;
                                              }
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.pa_state && touched.pa_state
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="0">--Select--</option>
                                        {(values.stateListForPer || []).map(
                                          team => (
                                            <option
                                              key={team.stateId}
                                              value={team.stateId}
                                            >
                                              {pascalCase(team.stateName)}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="pa_state"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        City{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="pa_city"
                                        id="pa_city"
                                        onChange={event => {
                                          if (
                                            event.target.value !== undefined
                                          ) {
                                            setFieldValue(
                                              (values.pa_city =
                                                event.target.value)
                                            );
                                          }
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.pa_city && touched.pa_city
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="0">--Select--</option>
                                        {(values.cityListForPer || []).map(
                                          team => (
                                            <option
                                              key={team.cityId}
                                              value={team.cityId}
                                            >
                                              {pascalCase(team.cityName)}
                                            </option>
                                          )
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="pa_city"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>

                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                      <label>
                                        Pin{" "}
                                        <span className="validation"> *</span>{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="pa_postal_code"
                                        id="pa_postal_code"
                                        autoComplete="pa_postal_code"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.pa_postal_code &&
                                            touched.pa_postal_code
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Postal Code"
                                      />
                                      <ErrorMessage
                                        name="pa_postal_code"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>

                            {this.state.show && (
                              <section
                                className="create-parent page-section"
                                id="create-parent"
                              >
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 className="sub-header">
                                      Parent Details
                                    </h2>
                                    <div className="address-border">
                                      <div className="form-group">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <h5 className="address-heading">
                                            Father Details
                                          </h5>
                                        </div>
                                        <div className="form-group">
                                          <div class="row">
                                            <div class="col-md-4 col-sm-4 col-xs-12">
                                              <label>
                                                Father's Name{" "}
                                                {/* <span className="validation">
                                                  {" "}
                                                  *
                                                </span>{" "} */}
                                              </label>
                                              <Field
                                                type="text"
                                                name="fatherName"
                                                id="fatherName"
                                                onChange={handleChange}
                                                autoComplete="fatherName"
                                                className={
                                                  "form-control" +
                                                  (errors.fatherName &&
                                                    touched.fatherName
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                placeholder="Father's Name"
                                              />
                                              <ErrorMessage
                                                name="fatherName"
                                                className="validation"
                                                component="div"
                                              />
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12">
                                              <label>Birth Date</label>
                                              <Field
                                                type="date"
                                                name="dob_2"
                                                max={this.state.today}
                                                id="dob_2"
                                                onChange={handleChange}
                                                className={
                                                  "form-control" +
                                                  (errors.dob_2 && touched.dob_2
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name="dob_2"
                                                className="validation"
                                                component="div"
                                              />
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12">
                                              <label>Occupation Type</label>
                                              <Field
                                                as="select"
                                                name="employment_type_1"
                                                id="employment_type_1"
                                                onChange={event => {
                                                  setFieldValue(
                                                    (values.employment_type_1 =
                                                      event.target.value)
                                                  );
                                                  if (
                                                    event.target.value ===
                                                    "6" ||
                                                    event.target.value === "8"
                                                  ) {
                                                    setFieldValue(
                                                      (values.company_name_1 =
                                                        "")
                                                    );
                                                  }
                                                }}
                                                className={
                                                  "form-control" +
                                                  (errors.employment_type_1 &&
                                                    touched.employment_type_1
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              >
                                                <option>--Select--</option>
                                                {(
                                                  this.state
                                                    .employeTypeForParents || []
                                                ).map(team => (
                                                  <option
                                                    key={team.codeValueId}
                                                    value={team.codeValueId}
                                                  >
                                                    {team.codeValue}
                                                  </option>
                                                ))}
                                              </Field>
                                              <ErrorMessage
                                                name="employment_type_1"
                                                component="div"
                                                className="validation"
                                              />
                                            </div>
                                          </div>
                                          <div class="row">
                                            {values.employment_type_1 === "6" ||
                                              values.employment_type_1 === "8" ||
                                              values.employment_type_1 === 6 ||
                                              values.employment_type_1 === 8 ? (
                                                ""
                                              ) : (
                                                <div class="col-md-4 col-sm-4 col-xs-12">
                                                  <label>Company Name </label>
                                                  <Field
                                                    type="text"
                                                    name="company_name_1"
                                                    autoComplete="company_name_1"
                                                    onChange={handleChange}
                                                    className={
                                                      "form-control" +
                                                      (errors.company_name_1 &&
                                                        touched.company_name_1
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    placeholder="Company Name"
                                                  />
                                                  <ErrorMessage
                                                    name="company_name_1"
                                                    className="validation"
                                                    component="div"
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="address-border">
                                      <div className="form-group">
                                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                          <h5 className="address-heading">
                                            Mother Details
                                          </h5>
                                        </div>
                                        <div class="row">
                                          <div class="col-md-4 col-sm-4 col-xs-12">
                                            <label>Mother Name </label>
                                            <Field
                                              type="text"
                                              name="mother_name"
                                              autoComplete="mother_name"
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.mother_name &&
                                                  touched.mother_name
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder="Mother's Name"
                                            />
                                            <ErrorMessage
                                              name="mother_name"
                                              className="validation"
                                              component="div"
                                            />
                                          </div>
                                          <div class="col-md-4 col-sm-4 col-xs-12">
                                            <label>Birth Date</label>
                                            <Field
                                              type="date"
                                              name="dob_3"
                                              id="dob_3"
                                              max={this.state.today}
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.dob_3 && touched.dob_3
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name="dob_3"
                                              className="validation"
                                              component="div"
                                            />
                                          </div>
                                          <div class="col-md-4 col-sm-4 col-xs-12">
                                            <label>Occupation Type</label>
                                            <Field
                                              as="select"
                                              name="employment_type_2"
                                              id="employment_type_2"
                                              onChange={event => {
                                                setFieldValue(
                                                  (values.employment_type_2 =
                                                    event.target.value)
                                                );
                                                if (
                                                  event.target.value === "6" ||
                                                  event.target.value === "8"
                                                ) {
                                                  setFieldValue(
                                                    (values.company_name_2 = "")
                                                  );
                                                }
                                              }}
                                              className={
                                                "form-control" +
                                                (errors.employment_type_2 &&
                                                  touched.employment_type_2
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            >
                                              <option>--Select--</option>
                                              {(
                                                this.state
                                                  .employeTypeForParents || []
                                              ).map(team => (
                                                <option
                                                  key={team.codeValueId}
                                                  value={team.codeValueId}
                                                >
                                                  {team.codeValue}
                                                </option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="employment_type_2"
                                              component="div"
                                              className="validation"
                                            />
                                          </div>
                                        </div>
                                        <div className="row">
                                          {values.employment_type_2 === "6" ||
                                            values.employment_type_2 === "8" ||
                                            values.employment_type_2 === 6 ||
                                            values.employment_type_2 === 8 ? (
                                              ""
                                            ) : (
                                              <div class="col-md-4 col-sm-4 col-xs-12">
                                                <label>Company Name </label>
                                                <Field
                                                  type="text"
                                                  name="company_name_2"
                                                  autoComplete="company_name_2"
                                                  onChange={handleChange}
                                                  className={
                                                    "form-control" +
                                                    (errors.company_name_2 &&
                                                      touched.company_name_2
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder="Company Name"
                                                />
                                                <ErrorMessage
                                                  name="company_name_2"
                                                  className="validation"
                                                  component="div"
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                            {this.state.show && (
                              <section
                                class="create-qualification page-section"
                                id="create-qualification"
                              >
                                <div class="row">
                                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                    <h2 class="sub-header">
                                      Qualification Details
                                    </h2>
                                    <div className="qualification-table">
                                      <FieldArray
                                        name="learnerQualification"
                                        render={arrayHelpers => (
                                          <div>
                                            {values.learnerQualification &&
                                              values.learnerQualification.length >
                                              0 ? (
                                                <div className="table-row">
                                                  <table
                                                    className="table table-bordered table-hover"
                                                    id="tab_logic"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th className="text-center">
                                                          {" "}
                                                            Institute Name
                                                      </th>
                                                        <th className="text-center">
                                                          {" "}
                                                             Category{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            Qualification Type{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            Passing Year
                                                      </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            Pursuing Year{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            CGPA / Percentage{" "}
                                                        </th>
                                                        <th className="text-center">
                                                          {" "}
                                                            Action{" "}
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {values.learnerQualification.map(
                                                        (friend, index) => (
                                                          <tr key={index}>
                                                            <td>
                                                              <Field
                                                                name={`learnerQualification.${index}.instituteName`}
                                                                className="form-control"
                                                                value={
                                                                  friend.instituteName
                                                                }
                                                              />
                                                            </td>
                                                            <td>
                                                              <Field
                                                                name={`learnerQualification.${index}.qualificationCategory`}
                                                                className="form-control"
                                                                value={
                                                                  friend.qualificationCategory
                                                                }
                                                              />
                                                            </td>
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                name={`learnerQualification.${index}.qualificationType`}
                                                                className="form-control"
                                                              // value={
                                                              //   friend.qualificationType
                                                              // }
                                                              >
                                                                <option value="">
                                                                  Select Education
                                                              </option>
                                                                <option value="Under Graduate">
                                                                  Under Graduate
                                                              </option>
                                                                <option value="Graduate">
                                                                  Graduate
                                                              </option>
                                                                <option value="Post Graduate">
                                                                  Post Graduate
                                                              </option>
                                                              </Field>
                                                            </td>
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                className="form-control"
                                                                name={`learnerQualification.${index}.passingYear`}
                                                                disabled={
                                                                  friend.qualificationType ==
                                                                    "Under Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "under Graduate"
                                                                    ? true
                                                                    : false
                                                                }
                                                                value={
                                                                  friend.qualificationType ==
                                                                    "Under Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "under Graduate"
                                                                    ? ""
                                                                    : friend.passingYear
                                                                }
                                                                type="number"
                                                              >
                                                                <option value="">
                                                                  --Select--
                                                              </option>
                                                                <option
                                                                  value={1970}
                                                                >
                                                                  1970
                                                              </option>
                                                                <option
                                                                  value={1971}
                                                                >
                                                                  1971
                                                              </option>
                                                                <option
                                                                  value={1972}
                                                                >
                                                                  1972
                                                              </option>
                                                                <option
                                                                  value={1973}
                                                                >
                                                                  1973
                                                              </option>
                                                                <option
                                                                  value={1974}
                                                                >
                                                                  1974
                                                              </option>
                                                                <option
                                                                  value={1975}
                                                                >
                                                                  1975
                                                              </option>
                                                                <option
                                                                  value={1976}
                                                                >
                                                                  1976
                                                              </option>
                                                                <option
                                                                  value={1977}
                                                                >
                                                                  1977
                                                              </option>
                                                                <option
                                                                  value={1978}
                                                                >
                                                                  1978
                                                              </option>
                                                                <option
                                                                  value={1979}
                                                                >
                                                                  1979
                                                              </option>
                                                                <option
                                                                  value={1980}
                                                                >
                                                                  1980
                                                              </option>
                                                                <option
                                                                  value={1981}
                                                                >
                                                                  1981
                                                              </option>
                                                                <option
                                                                  value={1982}
                                                                >
                                                                  1982
                                                              </option>
                                                                <option
                                                                  value={1983}
                                                                >
                                                                  1983
                                                              </option>
                                                                <option
                                                                  value={1984}
                                                                >
                                                                  1984
                                                              </option>
                                                                <option
                                                                  value={1985}
                                                                >
                                                                  1985
                                                              </option>
                                                                <option
                                                                  value={1986}
                                                                >
                                                                  1986
                                                              </option>
                                                                <option
                                                                  value={1987}
                                                                >
                                                                  1987
                                                              </option>
                                                                <option
                                                                  value={1988}
                                                                >
                                                                  1988
                                                              </option>
                                                                <option
                                                                  value={1989}
                                                                >
                                                                  1989
                                                              </option>
                                                                <option
                                                                  value={1990}
                                                                >
                                                                  1990
                                                              </option>
                                                                <option
                                                                  value={1991}
                                                                >
                                                                  1991
                                                              </option>
                                                                <option
                                                                  value={1992}
                                                                >
                                                                  1992
                                                              </option>
                                                                <option
                                                                  value={1993}
                                                                >
                                                                  1993
                                                              </option>
                                                                <option
                                                                  value={1994}
                                                                >
                                                                  1994
                                                              </option>
                                                                <option
                                                                  value={1995}
                                                                >
                                                                  1995
                                                              </option>
                                                                <option
                                                                  value={1996}
                                                                >
                                                                  1996
                                                              </option>
                                                                <option
                                                                  value={1997}
                                                                >
                                                                  1997
                                                              </option>
                                                                <option
                                                                  value={1998}
                                                                >
                                                                  1998
                                                              </option>
                                                                <option
                                                                  value={1999}
                                                                >
                                                                  1999
                                                              </option>
                                                                <option
                                                                  value={2000}
                                                                >
                                                                  2000
                                                              </option>
                                                                <option
                                                                  value={2001}
                                                                >
                                                                  2001
                                                              </option>
                                                                <option
                                                                  value={2002}
                                                                >
                                                                  2002
                                                              </option>
                                                                <option
                                                                  value={2003}
                                                                >
                                                                  2003
                                                              </option>
                                                                <option
                                                                  value={2004}
                                                                >
                                                                  2004
                                                              </option>
                                                                <option
                                                                  value={2005}
                                                                >
                                                                  2005
                                                              </option>
                                                                <option
                                                                  value={2006}
                                                                >
                                                                  2006
                                                              </option>
                                                                <option
                                                                  value={2007}
                                                                >
                                                                  2007
                                                              </option>
                                                                <option
                                                                  value={2008}
                                                                >
                                                                  2008
                                                              </option>
                                                                <option
                                                                  value={2009}
                                                                >
                                                                  2009
                                                              </option>
                                                                <option
                                                                  value={2010}
                                                                >
                                                                  2010
                                                              </option>
                                                                <option
                                                                  value={2011}
                                                                >
                                                                  2011
                                                              </option>
                                                                <option
                                                                  value={2012}
                                                                >
                                                                  2012
                                                              </option>
                                                                <option
                                                                  value={2013}
                                                                >
                                                                  2013
                                                              </option>
                                                                <option
                                                                  value={2014}
                                                                >
                                                                  2014
                                                              </option>
                                                                <option
                                                                  value={2015}
                                                                >
                                                                  2015
                                                              </option>
                                                                <option
                                                                  value={2016}
                                                                >
                                                                  2016
                                                              </option>
                                                                <option
                                                                  value={2017}
                                                                >
                                                                  2017
                                                              </option>
                                                                <option
                                                                  value={2018}
                                                                >
                                                                  2018
                                                              </option>
                                                                <option
                                                                  value={2019}
                                                                >
                                                                  2019
                                                              </option>
                                                              </Field>
                                                            </td>
                                                            <td>
                                                              <Field
                                                                as="select"
                                                                className="form-control"
                                                                disabled={
                                                                  friend.qualificationType ==
                                                                    "Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "Post Graduate"
                                                                    ? true
                                                                    : false
                                                                }
                                                                name={`learnerQualification.${index}.persuingYear`}
                                                                value={
                                                                  friend.qualificationType ==
                                                                    "Graduate" ||
                                                                    friend.qualificationType ==
                                                                    "Post Graduate"
                                                                    ? ""
                                                                    : friend.persuingYear
                                                                }
                                                              >
                                                                <option value="">
                                                                  --Select--
                                                              </option>
                                                                <option value="1">
                                                                  1st
                                                              </option>
                                                                <option value="2">
                                                                  2nd
                                                              </option>
                                                                <option value="3">
                                                                  3rd
                                                              </option>
                                                                <option value="4">
                                                                  4th
                                                              </option>
                                                              </Field>
                                                            </td>
                                                            <td>
                                                              <Field
                                                                className="form-control"
                                                                name={`learnerQualification.${index}.grade`}
                                                                value={
                                                                  friend.grade
                                                                }
                                                              />
                                                            </td>
                                                            <td>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                onClick={() => {
                                                                  arrayHelpers.remove(
                                                                    index
                                                                  );

                                                                  // this.removeByAttr(
                                                                  //   this.state
                                                                  //     .learnerQualification,
                                                                  //   "instituteName",
                                                                  //   friend.instituteName
                                                                  // );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-minus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                              <button
                                                                type="button"
                                                                className="btn-8 ml-5 mr-5 mt-10"
                                                                onClick={() => {
                                                                  arrayHelpers.insert(
                                                                    index + 1,
                                                                    ""
                                                                  );
                                                                }}
                                                              >
                                                                <i
                                                                  class="fa fa-plus"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="btn-4"
                                                  onClick={() => {
                                                    arrayHelpers.push("");
                                                  }}
                                                >
                                                  Add a Qualification
                                                </button>
                                              )}
                                          </div>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                            {this.state.show && (
                              <section
                                className="create-profile page-section"
                                id="create-profile"
                              >
                                <h2 className="sub-header">Profile Details</h2>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Upload Profile Image</label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <img
                                            className="profile-pic"
                                            src={values.profilePhotoURL}
                                          />
                                          <div className="profile">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            ></i>
                                            <FileBase64
                                              multiple={true}
                                              onChange={handleChange}
                                              onDone={event => {
                                                if (
                                                  [
                                                    "image/jpg",
                                                    "image/jpeg",
                                                    "image/png"
                                                  ].indexOf(event[0].type) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForProfileImage = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 5000000
                                                ) {
                                                  values.document.push({
                                                    documentHash: event[0].base64,
                                                    documentType: "ProfilePhoto",
                                                    documentName: event[0].name
                                                  });
                                                  setFieldValue(
                                                    (values.documentTypeCheckForProfileImage = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForProfileCheck = false)
                                                  );
                                                  values.profilePhotoURL =
                                                    event[0].base64;
                                                  setFieldValue(
                                                    (values.profilePhotoURL =
                                                      event[0].base64)
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    (values.documentSizeCheckForProfileCheck = true)
                                                  );
                                                }
                                              }}
                                            />
                                          </span>
                                          <h4>Add Image</h4>
                                          {/* <span className="icon">
                                          <i
                                            className="fa fa-times-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span> */}
                                          {/* <h4>Remove Image</h4> */}
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              Supported Formats: .jpg, .jpeg, .png
                                            only. <br />
                                              File Size: Max 5 MB
                                          </span>
                                          </div>
                                          {values.documentSizeCheckForProfileCheck ? (
                                            <p style={{ color: "red" }}>
                                              File Size is too large(Maximum File
                                              Size:5MB).
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                          {values.documentTypeCheckForProfileImage ? (
                                            <p style={{ color: "red" }}>
                                              Invalid image format
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Upload Text Resume</label>
                                      <div className="text-resume">
                                        <h3>Select File To Upload</h3>
                                      </div>
                                      <div className="profile-add text-center">
                                        {values.resumeDocumentNameDuringEdit !=
                                          "" ? (
                                            <span>
                                              {
                                                values.resumeDocumentNameDuringEdit
                                              }
                                            </span>
                                          ) : values.resumeDocumentName ? (
                                            <span>
                                              {values.resumeDocumentName}
                                            </span>
                                          ) : (
                                              <span>
                                                <span className="icon">
                                                  <i
                                                    className="fa fa-times-circle"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                                <h4>No File Chosen</h4>
                                              </span>
                                            )}
                                        <div>
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-paperclip color_magento"
                                              aria-hidden="true"
                                            ></i>
                                            {values.documentSizeCheckForResume ? (
                                              <p style={{ color: "red" }}>
                                                File Size is too large(Maximum
                                                File Size:2MB).
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                            {values.documentTypeCheckForResume ? (
                                              <p style={{ color: "red" }}>
                                                Invalid Format
                                              </p>
                                            ) : (
                                                ""
                                              )}
                                            <FileBase64
                                              multiple={true}
                                              name="resumeDocumentURL"
                                              onChange={handleChange}
                                              onDone={event => {
                                                const checkType = event[0].name.split(
                                                  "."
                                                );
                                                if (
                                                  [
                                                    "pdf",
                                                    "docx",
                                                    "rtf",
                                                    "doc"
                                                  ].indexOf(checkType[1]) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForResume = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 2000000
                                                ) {
                                                  values.document.push({
                                                    documentHash:
                                                      event[0].base64,
                                                    documentType:
                                                      "ResumeDocument",
                                                    documentName: event[0].name
                                                  });
                                                  setFieldValue(
                                                    (values.documentTypeCheckForResume = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForResume = false)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeDocumentName =
                                                      event[0].name)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeDocumentNameDuringEdit =
                                                      "")
                                                  );
                                                  setFieldValue(
                                                    (values.downloadResumeAfterSelecting =
                                                      event[0].base64)
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    (values.documentSizeCheckForResume = true)
                                                  );
                                                }
                                              }}
                                            />

                                            {/* <a
                                          href={values.resumeDocumentURL}
                                          className="fa fa-download"
                                        >
                                          Download
                                        </a> */}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                          <a className="icon btn-file">
                                            {values.downloadResumeAfterSelecting ? (
                                              <a
                                                href={
                                                  values.downloadResumeAfterSelecting
                                                }
                                                className="download"
                                                download={
                                                  values.resumeDocumentName
                                                }
                                              >
                                                <i
                                                  className="fa fa-download"
                                                  aria-hidden="true"
                                                ></i>
                                                <h4> Download Resume </h4>
                                              </a>
                                            ) : (
                                              values.resumeDocumentURL || ""
                                            ).includes("pdf") ? (
                                                  <a
                                                    href={values.resumeDocumentURL}
                                                    className="download"
                                                    target="_blank"
                                                  >
                                                    <i
                                                      className="fa fa-download"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <h4> Download Resume </h4>
                                                  </a>
                                                ) : values.resumeDocumentURL ? (
                                                  <a
                                                    href={values.resumeDocumentURL}
                                                    className="download"
                                                  >
                                                    <i
                                                      className="fa fa-download"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <h4> Download Resume </h4>
                                                  </a>
                                                ) : (
                                                    <span>
                                                      <i
                                                        className="fa fa-download"
                                                        aria-hidden="true"
                                                      ></i>
                                                      <h4> Download Resume </h4>
                                                    </span>
                                                  )}
                                          </a>
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              Supported Formats: .docx, .pdf, .rtf
                                            only. <br />
                                              File Size: Max 5 MB
                                          </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                      <label>Upload Video Resume</label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <div className="profile-pic">
                                            <div className="profile">
                                              <ReactPlayer
                                                width="100%"
                                                height="90"
                                                url={values.resumeVideoURL}
                                                playing={false}
                                                controls
                                              ></ReactPlayer>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="hidden-md hidden-lg hidden-sm col-xs-12">
                                        <div className="profile-add">
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            ></i>
                                            <FileBase64
                                              multiple={true}
                                              name="resumeVideoURL"
                                              onChange={handleChange}
                                              onDone={event => {
                                                if (
                                                  [
                                                    "video/mp4",
                                                    "video/avi",
                                                    "video/mpeg"
                                                  ].indexOf(event[0].type) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForVideo = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 10000000
                                                ) {
                                                  values.document.push({
                                                    documentHash: event[0].base64,
                                                    documentType: "VideoResume",
                                                    documentName: event[0].name
                                                  });
                                                  setFieldValue(
                                                    (values.documentTypeCheckForVideo = false)
                                                  );
                                                  setFieldValue(
                                                    (values.documentSizeCheckForVideoCheck = false)
                                                  );
                                                  setFieldValue(
                                                    (values.resumeVideoURL =
                                                      event[0].base64)
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    (values.documentSizeCheckForVideoCheck = true)
                                                  );
                                                }
                                              }}
                                            />
                                          </span>
                                          <h4>Add Video</h4>
                                          {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Video</h4> */}
                                          {values.documentSizeCheckForVideoCheck ? (
                                            <p style={{ color: "red" }}>
                                              File Size is too large(Maximum File
                                              Size:10MB).
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                          {values.documentTypeCheckForVideo ? (
                                            <p style={{ color: "red" }}>
                                              Invalid Format
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              Supported Formats: .mp4, .avi, .mpeg
                                            only. <br />
                                              File Size: Max 10 MB
                                          </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-4 col-sm-4 hidden-xs">
                                      <div className="profile-add">
                                        <span className="icon btn-file text-center">
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          ></i>
                                          <FileBase64
                                            multiple={true}
                                            onChange={handleChange}
                                            onDone={event => {
                                              if (
                                                [
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/png"
                                                ].indexOf(event[0].type) < 0
                                              ) {
                                                setFieldValue(
                                                  (values.documentTypeCheckForProfileImage = true)
                                                );
                                              } else if (
                                                event[0].file.size <= 5000000
                                              ) {
                                                values.document.push({
                                                  documentHash: event[0].base64,
                                                  documentType: "ProfilePhoto",
                                                  documentName: event[0].name
                                                });
                                                setFieldValue(
                                                  (values.documentTypeCheckForProfileImage = false)
                                                );
                                                setFieldValue(
                                                  (values.documentSizeCheckForProfileCheck = false)
                                                );
                                                values.profilePhotoURL =
                                                  event[0].base64;
                                                setFieldValue(
                                                  (values.profilePhotoURL =
                                                    event[0].base64)
                                                );
                                              } else {
                                                setFieldValue(
                                                  (values.documentSizeCheckForProfileCheck = true)
                                                );
                                              }
                                            }}
                                          />
                                        </span>
                                        <h4>Add Image</h4>
                                        {/* <span className="icon">
                                          <i
                                            className="fa fa-times-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </span> */}
                                        {/* <h4>Remove Image</h4> */}
                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            Supported Formats: .jpg, .jpeg, .png
                                            only. <br />
                                              File Size: Max 5 MB
                                          </span>
                                        </div>
                                        {values.documentSizeCheckForProfileCheck ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:5MB).
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                        {values.documentTypeCheckForProfileImage ? (
                                          <p style={{ color: "red" }}>
                                            Invalid image format
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 hidden-xs">
                                      <div className="profile-add">
                                        <a className="icon btn-file">
                                          {values.downloadResumeAfterSelecting ? (
                                            <a
                                              href={
                                                values.downloadResumeAfterSelecting
                                              }
                                              className="download"
                                              download={
                                                values.resumeDocumentName
                                              }
                                            >
                                              <i
                                                className="fa fa-download"
                                                aria-hidden="true"
                                              ></i>
                                              <h4> Download Resume </h4>
                                            </a>
                                          ) : (
                                            values.resumeDocumentURL || ""
                                          ).includes("pdf") ? (
                                                <a
                                                  href={values.resumeDocumentURL}
                                                  className="download"
                                                  target="_blank"
                                                >
                                                  <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                  ></i>
                                                  <h4> Download Resume </h4>
                                                </a>
                                              ) : values.resumeDocumentURL ? (
                                                <a
                                                  href={values.resumeDocumentURL}
                                                  className="download"
                                                >
                                                  <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                  ></i>
                                                  <h4> Download Resume </h4>
                                                </a>
                                              ) : (
                                                  <span>
                                                    <i
                                                      className="fa fa-download"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <h4> Download Resume </h4>
                                                  </span>
                                                )}
                                        </a>
                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            Supported Formats: .docx, .pdf, .rtf
                                            only. <br />
                                              File Size: Max 5 MB
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 hidden-xs">
                                      <div className="profile-add">
                                        <span className="icon btn-file text-center">
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          ></i>
                                          <FileBase64
                                            multiple={true}
                                            name="resumeVideoURL"
                                            onChange={handleChange}
                                            onDone={event => {
                                              if (
                                                [
                                                  "video/mp4",
                                                  "video/avi",
                                                  "video/mpeg"
                                                ].indexOf(event[0].type) < 0
                                              ) {
                                                setFieldValue(
                                                  (values.documentTypeCheckForVideo = true)
                                                );
                                              } else if (
                                                event[0].file.size <= 10000000
                                              ) {
                                                values.document.push({
                                                  documentHash: event[0].base64,
                                                  documentType: "VideoResume",
                                                  documentName: event[0].name
                                                });
                                                setFieldValue(
                                                  (values.documentTypeCheckForVideo = false)
                                                );
                                                setFieldValue(
                                                  (values.documentSizeCheckForVideoCheck = false)
                                                );
                                                setFieldValue(
                                                  (values.resumeVideoURL =
                                                    event[0].base64)
                                                );
                                              } else {
                                                setFieldValue(
                                                  (values.documentSizeCheckForVideoCheck = true)
                                                );
                                              }
                                            }}
                                          />
                                        </span>
                                        <h4>Add Video</h4>
                                        {/* <span className="icon">
                                            <i
                                              className="fa fa-times-circle"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <h4>Remove Video</h4> */}
                                        {values.documentSizeCheckForVideoCheck ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:10MB).
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                        {values.documentTypeCheckForVideo ? (
                                          <p style={{ color: "red" }}>
                                            Invalid Format
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            Supported Formats: .mp4, .avi, .mpeg
                                            only. <br />
                                              File Size: Max 10 MB
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            )}
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                              <a href="">
                                <button
                                  type="submit"
                                  name="submitForm"
                                  class="btn-4 pull-right"
                                  onClick={async () => {
                                    const curErrors = await formikProps.validateForm();
                                    const curErrorsKeys = Object.keys(
                                      curErrors
                                    );

                                    if (curErrorsKeys.length) {
                                      // Assuming curErrorsKeys[0] identifies the first failing field, use that to
                                      // find the correct DOM element to call .focus() on.
                                      //
                                      // Since I set the id attribute on all my form's <input>s to match the error
                                      // keys, I just use document.getElementById(), but of course you could use refs
                                      const el = document.getElementById(
                                        curErrorsKeys[0]
                                      );

                                      if (el) el.focus();
                                    }
                                  }}
                                >
                                  Submit
                                </button>
                              </a>
                              <a href="#">
                                <button
                                  type="button"
                                  name="cancelForm"
                                  class="btn-4 mr-20 pull-right"
                                  onClick={this.handleCancel}
                                >
                                  Cancel
                                </button>
                              </a>
                            </div>
                          </div>
                        </main>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

export default AdminProfileEdit;
