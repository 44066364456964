import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { LoginService } from "../../services/login.service";
import { ProductService } from "../../services/product.service";
import $ from "jquery";
import { unblockUser } from "../../services/admin.service";
// import { success, successNotification } from "../notification/notifications";
import { ExcelService } from "../Excel/excel";
import { success, successNotification, error, errorNotification, warning, warningNotification } from "../notification/notifications";
import { GeneralEnquiryService } from "../../services/generalenquiryService";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";

class AdminListLandingPage extends Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem('login') !== null) {
      this.state = {
        roleType: "",
        cheeseIsReady: 1,
        ca_address_id: "",
        ca_address_1: "",
        ca_address_2: "",
        ca_country: "",
        ca_state: "",
        ca_city: "",
        ca_postal_code: "",
        pa_address_id: "",
        pa_address_1: "",
        pa_address_2: "",
        pa_country: "",
        pa_state: "",
        pa_city: "",
        pa_postal_code: "",
        learnerFatherName: "",
        learnerFatherDOB: "",
        learnerFatherEmployementType: "",
        learnerFatherCompanyName: "",
        learnerMotherName: "",
        learnerMotherDOB: "",
        learnerMotherEmployementType: "",
        learnerMotherCompanyName: "",
        addressFlag: false,
        adminUserFlag: false,
        respnoseData: this.props.adminUser,
        selectionuser: [],
        selectAlluser: false,
        disableapproveuser: true,
        disabledownloaduser: true,
        filtered: [],
        griddata: (this.props || '').adminUser ? this.props.adminUser.filter(
          role => role.userRoleMatrixList[0].roleId.roleId != 1
        ) : [],
        loading: false,
      }
      //      //console.log("listtttttttrole", this.state.respnoseData)
    }
    else {
      window.location.href = '/';
    }
  }

  componentDidMount() {
    // this.setState({
    //   griddata: this.props.adminUser.filter(
    //     role => role.userRoleMatrixList[0].roleId.roleId != 1
    //   ),
    // })

  }

  stateonpagechange = (props) => {
    this.setState({
      griddata: this.props.adminUser.filter(
        role => role.userRoleMatrixList[0].roleId.roleId != 1
      ),
      selectAlluser: this.state.selectAlluser == true ? false : false,
    })

  }

  stateonfilterchange = (props) => {
    this.setState({
      griddata: this.props.adminUser.filter(
        role => role.userRoleMatrixList[0].roleId.roleId != 1
      ),

    })

  }

  filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;
  handleRole = event => {
    this.setState({ roleType: event.target.value });
  };

  handleClick = value => {
    if (value.loginAttempt < 3) {
      this.props.history.push("/admin-edit-user-profile", { role: value });
    }
    else {
      warning("User Account is blocked. You cannot Edit the profile", warningNotification);
    }

  };

  exportToCSV = () => {
    let modifiedData = [];
    let data = this.state.respnoseData;
    data.forEach(element => {
      var data1 = {
        LearnerId: element.partyID,
        Name: element.name,
        Role: element.userRoleMatrixList[0].roleId.roleName,
        UserLoginName: element.userLoginName,
        MobileNumber: element.mobileNumber,
        EmailId: element.emailId,
        ActivateDate: element.activateDate ? element.activateDate.split(' ')[0] : '',
        ExpiryDate: element.expiryDate ? element.expiryDate.split(' ')[0] : ' ',
        CreatedBy: element.createdBy,
        CreatedDate: element.createdDate ? element.createdDate.split(' ')[0] : '',
        LastModifiedBy: element.lastModifiedBy,
        LastModifiedDateTime: element.lastModifiedDateTime ? element.lastModifiedDateTime.split(' ')[0] : '',
        IsActive: element.isActive,
        LastLoginDate: element.lastLoginDateTime ? element.lastLoginDateTime.split(' ')[0] : ''
      };
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, 'FC-UserList-');

  };


  handleBlock = (event, values) => {
    this.setState({ loading: true });
    //setTimeout(() => {
    let data = {
      userId: values.userId,
      loginAttempt: values.loginAttempt === 3 ? 0 : 3
    };
    LoginService.TokenUser(res => {
      if (res.status === "success") {
        localStorage.setItem("jwt-token", res.responseObject);
        unblockUser(data, cb => {
          if (cb.status === "success") {
            success("Status changed successfully", successNotification);
            this.props.fetchAdminUser('limit=-1');
            this.setState({ loading: false });
          }
        });
      }
      //}, 1000);
    })
  }

  handleStatus = (event, values) => {

    setTimeout(() => {
      this.setState({ loading: true });
      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          this.setState({ selectionuser: [] });
          let data = {
            roleID: values.userRoleMatrixList[0].roleId.roleId,
            partyID: values.partyID,
            userID: values.userId,
            isActive: values.isActive === 1 ? 0 : 1
          };

          this.state.selectionuser.push(data)

          ProductService.bulkupdateuserstatus(this.state.selectionuser, res => {

            if (res.status === "success") {
              success("User Status Updated successfully", successNotification);
              this.props.fetchAdminUser('limit=-1');
              this.setState({ loading: false });
            }
            else {
              error(res.reasonText, errorNotification);
            }
          });

        }
      });
    }, 1000);
  }

  sendEmail = (values) => {
    this.setState({
      dat: values.name,
      dat2: values.emailId,
      stud: values.partyID
    })
    // //console.log("values",this.state.dat)
  }

  handleaddCourse = (props) => {

    if (props.isActive === 0) {
      warning("You cannot assign the courses to learner, who are In-Active. Please activate the learner first then Assign the course", warningNotification);
    } else {
      if (props.loginAttempt < 3) {
        this.props.history.push("/add-learner-course", { role: props })
      }
      else {
        warning("User Account is blocked. You cannot Edit the profile", warningNotification);
      }
    }

  }
  handleChangePassword = (props) => {
    this.props.history.push("/changePassword", {
      userId: props.userId,
      userLoginName: props.userLoginName,
      emailId: props.emailId,
      fromAdminChangePassword: true,
      name: props.name
    })
  }


  userbulk = () => {

    // this.props.history.push("/userbulkupload")
    this.props.history.push("/userbulkupload",
      {
        alerts: this.props.alertNotificationEvents,
        notification: this.props.notificationEvents,
      })
  }

  adminur = () => {
    // var currpage = window.location.href;
    // //alert(currpage.substring(currpage.lastIndexOf('/') + 1))
    // if (currpage.substring(currpage.lastIndexOf('/') + 1) == 'admin-user-list' || currpage.substring(currpage.lastIndexOf('/') + 1) == 'admin-user-list#') {
    //   localStorage.setItem("profilebackto", 'userlist')
    // }
    // else {
    //   localStorage.setItem("profilebackto", 'admin-dashboard')

    // }

    this.props.history.push("/admin-user-profile", {
      alerts: this.props.alertNotificationEvents,
      notification: this.props.notificationEvents
    })
  }

  // Check All User Handler
  checkAlluserHandler = () => {

    var self = this
    //alert('clicked'+ self.state.selectAll)

    this.setState({
      selectAlluser: self.state.selectAlluser == true ? false : true,
    })

    //$("input:checkbox").prop("checked", self.state.selectAll);




  }

  // Check Single Handler
  handleCheck = (props) => {

    //console.log("Checkbox props  : ", props)
    //alert('select single'+ JSON.stringify(props));
    //console.log("chcked")
  }


  bulkupdateuserstatus = (e) => {
    if (this.state.selectAlluser == false) {
    }
    let ActiveInActiveValue = 1;
    if (e.currentTarget.name === 'InActive') {
      ActiveInActiveValue = 0;
    }
    var userstatus = ActiveInActiveValue
    this.setState({ selectionuser: [] });
    var checkeduserdata = [];
    $.each($("input[type='checkbox']:checked"), function () {
      if ($(this).val() != 'on' && $(this).val() != 'NaN') {
        checkeduserdata.push(parseInt($(this).val()))
      }
    });

    if (checkeduserdata.length > 0) {
      this.props.adminUser.map(person => {
        if (checkeduserdata.includes(person.userId)) {
          var usersdata = {};
          Object.assign(usersdata, { roleID: person.userRoleMatrixList[0].roleId.roleId });
          Object.assign(usersdata, { userID: person.userId });
          Object.assign(usersdata, { partyID: person.partyID });
          Object.assign(usersdata, { isActive: userstatus });
          this.setState({ selectionuser: this.state.selectionuser.push(usersdata), })
        }
      })

      ProductService.bulkupdateuserstatus(this.state.selectionuser, res => {
        if (res.status === "success") {
          success("User Status Updated successfully", successNotification);
          this.props.fetchAdminUser('limit=-1');
        }
        else {
          error(res.reasonText, errorNotification);
          this.props.fetchAdminUser('limit=-1');
        }
      });
    }
  }

  render() {

    console.log("PROPS: ", this.props.adminUser);
    return (
      <React.Fragment>
        <PopUpPortal
          HidePopup={this.state.loading}
          IsVisible={this.state.loading}
        />
        <section class="dashboard-tab user-tab pt-80 mb-50">
          <div class="padding-rl-50">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="table-header">
                    <h3>
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                          <span class="left-icon">
                            <i class="fa fa-list" aria-hidden="true"></i>
                          </span>{" "}
                          User List
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12">
                          <span class="right-icon">
                            <a title="Export">
                              <i
                                onClick={() => this.exportToCSV()}
                                class="fa fa-file-excel-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span>
                          <span class="right-icon">
                            {/* <a href="/userbulkupload"> */}
                            <a title="Bulk Upload" onClick={this.userbulk}>
                              <i class="fa fa-upload" aria-hidden="true"></i>
                            </a>
                          </span>
                          {/* <span class="right-icon">
                            <a title="Add User" onClick={this.adminur}>
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </a>
                          </span> */}
                          <span class="right-icon">
                            {/* <a href="/admin-user-profile"> */}
                            <a title="Bulk Active" name="Active" onClick={this.bulkupdateuserstatus}>
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </a>
                          </span>
                          <span class="right-icon">
                            {/* <a href="/admin-user-profile"> */}
                            <a title="Bulk In-Active" name="InActive" onClick={this.bulkupdateuserstatus}>
                              <i class="fa fa-close" aria-hidden="true"></i>
                            </a>
                          </span>
                          {/* <span class="right-icon">
                            <button class="btn-5 mr-20 ml-20 mt-10" type="button" value="1" onClick={this.bulkupdateuserstatus}> Bulk Active </button>
                          </span>
                          <span class="right-icon">
                            <button class="btn-5 mr-20 ml-20 mt-10" type="button" value="0" onClick={this.bulkupdateuserstatus}> Bulk Inactive </button>
                          </span> */}
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div class="bg-box">
                    <div class="table-responsive">
                      {/* {this.props.adminUser[0]["userRoleMatrixList"] !== undefined ?  */}
                      <ReactTable
                        minRows={2}
                        data={this.props.adminUser === undefined ? [] : this.props.adminUser.length > 0 ? this.props.adminUser.filter(
                          role => role.userRoleMatrixList[0].roleId.roleId != 1
                        ) : []}
                        filtered={this.state.filtered}
                        onFilteredChange={filtered => {
                          this.setState({
                            filtered: filtered,
                            griddata: this.props.adminUser.filter(
                              role => role.userRoleMatrixList[0].roleId.roleId != 1
                            ),
                          });
                        }}

                        className="-striped -highlight"
                        filterable
                        onPageChange={this.stateonpagechange}
                        defaultFilterMethod={this.filterCaseInsensitive}
                        ref={(refReactTable) => { this.refReactTable = refReactTable; }}

                        columns={[
                          {
                            columns: [
                              {
                                Header: (
                                  <input
                                    label='check '
                                    type='checkbox'
                                    name="selectall"
                                    value="selectall"
                                    onChange={this.checkAlluserHandler}
                                    checked={this.state.selectAlluser === true ? 'checked' : ""}
                                  />
                                ),
                                id: 'checkbox5',
                                width: 30,
                                sortable: false,
                                filterable: false,
                                accessor: d => d.userId,
                                Cell: props => {

                                  let status = <input type="checkbox" name={props.original.userId}
                                    value={props.original.userId} defaultChecked={this.state.selectAlluser}
                                    onChange={e => this.handleCheck(props.original)} />


                                  return (
                                    <div>

                                      {status}
                                    </div>
                                  );

                                }
                              },
                              {
                                Header: "User ID",
                                id: "partyID",
                                accessor: d => d.partyID.toString()
                              },
                              {
                                Header: "User Name",
                                id: "fullName",
                                accessor: d => d.name
                              },

                              {
                                Header: "Role",
                                id: "roles",
                                accessor: d =>
                                  d.userRoleMatrixList[0].roleId.roleName
                              },

                              {
                                Header: "Email",
                                id: "activeForm",
                                accessor: d => d.emailId
                              },

                              {
                                Header: "Created On",
                                id: "createdDate",
                                accessor: d => d.createdDate ? d.createdDate.split(' ')[0] : ''
                              },

                              {
                                Header: "Last Accessed On",
                                id: "lastLogin",
                                accessor: d => d.lastLoginDateTime ? d.lastLoginDateTime : ''
                              },
                              {
                                Header: "Expiration Date",
                                id: "activeTill",
                                accessor: d => d.expiryDate ? d.expiryDate.split(' ')[0] : ' '
                              },

                              {
                                Header: "Status",
                                id: "isActive",
                                filterable: false,
                                sortable: false,
                                Cell: props => {

                                  return (
                                    <div>

                                      <Toggle
                                        icons={false}
                                        defaultChecked={
                                          parseInt(props.original.isActive) == 1
                                            ? true
                                            : false
                                        }
                                        onChange={e =>
                                          this.handleStatus(e, props.original)
                                        }
                                      />
                                    </div>
                                  );
                                }
                              },
                              {
                                Header: "Block/Unblock",
                                id: "loginAttempt",
                                filterable: false,
                                sortable: false,
                                //accessor: d => d.loginAttempt,        
                                Cell: props => {
                                  return (
                                    <Toggle
                                      icons={false}
                                      defaultChecked={
                                        props.original.loginAttempt >= 3
                                          ? false
                                          : true
                                      }
                                      onChange={e =>
                                        this.handleBlock(e, props.original)
                                      }
                                    />
                                  );
                                }
                              },
                              {
                                Header: "Action",
                                id: "action",
                                filterable: false,
                                sortable: false,
                                Cell: props => {
                                  return (
                                    <div>
                                      <a class="mar-left">
                                        {
                                          //props.original.isActive == 1 ?
                                          <i
                                            title="Send Email"
                                            className="fa fa-paper-plane"
                                            aria-hidden="true" data-toggle="modal"
                                            data-keyboard="true"
                                            data-target="#modal-send-mail"
                                            onClick={() =>
                                              this.sendEmail(props.original)
                                            }
                                          ></i>
                                          //: ''
                                        }
                                      </a>
                                      {/* <a class="mar-left">
                                        {parseInt(props.original.isActive) == 1 ?
                                          <i
                                            title="Edit Profile"
                                            className="fa fa-edit fa-2x"
                                            aria-hidden="true"
                                            onClick={() =>
                                              this.handleClick(props.original)
                                            }
                                          ></i>
                                          : ''
                                        }
                                      </a> */}
                                      <a class="mar-left">
                                        {(parseInt(props.original.isActive) == 1 && props.original.userRoleMatrixList[0].roleId.roleName === 'DISTRIBUTOR') ?
                                          <i
                                            title="Assign Course"
                                            className="fa fa-plus fa-2x"
                                            aria-hidden="true"
                                            onClick={() =>
                                              this.handleaddCourse(props.original)
                                            }
                                          ></i> : ''

                                        }

                                      </a>
                                      <a class="mar-left">
                                        {(parseInt(props.original.isActive) == 1 && props.original.userRoleMatrixList[0].roleId.roleName === 'DISTRIBUTOR') ?
                                          <i
                                            title="Change Password"
                                            className="fa fa-lock"
                                            aria-hidden="true"
                                            onClick={() =>
                                              this.handleChangePassword(props.original)
                                            }
                                          ></i> : ''

                                        }

                                      </a>
                                    </div>
                                  );
                                }
                              },

                            ]
                          }
                        ]}
                        defaultPageSize={10}
                        style={{
                          width: "100%",
                          maxHeight: "500px"
                        }}
                      />
                      {/* : ''} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Open add modules popup   */}
          <div class="modal fade" id="modal-send-mail" tabindex="-1" role="dialog" aria-labelledby="modalLabel" data-keyboard="true" aria-hidden="true">
            <div class="modal-dialog modal-md" role="document">
              <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    subject: '',
                    emailId: '',
                    message: ''
                  }}
                  validationSchema={Yup.object().shape({
                    //static validations
                    subject: Yup.string()
                      .required('You must specify a subject'),
                    emailId: Yup.string()
                      .email('Invalid E-Mail'),
                    //.required('You must specify a E-Mail Id'),
                    message: Yup.string()
                      .required('You must specify a Message'),
                  }
                  )}

                  // onSubmit={(values, actions) => {
                  //   //console.log("Submitted :" + JSON.stringify(values));
                  // }}
                  onSubmit={(fields, { resetForm }) => {
                    //console.log("field", fields.subject)
                    var adminPartyid = localStorage.getItem("userid-Token");
                    //console.log("adminPartyid", adminPartyid)
                    let data = {
                      fullName: this.state.dat,
                      emailID: this.state.dat2,
                      enquirySubject: fields.subject,
                      ccemailList: fields.emailId,
                      enquiryMessage: fields.message,
                      learnerPartyID: this.state.stud,
                      adminPartyID: adminPartyid,
                      enquiryType: 2
                    }

                    //console.log("dataemail", data)
                    GeneralEnquiryService.PostEnquiry(data, res => {
                      //console.log("res", res)
                      if (res.status === "success") {
                        success(
                          "Message has been delivered to the user successfully.",
                          successNotification
                        );
                        $(document).ready(function () {
                          $("#close").click();
                        });
                        resetForm({});
                      }
                    });
                  }}

                  render={({
                    errors, touched, handleSubmit
                  }) => (
                    <React.Fragment>
                      <Form onSubmit={handleSubmit} className="form-group">
                        <section class="updateGDPI">
                          <div class="modal-body">
                            <div class="row">
                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <div class="product-add">
                                  <div class="form-group">
                                    <div class="row">
                                      <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>
                                          Subject
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="subject"
                                          id="subject"
                                          autoComplete="subject"
                                          className={
                                            "form-control" +
                                            (errors.subject &&
                                              touched.text
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Subject"
                                          className="form-control"
                                        />
                                        <ErrorMessage
                                          name="subject"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                      <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>
                                          CC
                                          </label>
                                        <Field
                                          type="emailId"
                                          name="emailId"
                                          id="emailId"
                                          autoComplete="emailId"
                                          className={
                                            "form-control" +
                                            (errors.emailId &&
                                              touched.emailId
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="CC"
                                        />
                                        <ErrorMessage
                                          name="emailId"
                                          component="div"
                                          className="validation"
                                        />
                                      </div>
                                      <div class="col-md-12 col-sm-12 col-xs-12">
                                        <label>
                                          Message
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field as="textarea" rows="8" cols="50" name="message"
                                          id="message"
                                          autoComplete="message"
                                          className={
                                            "form-control" +
                                            (errors.message && touched.message
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="message"
                                          className="validation"
                                          component="div"
                                        />
                                        {/* <textarea rows="8" cols="50" class="form-control"></textarea> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">
                                    <button
                                      type="submit"
                                      class="btn-4 pull-right"
                                    >Send</button>
                                    <button
                                      type="button"
                                      id="close"
                                      class="btn-4 mr-20 pull-right"
                                      data-dismiss="modal" aria-label="Close"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </Form>
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AdminListLandingPage;
