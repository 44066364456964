import { FETCH_PREASSESSMENT_INSTRUCTION, FETCH_PREASSESSMENT_TERMS_CONDITION } from '../action/preAssessmentAction';

const initialState = {
    pending: false,
    preInstruction: [],
    preTestTermCondition : [],
    error: null
}

const PreAssessmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PREASSESSMENT_INSTRUCTION:
            return {
                ...state,
                pending: false,
                preInstruction: action.payload
            }
        case FETCH_PREASSESSMENT_TERMS_CONDITION:
        return {
            ...state,
            pending: false,
            preTestTermCondition: action.payload
        }
        
        default:
            return state;
    }
}
export default PreAssessmentReducer;

export const getPreAssessmentInstruction = state => state.preInstruction;
export const getPreAssessmentTermAndCondition = state => state.preTestTermCondition;
