import React, { Component } from "react";
import DashboardHeader from "../../components/dashboardheader/dashboardheader";
import Finxfooter from "../finxfooter/finxfooter";
import ViewRoles from "../../components/roles/view-roles";
import { connect } from "react-redux";
import { getRoles, getRolesPending } from "../../reducer/roleReducer";
import { bindActionCreators } from "redux";
import { fetchRoles, addRoles, editRoles } from "../../services/fetchRoles";
import { LoginService } from "../../services/login.service";
import AdminHeader from "../../components/dashboardheader/adminheader";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { getAlertNotificationEvents, getAlertNotificationEventsPending, getAlertNotificationEventsError } from "../../reducer/alertsNotificationReducer";
import { getNotificationEvents, getNotificationEventsPending, getNotificationEventsError } from "../../reducer/notificationReducer";
import { fetchAlertNotificationEvents, fetchNotificationEvents } from "../../services/fetchLearnerDashboard.service";


import $ from "jquery";

const mapStateToProps = state => ({
  roles: getRoles(state.roleReducer),
  pending: getRolesPending(state.roleReducer),

  alertNotificationEvents: getAlertNotificationEvents(
    state.alertNotificationEventsReducer
  ),
  pendingAlertNotificationEvents: getAlertNotificationEventsPending(
    state.alertNotificationEventsReducer
  ),
  errorAlertNotificationEvents: getAlertNotificationEventsError(
    state.alertNotificationEventsReducer
  ),

  notificationEvents: getNotificationEvents(state.notificationEventsReducer),
  pendingNotificationEvents: getNotificationEventsPending(
    state.notificationEventsReducer
  ),
  errorNotificationEvents: getNotificationEventsError(
    state.notificationEventsReducer
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchRoles: fetchRoles,
      addRole: addRoles,
      editRole: editRoles,
      fetchAlertNotificationEvents: fetchAlertNotificationEvents,
      fetchNotificationEvents: fetchNotificationEvents,
    },
    dispatch
  );

const connectFunction = connect(mapStateToProps, mapDispatchToProps);

const roles = connectFunction(
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }


    componentDidMount() {

      const { fetchRoles, fetchAlertNotificationEvents,
        fetchNotificationEvents, } = this.props;

      let partyID = localStorage.getItem("userid-Token");

      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          let params = "limit=-1"
          fetchRoles(params);

        }
      });
      fetchAlertNotificationEvents();
      fetchNotificationEvents("partyId=" + partyID);
    }

    render() {
      const { roles, pending,
        alertNotificationEvents,
        notificationEvents,
      } = this.props;

      //console.log("Props data : " , roles)
      if (pending) {
        return (
          <React.Fragment>
            <PopUpPortal
              HidePopup={this.state.loading}
              IsVisible={this.state.loading}
            />
            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <ViewRoles
              roles={roles}
              addRole={this.props.addRole}
              editRole={this.props.editRole}
              fetchRoles={fetchRoles}
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <AdminHeader
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <ViewRoles
              roles={roles}
              addRole={this.props.addRole}
              editRole={this.props.editRole}
              fetchRoles={fetchRoles}
              //alertNotificationEvents={alertNotificationEvents}
              //notificationEvents={notificationEvents}
              {...this.props}
            />
            <Finxfooter />
          </React.Fragment>
        );
      }
    }
  }
);

export default roles;
