export const FETCH_NOTIFICATION_EVENTS_PENDING = 'FETCH_NOTIFICATION_EVENTS_PENDING';
export const FETCH_NOTIFICATION_EVENTS_SUCCESS = 'FETCH_NOTIFICATION_EVENTS_SUCCESS';
export const FETCH_NOTIFICATION_EVENTS_ERROR = 'FETCH_NOTIFICATION_EVENTS_ERROR';

export function fetchNotificationEventsPending() {
    return {
        type: FETCH_NOTIFICATION_EVENTS_PENDING
    }
}   

export function fetchNotificationEventsSuccess(notification_events) {
    return {
        type: FETCH_NOTIFICATION_EVENTS_SUCCESS,
        payload: notification_events
    }
}

export function fetchNotificationEventsError(error) {
    return {
        type: FETCH_NOTIFICATION_EVENTS_ERROR,
        error: error
    }
}