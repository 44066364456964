export const FETCH_USER_PROFILE_PERSONNEL_DATA =
  "FETCH_USER_PROFILE_PERSONNEL_DATA";
  export const FETCH_USER_PROFILE_ADDRESS_DATA =
  "FETCH_USER_PROFILE_ADDRESS_DATA";

export function fetchUserProfileData(personnelData) {
  return {
    type: FETCH_USER_PROFILE_PERSONNEL_DATA,
    payload: personnelData
  };
}

export function fetchUserAddressData(addressData) {
  return {
    type: FETCH_USER_PROFILE_ADDRESS_DATA,
    payload: addressData
  };
}
