import { FETCH_STATUS_COUNT_ACTION_PENDING, FETCH_STATUS_COUNT_LIST_ACTION_SUCCESS, FETCH_STATUS_COUNT_ACTION_ERROR } from '../action/funnelStatusCountAction';

const initialState = {
    
    pending: false,
    statusCountList: [],
    error: null
}

const funnelStatusCountReducer = (state = initialState, action) => {
   
    switch (action.type) {
        case FETCH_STATUS_COUNT_ACTION_PENDING:
            return {
                ...state,
                pending: true,
            }
        case FETCH_STATUS_COUNT_LIST_ACTION_SUCCESS:
            return {
                ...state,
                pending: false,
                statusCountList: action.payload,
              
            }
        case FETCH_STATUS_COUNT_ACTION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,
            }
        default:
            return state;
    }
}
export default funnelStatusCountReducer;
export const getStatusCount = state => state.statusCountList;
export const getStatusCountPending = state => state.pending;
export const getStatusCountError = state => state.error;
