import React, { Component } from 'react';
import Finxlogo from '../../assets/images/dapIcon.png';
import MyTour from './mytour';

class WelcomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taketour: false
        }
    }
    componentDidMount() {

    }
    tourHandler = event => {
        event.preventDefault();
        this.setState({ taketour: true });
    }
    render() {

        return (
            <React.Fragment>
                {/* {this.state.taketour ? <MyTour {...this.props} />
                    : */}
                <section class="welcome-page">
                    <div class="container">
                        <div id="welcome">
                            <div class="welcome">
                                <h2 class="welcome_title"> Welcome to</h2>
                                <div class="welcome-logo">
                                    <img src={Finxlogo} class="img-responsive center-block" width="250px" height="auto" />
                                    <br />
                                    <p>
                                        Your gateway to a great career in BFSI industry
                                        </p>
                                </div>
                                <br />
                                <a href="#" onClick={this.tourHandler}> <button type="submit" class="btn-1 mt-100">Take a Tour</button></a>
                            </div>
                        </div>
                    </div>
                </section>}
            </React.Fragment >
        );
    }

}

export default WelcomePage;


