import {
  FETCH_USER_PROFILE_PERSONNEL_DATA,
  FETCH_USER_PROFILE_ADDRESS_DATA
} from "../action/userProfile";

const initialState = {
  pending: false,
  personnelData: [],
  addressData: [],
  error: null
};

const userProfileReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_PERSONNEL_DATA:
      return {
        ...state,
        pending: false,
        personnelData: action.payload
      };
    case FETCH_USER_PROFILE_ADDRESS_DATA:
      return {
        ...state,
        pending: false,
        addressData: action.payload
      };
    default:
      return state;
  }
};

export default userProfileReducers;
export const getPersonnelData = state => state.personnelData;
export const getPersonnelAddressData = state => state.addressData;
