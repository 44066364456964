import React, { Component } from 'react';
import CanvasJSReact from '../../assets/Charts/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
 
class FunnelChart extends Component {
	render() {
		var dataPoint;
		var total;
		const options = {
			animationEnabled: true,
			theme: "light1", //"light1", "dark1", "dark2"
			// title:{
			// 	text: "Distributor Analysis"
			// },
			data: [{
				type: "pyramid",
				reversed: true,
				neckWidth: "0",
				neckHeight: "0",
				toolTipContent: "<b>{label}</b>: {y} <b>({percentage}%)</b>",
				indexLabelPlacement: "outside",
				indexLabel: "{label} ({percentage}%)",
				indexLabelFontColor: "Black",
				dataPoints: [
					{ y: 500, label: "Profile Created",  color: "#1268b7"  },
					{ y: 312, label: "NISM Fees Paid", color: "#dc7e20", exploded: true},
					{ y: 800, label: "Course Access Provided",color: "#509b1b", exploded: true },
					{ y: 278, label: "NISM Exam",color: "#9f2668", exploded: true },
					{ y: 465,  label: "NISM Profile Created",color: "#1290ad", exploded: true },
				]
			}]
		}
		//calculate percentage
		dataPoint = options.data[0].dataPoints;
		total = dataPoint[0].y;
		for(var i = 0; i < dataPoint.length; i++) {
			if(i === 0) {
				options.data[0].dataPoints[i].percentage = 100;
			} else {
				options.data[0].dataPoints[i].percentage = ((dataPoint[i].y / total) * 100).toFixed(2);
			}
		}
		
		return (
		<div>
			<CanvasJSChart options = {options} 
				/* onRef={ref => this.chart = ref} */
			/>
			{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
		</div>
		);
	}
}

export default FunnelChart;