import React from 'react';
import { Link } from 'react-router-dom';
import './Unauthorized.css';

const Unauthorized = () => {
  return (
    <div className='container-unauthorized'>
      <div class="message-unauthorized">
        <h1>403 - You are not Authorized to access this Page</h1>
        <p>Uh oh, Gandalf is blocking the way!<br />Maybe you have a typo in the url? Or you meant to go to a different location? Like...Hobbiton?</p>
      </div>
      {/* <p><Link to='/'>Back to Home</Link></p> */}
    </div>
  )
}

export default Unauthorized;