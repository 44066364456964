import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import { addAdminUser } from "../../services/admin.service";
import { ProductService, fe } from "../../services/product.service";
import FileBase64 from "react-file-base64";
import ReactPlayer from "react-player";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { Global_var } from "../../global/global_var";
//import Dropzone from '../dropzone/Dropzone';
import $ from "jquery";
import {
  success,
  warning,
  successNotification,
  error,
  errorNotification,
  warningNotification
} from "../notification/notifications";
import { LoginService } from "../../services/login.service";
import { RegionManagementService } from "../../services/regionManagement.service";
import { RegisterUserService } from "../../services/registration.service";

class AddProduct extends Component {
  constructor(props) {

    super(props);
    //console.log("in my product", props)

    this.state = {
      loading: false,
      "autoplay": false,
    }
  }

  componentWillMount() {
    LoginService.TokenUser(res => {
      localStorage.setItem("jwt-token", res.responseObject);
    });
  }

  handleUpload = (ev) => {
    ev.document.forEach(element => {
      let data1 = {

        documentType: element.documentType,
        documentKey: localStorage.getItem("userid-Token"),
        documentName: element.documentName,
        replaceIfExists: "1",
        documentHash: element.documentHash,
        storageLocation: "AWS",
        partyId: localStorage.getItem("userid-Token"),
        buId: "2",
        bucketTag: "Dap-bucket-tag",
        documentTag: element.documentType
      }
      ProductService.addProductImageVideo(
        data1,
        cb => {

          if (cb.responseObject.documentType == "ProductThumbNail") {
            localStorage.setItem('prdthumbnail', cb.responseObject.documentPath)
          }
          if (cb.responseObject.documentType == "ProductVideourl") {
            localStorage.setItem('prdvideourl', cb.responseObject.documentPath)
          }
          //console.log("CB", cb.status);
          if (cb.status === "success") {

          } else {
            // error(cb.reasonText, {
            //   ...errorNotification,
            //   container: "top-center"
            // });
            warning(cb.reasonText, {
              ...warningNotification,
              container: "top-center"
            })
          }
        },
        err => console.log("Error", err)
      );
    });


  }
  handleCancel = () => {
    this.props.history.goBack('-1');
    //this.props.history.push("/course-list");
  };


  render() {
    const urls = (/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)+$/);
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          today: moment(new Date()).format("YYYY-MM-DD"),
          products: "",
          productName: "",
          productThumbnail: "",
          productLink: "",
          partnerPartyID: "",
          partner: "",

          isParentProduct: "",
          parentProductID: "",

          //buid: 2,
          productAnchor: "",
          productSubtitle: "",
          productLanguage: "",
          productDescription: "",
          productTypeList: this.props.productType,
          partnerList: this.props.partner,
          productType: "",
          productCode: "",
          productPrice: "",
          isActive: "",
          createdBy: "",
          createdDate: "",
          lastModifiedBy: "",
          lastModifiedDate: "",
          productMinPassingScore: "",
          totalScore: "",
          partnerID: "",
          productID: "",
          providerProductID: "",
          role: "",
          status: "",
          statusList: this.props.status,
          hasModules: "",
          isOptional: "",
          isDisplayinCourseCatalog: "",
          reAttemptPrice: "",
          // hasModulesList: this.props.hasModule,

          currentState: "add",

          ProductThumbNailURL: "",
          documentTypeCheckForProductTImage: false,
          document: [],

          ProductVideoURL: "",
          documentTypeCheckForVideo: false,
          documentSizeCheckForVideoCheck: false,

        }}
        validationSchema={Yup.object().shape({
          //static validations
          productName: Yup.string().required("You must specify a Product Name"),
          productType: Yup.string().required("You must specify a Product Type"),
          productCode: Yup.string(),
          // productLink: Yup.string().url().matches("^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"),
          productLink: Yup.string().matches(urls, { message: "Enter valid url", excludeEmptyString: false }),
          partner: Yup.string().required("You must specify a Partner"),
          productAnchor: Yup.string(),
          productSubtitle: Yup.string(),
          //productLanguage: Yup.string().required("You must specify a Product Language"),
          productPrice: Yup.string().matches(
            /^[0-9]+\d$/,
            "Please provide valid product price"
          ).required("You must specify a product price"),
          productMinPassingScore: Yup.string().matches(
            /^[0-9]+\d$/,
            "Please provide valid minimum passing score"
          ),
          totalScore: Yup.string().matches(
            /^[0-9]+\d$/,
            "Please provide valid score"
          ),
          // reAttemptPrice: Yup.string().matches(
          //   /^[0-9]+\d$/,
          //   "Please provide valid reattempt price"
          // ).required("You must specify a reattempt price"),
          status: Yup.string().required("You must specify a Status"),
          //productDescription: Yup.string().required("Product Description  is required"),

        })}

        onSubmit={values => {
          this.setState({ loading: true });
          this.handleUpload(values);
          setTimeout(() => {


            let hasModule = ''
            if (values.hasModules == true) {
              hasModule = 1
            }
            else {
              hasModule = 0
            }

            let isOptional = ''
            if (values.isOptional == true) {
              isOptional = 1
            }
            else {
              isOptional = 0
            }

            let isDisplayinCourseCatalog = ''
            if (values.isDisplayinCourseCatalog == true) {
              isDisplayinCourseCatalog = 1

            }
            else {
              isDisplayinCourseCatalog = 0

            }

            let today = new Date();



            let data = {
              products: values.products,
              productName: values.productName.trim(),
              productLink: values.productLink.trim(),
              partnerPartyID: values.partnerPartyID,
              partner: values.partner,
              partnerID: values.partner,
              isParentProduct: values.isParentProduct,
              parentProductID: values.parentProductID,
              hasModules: hasModule,
              isOptional: isOptional,
              isDisplayinCourseCatalog: isDisplayinCourseCatalog,
              //buid: values.buid,
              productAnchor: values.productAnchor.trim(),
              productSubtitle: values.productSubtitle.trim(),
              productLanguage: values.productLanguage,
              productDescription: values.productDescription.trim(),
              productType: values.productType,
              productCode: values.productCode,
              productPrice: values.productPrice,
              isActive: values.status,
              createdDate: values.createdDate ? moment(today).format('YYYY-DD-MM') : values.createdDate,
              lastModifiedBy: values.lastModifiedBy,
              productMinPassingScore: values.productMinPassingScore,
              totalScore: values.totalScore,
              status: values.status,
              productTypeList: values.productTypeList,
              partnerList: values.partnerList,
              statusList: values.statusList,
              productReattemptPrice: values.reAttemptPrice,
              productThumbnail: localStorage.getItem('prdthumbnail'),
              document: values.document,
              productVideourl: localStorage.getItem('prdvideourl'),

              createdBy: localStorage.getItem('userid-Token'),

            };
            //console.log(JSON.stringify(data));
            //console.log("DATA : ", data);


            ProductService.addProduct(
              data,
              cb => {
                //console.log("CB", cb.status);
                if (cb.status === "success") {
                  //console.log("CB.STATUS", cb.status);
                  this.setState({ loading: false });
                  success(
                    "Course added successfully!",
                    successNotification
                  );
                  // localStorage.removeitem('prdvideourl');
                  // localStorage.removeitem('prdthumbnail');
                  this.props.history.push("/course-list");


                } else {
                  // error(cb.reasonText, {
                  //   ...errorNotification,
                  //   container: "top-center"
                  // });
                  warning(cb.reasonText, {
                    ...warningNotification,
                    container: "top-center"
                  })
                  this.setState({ loading: false });
                }
              },
              err => console.log("Error", err)

            );
          }, 7000);
        }}
        render={({ errors, touched, handleSubmit, handleChange, values, setFieldValue, ...formikProps }) => (
          <React.Fragment>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section class="dashboard-tab user-profile pt-80 mb-50">
              <Form onSubmit={handleSubmit}
                onKeyDown={onKeyDown => {
                  if (
                    (onKeyDown.charCode || onKeyDown.keyCode) ===
                    13 &&
                    onKeyDown.target.name != "productType" &&
                    onKeyDown.target.name != "status" &&
                    onKeyDown.target.name != "partner" &&
                    onKeyDown.target.name != "productLanguage" &&
                    onKeyDown.target.name != "submitForm" &&
                    onKeyDown.target.name != "cancelForm"
                  ) {
                    onKeyDown.preventDefault();
                  }
                }}

                className="form-group">
                <div class="">
                  <div class="container">
                    <div class="row">


                      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pt-130">

                        <div class="bg-box">
                          <section class="create-user" id="create-user">
                            <div class="row">
                              <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <h2 class="sub-header">Course Details</h2>
                                <div class="form-group">
                                  <div class="row">
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Type{" "}
                                        <span className="validation">
                                          {" "}
                                          *
                                            </span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="productType"
                                        id="productType"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productType && touched.productType
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>
                                        {values.productTypeList.map(team => (

                                          <option
                                            key={team.codeValueId}
                                            value={team.codeValueId}
                                          >
                                            {team.codeValue}
                                          </option>

                                        ))}

                                      </Field>
                                      <ErrorMessage
                                        name="productType"
                                        component="div"
                                        className="validation"
                                      />

                                    </div>
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Status{" "}
                                        <span className="validation">
                                          {" "}
                                          *
                                            </span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="status"
                                        id="status"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.status && touched.status
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>

                                        {values.statusList.map(team => (
                                          <option
                                            key={team.codeValueId}
                                            value={team.codeValueId}
                                          >
                                            {team.codeValue}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="status"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>


                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Name{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="productName"
                                        id="productName"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productName &&
                                            touched.productName
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Course Name"
                                      />
                                      <ErrorMessage
                                        name="productName"
                                        className="validation "
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Code{" "}

                                      </label>
                                      <Field
                                        type="text"
                                        name="productCode"
                                        id="productCode"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productCode &&
                                            touched.productCode
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Course Code"
                                      />
                                      <ErrorMessage
                                        name="productCode"
                                        className="validation "
                                        component="div"
                                      />
                                    </div>


                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Link

                                      </label>
                                      <Field
                                        type="text"
                                        name="productLink"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productLink &&
                                            touched.productLink
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Course Link"
                                      />
                                      <ErrorMessage
                                        name="productLink"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>

                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Partner  {" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        as="select"
                                        name="partner"
                                        id="partner"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.partner &&
                                            touched.partner
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>
                                        {values.partnerList.map(team => (
                                          <option
                                            key={team.partnerID}
                                            value={team.partnerID}
                                          >
                                            {team.partnerName}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="partner"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row">


                                    {/* <div class="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                          BUID
                                          <span className="validation"> *</span>
                                          </label>
                                        <Field
                                          type="text"
                                          name="buid"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.buid &&
                                            touched.buid
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="buid"
                                        />
                                        <ErrorMessage
                                          name="buid"
                                          className="validation"
                                          component="div"
                                        />
                                      </div> */}
                                    {/* <div class="col-md-4 col-sm-6 col-xs-12">
                                        <label>
                                        HasModules
                                          <span className="validation"> *</span>
                                        </label>
                                      <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="sameAsAbove"
                                              // checked={
                                              //   this.state.checkAddressFlag
                                              // }
                                              // onClick={
                                              //   this.assignPerAddressSameAsCores
                                              // }
                                            />
                                            </div> */}

                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Anchor

                                      </label>
                                      <Field
                                        type="text"
                                        name="productAnchor"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productAnchor &&
                                            touched.productAnchor
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Course Anchor"
                                      />
                                      <ErrorMessage
                                        name="productAnchor"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Subtitle

                                      </label>
                                      <Field
                                        type="text"
                                        name="productSubtitle"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productSubtitle &&
                                            touched.productSubtitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Course Subtitle"
                                      />
                                      <ErrorMessage
                                        name="productSubtitle"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Language{" "}
                                      </label>
                                      <Field
                                        as="select"
                                        name="productLanguage"
                                        id="productLanguage"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productLanguage && touched.productLanguage
                                            ? " is-invalid"
                                            : "")
                                        }
                                      >
                                        <option value="">--Select--</option>
                                        {/* {values.productLanguage.map(team => (
                                                <option
                                                  key={team.productLanguageId}
                                                  value={team.productLanguageId}
                                                >
                                                  {team.productLanguage}
                                                </option>
                                              ))} */}
                                        <option value="1">English</option>
                                      </Field>
                                      <ErrorMessage
                                        name="productLanguage"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                  </div>
                                  <div class="row">

                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Price
                                          <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="productPrice"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productPrice &&
                                            touched.productPrice
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Course Price"
                                      />
                                      <ErrorMessage
                                        name="productPrice"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    {/* <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Minimum Passing Score
                                      </label>
                                      <Field
                                        type="text"
                                        name="productMinPassingScore"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productMinPassingScore &&
                                            touched.productMinPassingScore
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Minimum Passing Score"
                                      />
                                      <ErrorMessage
                                        name="productMinPassingScore"
                                        className="validation"
                                        component="div"
                                      />
                                    </div> */}
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Reattempt Price
                                      </label>
                                      <Field
                                        type="text"
                                        name="reAttemptPrice"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.reAttemptPrice &&
                                            touched.reAttemptPrice
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Reattempt Price"
                                      />
                                      <ErrorMessage
                                        name="reAttemptPrice"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Total Score
                                      </label>
                                      <Field
                                        type="text"
                                        name="totalScore"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.totalScore &&
                                            touched.totalScore
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Total Score"
                                      />
                                      <ErrorMessage
                                        name="totalScore"
                                        className="validation"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <Field
                                        type="checkbox"
                                        name="isDisplayinCourseCatalog"
                                        onChange={handleChange} >
                                      </Field>
                                      <label className="form-field" htmlFor="isDisplayinCourseCatalog">
                                        <span>&nbsp;Visible</span>
                                      </label>
                                      <div className="form-field-error">{errors.isDisplayinCourseCatalog}</div>
                                    </div>

                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <Field
                                        type="checkbox"
                                        name="hasModules"
                                        onChange={handleChange}
                                      />
                                      <label className="form-field" htmlFor="hasModules">
                                        <span>&nbsp;Module</span>
                                        {/* <input
                                                    name="hasModules"
                                                    type="checkbox"
                                                    onChange={handleChange}
                                                  /> */}


                                      </label>
                                      <div className="form-field-error">{errors.hasModules}</div>
                                    </div>
                                    <div class="col-md-4 col-sm-6 col-xs-12">
                                      <Field
                                        type="checkbox"
                                        name="isOptional"
                                        onChange={handleChange}
                                      />
                                      <label className="form-field" htmlFor="isOptional">
                                        <span>&nbsp;Is Optional</span>
                                        {/* <input
                                                    name="hasModules"
                                                    type="checkbox"
                                                    onChange={handleChange}
                                                  /> */}


                                      </label>
                                      <div className="form-field-error">{errors.isOptional}</div>
                                    </div>
                                    {/* <div class="col-md-4 col-sm-6 col-xs-12">
                                      <label>
                                        Product Thumbnail{" "}
                                        <span className="validation"> *</span>
                                      </label>
                                      <Field
                                        type="text"
                                        name="productThumbnail"
                                        id="productThumbnail"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.productThumbnail && touched.productThumbnail
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Product Thumbnail"
                                      />
                                      <ErrorMessage
                                        name="productThumbnail"
                                        className="validation"
                                        component="div"
                                      />
                                    </div> */}

                                  </div>
                                  <div class="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>Thumbnail</label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <img
                                            className="profile-pic"
                                            src={values.ProductThumbNailURL}
                                          />
                                          <div className="profile">
                                            <i
                                              className="fa fa-user"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="profile-add">
                                        <span className="icon btn-file text-center">
                                          <i
                                            className="fa fa-plus-circle"
                                            aria-hidden="true"
                                          ></i>
                                          <FileBase64
                                            multiple={true}
                                            onChange={handleChange}
                                            onDone={event => {
                                              if (
                                                [
                                                  "image/jpg",
                                                  "image/jpeg",
                                                  "image/png"
                                                ].indexOf(event[0].type) < 0
                                              ) {
                                                setFieldValue((values.documentTypeCheckForProductTImage = true));
                                              } else if (
                                                event[0].file.size <= 5000000
                                              ) {
                                                values.document.push({
                                                  documentHash: event[0].base64,
                                                  documentType: "ProductThumbNail",
                                                  documentName: event[0].name.split('.')[0] + ".png"
                                                });
                                                setFieldValue((values.documentTypeCheckForProductImage = false));
                                                setFieldValue((values.documentSizeCheckForProductCheck = false));
                                                values.ProductThumbNailURL = event[0].base64;
                                                setFieldValue((values.ProductThumbNailURL = event[0].base64));

                                              } else {
                                                setFieldValue(
                                                  (values.documentSizeCheckForProductCheck = true)
                                                );
                                              }

                                              // localStorage.setItem("base64-uploadimage", event[0].base64);
                                            }

                                            }

                                          />
                                        </span>
                                        <h4 >Add Image</h4>

                                        {/* <Dropzone
                                          onFilesAdded={this.handleUpload }
                                         // disabled={this.state.uploading || this.state.successfullUploaded}
                                        /> */}
                                        <div className="col-md-12 col-sm-12">
                                          <span class="file-size-limit">
                                            Supported Formats: .jpg, .jpeg, .png
                                          only. <br />
                                            File Size: Max 5 MB
                                        </span>
                                        </div>
                                        {values.documentSizeCheckForProductCheck ? (
                                          <p style={{ color: "red" }}>
                                            File Size is too large(Maximum File
                                            Size:5MB).
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                        {values.documentTypeCheckForProductImage ? (
                                          <p style={{ color: "red" }}>
                                            Invalid image format
                                          </p>
                                        ) : (
                                            ""
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                      <label>Video url</label>
                                      <div className="profile-detail">
                                        <div className="circle">
                                          <div className="profile-pic">
                                            <div className="profile">
                                              <ReactPlayer
                                                url={values.ProductVideoURL}
                                                // playing
                                                controls
                                                autoPlay
                                              ></ReactPlayer>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="profile-add">
                                          <span className="icon btn-file text-center">
                                            <i
                                              className="fa fa-plus-circle"
                                              aria-hidden="true"
                                            ></i>
                                            <FileBase64
                                              multiple={true}
                                              name="ProductVideoURL"
                                              onChange={handleChange}
                                              onDone={event => {

                                                if (
                                                  [
                                                    "video/mp4",
                                                    "video/avi",
                                                    "video/mpeg"
                                                  ].indexOf(event[0].type) < 0
                                                ) {
                                                  setFieldValue(
                                                    (values.documentTypeCheckForVideo = true)
                                                  );
                                                } else if (
                                                  event[0].file.size <= 10000000
                                                ) {
                                                  values.document.push({
                                                    documentHash: event[0].base64,
                                                    documentType: "ProductVideourl",
                                                    documentName: event[0].name
                                                  });
                                                  setFieldValue((values.documentTypeCheckForVideo = false));
                                                  setFieldValue((values.documentSizeCheckForVideoCheck = false));
                                                  setFieldValue((values.ProductVideoURL = event[0].base64));
                                                } else {
                                                  setFieldValue((values.documentSizeCheckForVideoCheck = true));
                                                }
                                              }}
                                            />
                                          </span>
                                          <h4>Add Video</h4>

                                          {values.documentSizeCheckForVideoCheck ? (
                                            <p style={{ color: "red" }}>
                                              File Size is too large(Maximum File
                                              Size:10MB).
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                          {values.documentTypeCheckForVideo ? (
                                            <p style={{ color: "red" }}>
                                              Invalid Format
                                            </p>
                                          ) : (
                                              ""
                                            )}
                                          <div className="col-md-12 col-sm-12">
                                            <span class="file-size-limit">
                                              Supported Formats: .mp4, .avi, .mpeg
                                            only. <br />
                                              File Size: Max 10 MB
                                          </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <div className="form-group">
                                        <label>Description</label>
                                        <textarea rows="8" cols="50" name="message"
                                          name="productDescription"
                                          id="productDescription"
                                          onChange={handleChange}
                                          autoComplete="productDescription"
                                          className={
                                            "form-control" +
                                            (errors.productDescription && touched.productDescription
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="productDescription"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </section>

                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">

                            <button
                              type="submit"
                              class="btn-4 pull-right"
                              onClick={async () => {
                                const curErrors = await formikProps.validateForm();
                                const curErrorsKeys = Object.keys(
                                  curErrors
                                );

                                if (curErrorsKeys.length) {
                                  // Assuming curErrorsKeys[0] identifies the first failing field, use that to
                                  // find the correct DOM element to call .focus() on.
                                  //
                                  // Since I set the id attribute on all my form's <input>s to match the error
                                  // keys, I just use document.getElementById(), but of course you could use refs
                                  const el = document.getElementById(
                                    curErrorsKeys[0]
                                  );

                                  if (el) el.focus();
                                }
                                if (curErrorsKeys.length) {
                                  const el = document.getElementById(
                                    curErrorsKeys[0]
                                  );

                                  if (el) el.focus();
                                }
                              }}
                            >
                              Submit
                                </button>

                            <a>
                              <button
                                name="cancelForm"
                                type="button"
                                class="btn-4 mr-20 pull-right"
                                onClick={this.handleCancel}

                              >
                                Cancel
                                </button>
                            </a>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </section>



          </React.Fragment>
        )}
      />
    );
  }
}

export default AddProduct;
