export const FETCH_STATUS_COUNT_ACTION_PENDING = 'FETCH_STATUS_COUNT_ACTION_PENDING';
//export const FETCH_STATUS_COUNT_ACTION_SUCCESS = 'FETCH_STATUS_COUNT_ACTION_SUCCESS';
export const FETCH_STATUS_COUNT_ACTION_ERROR = 'FETCH_STATUS_COUNT_ACTION_ERROR';
export const FETCH_STATUS_COUNT_LIST_ACTION_SUCCESS = 'FETCH_STATUS_COUNT_LIST_ACTION_SUCCESS';

export function fetchStatusCountPending() {
    return {
        type: FETCH_STATUS_COUNT_ACTION_PENDING,
    }
}


export function fetchStatusCountError(error) {
    return {
        type: FETCH_STATUS_COUNT_ACTION_ERROR,
        error: error
    }
}



export function fetchStatusCountListSuccess(Data) {
    return {
        type: FETCH_STATUS_COUNT_LIST_ACTION_SUCCESS,
        payload: Data
    }
}