import React from "react";
import Finxfooter from "../../../containers/finxfooter/finxfooter";
import DashboardHeader from "../../dashboardheader/dashboardheader";
import axios from "axios";
import { promises } from "dns";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoginService } from "../../../services/login.service";
//import { logout } from "../../../services/logout.service";

import PasswordStrengthMeter from "./passwordStrengthMeter";
import {
  success,
  successNotification,
  error,
  errorNotification,
  warning,
  warningNotification,
} from "../../notification/notifications";
import { PopUpPortal } from "../../../containers/popups/PopUpPortal";
import AdminHeader from "../../dashboardheader/adminheader";
import $ from "jquery";

class changepassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userLoginName: "",
      userOldPassword: "",
      emailId: "",
      userPassword: "",
      userId: "",
      token: "",
      isPasswordChanged: false,
      loading: false,
      forcePasswordChangeOnNextLoginFlag: this.props.location.state.forcePasswordChangeOnNextLoginFlag,
      roleName: localStorage.getItem("roleName"),
    };
  }
  handleCancelClick = () => {
    if (this.state.forcePasswordChangeOnNextLoginFlag !== '1') {
      //this.props.history.goBack("-1")
      window.location.replace('/learnerDashboard');
    } else {
      warning("Please update Password before proceeding", warningNotification);
    }
  };

  componentDidMount() {
    if (localStorage.getItem("reload") === "true") {
      localStorage.setItem("reload", "false");
      window.location.reload();
    }
  }

  render() {
    return (
      <Formik
        initialValues={{
          userId: "",
          userLoginName: "",
          emailId: "",
          userPassword: "",
          confirmPassword: "",
          userOldPassword: "",
        }}
        validationSchema={Yup.object().shape({
          userOldPassword: Yup.string()
            // .matches(
            //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{5,})(^\S*$)/,
            //   "Please enter password as per password policy"
            // )
            .required("You must specify a Current Password"),
          //.notoneOf([Yup.ref("userPassword")], "Password should not be same as New Password"),
          userPassword: Yup.string()
            .required("You must specify a New Password")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{5,})(^\S*$)/,
              "Please enter password as per password policy"
            ),
          confirmPassword: Yup.string()
            .required("You must specify a Confirm Password")
            .oneOf([Yup.ref("userPassword")], "Password does not match"),
          //.notoneOf([Yup.ref("userOldPassword")], "Password should not be same as Old Password"),
        })}
        onSubmit={(fields) => {
          debugger
          if ((((this.props || "").location || "").state || "").fromAdminChangePassword === true) {
            this.setState({ loading: true });
            var userPassword = fields["userPassword"];
            var userOldPassword = fields["userOldPassword"];
            var data2 = localStorage.login
            var data = {
              userOldPassword: fields.userOldPassword,
              userPassword: fields.userPassword,
              userLoginName: this.props.location.state.userLoginName,
              emailId: this.props.location.state.emailId,
              userId: this.props.location.state.userId,
              name: this.props.location.state.name,
              partyId: JSON.parse(localStorage.login).partyId
            };
          } else {
            this.setState({ loading: true });
            userPassword = fields["userPassword"];
            userOldPassword = fields["userOldPassword"];
            if (localStorage.getItem('roleName') === "CIEL OPS" && localStorage.getItem('login') !== null) {
              data = {
                userOldPassword: userOldPassword,
                userPassword: userPassword,
                userLoginName: JSON.parse(localStorage.getItem("login"))[
                  "userName"
                ],
                emailId: JSON.parse(localStorage.getItem("login"))["emailId"],
                userId: JSON.parse(localStorage.getItem("login"))["userId"],
                name: JSON.parse(localStorage.getItem("login"))["name"],
                partyId: JSON.parse(localStorage.login).partyId
              };
            } else {
              var data1 = localStorage.getItem("PersonnelData")
              data = {
                userOldPassword: userOldPassword,
                userPassword: userPassword,
                userLoginName: JSON.parse(data1).responseObject.emailID,
                emailId: JSON.parse(data1).responseObject.emailID,
                userId: JSON.parse(localStorage.getItem("login"))["userId"],
                name: JSON.parse(localStorage.getItem("login"))["name"],
                partyId: JSON.parse(localStorage.login).partyId
              };
            }
          }

          if ((((this.props || "").location || "").state || "").fromAdminChangePassword === true) {
            LoginService.ChangePassword(data, (cb) => {
              if (cb.status === "success") {
                this.setState({ isPasswordChanged: true });
                if (this.state.isPasswordChanged) {
                  this.setState({ loading: false });
                  success(
                    "Password changed successfully",
                    successNotification
                  );
                  setTimeout(() => {
                    this.props.history.push("/admin-user-list");
                  }, 2000);
                }
              } else {
                this.setState({ loading: false });
                warning(cb.reasonText, warningNotification);
              }
            });
          } else {
            LoginService.ChangePassword(data, (cb) => {
              if (cb.status === "success") {

                this.setState({ isPasswordChanged: true });
                if (this.state.isPasswordChanged) {
                  // var data = {
                  //   userName: JSON.parse(localStorage.getItem("login"))[
                  //     "userName"
                  //   ],
                  //   userId: JSON.parse(localStorage.getItem("login"))["userId"],
                  // };
                  var logoutdata ={}
                  if (localStorage.getItem('roleName') === "CIEL OPS" && localStorage.getItem('login') !== null) {
                     data = {
                      userName: JSON.parse(localStorage.getItem("login"))[
                        "userName"
                      ],
                      userId: JSON.parse(localStorage.getItem("login"))["userId"],
                    };
                  } else {
                    var data1 = localStorage.getItem("PersonnelData")
                    logoutdata = {
                      userName: JSON.parse(data1).responseObject.emailID,
                      userId: JSON.parse(localStorage.getItem("login"))["userId"],

                    };
                  }
                  LoginService.LogoutUser(logoutdata, false, (res) => {
                    if (res.status === "success") {
                      this.setState({ loading: false });
                      let subscribe = localStorage.getItem('nwesLetterEmailId')
                      localStorage.clear();
                      // if (subscribe) {
                      //   localStorage.setItem('nwesLetterEmailId', 'SUBSCRIBED')
                      // }
                      //  logout.setSubscriber();
                      success(
                        "Password changed successfully. Please login with the new password.",
                        //  "Password changed successfully",
                        successNotification
                      );
                      setTimeout(() => {
                        this.props.history.push("/");
                      }, 5000);
                    } else {
                      this.setState({ loading: false });
                      warning(res.reasonText, warningNotification);
                    }
                  });
                }
              } else {
                this.setState({ loading: false });
                warning(cb.reasonText, warningNotification);
              }
            });
          }


        }}
        render={({ errors, touched, setFieldValue, values, handleChange }) => (
          <React.Fragment>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}

            <Form className="form_name">
              <section className="change-password pt-30 mb-120">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 mt-30"></div>
                    <div className="col-md-5 col-lg-5 col-sm-6 col-xs-12 mt-30">
                      <div className="change-pass">
                        <div className="lock-img">
                          <i className="fa fa-lock" aria-hidden="true"></i>
                        </div>
                        <h2>Change Password</h2>
                        <div className="bottom-line"></div>
                        <div className="pass-form">
                          <div>
                            <label>CURRENT PASSWORD</label>
                            <Field
                              type="password"
                              name="userOldPassword"
                              autoComplete="off"
                              //onChange={handleChange}
                              placeholder="Enter Current Password"
                              className="form-control"
                            />
                            <span className="icon">
                              {values.userOldPassword && !errors.userOldPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-check-circle green"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </span>
                              ) : touched.userOldPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-times-circle red"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                    ""
                                  )}
                            </span>
                            <ErrorMessage
                              name="userOldPassword"
                              component="div"
                              className="validation"
                            />
                          </div>
                          <div>
                            <label>NEW PASSWORD</label>
                            <Field
                              type="password"
                              name="userPassword"
                              autoComplete="off"
                              onChange={handleChange}
                              placeholder="Enter New Password"
                              className="form-control"
                            />

                            <span className="icon">
                              {values.userPassword && !errors.userPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-check-circle green"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </span>
                              ) : touched.userPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-times-circle red"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                    ""
                                  )}
                            </span>
                            <ErrorMessage
                              name="userPassword"
                              component="div"
                              className="validation"
                            />
                            <span class="file-size-limit">
                              Your password must contain at least 5 characters and must include 1
                              uppercase alphabet, 1 lowercase alphabet, 1 number and 1 special character
                               and no space </span>
                          </div>
                          <PasswordStrengthMeter
                            password={values.userPassword}
                          />
                          <div className="">
                            <label>CONFIRM PASSWORD</label>
                            <Field
                              type="password"
                              name="confirmPassword"
                              autoComplete="off"
                              id="confirmPassword"
                              placeholder="Enter Confirm New Password"
                              className="form-control icon"
                            />
                            <span className="icon">
                              {values.confirmPassword &&
                                !errors.confirmPassword ? (
                                  <span className="">
                                    {" "}
                                    <i
                                      className="fa fa-check-circle green"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </span>
                                ) : touched.confirmPassword ? (
                                  <span className="">
                                    {" "}
                                    <i
                                      className="fa fa-times-circle red"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                ) : (
                                    ""
                                  )}
                            </span>
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="validation"
                            />
                            {/* <span class="file-size-limit">
                              Your password must contain at least 5 characters and must include 1
                              uppercase alphabet, 1 lowercase alphabet, 1 number and 1 special character
                               and no space </span> */}
                          </div>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-md-10 col-lg-10 col-sm-12 col-xs-12 col-md-offset-2">

                              <button
                                type="button"
                                className="btn-5 mt-40 mr-20"
                                onClick={() =>
                                  this.handleCancelClick()
                                }
                              >
                                Cancel
                              </button>

                              <button type="Submit" className="btn-5 mt-40">
                                Submit
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div></div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 mt-30">
                      <div class="alert alert-info">
                        <b>Password Policy:</b>
                        <p>
                          {" "}
                          Atleast 1 character should be Capital ['A-Z']
                          {values.userPassword.match(/^(?=.*[A-Z])/) ? (
                            <span className="">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                              <span className="">
                                {" "}
                                <i
                                  className="fa fa-times-circle red"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                        </p>

                        <p>
                          Atleast 1 character should be Small ['a-z']
                          {values.userPassword.match(/^(?=.*[a-z])/) ? (
                            <span className="">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                              <span className="">
                                {" "}
                                <i
                                  className="fa fa-times-circle red"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}{" "}
                        </p>

                        <p>
                          Atleast 1 character should be Numeric ['0-9']
                          {values.userPassword.match(/(?=.*[0-9])/) ? (
                            <span className="">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                              <span className="">
                                {" "}
                                <i
                                  className="fa fa-times-circle red"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                        </p>

                        <p>
                          Atleast 1 character should be Special [@#$%&*]
                          {values.userPassword.match(/(?=.*[!@#\$%\^&\*])/) ? (
                            <span className="">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                              <span className="">
                                {" "}
                                <i
                                  className="fa fa-times-circle red"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                        </p>

                        <p>
                          Atleast 5 character should be there
                          {values.userPassword.match(/(?=.{5,})/) ? (
                            <span className="">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                              <span className="">
                                {" "}
                                <i
                                  className="fa fa-times-circle red"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                        </p>
                        <p>
                          No Space is allowed [ ]
                          {values.userPassword.match(/(^\S*$)/) ? (
                            <span className="">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                              <span className="">
                                {" "}
                                <i
                                  className="fa fa-times-circle red"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
            <Finxfooter />
          </React.Fragment>
        )}
      />
    );
  }
}

export default changepassword;
