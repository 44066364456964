export const FETCH_LEARNER_PROGRESS_OVERVIEW =
  "FETCH_LEARNER_PROGRESS_OVERVIEW";
export const FETCH_LEARNER_PROGRESS_OVERVIEW_PENDING =
  "FETCH_LEARNER_PROGRESS_OVERVIEW_PENDING";
export const FETCH_LEARNER_PROGRESS_OVERVIEW_ERROR =
  "FETCH_LEARNER_PROGRESS_OVERVIEW_ERROR";

export function fetchLearnerProgressOverviewPending() {
  return {
    type: FETCH_LEARNER_PROGRESS_OVERVIEW_PENDING
  };
}

export function fetchLearnerProgressOverviewSuccess(learnerProgressOverview) {
  return {
    type: FETCH_LEARNER_PROGRESS_OVERVIEW,
    payload: learnerProgressOverview
  };
}

export function fetchLearnerProgressOverviewError(error) {
  return {
    type: FETCH_LEARNER_PROGRESS_OVERVIEW_ERROR,
    error: error
  };
}
