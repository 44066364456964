
import React from 'react';
import ErrorImg from '../../assets/images/error.png';

class BrowserErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        //localStorage.clear();
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <React.Fragment>
                <div class="container">
                    <div id="notfound">
                        <div class="notfound">
                            <div class="notfound-404">
                                <img src={ErrorImg} class="img-responsive center-block" width="200px" height="auto" />
                            </div>
                            <h2>Browser Not Supported !!</h2>
                            <p>Please Check Below Supported Browser...</p>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Browser/Device</th>
                                        {/* <th scope="col">Device</th> */}
                                        <th scope="col">Version</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {/* <td>Ipad</td> */}
                                        <td> ipad mini</td>
                                        <td>12 and above</td>
                                    </tr>
                                    <tr>
                                        {/* <td>Iphone</td> */}
                                        <td> iphone 6s</td>
                                        <td>11 and above</td>
                                    </tr>
                                    <tr>
                                        {/* <td>Mac os</td> */}
                                        <td> Safari</td>
                                        <td>Sierra 10.1 and above</td>
                                    </tr>
                                    <tr>
                                        <td>Chrome</td>
                                        {/* <td> All</td> */}
                                        <td>55 and above</td>
                                    </tr>
                                    <tr>
                                        <td>Firefox</td>
                                        {/* <td> All</td> */}
                                        <td>50 and above</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* <button type="button" class="btn-1 mt-30">
                                <a href="/">Go To Homepage</a></button> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }
        return this.props.children;
    }
}

export default BrowserErrorBoundary;
