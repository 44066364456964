export const FETCH_DISTRIBUTOR_LIST_ACTION_PENDING = 'FETCH_DISTRIBUTOR_LIST_ACTION_PENDING';
export const FETCH_DISTRIBUTOR_LIST_ACTION_SUCCESS = 'FETCH_DISTRIBUTOR_LIST_ACTION_SUCCESS';
export const FETCH_DISTRIBUTOR_LIST_ACTION_ERROR = 'FETCH_DISTRIBUTOR_LIST_ACTION_ERROR';

export function fetchDistributorListPending() {
    return {
        type: FETCH_DISTRIBUTOR_LIST_ACTION_PENDING,
    }
}

export function fetchDistributorListSuccess(distributorList) {

    return {
        type: FETCH_DISTRIBUTOR_LIST_ACTION_SUCCESS,
        payload: distributorList
    }
}

export function fetchDistributorListError(error) {
    return {
        type: FETCH_DISTRIBUTOR_LIST_ACTION_ERROR,
        error: error
    }
}