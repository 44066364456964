import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getProductPreRequisite, getProductPreRequisitePending, getProductPreRequisiteError } from "../../reducer/productPreRequisiteReducer";
import { fetchProductPreRequisiteByParentProductId, fetchProductPreRequisiteByParentProduct, fetchProductDetailsByPartyId } from "../../services/fetchLearnerDashboard.service";
import { LoginService } from "../../services/login.service";
import { CourserAndModuleService } from "../../services/courseAndModule.service";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { error, errorNotification } from "../notification/notifications";

class MyCouseModule extends React.Component {
    constructor(props) {
        super(props);
        //console.log("my Course", props);
        this.state = {
            courseandmodule: []

        };
    }
    componentDidMount() {
        LoginService.TokenUser(res => {
            localStorage.setItem("jwt-token", res.responseObject);
            if (this.props.currentproduct) {
                let productId = this.props.currentproduct.productEntity ? this.props.currentproduct.productEntity.productID : this.props.currentproduct.productID;

                let learnerProductID = "productID=" + productId + "&learnerPartyID=" + localStorage.getItem('userid-Token');
                CourserAndModuleService.fetchCourseModuleDetailsByPartyID(learnerProductID, res => {
                    //console.log("My course Details ", res.responseListObject);

                    if (res.status === 'success') {
                        if (res.reasonCode !== 'SM-20') {
                            let CourseModulelist = res.responseListObject;
                            LoginService.TokenUser(response => {
                                localStorage.setItem("jwt-token", response.responseObject);
                                let learnerProductID = "productID=" + productId;
                                CourserAndModuleService.fetchModuleDetailsByProductID(learnerProductID, response => {
                                    if (response.status === 'success') {
                                        //console.log("My course Name Details ", response.responseListObject);
                                        let coursemod = [];
                                        CourseModulelist.map(item => {
                                            response.responseListObject.map(itm => {
                                                if (item.product_module_id === itm.productModuleID) {
                                                    let coursemodulepojo = {
                                                        assessment_module_id: item.assessment_module_id,
                                                        learnerPartyId: item.learnerPartyId,
                                                        productId: item.productId,
                                                        assessment_id: item.assessment_id,
                                                        product_module_id: item.product_module_id,
                                                        is_personality_test: item.is_personality_test === undefined ? "False" : "True",
                                                        is_typing_test: item.is_typing_test === undefined ? "False" : "True",
                                                        module_start_date: item.module_start_date === undefined ? "" : item.module_start_date,
                                                        module_end_date: item.module_end_date === undefined ? "" : item.module_end_date,
                                                        moduleName: itm.moduleName,
                                                        moduleDescription: itm.moduleDescription,
                                                        moduleCode: itm.moduleCode
                                                    }
                                                    coursemod.push(coursemodulepojo);
                                                }
                                            });
                                        });
                                        this.setState({ courseandmodule: coursemod });
                                    } else if (response.status === 'fail') {
                                        this.setState({ loading: false });
                                        if (response.reasonCode === 'SM_11') {
                                            window.location.href = '/';
                                        } else {
                                            error(res.reasonText, errorNotification);
                                        }

                                    }
                                });
                            });
                        }
                    } else if (res.status === 'fail') {
                        this.setState({ loading: false });
                        if (res.reasonCode === 'SM_11') {
                            window.location.href = '/';
                        } else {
                            error(res.reasonText, errorNotification);
                        }

                    }
                });
            }
        });

    }
    filterCaseInsensitive = ({ id, value }, row) => {
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(value.toLowerCase()) : true
      }

    render() {
        return (

            <React.Fragment>
                {this.state.courseandmodule ?
                    <div className="row">
                        <div className="qc">
                            <ReactTable
                                data={this.state.courseandmodule}
                                className="-striped -highlight"
                                defaultFilterMethod={this.filterCaseInsensitive}
                                columns={[
                                    {
                                        Header: "Module Name",
                                        id: "moduleName",
                                        accessor: d => d.moduleName
                                    },
                                    {
                                        Header: "Personality Test",
                                        id: "ispersonalitytest",
                                        accessor: d => d.is_personality_test
                                    },
                                    {
                                        Header: "Typing Test",
                                        id: "is_typing_test",
                                        accessor: d => d.is_typing_test
                                    },
                                    {
                                        Header: "Module Start Date",
                                        id: "module_start_date",
                                        accessor: d => d.module_start_date
                                    },
                                    {
                                        Header: "Module End Date",
                                        id: "module_end_date",
                                        accessor: d => d.module_end_date
                                    }
                                ]}
                                defaultPageSize={5}
                                style={{
                                    width: "80%",
                                    height: "auto",
                                    background: 'white',
                                    color: 'black'
                                }}
                            />
                        </div>
                    </div>
                    : ""}
            </React.Fragment>
        );
    }
}
//);
export default MyCouseModule;
